import React, { useEffect, useState, useCallback } from "react";
import { Jobs } from "../services/job";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllJobCompany, setJobPage } from "../redux/company";

const job = new Jobs();

export default function useJob({
  all = true,
  id = null,
  instant = false,
  companyPage = false,
  getAll = false,
}) {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false); // Nouvel état
  const { latitude, longitude } = useSelector(
    (state) => state.GeolocationSlice
  );
  const { loading: appLoading } = useSelector(
    (state) => state.ApplicationSlice
  );
  const { item } = useSelector((state) => state.DetailSlice);
  const { q, hr, r, urgTod, creatAt, starsAsc, distAsc } = useSelector(
    (state) => state.FilterSlice
  );

  const initialize = useCallback(
    (value) => {
      if (all && value.length === 0) {
        setHasMore(false);
      } else {
        setData(value);
      }
    },
    [all]
  );

  const fetchData = useCallback(async () => {
    setLoading(true); // Début du chargement
    try {
      await job
        .getJobs({
          lat: latitude,
          lng: longitude,
          distAsc: !instant ? distAsc : true,
          starsAsc: starsAsc,
          creatAt: creatAt,
          hr: !instant ? hr : null,
          r: !instant ? r : null,
          q: !instant ? q : null,
          urgTod: urgTod,
          limit: instant ? 15 : null,
          companyId: companyPage && item ? item.id : null,
          getAll: getAll,
        })
        .then(initialize);
    } catch (err) {
      setError("An error occurred while fetching data.");
    }
    setLoading(false); // Fin du chargement
  }, [
    latitude,
    longitude,
    q,
    hr,
    r,
    urgTod,
    creatAt,
    starsAsc,
    distAsc,
    appLoading,
    instant,
    companyPage,
    item,
    initialize,
  ]);

  useEffect(() => {
    if (all || companyPage) {
      fetchData();
    } else {
      job.getOneJob(id).then(initialize);
    }

    return () => setData([]);
  }, [all, companyPage, fetchData, initialize, id]);

  return { data, hasMore, error, fetchData, loading }; // Retourne l'état de chargement
}

export function useJobCompany() {
  const dispatch = useDispatch();
  const { job_page, job_limit, job_search } = useSelector(
    (state) => state.companySlice
  );

  const fetchData = useCallback(() => {
    dispatch(fetchAllJobCompany());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setJobPage(1));
  }, [job_search, job_limit]);

  useEffect(() => {
    fetchData();
  }, [fetchData, job_page, job_limit, job_search]);
}
