import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompany } from "../redux/company";
import socket from "../redux/socket";











export default function useCompany(layoutCompany = true) {
  const { company, load } = useSelector((state) => state.companySlice);
  const { id } = useSelector((state) => state.authenticationSlice);
  const dispatch = useDispatch();

  const fetchData = useCallback(() => {
    dispatch(fetchCompany());
  }, [dispatch, load]);

  const sendToReactNative = useCallback(() => {
    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          token: localStorage.getItem("userToken"),
        })
      );
  }, [dispatch]);

  useEffect(() => {
    if (layoutCompany) {
      fetchData();
    }
  }, [fetchData]);

  useEffect(() => {
    if (layoutCompany) {
      sendToReactNative();
      setTimeout(() => {
        socket.emit("message", {
          targetUserID: id,
          type: "ask-push-notification",
          message: {
            content: "gimme-push",
            data: {
              id: id,
            },
          },
        });
      }, 20000);
    }
  }, []);

  return { company: company, onLoad: load };
}
