import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Stars from "../../../components/rating/Stars";
import useCandidate from "../../../hook/candidate";
import moment from "moment";

function Schedule({ events }) {
  const { open, availability, startTime, endTime } = useSelector(
    (state) => state.CalendarSlice
  );
  const dispatch = useDispatch();

  const timeSlotDuration = 30; // Duration of each time slot in minutes

  // Calculate the total number of time slots
  const totalSlots = Math.floor(
    (endTime - startTime) / (timeSlotDuration * 60 * 1000)
  );

  const calculateGridRow = (eventStartTime) => {
    const minutesDiff = Math.floor(
      (new Date(eventStartTime) - startTime) / (60 * 1000)
    );

    const slotCount = Math.floor(minutesDiff / timeSlotDuration);
    return slotCount + 2;
  };

  const filteredEvents = events.filter(
    (event) =>
      new Date(event.startTime).getTime() >= startTime.getTime() &&
      new Date(event.startTime).getTime() <= endTime.getTime()
  );

  const groupedEventsByDate = filteredEvents.reduce((groupedEvents, event) => {
    const eventDate = new Date(event.startTime).toDateString();
    if (!groupedEvents[eventDate]) {
      groupedEvents[eventDate] = [];
    }
    groupedEvents[eventDate].push(event);
    return groupedEvents;
  }, {});

  const scheduleItems = Object.keys(groupedEventsByDate).map((date) => {
    const eventsOnThisDay = groupedEventsByDate[date];
    const gridRowStart = calculateGridRow(eventsOnThisDay[0].startTime);
    const gridRowEnd =
      gridRowStart + Math.ceil(eventsOnThisDay[0].duration / timeSlotDuration);
    return (
      <li
        key={date}
        className={`relative overflow-scroll md:justify-center border-l-indigo-600 border-l-4  md:overflow-hidden  md:hover:overflow-scroll rounded-lg bg-sky-50 mt-px max-h-fit p-2 flex  flex-row`}
        style={{
          gridRow: `${gridRowStart} / span ${gridRowEnd - gridRowStart}`,
        }}
      >
        <div>
          {eventsOnThisDay.map((event, index) => (
            <EventCard key={index} event={event} index={index} />
          ))}
        </div>
      </li>
    );
  });

  return (
    <ol
      className="col-start-1 col-end-2 row-start-1 grid grid-cols-1"
      style={{
        gridTemplateRows: `1.75rem repeat(${totalSlots}, minmax(0, 1fr)) auto`,
      }}
    >
      {scheduleItems}
    </ol>
  );
}

const EventCard = ({ event, index, key }) => {
  // Add your event card component implementation here...
  return (
    <div key={key}>
      <time
        dateTime={event.startTime}
        className="bg-sky-50 max-w-screen-xs sm:max-w-screen-sm md:max-w-screen-md flex justify-center items-center rounded-lg p-5"
      >
        <div className="card py-2 px-2 shadow-md bg-white flex flex-col w-full border border-l-4 border-l-sky-400  ">
          <div className="flex flex-row">
            <div className="flex-1 flex-col flex justify-start items-start">
              <h1 className=" sm:text-lg lg:text-lg md:text-xl text-indigo-800 leading-8 font-extrabold ">
                {event.title}
              </h1>
              <span className="text-xs to-zinc-400">{event.location}</span>
            </div>

            <div className="flex-1 flex justify-end text-xs sm:text-sm items-center text-indigo-900">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
                />
              </svg>

              <h1 className=" font-semibold text-right">
                {moment(event.startTime).add(10, "minutes").fromNow()}
              </h1>
            </div>
          </div>
          <div className="flex-row flex">
            <div className="flex-1">
              <img
                src={require("../../../assets/img/brands/T.png")}
                className="w-7 h-7"
                alt=""
                srcset=""
              />
            </div>
          </div>
          <hr className="my-2" />
          <div className="h-fit w-full md:hover:overflow-x-auto md:overflow-hidden justify-center overflow-scroll flex flex-nowrap space-x-4  ">
            <span className="inline-flex whitespace-nowrap items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-sky-900">
              Teams
            </span>
            <span className="inline-flex whitespace-nowrap items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-sky-900">
              Capacity
            </span>
            <span className="inline-flex   whitespace-nowrap items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-sky-900">
              Gestion d'equipe
            </span>
            <span className="inline-flex whitespace-nowrap items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-sky-900">
              Teams
            </span>
          </div>
          <hr className="my-2" />
          <div className="flex justify-center flex-wrap  py-2">
            <span className="bg-gray-100 text-gray-800 font-semibold text-xs mb-2 lg:text-sm  md:font-medium inline-flex items-center px-2.5 py-0.5 rounded mr-2 dark:bg-gray-700 dark:text-gray-400 border border-gray-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                />
              </svg>
              &nbsp; {event.startDate}
            </span>
            <span className="bg-blue-100 text-blue-800 text-xs mb-2 lg:text-sm font-semibold md:font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              &nbsp; {`${event.startHour} - ${event.endHour}`}
            </span>
          </div>
        </div>
      </time>
    </div>
  );
};

export default Schedule;
