import { useEffect, useState } from "react";
import { Industry } from "../services/industry";
import { Currency } from "../services/currency";

const currency = new Currency();

export default function useCurrencies() {
  const [datas, setDatas] = useState();

  function initialize(value) {
    setDatas(value);
  }

  useEffect(() => {
    fechData();
  }, []);

  function fechData() {
    currency.getCurrencies().then(initialize);
  }

  return datas;
}
