import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import useCurrentBreakpoint from "../../hook/tailwindBreakPoint";
import { useDispatch, useSelector } from "react-redux";
import {
  setEmail,
  setFirstName,
  setLastName,
  setLocation,
  setPhone,
  setPrefix,
  setRole,
} from "../../redux/registration";
import { setClose } from "../../redux/teams";
import RadioRole from "./radio-group";
import { createSubUser } from "../../redux/users";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CommandTeams() {
  const brk = useCurrentBreakpoint();
  const { open } = useSelector((state) => state.TeamSlice);
  const { pending } = useSelector((state) => state.UserSlice);
  const { firstName, lastName, phone, email, prefix, location } = useSelector(
    (state) => state.RegistrationSlice
  );
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createSubUser());
  };

  return (
    <Transition.Root show={open} as={Fragment} appear>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => dispatch(setClose())}
      >
        <Transition.Child
          as={Fragment}
          enter={(brk !== "sm" || brk !== "xs") && "ease-out duration-300"}
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave={(brk !== "sm" || brk !== "xs") && "ease-in duration-200"}
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed flex justify-center inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto py-20 px-4  md:p-20">
          <Transition.Child
            as={Fragment}
            enter={(brk !== "sm" || brk !== "xs") && "ease-out duration-300"}
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave={(brk !== "sm" || brk !== "xs") && "ease-in duration-200"}
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-2xl  transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <form
                onSubmit={(e) => handleSubmit(e)}
                className="h-auto p-3 relative "
              >
                {pending && (
                  <div
                    role="status"
                    class="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
                  >
                    <svg
                      aria-hidden="true"
                      class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-sky-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span class="sr-only">Loading...</span>
                  </div>
                )}

                <h1 className="text-sm font-semibold">
                  Register a new team member to your company
                </h1>
                <hr className="my-3" />

                {/*     <div
                  className={`grid grid-cols-6 gap-6  ${
                    pending && "opacity-25"
                  }`}
                >
                  <div className=" col-span-3">
                    <label
                      htmlFor="firstName"
                      className="block md:text-sm text-xs font-medium leading-6 text-gray-900"
                    >
                      First name<span className="text-red-400">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        onChange={(e) => dispatch(setFirstName(e.target.value))}
                        name="firstName"
                        required
                        id="firsName"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 md:text-sm text-xs sm:leading-6"
                        placeholder="John"
                      />
                    </div>
                  </div>
                  <div className=" col-span-3">
                    <label
                      htmlFor="lastName"
                      className="block md:text-sm text-xs font-medium leading-6 text-gray-900"
                    >
                      Last name<span className="text-red-400">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="lastName"
                        required
                        id="lastName"
                        onChange={(e) => dispatch(setLastName(e.target.value))}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 md:text-sm text-xs sm:leading-6"
                        placeholder="Smith"
                      />
                    </div>
                  </div>
                  <div className=" col-span-3">
                    <label
                      htmlFor="email"
                      className="block md:text-sm text-xs font-medium leading-6 text-gray-900"
                    >
                      Email<span className="text-red-400">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="email"
                        name="email"
                        required
                        id="email"
                        onChange={(e) => dispatch(setEmail(e.target.value))}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 md:text-sm text-xs sm:leading-6"
                        placeholder="you@example.com"
                      />
                    </div>
                  </div>
                  <div className=" col-span-full">
                    <label
                      htmlFor="role"
                      className="block md:text-sm text-xs font-medium leading-6 text-gray-900"
                    >
                      Role<span className="text-red-400">*</span>
                    </label>

                    <select
                      id="role"
                      name="role"
                      required
                      onChange={(e) => dispatch(setRole(e.target.value))}
                      className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-sky-600 md:text-sm text-xs sm:leading-6"
                    >
                      <option disabled>User role</option>
                      <option value={"ADMIN"}>Admin</option>
                      <option value={"MEMBER"}>Member</option>
                    </select>
                  </div>
                </div> */}

                <div class="mt-5">
                  <div
                    onSubmit={handleSubmit}
                    className="gap-y-4 gap-x-3 grid grid-cols-6 "
                  >
                    <div className="col-span-3">
                      <label
                        htmlFor="firstName"
                        className="block text-xs xs:text-sm font-medium leading-6 text-gray-900"
                      >
                        First name <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-2">
                        <input
                          id="firstName"
                          name="firstName"
                          value={firstName}
                          onChange={(e) => dispatch(setFirstName(e))}
                          type="text"
                          autoComplete="first-name"
                          required
                          className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600  text-xs xs:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="col-span-3">
                      <label
                        htmlFor="lastName"
                        className="block text-xs xs:text-sm font-medium leading-6 text-gray-900"
                      >
                        Last name <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-2">
                        <input
                          id="lastName"
                          name="lastName"
                          value={lastName}
                          type="text"
                          onChange={(e) => dispatch(setLastName(e))}
                          autoComplete="last-name"
                          required
                          className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600  text-xs xs:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="col-span-full">
                      <label
                        htmlFor="phone"
                        className="block text-xs xs:text-sm font-medium leading-6 text-gray-900"
                      >
                        Phone
                      </label>
                      <div className="mt-2 relative">
                        <div className="absolute inset-y-0 left-0 flex items-center">
                          <label htmlFor="prefix" className="sr-only">
                            Prefix
                          </label>
                          <select
                            id="prefix"
                            name="phone.prefix"
                            onChange={(e) => dispatch(setPrefix(e))}
                            value={prefix}
                            className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-sky-600 md:text-sm text-xs"
                          >
                            <option value={"+44"}>UK (+44)</option>
                            <option value={"+966"}>KSA (+966)</option>
                            <option value={"+971"}>UAE (+971)</option>
                          </select>
                        </div>
                        <input
                          id="phone"
                          name="phone"
                          value={phone}
                          type="tel"
                          onChange={(e) => dispatch(setPhone(e))}
                          autoComplete="phone-number"
                          required
                          className="block w-full pl-32 rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600  text-xs xs:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="col-span-full">
                      <label
                        htmlFor="email"
                        className="block text-xs xs:text-sm font-medium leading-6 text-gray-900"
                      >
                        Email address <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-2">
                        <input
                          id="email"
                          name="email"
                          value={email}
                          type="email"
                          onChange={(e) => dispatch(setEmail(e))}
                          autoComplete="email"
                          required
                          className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600  text-xs xs:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="col-span-full">
                      <RadioRole />

                      {/*  <select
                        id="location"
                        name="location"
                        onChange={(e) => dispatch(setRole(e))}
                        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        defaultValue="Canada"
                      >
                        <option value={"MANAGER"}>MANAGER</option>
                        <option value={"MEMBER"}>MEMBER</option>
                      </select> */}
                    </div>

                    <div className="col-span-full my-5">
                      <div className="relative">
                        <div
                          className="absolute inset-0 flex items-center"
                          aria-hidden="true"
                        >
                          <div className="w-full border-t border-gray-300" />
                        </div>
                        <div className="relative flex justify-center">
                          <span className="bg-white px-2 text-sm text-gray-500">
                            Extra data
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-full">
                      <label
                        htmlFor="address"
                        className="block text-xs xs:text-sm font-medium leading-6 text-gray-900"
                      >
                        Location
                      </label>
                      <div className="mt-2">
                        <input
                          id="address"
                          name="address"
                          value={location}
                          type="text"
                          onChange={(e) =>
                            dispatch(setLocation(e.currentTarget.value))
                          }
                          autoComplete="address"
                          required
                          className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600  text-xs xs:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="my-6 flex justify-center space-x-4 items-center">
                  <button
                    type="button"
                    onClick={() => dispatch(setClose())}
                    className="rounded-md bg-red-50 px-3.5 py-2.5 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-100"
                  >
                    Cancel
                  </button>

                  <button
                    type="submit"
                    className="rounded-md disabled:bg-gray-400 disabled:text-white bg-sky-50 px-3.5 py-2.5 text-sm font-semibold text-sky-600 shadow-sm hover:bg-sky-100"
                  >
                    Add new member
                  </button>
                </div>
              </form>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
