import React from "react";
import Map from "../Dashboard/Map";


function CandidatePage() {
  return (
    <div className="grid h-[calc(100vh-4rem)] bg-slate-300 w-full">
      <Map />
    </div>
  );
}

export default CandidatePage;
