import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useSelector } from "react-redux";

export default function Toast() {
  const { show } = useSelector((state) => state.ToastSlice);

  return (
    <div
      className={`rounded-md bg-green-50 p-4 ${
        !show && "hidden"
      } fixed bottom-4 right-8 z-[100000] `}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            className="h-5 w-5 text-green-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-green-800">
            Sent with success
          </p>
        </div>
      </div>
    </div>
  );
}
