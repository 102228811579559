import { createSlice } from "@reduxjs/toolkit";

export const FilterSlice = createSlice({
  name: "FilterSlice",
  initialState: {
    q: null,
    hr: 20,
    r: 10,
    urgTod: false,
    creatAt: false,
    starsAsc: false,
    distAsc: false,
    limit: null,
    filterOpen: false,
  },
  reducers: {
    setQuery: (state, action) => {
      state.q = action.payload;
    },
    setHr: (state, action) => {
      state.hr = action.payload;
    },
    setR: (state, action) => {
      state.r = action.payload;
    },
    setUrgTod: (state, action) => {
      state.urgTod = action.payload;
    },
    setCreatAt: (state, action) => {
      state.creatAt = action.payload;
    },
    setStarsAsc: (state, action) => {
      state.starsAsc = action.payload;
    },
    setDistAsc: (state, action) => {
      state.distAsc = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setFilterOpen: (state, action) => {
      state.filterOpen = action.payload;
    },
  },
});
