import React from 'react'

function Finance() {
    return (
        <div>
            Finance
        </div>
    )
}

export default Finance
