import { useId } from "react";
import { FadeIn } from "../components/utils/FadeIn";
import { Button } from "../components/utils/Button";
import { Offices } from "../components/utils/Offices";
import { Border } from "../components/utils/Border";
import { SocialMedia } from "../components/utils/SocialMedia";
import { PageIntro } from "../components/utils/PageIntro";
import { Container } from "../components/utils/Container";
import { useDispatch, useSelector } from "react-redux";
import {
  sendEmailContact,
  setDescription,
  setEmail,
  setFirstName,
  setLastName,
  setPhone,
} from "../redux/registration";

function TextInput({ label, ...props }) {
  let id = useId();

  return (
    <div className="group relative z-0 transition-all focus-within:z-10">
      <input
        type="text"
        id={id}
        {...props}
        placeholder=" "
        className="peer block w-full border border-neutral-300 bg-transparent px-6 pb-4 pt-12 text-base/6 text-neutral-950 ring-4 ring-transparent transition focus:border-sky-500 focus:outline-none focus:ring-sky-600/5 group-first:rounded-t-2xl group-last:rounded-b-2xl"
      />
      <label
        htmlFor={id}
        className="pointer-events-none absolute left-6 top-1/2 -mt-3 origin-left text-base/6 text-neutral-500 transition-all duration-200 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:font-semibold peer-focus:text-neutral-950 peer-[:not(:placeholder-shown)]:-translate-y-4 peer-[:not(:placeholder-shown)]:scale-75 peer-[:not(:placeholder-shown)]:font-semibold peer-[:not(:placeholder-shown)]:text-neutral-950"
      >
        {label}
      </label>
    </div>
  );
}

function RadioInput({ label, ...props }) {
  return (
    <label className="flex gap-x-3">
      <input
        type="radio"
        {...props}
        className="h-6 w-6 flex-none appearance-none rounded-full border border-sky-600/20 outline-none checked:border-[0.5rem] checked:border-sky-700 focus-visible:ring-1 focus-visible:ring-sky-600 focus-visible:ring-offset-2"
      />
      <span className="text-base/6 text-neutral-950">{label}</span>
    </label>
  );
}

function ContactForm() {
  const { firstName, lastName, email, phone, description, PendingeSent } =
    useSelector((state) => state.RegistrationSlice);
  const dispatch = useDispatch();

  function sendInquiry(e) {
    e.preventDefault();
    dispatch(sendEmailContact());
  }

  return (
    <FadeIn className="lg:order-last">
      <form onSubmit={sendInquiry}>
        <h2 className="font-display text-base font-semibold text-neutral-950">
          Work inquiries
        </h2>
        <div className="isolate mt-6 -space-y-px rounded-2xl bg-white/50">
          <TextInput
            label="First name"
            name="firstName"
            required
            onChange={(e) => dispatch(setFirstName(e))}
            value={firstName}
            autoComplete="first-name"
          />
          <TextInput
            label="Last name"
            name="lastName"
            required
            onChange={(e) => dispatch(setLastName(e))}
            value={lastName}
            autoComplete="last-name"
          />
          <TextInput
            label="Email"
            type="email"
            name="email"
            required
            onChange={(e) => dispatch(setEmail(e))}
            value={email}
            autoComplete="email"
          />

          <TextInput
            label="Phone"
            type="tel"
            name="phone"
            required
            autoComplete="tel"
            onChange={(e) => dispatch(setPhone(e))}
            value={phone}
          />
          <TextInput
            label="Message"
            required
            onChange={(e) => dispatch(setDescription(e))}
            value={description}
            name="message"
          />
        </div>
        <Button type="submit" className="mt-10">
          Let’s work together
        </Button>
      </form>
    </FadeIn>
  );
}

function ContactDetails() {
  return (
    <FadeIn>
      <h2 className="font-display text-base font-semibold text-neutral-950">
        Our offices
      </h2>
      <p className="mt-6 text-base text-neutral-600">
        Prefer doing things in person? We don’t but we have to list our
        addresses here for legal reasons.
      </p>

      <Offices className="mt-10 grid grid-cols-1 gap-8 sm:grid-cols-2" />

      <Border className="mt-16 pt-16">
        <h2 className="font-display text-base font-semibold text-neutral-950">
          Email us
        </h2>
        <dl className="mt-6 grid grid-cols-1 gap-8 text-sm sm:grid-cols-2">
          {[
            ["Info", "info@tempme.co"],
            ["support", "help@tempme.co"],
          ].map(([label, email]) => (
            <div key={email}>
              <dt className="font-semibold text-sky-600">{label}</dt>
              <dd>
                <a
                  href={`mailto:${email}`}
                  className="text-neutral-600 hover:text-neutral-950"
                >
                  {email}
                </a>
              </dd>
            </div>
          ))}
        </dl>
      </Border>

      <Border className="mt-16 pt-16">
        <h2 className="font-display text-base font-semibold text-neutral-950">
          Follow us
        </h2>
        <SocialMedia className="mt-6" />
      </Border>
    </FadeIn>
  );
}

export const metadata = {
  title: "Contact Us",
  description: "Let’s work together. We can’t wait to hear from you.",
};

export default function Contact() {
  return (
    <>
      <PageIntro
        eyebrow="Hit Us Up !"
        title="Let’s work"
        coloredText={"together"}
      >
        <p>We can’t wait to hear from you.</p>
      </PageIntro>

      <Container className="my-24 sm:my-32 lg:my-40 ">
        <div className="grid grid-cols-1 gap-x-8 gap-y-24 lg:grid-cols-2">
          <ContactForm />
          <ContactDetails />
        </div>
      </Container>
    </>
  );
}
