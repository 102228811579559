import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/20/solid";
import useApplication from "../../../hook/application";
import { useDispatch, useSelector } from "react-redux";
import { baseUrl } from "../../../utils";
import { updateApplicationCompany } from "../../../redux/application";
import { useCallback, useState } from "react";
import Application from "../../../Enum/Applicatition";
import moment from "moment";
import Stars from "../../../components/rating/Stars";

export default function Applications() {
  const launch = useApplication();
  const dispatch = useDispatch();
  const { applications, loading } = useSelector(
    (state) => state.ApplicationSlice
  );

  const [answerId, setAnswerId] = useState(null);
  const [time, setTime] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [openNote, setOpenNote] = useState(false);
  const [idNote, setIdNote] = useState(null);

  function handleNote(id) {
    setOpenNote((v) => !v);

    setIdNote(id);
  }

  const launchInterval = useCallback(
    (f, id, USER = null) => {
      if (isRunning) {
        console.warn("Interval is already running!");
        return;
      }

      setIsRunning(true);

      // Nettoyage d'un intervalle précédent, si existant
      if (intervalId) {
        clearInterval(intervalId);
      }

      setAnswerId(id);
      setTime(10);

      const interval = setInterval(() => {
        setTime((prevTime) => {
          if (prevTime === 1) {
            dispatch({
              type: "ApplicationSlice/setUserToSend",
              payload: USER,
            });
            clearInterval(interval);
            setAnswerId(null);
            setTime(null);
            f();
            setIsRunning(false); // Réinitialiser l'état de l'intervalle
          }
          return prevTime - 1;
        });
      }, 1000);

      setIntervalId(interval);
    },
    [isRunning, intervalId]
  );

  const cancelInterval = useCallback(() => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    setAnswerId(null);
    setTime(null);
    setIsRunning(false); // Réinitialiser l'état de l'intervalle
  }, [intervalId]);

  return (
    <div className=" flex-1 flex flex-col pb-3 px-2 max-h-[calc(100vh-4rem)] overflow-hidden">
      <h1 className="text-xl font-semibold text-sky-500">
        Latest applications
      </h1>
      <p className=" text-xs my-1 text-red-700">Chat now to secure your gig</p>
      <hr className="my-2" />
      <div className="flex-1 overflow-hidden   ">
        <ul
          role="list"
          className="grid grid-cols-1 gap-6 overflow-scroll max-h-full px-1 py-2"
        >
          {applications &&
            applications.map((item, index) => (
              <li
                key={index}
                className="col-span-1 divide-y divide-gray-200 rounded-lg ring-1 ring-offset-lime-900 ring-sky-400 bg-white shadow-sm"
              >
                <div className="flex relative w-full items-center justify-between space-x-6 p-6">
                  <span className="absolute  font-medium right-0 me-2 top-0 text-[0.650rem] mt-1">
                    {moment.unix(item.applicationDate).local().fromNow()}
                  </span>
                  <div className="flex-1 truncate">
                    <div className="flex items-center space-x-3">
                      <h3 className="truncate text-sm font-medium text-gray-900">
                        {[
                          item.candidate.firstName,
                          item.candidate.lastName,
                        ].join(" ")}
                      </h3>
                      <svg
                        onClick={() =>
                          dispatch({
                            type: "ApplicationSlice/setCandidateLaunch",
                            payload: item.candidate.id,
                          })
                        }
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 cursor-pointer animate-pulse text-blue-500"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                        />
                      </svg>
                    </div>
                    <p className="my-1 truncate text-xs text-gray-500">
                      {item.job.jobName}
                    </p>
                    <Stars number={item.candidate.stats.totalAverageStars} />
                  </div>
                  <img
                    className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
                    src={item.candidate.candidateProfile.profile.image}
                    alt=""
                  />
                </div>

                <div className="px-5 flex flex-col items-center justify-center ">
                  <a
                    className="inline-flex text-green-500 font-medium text-sm py-3 cursor-pointer"
                    onClick={() => handleNote(item.id)}
                  >
                    Note
                  </a>{" "}
                  {openNote && idNote === item.id && (
                    <span className="inline-flex items-center w rounded-md my-2 bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
                      Covering note : {item.note}
                    </span>
                  )}
                </div>

                <div>
                  {answerId === item.id && (
                    <div className="-mt-px flex divide-x divide-gray-200">
                      <button
                        type="button"
                        className="relative  -mr-px bg-sky-100 hover:bg-red-200 text-sky-500 inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold"
                      >
                        <span className="countdown">
                          <span style={{ "--value": time }}></span>
                        </span>
                      </button>
                      <button
                        onClick={cancelInterval}
                        type="button"
                        className="relative inline-flex w-0 flex-1 bg-orange-100 hover:bg-orange-300 text-orange-500 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold"
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                  {answerId !== item.id && (
                    <div className="-mt-px flex divide-x divide-gray-200">
                      <div className="flex w-0 flex-1">
                        <button
                          onClick={() =>
                            launchInterval(
                              () =>
                                dispatch(
                                  updateApplicationCompany({
                                    id: item.id,
                                    params: {
                                      status: Application.REJECTED,
                                    },
                                  })
                                ),
                              item.id,
                              item.candidate.userId
                            )
                          }
                          className="relative  -mr-px bg-red-100 hover:bg-red-200 text-red-500 inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                          Reject
                        </button>
                      </div>
                      <div className="-ml-px flex w-0 flex-1 ">
                        <button
                          role="button"
                          onClick={() =>
                            launchInterval(
                              () =>
                                dispatch(
                                  updateApplicationCompany({
                                    id: item.id,
                                    params: {
                                      status: Application.ACCEPTED,
                                    },
                                  })
                                ),
                              item.id,
                              item.candidate.userId
                            )
                          }
                          className="relative inline-flex w-0 flex-1 bg-teal-100 hover:bg-teal-300 text-teal-500 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M4.5 12.75l6 6 9-13.5"
                            />
                          </svg>
                          Accept
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}
