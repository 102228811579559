import { FadeIn, FadeInStagger } from "../components/utils/FadeIn";

const faqs = [
  {
    question: "Information Collection",
    answer:
      "We collect information you provide directly to us when you create an account, such as name, email address, and payment information.",
  },
  {
    question: "Use of Information",
    answer:
      "The information collected is used to provide and improve our services, process payments, and communicate with you.",
  },
  {
    question: "Sharing of Information",
    answer:
      "We do not sell or rent personal information to third parties. Information may be shared with third parties only when necessary to provide our services.",
  },
  {
    question: "Data Security",
    answer:
      "We implement a variety of security measures to maintain the safety of your personal information.",
  },
  {
    question: "Your Rights",
    answer:
      "You have the right to access, update, or delete the information we have on you.",
  },
  {
    question: "Changes to This Policy",
    answer:
      "We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page.",
  },
  {
    question: "Contact Us",
    answer:
      "If you have any questions about these Terms or our Privacy Policy, please contact us at info@tempme.co.",
  },
  // More questions...
];

export default function PrivacyPolicy() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:pt-32 lg:px-8 lg:py-40">
        <FadeInStagger className="lg:grid lg:grid-cols-12 lg:gap-8">
          <FadeIn className="lg:col-span-5">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
              Privacy Policy
            </h2>
            <p className="mt-4 text-base leading-7 text-gray-600">
              Your privacy is important to us. This policy explains how we
              collect, use, and protect your personal information.
            </p>
          </FadeIn>
          <div className="mt-10 lg:col-span-7 lg:mt-0">
            <dl className="space-y-10">
              {faqs.map((faq) => (
                <FadeIn key={faq.question}>
                  <dt className="text-base font-semibold leading-7 text-sky-900">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">
                    {faq.answer}
                  </dd>
                </FadeIn>
              ))}
            </dl>
          </div>
        </FadeInStagger>
      </div>
    </div>
  );
}
