import React from "react";
import OurCandidates from "./Canididates";
import Search from "../../../components/search/search";
import AddNewUser from "../../../components/utils/addNewUser";

function Candidates() {
  return (
    <div>
      <h1 className=" text-2xl font-extralight text-sky-600">
        Ours candidates
      </h1>
      <Search type={"c"} />

      <OurCandidates />
    </div>
  );
}

export default Candidates;
