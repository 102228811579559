import React from "react";
import Stats from "./stats";
import Candidate from "./candidate";
import { useSelector } from "react-redux";
import useShift from "../../../hook/shift";

function Dashboard() {
  const { company } = useSelector((state) => state.companySlice);
  useShift();
 

  return (
    <div className="  p-2 sm:p-4 flex flex-col">
    

      {company  && <Stats company={company} />}

      <hr className="my-5" />
      <h1 className="text-slate-500 text-lg md:text-xl font-medium mb-4">
        Candidates near you
      </h1>
      <Candidate />
      <hr className="my-5" />
    </div>
  );
}

export default Dashboard;
