import {
  PaperClipIcon,
  CalendarDaysIcon,
  CreditCardIcon,
  UserCircleIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/20/solid";
import Stars from "../../../components/rating/Stars";
import useJob from "../../../hook/job";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Application from "../../../Enum/Applicatition";
export default function Details() {
  const { id } = useParams();
  const { data } = useJob({ all: false, id: id });
  const { open } = useSelector((state) => state.ApplicationSlice);
  const { candidate, onLoad } = useSelector((state) => state.candidateSlice);
  const dispatch = useDispatch();

  const handleApply = () => {
    if (!candidate.user.visible) {
      dispatch({
        type: "candidateSlice/setOpen",
      });
    } else {
      dispatch({
        type: "ApplicationSlice/setOpen",
        payload: !open,
      });

      dispatch({
        type: "ApplicationSlice/setHr",
        payload: {
          hourlyRate: parseFloat(data.hourlyRate).toFixed(2),
          currency: data.company.currency.code,
          symbol: data.company.currency.symbol,
          name: data.jobName,
          jobId: data.id,
          date: data.startDate,
          time: `${data.startHour} - ${data.endHour}`,
          logo: data.company.logo,
          userId: data.company.userId,
        },
      });
    }
  };

  return data ? (
    <div className="grid xs:grid-cols-1 md:grid-cols-3 md:p-9 p-3 gap-10 relative">
      <div className="w-full inline-flex  md:hidden  items-center space-x-2 ">
        <div
          onClick={() => window.history.back()}
          className="inline-flex  items-center space-x-2 hover:text-indigo-500 cursor-pointer"
        >
          <svg
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            color="currentColor"
          >
            <g id="_4" data-name="4">
              <path d="m492 276h-472a20 20 0 0 1 0-40h472a20 20 0 0 1 0 40z" />
              <path d="m189 445a19.937 19.937 0 0 1 -14.142-5.858l-169-169a20 20 0 0 1 0-28.284l169-169a20 20 0 0 1 28.284 28.284l-154.858 154.858 154.858 154.858a20 20 0 0 1 -14.142 34.142z" />
            </g>
          </svg>
          <span className="text-xs">Go back</span>
        </div>
      </div>
      <div className="md:col-span-2 sm:pe-8  order-2 md:order-1 sm:border-r">
        <div className=" px-4 sm:px-0">
          <h3 className="text-3xl font-extrabold leading-7 text-sky-400">
            Job details
          </h3>
          <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
            Job details and application.
          </p>
        </div>
        <div className="mt-6">
          <dl className="grid grid-cols-1 sm:grid-cols-2">
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-2 sm:px-0">
              <h1 className="font-bold text-lg text-center">{data.jobName}</h1>
            </div>

            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Company email address
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                {data.company?.email}
              </dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Hourly rate
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                {data.hourlyRate} {data.company?.currency.code}/h
              </dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-2 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Shift
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                <div className="flex justify-center flex-wrap  py-2">
                  <span className="bg-gray-100 text-gray-800 font-semibold text-xs mb-2 lg:text-sm  md:font-medium inline-flex items-center px-2.5 py-0.5 rounded mr-2 dark:bg-gray-700 dark:text-gray-400 border border-gray-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                      />
                    </svg>
                    &nbsp;{new Date(data.startDate).toLocaleDateString()}
                  </span>
                  <span className="bg-blue-100 text-blue-800 text-xs mb-2 lg:text-sm font-semibold md:font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    &nbsp; {data.startHour} - {data.endHour}
                  </span>
                </div>
              </dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-2 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Location
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                {data?.location?.street} <br />
                {data?.location?.zip} {data?.location?.city}
                <br />
                {data?.location?.state}
              </dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-2 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Description
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                {data.description}
              </dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-2 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Skills
              </dt>
              <dd className="mt-1 space-x-3 flex flex-nowrap overflow-x-auto text-sm leading-6 text-gray-700 sm:mt-2">
                {data.skill?.map((el) => (
                  <span
                    key={el}
                    className="inline-flex font-bold items-center rounded-md bg-gray-100 px-2 py-1 text-xs  text-indigo-800"
                  >
                    {el}
                  </span>
                ))}
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <div className="md:col-span-1  order-1 md:order-2 w-full">
        <h2 className="sr-only">Summary</h2>
        <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
          <dl className="flex flex-wrap">
            <div className="flex-auto pl-6 pt-6">
              <dt className="text-md font-semibold leading-6 text-gray-900">
                {data?.company?.name}
              </dt>
              <dd className="mt-1 text-sm   leading-6 text-gray-900">
                <Stars number={data.company?.stars} />
              </dd>
            </div>
            {data.company?.owner && (
              <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                <dt className="flex-none">
                  <span className="sr-only">Client</span>
                  <UserCircleIcon
                    className="h-6 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </dt>
                <dd className="text-sm font-medium capitalize leading-6 text-gray-900">
                  {["Owner", data.company?.owner].join(" ")}
                </dd>
              </div>
            )}
          </dl>
          <div className="mt-6 flex justify-center border-t border-gray-900/5 px-6 py-6">
            <button
              type="button"
              disabled={candidate.applications.some((e) => e.job === data.id)}
              onClick={handleApply}
              className={`rounded-md ${
                candidate.applications.some(
                  (e) => e.job === data.id && e.status === Application.PENDING
                )
                  ? "bg-gray-400 animate-pulse"
                  : candidate.applications.some(
                      (e) =>
                        e.job === data.id && e.status === Application.ACCEPTED
                    )
                  ? "bg-teal-600"
                  : candidate.applications.some(
                      (e) =>
                        e.job === data.id && e.status === Application.REJECTED
                    )
                  ? "bg-red-700"
                  : "bg-sky-500"
              }  px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
            >
              {`${
                candidate.applications.some((e) => e.job === data.id)
                  ? candidate.applications.some(
                      (e) =>
                        e.job === data.id && e.status === Application.PENDING
                    )
                    ? "Pending"
                    : candidate.applications.some(
                        (e) =>
                          e.job === data.id && e.status === Application.ACCEPTED
                      )
                    ? "Accepted"
                    : "Rejected"
                  : "Apply now"
              }`}
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <section className="bg-white dark:bg-gray-900 ">
      <div className="container max-h-screen px-6 py-12 mx-auto lg:flex lg:items-center lg:gap-12">
        <div className="wf-ull lg:w-1/2">
          <p className="text-sm font-medium text-blue-500 dark:text-blue-400">
            404 error
          </p>
          <h1 className="mt-3 text-2xl font-semibold text-gray-800 dark:text-white md:text-3xl">
            Job N°{id} not found
          </h1>
          <p className="mt-4 text-gray-500 dark:text-gray-400">
            Sorry, the job you are looking for doesn't exist.Here are some
            helpful links:
          </p>

          <div className="flex items-center mt-6 gap-x-3">
            <button
              onClick={() => window.history.back()}
              className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:border-gray-700"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-5 h-5 rtl:rotate-180"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                />
              </svg>

              <span>Go back</span>
            </button>

            <Link
              to={"/"}
              className="w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-sky-400 rounded-lg shrink-0 sm:w-auto hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600"
            >
              Take me dashboard
            </Link>
          </div>
        </div>

        <div className="relative w-full mt-12 lg:w-1/2 lg:mt-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1"
            stroke="currentColor"
            className="w-52 max-w-sm lg:mx-auto"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
            />
          </svg>
        </div>
      </div>
    </section>
  );
}
