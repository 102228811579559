import React from "react";
import UsersD from "../../../components/details-user/users";
import { PlusIcon } from "@heroicons/react/24/outline";
import Search from "../../../components/search/search";
import AddNewUser from "../../../components/utils/addNewUser";

function Users() {
  return (
    <div className="w-full ">
      <UsersD />
    </div>
  );
}

export default Users;
