import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllContacts, getCount } from "../redux/users";
import { fetchCountMessage } from "../redux/conversation";

export function useFetchContact() {
  const dispatch = useDispatch();
  const { page_contact, contacts, count } = useSelector(
    (state) => state.UserSlice
  );

  const fetchData = useCallback(() => {
    dispatch(getAllContacts());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData, page_contact, count]);
}

export function useFetchCount() {
  const dispatch = useDispatch();

  const fetchData = useCallback(() => {
    dispatch(getCount());
    dispatch(fetchCountMessage());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
}
