import { ApiClient } from "./api";

export class Currency {
  constructor() {
    this.apiClient = new ApiClient();
  }

  async getCurrencies() {
    try {
      const { data } = await this.apiClient.get(`currency`);

      return data;
    } catch (error) {
      console.error(error);
    }
  }
}
