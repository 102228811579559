import { useDispatch, useSelector } from "react-redux";
import Application from "../../../Enum/Applicatition";
import { setFilter } from "../../../redux/application";

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
const tabs = [
  { name: "All", href: "", current: true },
  { name: "Accepted", href: Application.ACCEPTED, current: false },
  { name: "Pending", href: Application.PENDING, current: false },
  { name: "Reject", href: Application.REJECTED, current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Tabs() {
  const { filter } = useSelector((state) => state.ApplicationSlice);
  const dispatch = useDispatch();
  return (
    <div className="my-7">
      <div className="sm:hidden px-5">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          onChange={(e) => dispatch(setFilter(e.currentTarget.value))}
          className="block w-full rounded-md border-gray-300 focus:border-sky-500 focus:ring-sky-500"
          defaultValue={tabs.find((tab) => tab.href === filter).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.href}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav
          className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
          aria-label="Tabs"
        >
          {tabs.map((tab, tabIdx) => (
            <a
              key={tab.name}
              role="button"
              onClick={() => dispatch(setFilter(tab.href))}
              className={classNames(
                tab.href === filter
                  ? "text-gray-900"
                  : "text-gray-500 hover:text-gray-700",
                tabIdx === 0 ? "rounded-l-lg" : "",
                tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
              )}
              aria-current={tab.href === filter ? "page" : undefined}
            >
              <span>{tab.name}</span>
              <span
                aria-hidden="true"
                className={classNames(
                  tab.href === filter ? "bg-sky-500" : "bg-transparent",
                  "absolute inset-x-0 bottom-0 h-0.5"
                )}
              />
            </a>
          ))}
        </nav>
      </div>
    </div>
  );
}
