/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { PlusIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import {
  setOpenSideEdit,
  setTeamMember,
  setToogle,
} from "../../../redux/teams";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

export default function NewMember() {
  const { company } = useSelector((state) => state.companySlice);
  const dispatch = useDispatch();
  return (
    <div className="mx-auto sm:p-0 px-4 max-w-md sm:max-w-3xl mt-5">
      <div className="mt-10">
        <h3 className="text-sm font-medium text-gray-500 uppercase">
          {company.name}'s Teams{" "}
        </h3>
        <ul role="list" className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2">
          {company &&
            company.members.map((person, personIdx) => (
              <li key={personIdx}>
                <button
                  type="button"
                  onClick={() => {
                    dispatch(setOpenSideEdit(true));
                    dispatch(setTeamMember(person));
                  }}
                  className="group flex w-full items-center justify-between space-x-3 rounded-lg border border-gray-300 p-2 text-left shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                >
                  <span className="flex min-w-0 flex-1 items-center space-x-3">
                    <span className="block flex-shrink-0"></span>
                    <span className="block min-w-0 flex-1">
                      <span className="block truncate text-sm font-medium text-gray-900">
                        {person.name}
                      </span>
                      <span className="block truncate text-xs text-gray-400">
                        {person.email}
                      </span>
                      <span className="block truncate text-sm text-end uppercase font-medium text-gray-500">
                        {person.role}
                      </span>
                    </span>
                  </span>
                  <span className="inline-flex h-10 w-10 flex-shrink-0 items-center justify-center">
                    <InformationCircleIcon
                      className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </span>
                </button>
              </li>
            ))}
        </ul>
      </div>
      <div className="mt-10">
        <button
          type="button"
          onClick={() => dispatch(setToogle())}
          className="relative my-6 block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
            />
          </svg>
          <span className="mt-2 block text-sm font-semibold text-gray-900">
            Add team members
          </span>
        </button>
      </div>
    </div>
  );
}
