import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setFilter } from "../../../redux/application";
import Application from "../../../Enum/Applicatition";

function Stat() {
  const { candidate, onLoad } = useSelector((state) => state.candidateSlice);
  const dispatch = useDispatch();
  return (
    <div>
      <div className="hidden md:stats shadow-sm w-full">
        <Link
          to={"/history"}
          onClick={() => dispatch(setFilter(""))}
          className="stat hover:bg-slate-900/5 transition-all cursor-pointer"
        >
          <div className="stat-figure text-sky-400">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
              />
            </svg>
          </div>
          <div className="stat-title">Total application</div>

          {!onLoad && (
            <div className="stat-value text-sky-400">
              {candidate && candidate.stats.totalApplications}
            </div>
          )}
          {onLoad && (
            <div className="stat-value text-transparent bg-slate-200 animate-pulse rounded-full">
              10000
            </div>
          )}
        </Link>

        <Link
          to={"/history"}
          className="stat hover:bg-slate-900/5 transition-all cursor-pointer"
          onClick={() => dispatch(setFilter(Application.ACCEPTED))}
        >
          <div className="stat-figure text-teal-600">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div className="stat-title">Accepted</div>
          {!onLoad && (
            <div className="stat-value text-teal-600">
              {" "}
              {candidate && candidate.stats.totalAcceptedApplications}
            </div>
          )}

          {onLoad && (
            <div className="stat-value text-transparent bg-slate-200 animate-pulse rounded-full">
              10000
            </div>
          )}
        </Link>

        <Link
          to={"/upcoming"}
          className="stat hover:bg-slate-900/5 transition-all cursor-pointer"
        >
          <div className="stat-figure text-indigo-600">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
              />
            </svg>
          </div>
          <div className="stat-title">Shifts scheduled</div>
          {!onLoad && (
            <div className="stat-value text-indigo-600">
              {" "}
              {candidate && candidate.stats.totalValidShifts}
            </div>
          )}
          {onLoad && (
            <div className="stat-value text-transparent bg-slate-200 animate-pulse rounded-full">
              10000
            </div>
          )}
        </Link>

        <Link
          to={"/history"}
          className="stat hover:bg-slate-900/5 transition-all cursor-pointer"
        >
          <div className="stat-figure text-orange-600">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
              />
            </svg>
          </div>
          <div className="stat-title">Rating</div>
          {!onLoad && (
            <div className="stat-value text-orange-600">
              {" "}
              {candidate && candidate.stats.totalAverageStars}
            </div>
          )}

          {onLoad && (
            <div className="stat-value text-transparent bg-slate-200 animate-pulse rounded-full">
              10000
            </div>
          )}
        </Link>
      </div>

      <div className="xs:grid-cols-2 gap-1 grid md:hidden ">
        <Link
          to={"/history"}
          onClick={() => dispatch(setFilter(""))}
          className="shadow rounded-lg border"
        >
          <div className="grid gap-y-2 p-3 relative">
            <div className=" text-sky-400 absolute bottom-0 right-0 me-5 mb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={0.7}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
                />
              </svg>
            </div>
            <div className="text-center text-xs text-gray-400">
              Total applications
            </div>
            {!onLoad && (
              <div className="text-lg font-black text-center text-sky-400">
                {" "}
                {candidate && candidate.stats.totalApplications}
              </div>
            )}

            {onLoad && (
              <div className="text-lg text-transparent bg-slate-200 animate-pulse rounded-full">
                10
              </div>
            )}
          </div>
        </Link>
        <div className="shadow rounded-lg border">
          <Link to={"/history"} className="grid gap-y-2 p-3 relative">
            <div className=" text-orange-400 absolute bottom-0 right-0 me-5 mb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="0.7"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                />
              </svg>
            </div>
            <div className="text-center text-xs text-gray-400">
              Stars rating
            </div>
            {!onLoad && (
              <div className="text-lg font-black text-center text-orange-600">
                {candidate && candidate.stats.totalAverageStars}
              </div>
            )}
            {onLoad && (
              <div className="text-lg text-transparent bg-slate-200 animate-pulse rounded-full">
                10
              </div>
            )}
          </Link>
        </div>
        <div className="shadow rounded-lg border">
          <Link
            to={"/history"}
            onClick={() => dispatch(setFilter(Application.ACCEPTED))}
            className="grid gap-y-2 p-3 relative"
          >
            <div className="text-teal-600 absolute bottom-0 right-0 me-5 mb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="3.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                />
              </svg>
            </div>
            <div className="text-center text-xs text-gray-400">Accepted</div>
            {!onLoad && (
              <div className="text-lg font-black text-center text-teal-600">
                {candidate && candidate.stats.totalAcceptedApplications}
              </div>
            )}
            {onLoad && (
              <div className="text-lg text-transparent bg-slate-200 animate-pulse rounded-full">
                10
              </div>
            )}
          </Link>
        </div>
        <div className="shadow rounded-lg border">
          <Link to={"/upcoming"} className="grid gap-y-2 p-3 relative">
            <div className="text-indigo-600 absolute bottom-0 right-0 me-5 mb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="0.8"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                />
              </svg>
            </div>
            <div className="text-center text-xs text-gray-400">
              Shift scheduled
            </div>
            {!onLoad && (
              <div className="text-lg font-black text-center text-indigo-600">
                {" "}
                {candidate && candidate.stats.totalValidShifts}
              </div>
            )}
            {onLoad && (
              <div className="text-lg text-transparent bg-slate-200 animate-pulse rounded-full">
                10
              </div>
            )}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Stat;
