import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLimit, setQuery } from "../redux/users";
import { setLimit as setLimitCompany } from "../redux/company";


function useGlobalState() {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(location.pathname);

       dispatch(setQuery(""));

    if (location.pathname === "/messages") {
      dispatch(setLimit(20));
    }
    if (location.pathname === "/") {
      dispatch(setLimit(5));
      dispatch(setLimitCompany(3));
    }
    if (location.pathname === "/users") {
      dispatch(setLimit(8));
    }
    if (location.pathname === "/companies") {
      dispatch(setLimitCompany(10));
    }
  }, [location.pathname]);
}

export default useGlobalState;
