import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllApplicationCandidate,
  getApplicationCompany,
  setPage,
} from "../redux/application";

export default function useApplication() {
  const dispatch = useDispatch();

  const fetchData = useCallback(() => {
    dispatch(getApplicationCompany());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
}
export function useApplicationsCandidate() {
  const dispatch = useDispatch();
  const { limit, page, filter } = useSelector(
    (state) => state.ApplicationSlice
  );

  const fetchData = useCallback(() => {
    dispatch(getAllApplicationCandidate());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setPage(1))
  }, [filter]);
  useEffect(() => {
    fetchData();
  }, [fetchData, page, limit, filter]);
}
