import { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import { setRole } from "../../redux/teams";

const mailingLists = [
  {
    id: "MEMBER",
    title: "Member (employee)",
    description: "handles shifts ",
  },
  {
    id: "MANAGER",
    title: "Manager (employee)",
    description: "Posts jobs ; handles shifts ; Manages Finance",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function RadioRole() {
  const { role } = useSelector((state) => state.TeamSlice);
  const dispatch = useDispatch();

  function handleRole(e) {
    dispatch(setRole(e));
  }

  useEffect(() => {
    dispatch(setRole(mailingLists[0]));
  }, []);

  return (
    <RadioGroup value={role} onChange={handleRole}>
      <RadioGroup.Label className="block text-xs xs:text-sm font-medium leading-6 text-gray-900">
        Role <span className="text-red-500">*</span>
      </RadioGroup.Label>

      <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
        {mailingLists.map((mailingList) => (
          <RadioGroup.Option
            key={mailingList.id}
            value={mailingList}
            className={({ active }) =>
              classNames(
                active
                  ? "border-sky-600 ring-2 ring-sky-600"
                  : "border-gray-300",
                "relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
              )
            }
          >
            {({ checked, active }) => (
              <>
                <span className="flex flex-1">
                  <span className="flex flex-col">
                    <RadioGroup.Label
                      as="span"
                      className="block text-sm font-medium text-gray-900"
                    >
                      {mailingList.title}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className="mt-1 flex items-center text-sm text-gray-500"
                    >
                      {mailingList.description}
                    </RadioGroup.Description>
                  </span>
                </span>
                <CheckCircleIcon
                  className={classNames(
                    !checked ? "invisible" : "",
                    "h-5 w-5 text-sky-600"
                  )}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    active ? "border" : "border-2",
                    checked ? "border-sky-600" : "border-transparent",
                    "pointer-events-none absolute -inset-px rounded-lg"
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
