export default class Notification {
  static INCOMMING = "INCOMMING";
  static NEED_RECONF = "NEED_RECONFIRMATION";
  static RECONFIRMED = "RECONFIRMED_APPLICATION";
  static NOT_RECONFIRMED = "NOT_RECONFIRMED";
  static INVITE = "INVITE_APPLY";
  static RATE_EMPLOYER = "RATE_EMPLOYER"
  static NEW_APPLICATION = "NEW_APPLICATION";
  static REJECTED = "REJECTED_APPLICATION";

  static TYPE = (msg) => {
    switch (msg) {
      case this.INCOMMING:
        return "Incoming";
      case this.NEED_RECONF:
        return "Reconfirm your position";
      case this.RECONFIRMED:
        return "Application reconfirmed";
      case this.NOT_RECONFIRMED:
        return "Not reconfirmed";
      case this.INVITE:
        return "Invitation to apply";
      case "INVITATION_APPLY":
        return "Invitation to apply";
      case this.NEW_APPLICATION:
        return "New application !";
      case this.REJECTED:
        return "Rejected application!";
      case this.RATE_EMPLOYER:
        return "Rate your shift.";

      default:
        break;
    }
  };
}

