import React, { useEffect } from "react";
import OursCompanies from "../../../components/company/our_company";
import { PlusIcon } from "@heroicons/react/24/outline";
import Search from "../../../components/search/search";
import { useDispatch } from "react-redux";
import { setLimit } from "../../../redux/company";
import AddNewUser from "../../../components/utils/addNewUser";

function Companies() {


  return (
    <div>
      <OursCompanies />
    </div>
  );
}

export default Companies;
