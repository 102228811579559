import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCandidate } from "../redux/candidate";
import socket from "../redux/socket";

export default function useCandidate(layoutCandidate = true) {
  const { loading } = useSelector((state) => state.ApplicationSlice);
  const { profileData } = useSelector((state) => state.CalendarSlice);
  const { candidate, load } = useSelector((state) => state.candidateSlice);
    const { id } = useSelector((state) => state.authenticationSlice);

  const dispatch = useDispatch();

  const fetchData = useCallback(() => {
    dispatch(fetchCandidate());
  }, [dispatch, loading, profileData?.profile?.availability]);

  const sendToReactNative = useCallback(() => {
    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          token: localStorage.getItem("userToken"),
        })
      );
  }, [dispatch]);

  useEffect(() => {
    if (layoutCandidate) {
      fetchData();
    }
    
  }, [fetchData]);


  useEffect(() => {
    if (layoutCandidate) {
      sendToReactNative();
      setTimeout(() => {
        socket.emit("message", {
          targetUserID: id,
          type: "ask-push-notification",
          message: {
            content: "gimme-push",
            data: {
              id: id,
            },
          },
        });
      }, 20000);
    }
  }, []);



  return { datas: candidate, loading: load };
}
