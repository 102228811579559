import React, { useEffect, useRef, useState } from "react";
import DropdownSkills from "../../../components/dropdown/dropdownSkills";
import { UsersIcon } from "@heroicons/react/24/outline";
import { useCreateJob } from "./hook";
import useIndustries from "../../../hook/industry";
import { useDispatch, useSelector } from "react-redux";
import JobType from "../../../Enum/JobType";
import moment from "moment";
import { setOpen } from "../../../redux/company";

const JobForm = () => {
  const { company } = useSelector((state) => state.companySlice);
  const { datas } = useIndustries();
  const dispatch = useDispatch();

  const [location, setLocation] = useState({
    street: null,
    city: null,
    zip: null,
    state: null,
  });

  function handleChangeLocation(event) {
    const { name, value } = event.target;

    setLocation({ ...location, [name]: value });
  }

  const street = useRef(null);
  const city = useRef(null);
  const zip = useRef(null);
  const state = useRef(null);
  const street1 = useRef(null);

  const {
    handleInputChange,
    form,
    handleCheckboxChange,
    convertTo12HourFormat,
    calculateTimeDifference,
    save,
  } = useCreateJob({
    companyId: null,
    jobName: null,
    hourlyRate: 10,
    startDate: moment().format("YYYY-MM-DD"),
    startHour: null,
    endHour: null,
    description: null,
    location: {
      street: null,
      city: null,
      zip: null,
      state: null,
    },
    availability: true,
    totalHour: null,
    type: "DEFAULT",
    creatAt: moment.utc().unix(),
    industry: null,
    skill: [],
    nbCandidate: 1,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!company.user.visible) {
      dispatch(setOpen());
    }

    if (
      company.user.stripe !== null &&
      company.user.stripe.paymentMethods.data.length === 0
    ) {
      dispatch(setOpen());
    } else {
      form.companyId = company.id;
      form.totalHour = calculateTimeDifference(form.startHour, form.endHour);
      form.industry = JSON.parse(form.industry).id;
      form.startHour = convertTo12HourFormat(form.startHour);
      form.endHour = convertTo12HourFormat(form.endHour);
      form.location = location;
      save();
    }
  };

  useEffect(() => {
    // Function to check if the Google Maps script is already loaded
    const isScriptLoaded = (src) => {
      return Array.from(document.scripts).some((script) => script.src === src);
    };

    // Script URL
    const scriptSrc = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAAZzq54uXg1aSR-8NSY41uz5-4P5JCWQM&libraries=places&callback=initAutocomplete`;

    // Only append the script if it's not already loaded
    if (!isScriptLoaded(scriptSrc)) {
      const script = document.createElement("script");
      script.src = scriptSrc;
      script.async = true;
      document.body.appendChild(script);

      // Initialize the Autocomplete when the script is loaded
      window.initAutocomplete = initAutocomplete;

      return () => {
        // Clean up the script and Autocomplete on component unmount
        document.body.removeChild(script);
        delete window.initAutocomplete;
      };
    }
  }, []);

  function initAutocomplete() {
    const address1Field = street.current;
    const postalField = zip.current;

    // Create the autocomplete object, restricting the search predictions to addresses in the US and Canada.
    const autocomplete = new window.google.maps.places.Autocomplete(
      address1Field,
      {
        componentRestrictions: { country: ["gb", "sa", "ae", "fr"] },
        fields: ["address_components", "geometry"],
        types: ["address"],
      }
    );

    address1Field.focus();

    // When the user selects an address from the drop-down, populate the address fields in the form.
    autocomplete.addListener("place_changed", fillInAddress);

    function fillInAddress() {
      // Get the place details from the autocomplete object.
      const place = autocomplete.getPlace();
      let address1 = "";
      let postcode = "";

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      let updatedAddress = {};

      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case "street_number":
            address1 = `${component.long_name} ${address1}`;
            break;
          case "route":
            address1 += component.short_name;
            break;
          case "postal_code":
            postcode = `${component.long_name}${postcode}`;
            break;
          case "postal_code_suffix":
            postcode = `${postcode}-${component.long_name}`;
            break;
          case "locality":
            updatedAddress[`location.city`] = component.long_name;
            city.current.value = component.long_name;
            break;
          case "administrative_area_level_1":
            updatedAddress[`location.state`] = component.short_name;
            state.current.value = component.short_name;
            break;
        }
      }

      updatedAddress[`location.street`] = address1;
      street1.current.value = address1;
      updatedAddress[`location.zip`] = postcode;
      zip.current.value = postcode;

      const obj = {
        street: updatedAddress[`location.street`],
        state: updatedAddress[`location.state`],
        zip: updatedAddress[`location.zip`],
        city: updatedAddress[`location.city`],
      };

      setLocation(obj);
    }
  }

  console.log(form);

  return (
    <form
      onSubmit={handleSubmit}
      className=" p-2 sm:p-4 grid md:grid-cols-6 h-[calc(100vh-8rem)]  lg:h-[calc(100vh-4rem)] gap-3 divide-y  md:divide-y-0   "
    >
      <div className="md:col-span-2">
        <h1 className="text-sky-500 text-lg md:text-xl font-medium mb-4">
          Post new job
        </h1>
        <p className="mt-1 text-sm leading-6 text-gray-600 ">
          Create a new temp position and hire talent for your company today !
        </p>
      </div>

      <div className="md:col-span-4 py-2  px-3 md:px-2 gap-6 grid grid-cols-6 h-[96%] overflow-y-scroll scroll-m-0 ">
        <div className="col-span-3">
          <label
            htmlFor="Sector"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Sector
          </label>
          <select
            id="industry"
            name="industry"
            onChange={handleInputChange}
            /*   defaultValue={datas && datas[0].name} */
            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-sky-600 text-sm sm:leading-6"
          >
            <option disabled selected>
              Sector
            </option>
            {datas &&
              datas.map((el) => (
                <option key={el.name} value={JSON.stringify(el)}>
                  {el.name}
                </option>
              ))}
          </select>
        </div>
        <div className="col-span-3">
          <label
            htmlFor="jobName"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Sub sector
          </label>
          <select
            id="jobName"
            name="jobName"
            value={form.jobName}
            required
            onChange={handleInputChange}
            className="mt-2 block w-full rounded-md border-0   py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-sky-600 text-sm sm:leading-6"
          >
            <option disabled selected>
              Sub sector
            </option>
            {form.industry &&
              JSON.parse(form.industry).subSector.map((sub) => (
                <option key={sub.name}>{sub.name}</option>
              ))}
          </select>
        </div>
        <div className="col-span-full">
          <div>
            <label
              htmlFor="price"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Hourly rate
            </label>
            <div className="relative mt-2">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-gray-500 text-sm">
                  {company && company.currency.symbol}
                </span>
              </div>

              <div className="flex flex-col justify-end items-end space-y-6">
                <span className="p-2 border-2 rounded-lg text-gray-500 text-sm">
                  {form.hourlyRate} &nbsp; {company && company.currency.code}
                </span>
                <input
                  id="default-range"
                  type="range"
                  step={0.5}
                  name="hourlyRate"
                  onChange={handleInputChange}
                  value={form.hourlyRate}
                  class="w-full h-2 bg-gray-200  rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                />
              </div>
              {/*             <input
                type="text"
                name="hourlyRate"
                onChange={handleInputChange}
                value={form.hourlyRate}
                id="hourlyRate"
                required
                className="block w-full rounded-md border-0 py-1.5 pl-12 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 text-sm sm:leading-6"
                placeholder="0.00"
                aria-describedby="price-currency"
              /> */}
              {/*   <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span
                  className="text-gray-500 text-sm"
                  id="price-currency"
                >
                  {company && company.currency.code}
                </span>
              </div> */}
            </div>
          </div>
        </div>
        <div className="col-span-full">
          <label
            htmlFor="type"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Urgency
          </label>
          <select
            id="type"
            name="type"
            value={form.type}
            required
            onChange={handleInputChange}
            className={`mt-2 block w-full transition-all  duration-500 ${
              form.type === JobType.URGENT
                ? "bg-orange-500 text-white font-semibold focus:ring-orange-800"
                : form.type === JobType.TODAY
                ? "bg-red-700 text-white font-semibold focus:ring-red-800"
                : ""
            } rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-sky-600 text-sm sm:leading-6`}
            defaultValue={JobType.URGENT}
          >
            <option value={JobType.DEFAULT}>{JobType.DEFAULT}</option>
            <option value={JobType.URGENT}>{JobType.URGENT}</option>
            <option value={JobType.TODAY}>{JobType.TODAY}</option>
          </select>
        </div>

        <div className="col-span-full">
          <label
            htmlFor="nbCandidate"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Tempers needed
          </label>
          <div className="mt-2 flex rounded-md shadow-sm">
            <div className="relative flex flex-grow items-stretch focus-within:z-10">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <UsersIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="number"
                name="nbCandidate"
                value={form.nbCandidate}
                min={1}
                required
                onChange={handleInputChange}
                id="nbCandidate"
                className="block w-full  rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 text-sm sm:leading-6"
                defaultValue={1}
              />
            </div>
          </div>
        </div>

        <div className="col-span-full ">
          <label
            htmlFor="Location"
            className="block text-sm  font-medium leading-6 text-gray-900"
          >
            Location
          </label>

          <div className="grid-cols-2 gap-2 grid">
            <div className="relative mt-2 col-span-full flex items-center">
              <input
                type="text"
                name="Location"
                id="Location"
                ref={street}
                placeholder="Where it will take place"
                className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 text-sm sm:leading-6"
              />
              <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4 animate-bounce"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                  />
                </svg>
              </div>
            </div>

            <div className="col-span-1">
              <label htmlFor="location.street" className="sr-only">
                street
              </label>
              <input
                type="text"
                required
                ref={street1}
                name="street"
                value={location.street}
                onChange={handleChangeLocation}
                id="street"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 text-sm sm:leading-6"
                placeholder="street"
              />
            </div>
            <div className="col-span-1">
              <label htmlFor="location.city" className="sr-only">
                city
              </label>
              <input
                type="text"
                name="city"
                required
                ref={city}
                value={location.city}
                onChange={handleChangeLocation}
                id="city"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 text-sm sm:leading-6"
                placeholder="city"
              />
            </div>
            <div className="col-span-1">
              <label htmlFor="location.zip" className="sr-only">
                zip
              </label>
              <input
                type="text"
                ref={zip}
                name="zip"
                value={location.zip}
                onChange={handleChangeLocation}
                id="location.zip"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 text-sm sm:leading-6"
                placeholder="zip"
              />
            </div>
            <div className="col-span-1">
              <label htmlFor="state" className="sr-only">
                state
              </label>
              <input
                type="text"
                ref={state}
                onChange={handleChangeLocation}
                value={location.state}
                name="state"
                required
                id="state"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 text-sm sm:leading-6"
                placeholder="state"
              />
            </div>
          </div>
        </div>

        <div className="col-span-full">
          <label
            htmlFor="description"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Add a description
          </label>
          <div className="mt-2">
            <textarea
              rows={4}
              onChange={handleInputChange}
              name="description"
              value={form.description}
              id="description"
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 text-sm sm:leading-6"
              defaultValue={""}
            />
          </div>
        </div>
        <div className="col-span-full">
          <label
            htmlFor="skill"
            className="block text-smfont-medium leading-6 text-gray-900"
          >
            Skill(s) required
          </label>
          <div className="mt-2">
            <DropdownSkills
              handleCheckboxChange={handleCheckboxChange}
              item={form.industry && JSON.parse(form.industry).skills}
              skills={form.skill}
            />
          </div>
        </div>
        <div className="col-span-full">
          <label
            htmlFor="date"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Date
          </label>
          <div className="mt-2">
            <input
              type="date"
              required
              min={moment().format("YYYY-MM-DD")}
              onChange={handleInputChange}
              value={form.startDate}
              name="startDate"
              id="email"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 text-sm sm:leading-6"
              placeholder="city"
            />
          </div>
        </div>
        <div className="col-span-3">
          <label
            htmlFor="startHour"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            From
          </label>
          <div className="mt-2">
            <div className="relative max-w-sm">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                </svg>
              </div>
              <input
                datepicker
                type="time"
                value={form.startHour}
                required
                max={form.endHour && form.endHour}
                onChange={handleInputChange}
                name="startHour"
                className=" border border-gray-300 text-gray-900 text-sm   ring-gray-300 rounded-md focus:ring-sky-500 focus:border-sky-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-sky-500 dark:focus:border-sky-500"
                placeholder="Select date"
              />
            </div>
          </div>
        </div>
        <div className="col-span-3">
          <label
            htmlFor="endHour"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            To
          </label>
          <div className="mt-2">
            <div className="relative max-w-sm">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                </svg>
              </div>
              <input
                datepicker
                type="time"
                required
                value={form.endHour}
                min={form.startHour && form.startHour}
                onChange={handleInputChange}
                name="endHour"
                className=" border border-gray-300 text-gray-900 text-sm   ring-gray-300 rounded-md focus:ring-sky-500 focus:border-sky-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-sky-500 dark:focus:border-sky-500"
                placeholder="Select date"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="md:col-span-full py-2 flex flex-col items-end w-full h-[5%]">
        <button
          type="submit"
          className="rounded-md bg-sky-600  px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
        >
          Create new position
        </button>
      </div>
    </form>
  );
};

export default JobForm;
