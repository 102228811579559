import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { findOneUser, getStats, getUser } from "../redux/users";
import { useLocation } from "react-router-dom";

export function useUsers() {
  const dispatch = useDispatch();
  const { page, query, limit, type } = useSelector((state) => state.UserSlice);

  const fetchData = useCallback(() => {
    dispatch(getUser());
  }, [dispatch]);

  useEffect(() => {
    if (type === "u" && query) {
      fetchData();
    }
  }, [fetchData, query]);

  useEffect(() => {
    fetchData();
  }, [fetchData, page, limit]);
}

export function useFetchUser() {
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.UserSlice);

  const fetchData = useCallback(() => {
    dispatch(findOneUser());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData, id]);
}
export function useFetchStats() {
  const dispatch = useDispatch();

  const fetchData = useCallback(() => {
    dispatch(getStats());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
}
