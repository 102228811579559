import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShiftDate } from "../redux/application";

export function useCalendar() {
  const [days, setDates] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [weekView, setWeekView] = useState(false);
  const containerNav = useRef(null);
  const [currentWeekIndex, setCurrentWeekIndex] = useState(0);
  const [maxWeekIndex, setMaxWeekIndex] = useState(0);
  const [currentSet, setCurrentSet] = useState(false);
  const { open, availability, startTime, endTime, profileData } = useSelector(
    (state) => state.CalendarSlice
  );

  const { shiftDate } = useSelector((state) => state.ApplicationSlice);
  const dispatch = useDispatch();

  const generateDates = (date, weekView = false) => {
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth();

    const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
    const startDayOfWeek = (firstDayOfMonth.getDay() + 6) % 7; // Correction du décalage du jour de la semaine

    const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);
    const lastDateOfMonth = lastDayOfMonth.getDate();

    const days = [];

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    const addDay = (
      date,
      isCurrentMonth = false,
      isToday = false,
      events = []
    ) => {
      days.push({ date: formatDate(date), isCurrentMonth, isToday, events });
    };

    // Ajouter les jours du mois précédent
    if (!weekView) {
      const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1;
      const previousYear = currentMonth === 0 ? currentYear - 1 : currentYear;
      const lastDayOfPreviousMonth = new Date(
        previousYear,
        previousMonth + 1,
        0
      );
      const lastDateOfPreviousMonth = lastDayOfPreviousMonth.getDate();

      for (let i = startDayOfWeek - 1; i >= 0; i--) {
        const date = new Date(
          previousYear,
          previousMonth,
          lastDateOfPreviousMonth - i
        );
        addDay(date);
      }
    }

    // Ajouter les jours du mois courant
    for (let i = 1; i <= lastDateOfMonth; i++) {
      const date = new Date(currentYear, currentMonth, i);
      const isCurrentMonth = true;
      const isToday = date.toDateString() === new Date().toDateString();
      addDay(date, isCurrentMonth, isToday);
    }

    // Ajouter les jours du mois suivant
    if (!weekView) {
      const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1;
      const nextYear = currentMonth === 11 ? currentYear + 1 : currentYear;
      const remainingDays = (7 - (lastDayOfMonth.getDay() + 1)) % 7; // Nombre de jours restants jusqu'au dimanche

      for (let i = 1; i <= remainingDays; i++) {
        const date = new Date(nextYear, nextMonth, i);
        addDay(date);
      }
    }

    return days;
  };

  useEffect(() => {
    const generatedDates = generateDates(currentDate);
    setDates(generatedDates);
    selectDate(new Date());
  }, [
    currentDate,
    weekView,
    currentWeekIndex,
    profileData,
    maxWeekIndex,
    shiftDate,
  ]);

  useEffect(() => {
    setCurrentDate(shiftDate);
    selectDate(shiftDate);
  }, [shiftDate]);

  const nextMonth = () => {
    const nextDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      1
    );
    setCurrentDate(nextDate);
    setCurrentWeekIndex(0);

    setMaxWeekIndex(days.length - 1);
  };

  const previousMonth = () => {
    const previousDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      1
    );
    setCurrentDate(previousDate);
    setCurrentWeekIndex(0);
    setMaxWeekIndex(days.length - 1);
  };

  const goToToday = () => {
    setCurrentDate(new Date());
    setCurrentSet(false);
  };

  const selectDate = (date) => {
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
      weekday: "long",
    }).format(date);

    dispatch({
      type: "CalendarSlice/setEndStart",
      payload: date,
    });
    setSelectedDate(formattedDate);
  };

  const formattedMonth = new Intl.DateTimeFormat("en-US", {
    month: "long",
    year: "numeric",
  }).format(currentDate);

  function chunkArray(arr, size) {
    const chunks = [];
    for (let i = 0; i < arr.length; i += size) {
      chunks.push(arr.slice(i, i + size));
    }

    if (!currentSet) {
      setCurrentWeekIndex(getCurrentWeekIndex(arr));
      setCurrentSet(true);
    }

    setMaxWeekIndex(chunks.length - 1);

    return chunks;
  }

  function getCurrentWeekIndex(arr) {
    const currentDate = new Date();

    const chunks = [];
    for (let i = 0; i < arr.length; i += 7) {
      chunks.push(arr.slice(i, i + 7));
    }

    for (let i = 0; i < chunks.length; i++) {
      const week = chunks[i];
      for (const date of week) {
        if (
          new Date(date.date).toLocaleDateString() ===
          currentDate.toLocaleDateString()
        ) {
          return i; // Retourne l'indice du sous-tableau contenant la date du jour
        }
      }
    }

    return -1; // Retourne -1 si la date du jour n'a pas été trouvée dans les sous-tableaux
  }

  const RenderDayOfWeek = () => {
    const weekDays = ["S", "M", "T", "W", "T", "F", "S"];
    const current = generateDates(currentDate);
    const weekChunks = chunkArray(current, 7);

    return weekChunks[currentWeekIndex]?.map((day, index) => {
      const isToday =
        new Date(day.date).toLocaleDateString() ===
        new Date().toLocaleDateString();

      return (
        <button
          key={index}
          type="button"
          onClick={() => selectDate(new Date(day.date))}
          className={`flex flex-col items-center pb-1.5 pt-3 transition-all 
          ${
            isToday &&
            new Date(selectedDate).toLocaleDateString() !==
              new Date(day.date).toLocaleDateString() &&
            "text-sky-500"
          }
          ${
            new Date(selectedDate).toLocaleDateString() ===
              new Date(day.date).toLocaleDateString() && "text-white bg-sky-500"
          }
          
          ${!day.isCurrentMonth && "text-white bg-gray-300"}

          `}
        >
          <span className="font-extrabold">
            {weekDays[new Date(day.date).getDay()]}
          </span>
          <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-base font-semibold">
            {new Date(day.date).getDate()}
          </span>
        </button>
      );
    });
  };

  const nextWeek = () => {
    if (currentWeekIndex < maxWeekIndex) {
      setCurrentWeekIndex(currentWeekIndex + 1);
    }
    if (currentWeekIndex + 1 >= maxWeekIndex) {
      nextMonth();
    }
  };

  const previousWeek = () => {
    if (currentWeekIndex > 0) {
      setCurrentWeekIndex(currentWeekIndex - 1);
    } else {
      previousMonth();
    }
  };

  return {
    days,
    nextMonth,
    previousMonth,
    formattedMonth,
    goToToday,
    selectDate,
    selectedDate,
    RenderDayOfWeek,
    containerNav,
    weekView,
    setWeekView,
    nextWeek,
    previousWeek,
  };
}
