import {
  BriefcaseIcon,
  ChartPieIcon,
  ChatBubbleBottomCenterTextIcon,
  PaperClipIcon,
  PencilIcon,
  TrashIcon,
  UserCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/20/solid";
import { ChartBarIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function Card({ title, ...props }) {
  return (
    <Link {...props}>
      <h1 className="text-2xl font-extralight text-sky-600 hover:underline">
        {title}
      </h1>
    </Link>
  );
}

export default function ToolBar() {
  const { stats } = useSelector((state) => state.ShiftSlice);
  const { balance } = useSelector((state) => state.stripeSlice);

  return (
    <div className="grid lg:grid-cols-2 my-6 gap-4">
      <div className=" bg-white border rounded-md p-4">
        <h1 className="text-2xl font-extralight text-sky-600">Balance</h1>
        <h1 className="mt-4 text-4xl font-bold uppercase inline-block bg-gradient-to-r from-indigo-600 text-transparent bg-clip-text to-sky-600 ">
          {balance.available &&
            balance.available[0] &&
            [balance.available[0].currency, balance.available[0].amount].join(
              " "
            )}
        </h1>
        <div className="mt-8 grid sm:grid-cols-3 gap-3">
          <div className="  rounded-md p-3 bg-[#F3F4F6] ">
            <img
              src={require("../../../assets/img/illustration/wall-clock_595045.png")}
              className="w-8 h-8 "
              alt=""
              srcset=""
            />

            <h1 className="mt-3 font-light   ">Total hours</h1>
            <h1 className="mt-1 font-bold text-xl text-sky-600 ">
              {" "}
              {stats.totalHours}
            </h1>
          </div>
          <div className=" bg-[#F3F4F6] rounded-md p-3 ">
            <img
              src={require("../../../assets/img/illustration/income_8206197.png")}
              className="w-8 h-8 "
              alt=""
              srcset=""
            />

            <h1 className="mt-3 font-light ">Expenses</h1>
            <h1 className="mt-1 font-bold text-xl text-sky-600 ">
              {stats.totalExpenses}
            </h1>
          </div>
          <div className=" bg-[#F3F4F6] rounded-md p-3 ">
            <img
              src={require("../../../assets/img/illustration/calendar_10403058.png")}
              className="w-8 h-8 "
              alt=""
              srcset=""
            />

            <h1 className="mt-3 font-light ">Upcoming shift</h1>
            <h1 className="mt-1 font-bold text-xl text-sky-600">
              {" "}
              {stats.validShiftsCount}
            </h1>
          </div>
        </div>
      </div>
      <div className="relative  grid grid-cols-2 gap-3">
        <Card
          to="/profile"
          className="bg-white h-32 p-4 flex justify-center items-center rounded-md border "
          title={"Settings"}
        />
        <Card
          to="/contact"
          className="bg-white h-32 p-4 flex justify-center items-center rounded-md border "
          title={"Inquires"}
        />
        <Card
          to="/messages"
          className="bg-white h-32 col-span-2 p-4 flex justify-center items-center rounded-md border "
          title={"Messages"}
        />

        {/*  <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex justify-center">
        <span className="isolate inline-flex -space-x-px rounded-md shadow-sm">
          <Link
            to="/users"
            className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
          >
            <span className="sr-only">Edit</span>
            <UserGroupIcon className="h-5 w-5" aria-hidden="true" />
          </Link>
          <Link
            to="/candidates"
            className="relative inline-flex items-center bg-white px-3 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
          >
            <span className="sr-only">Attachment</span>
            <BriefcaseIcon className="h-5 w-5" aria-hidden="true" />
          </Link>
          <Link
            to="/contact"
            className="relative inline-flex items-center bg-white px-3 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
          >
            <span className="sr-only">Annotate</span>
            <ChatBubbleBottomCenterTextIcon
              className="h-5 w-5"
              aria-hidden="true"
            />
          </Link>
          <Link
            to="/finance"
            className="relative inline-flex items-center rounded-r-md bg-white px-3 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
          >
            <span className="sr-only">Delete</span>
            <ChartPieIcon className="h-5 w-5" aria-hidden="true" />
          </Link>
        </span>
      </div> */}
      </div>
    </div>
  );
}
