import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { baseUrl } from "../../../utils";
import { setModalCardOpen } from "../../../redux/company";
import moment from "moment";
import TableFinance from "../Finance/table";
import PayementDetails, { CreditCards } from "../Finance/payement_details";
import { Link } from "react-router-dom";
import { setFirstPayoutId, setLastPayoutId } from "../../../redux/stripe";
import { SpinnerLoadData } from "../../../components/utils/spinner-load";

function Stats() {
  const { company, onLoad } = useSelector((state) => state.companySlice);
  return (
    <div className="max-w-[85rem] py-6 px-4 sm:px-6 lg:px-8 mx-auto">
      <div className="grid gap-6 grid-cols-2 sm:gap-12 lg:grid-cols-3 lg:gap-8">
        <div className="flex flex-col items-center">
          <h4 className="text-sm md:text-xl font-semibold text-gray-800 dark:text-gray-200">
            Total Hours
          </h4>
          <p className="mt-2 sm:mt-3 text-2xl text-center sm:text-5xl font-bold text-sky-500">
            {company.finance.totalHours} H
          </p>
          <p className="mt-1 text-sm text-gray-500 text-center">
            Number of hour spent
          </p>
        </div>

        <div className="flex flex-col items-center">
          <h4 className="text-sm md:text-xl  font-semibold text-gray-800 dark:text-gray-200">
            Total paid
          </h4>
          <p className="mt-2 sm:mt-3 text-2xl text-center sm:text-5xl font-bold text-green-500">
            {[company.finance.totalSpent, company.currency.code].join(" ")}
          </p>
          <p className="mt-1 text-sm text-center text-gray-500">
            Total money to candidates{" "}
          </p>
        </div>

        <div className="flex flex-col items-center col-span-2 lg:col-span-1">
          <h4 className="text-sm md:text-xl  font-semibold text-gray-800 dark:text-gray-200">
            No show
          </h4>
          <p className="mt-2 sm:mt-3 text-2xl sm:text-5xl font-bold text-red-500">
            {company.finance.noShowCount}
          </p>
          <p className="mt-1 text-gray-500 text-sm text-center">
            Number of candidates absent
          </p>
        </div>
      </div>
    </div>
  );
}

function Finance() {
  const { company, onLoad } = useSelector((state) => state.companySlice);
   const { shifts, limit, page } = useSelector((state) => state.ShiftSlice);
  const { payouts } = useSelector((state) => state.stripeSlice);
  const dispatch = useDispatch();
  return company && shifts ? (
    <div className="flex flex-col">
      <Stats />
      <hr />
      <div className="p-2">
        {company.user &&
          company.user.stripe &&
          company.user.stripe.requirements.currently_due.length > 0 && (
            <a
              type="button"
              href={`/api/stripe/create-account/${company.user.id}?redirect=${window.location.href}&tab=2`}
              target="_top"
              className="p-5 flex justify-center  my-5 items-center flex-col col-span-full rounded-lg border-dashed border-2"
            >
              <span className="font-medium text-xl max-w-screen-sm text-center">
                To facilitate your financial management, your acceptance of the
                Stripe Service Agreement is essential. <br />
                <p className="text-xs leading-3 text-sky-600 md:text-sm">
                  Requirement: Provide your IBAN (takes approximately 1-3
                  minutes).
                </p>
              </span>
              <p className="text-sky-600 text-lg font-medium animate-pulse mt-4">
                Click here to update it !
              </p>
            </a>
          )}

        <>
          {company.user &&
            company.user.stripe &&
            company.user.stripe.requirements.currently_due.length === 0 && (
              <div className="inline-flex  items-center justify-end w-full space-x-5">
                <span className="text-sky-500   text-sm text-center  cursor-pointer font-semibold hover:text-sky-300 active:text-sky-500">
                  Stripe dashbord
                </span>
              </div>
            )}
        </>
      </div>

      {company.user &&
        company.user.stripe &&
        company.user.stripe.requirements.currently_due.length === 0 && (
          <>
            <hr />
            <br />
            <br />
            <CreditCards />
            <br />
            <br />
            <hr />
            <br />
            <br />
            <PayementDetails />
            <br />
            <br />
            <hr />
            <div className="lg:px-8 px-4 py-4">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-base font-semibold leading-6 text-sky-900">
                    Payout transaction
                  </h1>
                  <p className="mt-2  max-w-xl text-sm text-gray-700">
                    A Payout is created when you receive funds from Stripe or
                    when you pay someone
                  </p>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                  <Link
                    to={"/jobs"}
                    type="button"
                    className="block rounded-md bg-sky-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                  >
                    Post a job
                  </Link>
                </div>
              </div>

              <div class="relative overflow-x-auto mt-8 flow-root">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" class="px-6 py-3">
                        Id
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Amount
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Status
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Destination
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {payouts.data &&
                      payouts.data.map((payout) => (
                        <tr class="bg-white dark:bg-gray-800">
                          <th
                            scope="row"
                            class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            <div className="flex flex-col">
                              <span>{payout.id}</span>
                              <span className="text-xs text-sky-700">
                                Arrival Date :{" "}
                                {moment
                                  .unix(payout.arrival_date)
                                  .local()
                                  .fromNow()}
                              </span>
                            </div>
                          </th>
                          <td class="px-6 py-4">
                            {" "}
                            {[
                              payout.currency.toUpperCase(),
                              payout.amount / 100,
                            ].join(" ")}
                          </td>
                          <td class="px-6 py-4">{payout.status}</td>
                          <td class="px-6 py-4">
                            <div className="flex flex-col text-xs ">
                              <span> {payout.type}</span>
                              <span>{payout.destination}</span>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <nav
                className="flex  w-full items-center justify-between  bg-white px-4 py-3 sm:px-6"
                aria-label="Pagination"
              >
                <div className="hidden sm:block"></div>
                <div className="flex flex-1 justify-between sm:justify-end">
                  <button
                    disabled={!payouts.prev_more}
                    onClick={() =>
                      dispatch(setFirstPayoutId(payouts.previous_ending_before))
                    }
                    className=" disabled:bg-slate-400/40 disabled:text-white relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                  >
                    Previous
                  </button>
                  <button
                    disabled={!payouts.has_more}
                    onClick={() =>
                      dispatch(setLastPayoutId(payouts.next_starting_after))
                    }
                    className="disabled:bg-slate-400/40 disabled:text-white relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                  >
                    Next
                  </button>
                </div>
              </nav>
            </div>
            <hr />
            <br />
            <br />
            <TableFinance />
          </>
        )}
    </div>
  ) : (
    <SpinnerLoadData />
  );
}

export default Finance;
