import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { postLogo, updateProfileCompany } from "../redux/company";
import {
  postImage,
  udateUserData,
  updateProfileCandidate,
} from "../redux/candidate";
import { updateUser, updateUserB } from "../redux/authentication";
import Resizer from "react-image-file-resizer";

// Utility function to validate a given value against a regex
function isValid(value, regex) {
  return new RegExp(regex).test(value);
}

const resizeFile = (file, w = 800, h = 800) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file, // the file from input
      w, // width
      h, // height
      "JPEG", // compress format
      100, // quality
      0, // rotation
      (uri) => {
        resolve(uri);
      },
      "file" // output type
    );
  });

// Function to update nested keys
function updateNestedKeys(temp, outerKey, innerKey, value, type) {
  if (type === "file") {
    return { ...temp, [outerKey]: { ...temp[outerKey], [innerKey]: value[0] } };
  }
  if (innerKey === "currency") {
    return {
      ...temp,
      [outerKey]: { ...temp[outerKey], [innerKey]: JSON.parse(value) },
    };
  }
  return { ...temp, [outerKey]: { ...temp[outerKey], [innerKey]: value } };
}

export function useUpdateProfile(initialState, data) {
  const [temp, setTemp] = useState(initialState);
  const [load, setLoad] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const prefix = data.phone.substring(0, 4); // Adjust based on your actual prefix length
    const number = data.phone.substring(4);
    setTemp({ ...temp, phone: { value: number, prefix } });
  }, []);

  function updateDeepNestedKeys(object, keys, value, type) {
    let current = object;
    for (let i = 0; i < keys.length - 1; i++) {
      if (!current[keys[i]]) current[keys[i]] = {};
      current = current[keys[i]];
    }
    current[keys[keys.length - 1]] = type === "file" ? value[0] : value;
    return object;
  }

  function onUpdate(event) {
    const { name, value, type, files, checked } = event.target;

    const dotCount = (name.match(/\./g) || []).length;

    if (name.includes(".") && dotCount === 1) {
      const [outerKey, innerKey] = name.split(".");
      let newValue = type === "file" ? files : value;

      if (
        (outerKey === "hourlyRate" && ["value"].includes(innerKey)) ||
        (outerKey === "legalDocumentId" && innerKey === "documentNumber")
      ) {
        const regex =
          outerKey === "hourlyRate" ? "^[0-9/\\.]{0,5}$" : "^[a-zA-Z0-9]*$";
        newValue = isValid(value, regex) ? value : "";
      }

      if (outerKey === "phone" && innerKey === "value") {
        const regex = "^[+]?[0-9s]{0,15}$";
        newValue = isValid(value, regex) ? value.toString() : "";
      }

      setTemp(updateNestedKeys(temp, outerKey, innerKey, newValue, type));
    } else if (dotCount > 1) {
      let newValue = type === "checkbox" ? checked : value;
      const keys = name.split(".");
      console.log(updateDeepNestedKeys({ ...temp }, keys, newValue, type));
      setTemp(updateDeepNestedKeys({ ...temp }, keys, newValue, type));
    } else {
      if (["crn", "vat"].includes(name) && data.name) {
        setField(name, data.currency.country.code, value);
      } else {
        setTemp({
          ...temp,
          [name]: value,
        });
      }
    }
  }

  function setField(name, country, value) {
    let pattern;

    if (name === "crn") {
    }

    if (name === "vat") {
    }
    console.log(isValid(value, pattern));
    console.log(country);
    setTemp({
      ...temp,
      [name]: isValid(value, pattern) ? value : "",
    });
  }

  function isChanged() {
    return JSON.stringify(data) === JSON.stringify(temp);
  }

  function isChangedDocument() {
    console.log(data);
    return (
      JSON.stringify(data.user.legalDocumentId) ===
      JSON.stringify(temp.user.legalDocumentId)
    );
  }

  function saveCompany() {
    const {
      id,
      email,
      description,
      phone,
      address,
      industry,
      owner,
      vat,
      crn,
    } = temp;
    let convertPhone = null;

    if (phone.prefix && phone.value) {
      convertPhone = `${phone.prefix}${phone.value.toString()}`;
    }

    dispatch(
      updateProfileCompany({
        id,
        params: {
          email,
          description,
          phone: convertPhone,
          address,
          industry: [industry],
          owner,
          vat,
          crn,
        },
      })
    );
  }

  function isChangedNotification() {
    return (
      JSON.stringify(data.user.notification) ===
      JSON.stringify(temp.user.notification)
    );
  }

  function saveNotificationUser() {
    const { user } = temp;
    console.log("dded");
    dispatch(
      updateUserB({
        data: {
          notification: user.notification,
        },
      })
    );
  }

  async function saveUserCandidate() {
    setLoad(false);
    const { documentNumber, issueDate, expiryDate, doc } = temp.legalDocumentId;
    const resizedImage = doc
      ? doc.type !== "application/pdf"
        ? await resizeFile(doc)
        : doc
      : "";

    dispatch(
      udateUserData({
        params: {
          legalDocumentId: {
            documentNumber: documentNumber
              ? documentNumber
              : temp.user.legalDocumentId.documentNumber,
            issueDate: issueDate
              ? issueDate
              : temp.user.legalDocumentId.issueDate,
            expiryDate: expiryDate
              ? expiryDate
              : temp.user.legalDocumentId.expiryDate,
          },
        },
        file: resizedImage,
      })
    );

    setLoad(true);
    setTemp(initialState);
  }

  function saveCandidate() {
    const { id, phone, address, title, hourlyRate, radius } = temp;

    let convertPhone = null;

    if (!hourlyRate.currency) {
      hourlyRate.currency = {
        id: 1,
        code: "GBP",
        name: "pound sterling",
        symbol: "£",
        country: {
          id: 1,
          code: "UK",
          name: "United Kingdom",
          currencyId: 1,
        },
      };
    }

    if (phone.prefix && phone.value) {
      convertPhone = `${phone.prefix}${phone.value.toString()}`;
    }

    dispatch(
      updateProfileCandidate({
        id,
        params: {
          phone: convertPhone.replace(/^(\+\d+?)0/, "$1"),
          address,
          title,
          hourlyRate,
          radius,
        },
      })
    );
  }

  return {
    onUpdate,
    isChanged,
    saveCompany,
    saveCandidate,
    temp,
    saveUserCandidate,
    isChangedDocument,
    isChangedNotification,
    saveNotificationUser,
    load,
  };
}

export function useLogo() {
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();

  function getFile(data) {
    setFile(data);
  }

  function reset() {
    setFile(null);
  }

  async function save(id, action) {
    const resizedImage = await resizeFile(file, 250, 250);

    dispatch(action({ id, file: resizedImage }));
    reset();
  }

  return {
    theFile: file,
    getFile,
    reset,
    save: (id) => save(id, postLogo),
    saveImageCandidate: (id) => save(id, postImage),
  };
}
