import {
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
} from "@heroicons/react/20/solid";
import { FadeIn } from "../components/utils/FadeIn";

export default function AboutTempME() {
  const stats = [
    { label: "Founded", value: "2023" },
    { label: "Employees", value: "5" },
    { label: "Countries", value: "3" },
    { label: "Raised", value: "$3M" },
  ];

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-4">
            <img
              className="object-cover "
              src={require("../assets/img/peace.jpeg")}
              alt=""
            />
          </div>
          <div>
            <FadeIn className="text-base leading-7 text-gray-700 lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-sky-600">
                Our story
              </p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                The Dawn of a New Professional Era :{" "}
                <span className="text-sky-600">The TempME Story</span>
              </h1>
              <div className="max-w-xl">
                <p className="mt-6">
                  Once upon a bustling modern world, the dream of work-life
                  harmony was a distant star in the sky for many. Workers longed
                  for the perfect blend of professional and personal life, while
                  companies were in a relentless quest to fill their skill gaps
                  without increasing their fixed costs. It was against this
                  backdrop that TempME emerged, a beacon of hope in the sea of
                  career opportunities.
                </p>
                <p className="mt-8">
                  TempME isn't just a platform; it's a movement, a revolution
                  that empowers everyone to take control of their work life.
                  Here, both individuals and businesses discover that a
                  different way of working is not only possible—it's
                  exhilarating.
                </p>
                <p className="mt-8">
                  Picture a world where you choose when and where to work,
                  diving into diverse industries, meeting inspiring minds, and
                  accumulating a wealth of skills along the way. TempME makes
                  this a reality for the bold professionals who dare to take the
                  step.
                </p>
                <p className="mt-8">
                  For businesses, TempME unlocks a world of passionate, agile
                  talent, ready to bring their expertise and fresh energy. It’s
                  a chance to bid farewell to staffing shortages and welcome a
                  workforce that’s flexible and committed.
                </p>
                <p className="mt-8">
                  Behind every gig on TempME is a success story, an improved
                  chapter of life, a stride towards the future. There are
                  parents who now have the ability to take their children to
                  school and still pursue their careers, students financing
                  their studies while building an impressive resume, and
                  businesses thriving through agility and innovation.
                </p>
                <p className="mt-8">
                  Joining TempME means embracing a community of pioneers and
                  innovators, where each gig is more than a temporary job; it's
                  an adventure, an experience, a stepping stone to the next
                  summit in your professional journey.
                </p>
                <p className="mt-8">
                  So why wait? Open the door to new possibilities, enrich your
                  career and personal journey, and write your own story with
                  TempME.
                </p>
              </div>
            </FadeIn>
            <dl className="mt-10 grid grid-cols-2 gap-8 border-t border-gray-900/10 pt-10 sm:grid-cols-4">
              {stats.map((stat, statIdx) => (
                <div key={statIdx}>
                  <dt className="text-sm font-semibold leading-6 text-gray-600">
                    {stat.label}
                  </dt>
                  <dd className="mt-2 text-3xl font-bold leading-10 tracking-tight text-gray-900">
                    {stat.value}
                  </dd>
                </div>
              ))}
            </dl>
            <div className="mt-10 flex">
              <a
                href="/registration"
                className="text-base font-semibold leading-7 text-sky-600"
              >
                Learn more about TempME <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
