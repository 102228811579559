import React from "react";

function GoBack() {
  return (
    <div className="w-full inline-flex  items-center space-x-2 ">
      <div
        onClick={() => window.history.back()}
        className="inline-flex  pl-3 pt-2 items-center space-x-2 group-hover:text-sky-500 cursor-pointer"
      >
        <svg
          viewBox="0 0 512 512"
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4 "
          color="currentColor"
        >
          <g id="_4" data-name="4">
            <path d="m492 276h-472a20 20 0 0 1 0-40h472a20 20 0 0 1 0 40z" />
            <path d="m189 445a19.937 19.937 0 0 1 -14.142-5.858l-169-169a20 20 0 0 1 0-28.284l169-169a20 20 0 0 1 28.284 28.284l-154.858 154.858 154.858 154.858a20 20 0 0 1 -14.142 34.142z" />
          </g>
        </svg>
        <span className="text-xs md:text-sm font-semibold">Go back</span>
      </div>
    </div>
  );
}

export default GoBack;
