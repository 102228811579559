import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/index.css";
import "./assets/css/transistion.css";

import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { mainStore } from "./redux";
import DirectionRoute from "./router";
import Toast from "./components/utils/Toaster";
import ActionRemoveAccount from "./components/modal/actionRemoveAccount";

const root = ReactDOM.createRoot(document.getElementById("root"));

function Index() {
  return root.render(
    <Router
      basename="/"
      future={{
        v7_startTransition: true,
      }}
    >
      <Provider store={mainStore}>
        <DirectionRoute />
        <Toast />
        <ActionRemoveAccount />
      </Provider>
    </Router>
  );
}

Index();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
