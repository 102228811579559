import { Fragment, useEffect } from "react";
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
  FireIcon,
  FlagIcon,
  MapPinIcon,
} from "@heroicons/react/20/solid";
import { Menu, Transition } from "@headlessui/react";
import { useCalendar } from "../../../hook/calendar";
import { useSelector } from "react-redux";
import { baseUrl } from "../../../utils";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Upcoming() {
  const {
    days,
    nextMonth,
    previousMonth,
    formattedMonth,
    goToToday,
    selectDate,
    selectedDate,
  } = useCalendar();
  const { candidate } = useSelector((state) => state.candidateSlice);

  return (
    <div>
      <h2 className="text-sky-400 text-lg md:text-xl font-medium mb-4">
        Upcoming Shift
      </h2>
      <div className="lg:grid lg:grid-cols-12 lg:gap-x-16">
        <div className="mt-10 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-9">
          <div className="flex items-center text-gray-900">
            <button
              type="button"
              onClick={previousMonth}
              className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <div className="flex-auto font-semibold">{formattedMonth}</div>
            <button
              type="button"
              onClick={nextMonth}
              className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">Next month</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
            <div>M</div>
            <div>T</div>
            <div>W</div>
            <div>T</div>
            <div>F</div>
            <div>S</div>
            <div>S</div>
          </div>
          <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
            {days.map((day, dayIdx) => (
              <button
                key={day.date}
                onClick={() => selectDate(new Date(day.date))}
                type="button"
                className={classNames(
                  "py-1.5 hover:bg-gray-100 focus:z-10",
                  day.isCurrentMonth && !day.isToday
                    ? "bg-white"
                    : "bg-gray-50",
                  (day.isSelected || day.isToday) && "font-semibold",
                  day.isSelected && "text-white",
                  !day.isSelected &&
                    day.isCurrentMonth &&
                    !day.isToday &&
                    "text-gray-900",
                  !day.isSelected &&
                    !day.isCurrentMonth &&
                    !day.isToday &&
                    "text-gray-400",
                  day.isToday && !day.isSelected && "text-white bg-sky-500",
                  dayIdx === 0 && "rounded-tl-lg",
                  dayIdx === 6 && "rounded-tr-lg",
                  dayIdx === days.length - 7 && "rounded-bl-lg",
                  dayIdx === days.length - 1 && "rounded-br-lg"
                )}
              >
                <time
                  dateTime={day.date}
                  className={classNames(
                    "mx-auto flex h-7 w-7 items-center justify-center rounded-full",
                    day.isSelected && day.isToday && "bg-sky-400",
                    day.isSelected && !day.isToday && "bg-gray-900"
                  )}
                >
                  {day.date.split("-").pop().replace(/^0/, "")}
                </time>
                {candidate &&
                  candidate.events.filter((el) =>
                    day.date
                      ? new Date(el.startDate).toLocaleDateString() ===
                        new Date(day.date).toLocaleDateString()
                      : new Date(el.startDate).toLocaleDateString() ===
                        new Date().toLocaleDateString()
                  ).length > 0 && (
                    <FireIcon className="h-5 w-5 ms-5 text-orange-500 animate-pulse" />
                  )}
              </button>
            ))}
          </div>
        </div>

        <div className="lg:hidden inline-flex items-center justify-center w-full">
          <hr className="w-64 h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
          <span className="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900">
            {candidate && candidate.events.length} event(s)
          </span>
        </div>

        <ol className="mt-4 divide-y divide-gray-100 text-sm max-h-96 overflow-hidden hover:overflow-y-auto leading-6 lg:col-span-7 xl:col-span-8">
          {candidate &&
          candidate.events.filter((el) =>
            selectedDate
              ? new Date(el.startDate).toLocaleDateString() ===
                new Date(selectedDate).toLocaleDateString()
              : new Date(el.startDate).toLocaleDateString() ===
                new Date().toLocaleDateString()
          ).length > 0 ? (
            candidate.events
              .filter((el) =>
                selectedDate
                  ? new Date(el.startDate).toLocaleDateString() ===
                    new Date(selectedDate).toLocaleDateString()
                  : new Date(el.startDate).toLocaleDateString() ===
                    new Date().toLocaleDateString()
              )
              .map((event) => (
                <li key={event.id} className="relative flex space-x-6 py-6 ">
                  <img
                    src={event.logo}
                    alt="img"
                    className="h-14 w-14 flex-none rounded-lg"
                  />
                  <div className="flex-auto">
                    <h3 className="pr-10 font-semibold leading-none text-gray-900 xl:pr-0">
                      {event.title}
                    </h3>
                    <dl className="mt-2 flex flex-col text-gray-500 xl:flex-row">
                      <div className="flex items-start space-x-3">
                        <dt className="mt-0.5">
                          <span className="sr-only">Date</span>
                          <CalendarIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </dt>
                        <dd>
                          <time
                            dateTime={event?.startDate}
                            className="text-xs md:text-sm"
                          >
                            {event?.startDate} at {event?.startHour}
                          </time>
                        </dd>
                      </div>
                      <div className="mt-2 flex items-start space-x-3 xl:mt-0 xl:ml-3.5 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                        <dt className="mt-0.5">
                          <span className="sr-only">Location</span>
                          <MapPinIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </dt>
                        <dd className="text-xs md:text-sm">
                          {event?.location}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </li>
              ))
          ) : (
            <>{`No events events for ${new Date(
              selectedDate
            ).toLocaleDateString()}`}</>
          )}
        </ol>
      </div>
    </div>
  );
}
