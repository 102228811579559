import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchConversation,
  setTabConvSelected,
} from "../../redux/conversation";

const tabs = [
  { name: "General", href: "G", count: null, current: true },
  { name: "From support", href: "Fs", count: null, current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TabMessages() {
  const { tabConvSelected, adminHasSentMessage } = useSelector(
    (state) => state.conversationSlice
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchConversation());
  }, [tabConvSelected]);

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          onChange={(e) => dispatch(setTabConvSelected(e.currentTarget.value))}
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
          defaultValue={tabs.find((tab) => tab.href === tabConvSelected).name}
        >
          {tabs.map((tab) => (
            <option value={tab.href} key={tab.name}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                role="button"
                key={tab.name}
                onClick={() => dispatch(setTabConvSelected(tab.href))}
                className={classNames(
                  tab.href === tabConvSelected
                    ? "border-sky-500 text-sky-600"
                    : "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700",
                  "flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium  relative"
                )}
                aria-current={tab.current ? "page" : undefined}
              >
                {tab.name}
                {adminHasSentMessage && tab.href === "Fs" ? (
                  <span
                    className={classNames(
                      tab.current ? " bg-red-400" : " bg-red-600",
                      " hidden absolute -right-2 top-4 w-2 h-2 animate-pulse rounded-full font-medium md:inline-block"
                    )}
                  ></span>
                ) : null}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
