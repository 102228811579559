import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { User } from "../services/user";
import Roles from "../Enum/UserRoles";
import { setShowWithTimeout } from "./toast";

const user = new User();

export const createUser = createAsyncThunk(
  "RegistrationSlice/createUser",
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState();

    const {
      type,
      firstName,
      lastName,
      phone,
      email,
      location,
      password,
      country,
      name,
      prefix,
    } = state.RegistrationSlice;

    await user.postUser({
      role: type.id,
      firstName: firstName,
      lastName: lastName,
      phone: `${prefix}${phone}`,
      email: email,
      location: location,
      password: password,
      country: country ? country : 1,
      name: name,
    });

    try {
    } catch (error) {
      console.log("Erreur dans formatData :", error);
    }
  }
);

export const checkEmail = createAsyncThunk(
  "RegistrationSlice/checkEmail",
  async (arg, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const { email } = state.RegistrationSlice;

      return await user.checkEmail(email);
    } catch (error) {
      console.log("Erreur dans formatData :", error);
    }
  }
);

export const sendEmailContact = createAsyncThunk(
  "landing/contact",

  async (arg, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const { email, firstName, lastName, phone, description } =
        state.RegistrationSlice;

      await user
        .contact({
          data: {
            email,
            name: [firstName, lastName].join(" "),
            phone,
            description,
          },
          date: new Date(),
        })
        .then(() => {
          thunkAPI.dispatch(setShowWithTimeout());
          thunkAPI.dispatch(reset());
        });
    } catch (error) {
      console.log("Erreur dans formatData :", error);
    }
  }
);

export const RegistrationSlice = createSlice({
  name: "RegistrationSlice",
  initialState: {
    type: null,
    stage: 0,
    values: {},
    prefix: "+44",
    loading: false,
    modalOpen: false,
    firstName: null,
    lastName: null,
    phone: null,
    email: null,
    description: null,
    password: null,
    passwordConfirmation: null,
    name: null,
    country: null,
    location: null,
    emailUsed: false,
    PendingeSent: false,
    role: "ADMIN",
  },
  reducers: {
    setName: (state, action) => {
      state.name = action.payload.target.value;
    },
    setFirstName: (state, action) => {
      const isValid = new RegExp("^[a-zA-Z\\s-]*$").test(
        action.payload.target.value
      );
      if (isValid && action.payload.target.value !== "") {
        state.firstName = action.payload.target.value;
      } else {
        state.firstName = "";
      }
    },
    setLastName: (state, action) => {
      const isValid = new RegExp("^[a-zA-Z\\s-]*$").test(
        action.payload.target.value
      );
      if (isValid && action.payload.target.value !== "") {
        state.lastName = action.payload.target.value;
      } else {
        state.lastName = "";
      }
    },
    setPhone: (state, action) => {
      const isValid = new RegExp("^[+]?[0-9s]{0,15}$").test(
        action.payload.target.value
      );
      if (isValid && action.payload.target.value !== "") {
        state.phone = action.payload.target.value;
      } else {
        state.phone = "";
      }
    },
    setDescription: (state, action) => {
      state.description = action.payload.target.value;
    },
    setEmail: (state, action) => {
      state.email = action.payload.target.value;
    },
    setPassword: (state, action) => {
      state.password = action.payload.target.value;
    },
    setPasswordConfirmation: (state, action) => {
      state.passwordConfirmation = action.payload.target.value;
    },
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    setCountry: (state, action) => {
      state.country = action.payload.target.value;
    },
    setStage: (state, action) => {
      state.stage = action.payload;
    },
    setPrefix: (state, action) => {
      state.prefix = action.payload.target.value;
    },

    goBack: (state) => {
      if (state.stage >= 1) {
        state.stage = state.stage - 1;
      }
    },
    setOpen(state) {
      state.modalOpen = !state.modalOpen;
    },
    goNext: (state) => {
      state.stage = state.stage + 1;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload.target.value;
    },
    reset: (state) => {
      state.firstName = "";
      state.lastName = "";
      state.email = "";
      state.description = "";
      state.phone = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(createUser.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(checkEmail.pending, (state) => {
        state.emailUsed = false;
      })
      .addCase(checkEmail.fulfilled, (state, action) => {
        if (action.payload && action.payload.status) {
          state.emailUsed = true;
        }
      })
      .addCase(sendEmailContact.pending, (state, action) => {
        state.PendingeSent = true;
      })
      .addCase(sendEmailContact.fulfilled, (state, action) => {});
  },
});

export const {
  setValues,
  setStage,
  setPrefix,
  createUserSuccess,
  setName,
  setFirstName,
  setLastName,
  setPhone,
  setEmail,
  setPassword,
  setPasswordConfirmation,
  setLocation,
  setCountry,
  setDescription,
  setRole,
  reset,
} = RegistrationSlice.actions;
