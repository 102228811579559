import { useDispatch, useSelector } from "react-redux";
import { useApplicationsCandidate } from "../../../hook/application";
import moment from "moment";
import { setPage, setShiftDate } from "../../../redux/application";
import Tabs from "./tabs";
import { Link } from "react-router-dom";
import Application from "../../../Enum/Applicatition";
import Stars from "../../../components/rating/Stars";

const people = [
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  // More people...
];

export default function History() {
  useApplicationsCandidate();
  const { applications, limit, page } = useSelector(
    (state) => state.ApplicationSlice
  );
  const dispatch = useDispatch();

  const start = (page - 1) * limit + 1;
  const end = Math.min(page * limit, applications.total);
  return (
    <div className="px-4 mt-5 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Applications
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            See here your applications historics
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Link
            to={"/jobs"}
            className="block rounded-md bg-sky-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
          >
            Apply now
          </Link>
        </div>
      </div>
      <div className="-mx-4 mt-8 sm:-mx-0">
        <Tabs />
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
              >
                Company
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Position
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Status
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Date
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {applications.applications &&
              applications.applications.map((application) => (
                <tr key={application.id}>
                  <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                    {application.job.company.name}
                    <dl className="font-normal lg:hidden">
                      <dt className="sr-only">position</dt>
                      <dd className="mt-1 truncate text-gray-700">
                        {application.job.jobName}
                        <Stars
                          number={application.shifts[0]?.starRating?.candidate}
                        />
                      </dd>
                      <dt className="sr-only sm:hidden">Status</dt>
                      <dd className="mt-1 truncate text-gray-500 sm:hidden">
                        {application.status}
                      </dd>
                      <dt className="sr-only sm:hidden">Date</dt>
                      <dd className="mt-1 truncate text-gray-500 sm:hidden">
                        {moment
                          .unix(application.applicationDate)
                          .local()
                          .fromNow()}
                      </dd>
                    </dl>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                    <div className="flex flex-col space-y-2 ">
                      <span>{application.job.jobName}</span>
                      <span className="text-sm">
                        <Stars
                          number={application.shifts[0]?.starRating?.candidate}
                        />
                      </span>
                    </div>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {application.status}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {moment.unix(application.applicationDate).local().fromNow()}
                  </td>
                  <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                    <Link
                      to={
                        application.status === Application.ACCEPTED
                          ? "/upcoming"
                          : ""
                      }
                      onClick={() =>
                        dispatch(
                          setShiftDate(new Date(application.job.startDate))
                        )
                      }
                      className={
                        application.status === Application.ACCEPTED
                          ? "text-sky-600 hover:text-sky-900"
                          : "text-gray-500/50 cursor-not-allowed"
                      }
                    >
                      Shift<span className="sr-only">, {application.id}</span>
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <nav
        className="flex  w-full items-center justify-between  bg-white px-4 py-3 sm:px-6"
        aria-label="Pagination"
      >
        <div className="hidden sm:block">
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{start}</span> to{" "}
            <span className="font-medium">{end}</span> of{" "}
            <span className="font-medium">{applications.total}</span> results
          </p>
        </div>
        <div className="flex flex-1 justify-between sm:justify-end">
          <button
            onClick={() => dispatch(setPage(page - 1))}
            disabled={start === 1}
            className=" disabled:bg-slate-400/40 disabled:text-white relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
          >
            Previous
          </button>
          <button
            disabled={end === applications.total}
            onClick={() => dispatch(setPage(page + 1))}
            className="disabled:bg-slate-400/40 disabled:text-white relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
          >
            Next
          </button>
        </div>
      </nav>
    </div>
  );
}
