const { baseUrl } = require("../../../utils");

function convertTo24HourFormat(timeStr) {
  const [time, period] = timeStr.split(" ");
  let [hours, minutes] = time.split(":");
  hours = parseInt(hours);
  minutes = parseInt(minutes);

  if (period === "PM" && hours !== 12) {
    hours += 12;
  } else if (period === "AM" && hours === 12) {
    hours = 0;
  }

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
}

export default function getEventsCompany(data) {
  const events = [];

  for (const shift of data) {
    const job = shift.application.job;

    // Convert start and end hours to 24-hour format
    const startTimeStr = `${job.startDate}T${convertTo24HourFormat(
      job.startHour
    )}`;
    const endTimeStr = `${job.startDate}T${convertTo24HourFormat(job.endHour)}`;

    // Create Date objects in UTC timezone
    const startTime = new Date(startTimeStr + "Z");
    const endTime = new Date(endTimeStr + "Z");

    // Calculate duration in minutes
    const duration = (endTime - startTime) / (1000 * 60);

    events.push({
      title: `${job.jobName}`,
      applicationDate: shift.application.applicationDate,
      candidateName: [
        shift.application.candidate.firstName,
        shift.application.candidate.lastName,
      ].join(" "),
      imageUrl: shift.application?.candidate?.candidateProfile?.profile?.image
        ? shift.application?.candidate?.candidateProfile?.profile?.image
        : null,
      location: `${job.location.city}`,
      startDate: `${job.startDate}`,
      startHour: `${job.startHour}`,
      endHour: `${job.endHour}`,
      startTime: startTimeStr,
      skills: job.skill,
      duration: duration,
      id: shift.id,
    });
  }

  return events;
}
