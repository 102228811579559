import { useSelector, useDispatch } from "react-redux";
import { useEffect, useCallback } from "react";
import {
  fetchNotification,
  fetchNotificationCount,
  logNotificationPage,
} from "../redux/conversation";

export default function useNotifications() {
  const dispatch = useDispatch();
  const { notifications, notifOpen } = useSelector(
    (state) => state.conversationSlice
  );

  

  const fetchData = useCallback(() => {
    dispatch(fetchNotification());
  }, [dispatch]);

  const sendUpdateLog = useCallback(() => {
    dispatch(logNotificationPage());
  }, [dispatch]);

  const fetchCount = useCallback(() => {
    dispatch(fetchNotificationCount());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
    fetchCount();

    if (notifOpen) {
      sendUpdateLog();
    }
  }, [fetchData, notifOpen]);

  return {
    notifications,
  };
}
