import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { setCloseProfileSide } from "../../redux/admin";
import { baseUrl } from "../../utils";
import { Link } from "react-router-dom";

export default function SideProfile() {
  const { openProfileSide } = useSelector((state) => state.adminSlice);
  const { admin } = useSelector((state) => state.adminSlice);
  const dispatch = useDispatch();
  const navigation = [
    { name: "Profile", href: "#", current: true },
    { name: "Log out", href: "/login", current: false },
  ];
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const logout = async () => {
    const token = localStorage.getItem("userToken");
    await fetch([baseUrl, "api/login/out"].join("/"), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(() => {
      localStorage.clear();
      window.location.replace("/login");
    });

    // Rediriger l'utilisateur ou mettre à jour l'état de l'interface utilisateur
  };

  return (
    <Transition.Root show={openProfileSide} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => dispatch(setCloseProfileSide())}
      >
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden ">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex divide-y  mt-16 rounded-bl-xl lg:mt-1 flex-col overflow-y-scroll  bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          <div className="px-4 py-3">
                            <p className="text-sm">Signed in as</p>
                            <p className="truncate text-sm font-medium text-gray-900">
                              {admin && admin.name}
                            </p>
                          </div>
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                            onClick={() => dispatch(setCloseProfileSide())}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="px-4 sm:px-6">
                      <>
                        <Link
                          to={"/profile"}
                          className={classNames(
                            "block px-4 py-2 text-sm font-medium text-gray-700"
                          )}
                        >
                          Account settings
                        </Link>
                        <a
                          type="button"
                          onClick={logout}
                          className={classNames(
                            "block px-4 py-2 text-sm cursor-pointer font-medium text-gray-700"
                          )}
                        >
                          Sign out
                        </a>
                      </>
                    </div>

                    {/*  <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      <nav
                        className="flex flex-1 flex-col"
                        aria-label="Sidebar"
                      >
                        <ul role="list" className="-mx-2 space-y-1">
                          {navigation.map((item) => (
                            <li key={item.name}>
                              <a
                                onClick={
                                  item.href === "/login"
                                    ? () => {
                                        localStorage.clear();
                                        window.location.replace("/");
                                      }
                                    : {}
                                }
                                href={item.href}
                                className={classNames(
                                  "text-gray-700 hover:text-sky-600 hover:bg-gray-50",
                                  "group flex gap-x-3 rounded-md p-2 pl-3 text-sm leading-6 font-semibold"
                                )}
                              >
                                {item.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </nav>
                    </div> */}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
