import { ApiClient } from "./api";

export class Application {
  constructor() {
    this.apiClient = new ApiClient();
  }

  async getApplications() {
    try {
      const { data } = await this.apiClient.get(`application`);

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async getApplicationsCandidateHistorics(page, limit,filter) {
    try {
      const { data } = await this.apiClient.get(
        `application/candidate/historics?page=${page}&limit=${limit}&filter=${filter}`
      );

      return data;
    } catch (error) {
      console.error(error);
    }
  }
}
