import { createSlice } from "@reduxjs/toolkit";

export const NavigationSlice = createSlice({
  name: "NavigationSlice",
  initialState: {
    stage: 0,
  },
  reducers: {
    setStage: (state, action) => 
    {
      state.stage = action.payload;
    },
  },
});


export const { setStage } = NavigationSlice.actions