import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Companies } from "../services/companies";
import { fetchCompany } from "./company";

const companyApi = new Companies();

export const addTeamsMembers = createAsyncThunk(
  "TeamSlice/addTeamsMembers",
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState();
    const { firstName, lastName, email, role } = state.TeamSlice;
    const params = {
      member: {
        firstName: firstName,
        lastName: lastName,
        email: email,
        role: role,
      },
    };
    const { company } = state.companySlice;
    await companyApi.updateProfile(company.id, params);
    thunkAPI.dispatch(fetchCompany());
  }
);

export const TeamSlice = createSlice({
  name: "TeamSlice",
  initialState: {
    open: false,
    firstName: null,
    lastName: null,
    email: null,
    role: "MANAGER",
    pending: false,
    openSideEdit: false,
    teamMember: {},
  },
  reducers: {
    setToogle: (state) => {
      state.open = !state.open;
    },
    setClose: (state) => {
      state.open = false;
    },
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setOpenSideEdit: (state, action) => {
      state.openSideEdit = action.payload;
    },
    setTeamMember: (state, action) => {
      state.teamMember = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addTeamsMembers.fulfilled, (state, action) => {
        state.email = null;
        state.role = "ADMIN";
        state.firstName = null;
        state.lastName = null;
        state.pending = false;
        state.open = false;
      })
      .addCase(addTeamsMembers.pending, (state, action) => {
        state.pending = true;
      });
  },
});

export const {
  setToogle,
  setClose,
  setFirstName,
  setLastName,
  setEmail,
  setRole,
  setOpenSideEdit,
  setTeamMember,
} = TeamSlice.actions;
