import { Fragment, useEffect, useState } from "react";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import ReactStars from "react-rating-stars-component";
import {
  ArrowLeftIcon,
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  ArrowTrendingUpIcon,
  BanknotesIcon,
  CheckBadgeIcon,
  CheckIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import {
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  StarIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import QRCode from "react-qr-code";
import { shiftOne, updateShift, updateStarRating } from "../../redux/shift";
import { Watch } from "react-loader-spinner";
import { convertTo24HourFormat, formatName } from "../../utils";
import useCurrentBreakpoint from "../../hook/tailwindBreakPoint";
import moment from "moment";
import Shift from "../../Enum/shift";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const navigation = [
  {
    name: "Clock in",
    icon: ArrowLongRightIcon,
    passed: (shift) => {
      return shift.tracking.clock_in.status;
    },
    current: (shift) => {
      return (
        !shift.tracking.clock_in.status &&
        !shift.tracking.clock_out.status &&
        !shift.paid &&
        !shift.rated
      );
    },
    outTime: (shift) => {
      const time = moment(
        ` ${shift.application.job.startDate} ${shift.application.job.startHour}`,
        "YYYY-MM-DD hh:mm A"
      ).add(10, "minutes");

      const now = moment();

      now.format("YYYY-MM-DD hh:mm A");
      time.format("YYYY-MM-DD hh:mm A");

      return now.isAfter(time);
    },
    cancel: (shift) => {
      return shift.status === Shift.CANCEL;
    },
  },

  {
    name: "Clock out",
    icon: ArrowLongLeftIcon,
    passed: (shift) => {
      return shift.tracking.clock_out.status;
    },
    current: (shift) => {
      return (
        shift.tracking.clock_in.status &&
        !shift.tracking.clock_out.status &&
        !shift.paid &&
        !shift.rated
      );
    },
    outTime: (shift) => false,
    cancel: (shift) => {
      return shift.status === Shift.CANCEL;
    },
  },
  {
    name: "Pay",
    icon: BanknotesIcon,
    passed: (shift) => {
      return (
        shift.tracking.clock_in.status &&
        shift.tracking.clock_out.status &&
        shift.paid
      );
    },
    current: (shift) => {
      return (
        shift.tracking.clock_in.status &&
        shift.tracking.clock_out.status &&
        !shift.paid
      );
    },
    outTime: (shift) => false,
  },
  {
    name: "Rating",
    icon: StarIcon,
    passed: (shift) => {
      return (
        shift.tracking.clock_in.status &&
        shift.tracking.clock_out.status &&
        shift.paid &&
        shift.rated
      );
    },
    current: (shift) => {
      return (
        shift.tracking.clock_in.status &&
        shift.tracking.clock_out.status &&
        shift.paid &&
        !shift.rated
      );
    },
    outTime: (shift) => false,
    cancel: (shift) => {
      return shift.status === Shift.CANCEL;
    },
  },
];

export default function CommandShift() {
  const [query, setQuery] = useState("");
  const [stars, setStars] = useState(1);
  const [toogleYn, setToogleYn] = useState(false);
  const brk = useCurrentBreakpoint();
  const { shifPunch, loading, oneShift, idShift } = useSelector(
    (state) => state.ShiftSlice
  );

  const [confirm, setConfirmation] = useState(false);
  const [canCancel, setCanCancel] = useState(false);

  const { id } = useSelector((state) => state.authenticationSlice);
  const dispatch = useDispatch();
  const brkPoint = useCurrentBreakpoint();

  function toogle() {
    setToogleYn(!toogleYn);
  }

  const confirmClockIn = () => {
    const temp = JSON.parse(JSON.stringify(oneShift));
    temp.tracking.clock_in.status = true;
    dispatch(updateShift({ id: idShift, params: temp }));
  };

  const confirmClockOut = () => {
    const temp = JSON.parse(JSON.stringify(oneShift));
    temp.tracking.clock_out.status = true;
    temp.paid = true;
    dispatch(updateShift({ id: idShift, params: temp }));
  };

  const noShow = () => {
    const temp = JSON.parse(JSON.stringify(oneShift));
    temp.status = Shift.NO_SHOW;
    dispatch(updateShift({ id: idShift, params: temp }));
    setToogleYn(false);
  };
  const cancelShift = () => {
    const temp = JSON.parse(JSON.stringify(oneShift));
    temp.status = Shift.CANCEL;
    dispatch(updateShift({ id: idShift, params: temp }));
    setToogleYn(false);
  };
  const confirmRating = () => {
    dispatch(
      updateStarRating({
        id: idShift,
        params: {
          candidate: stars,
        },
      })
    );

    const temp = JSON.parse(JSON.stringify(oneShift));
    temp.rated = true;
    dispatch(updateShift({ id: idShift, params: temp }));
  };

  useEffect(() => {
    dispatch(shiftOne({ id: idShift }));
  }, [idShift]);

  return (
    <Transition.Root show={shifPunch} as={Fragment} appear>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() =>
          dispatch({
            type: "ShiftSlice/setClose",
          })
        }
      >
        <Transition.Child
          as={Fragment}
          enter={(brk !== "sm" || brk !== "xs") && "ease-out duration-300"}
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave={(brk !== "sm" || brk !== "xs") && "ease-in duration-200"}
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed flex justify-center inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto py-20 px-4  md:p-20">
          <Transition.Child
            as={Fragment}
            enter={(brk !== "sm" || brk !== "xs") && "ease-out duration-300"}
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave={(brk !== "sm" || brk !== "xs") && "ease-in duration-200"}
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-2xl  transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              {oneShift && !loading && (
                <>
                  <div className="inline-flex w-full p-2">
                    <div className="inline-flex space-x-2 w-full justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4 sm:w-5 sm:h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
                        />
                      </svg>
                      <span className="font-medium text-xs sm:text-sm whitespace-nowrap">
                        {" "}
                        {["xs", "sm"].includes(brkPoint)
                          ? formatName(oneShift.application.job.jobName)
                          : oneShift.application.job.jobName}{" "}
                      </span>
                    </div>
                    <div className="inline-flex w-full space-x-2 justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4 sm:w-5 sm:h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                        />
                      </svg>

                      <span className="font-medium text-xs sm:text-sm whitespace-nowrap ">
                        {" "}
                        {["xs", "sm"].includes(brkPoint)
                          ? formatName(
                              [
                                oneShift.application.candidate.firstName,
                                oneShift.application.candidate.lastName,
                              ].join(" ")
                            )
                          : [
                              oneShift.application.candidate.firstName,
                              oneShift.application.candidate.lastName,
                            ].join(" ")}{" "}
                      </span>
                    </div>
                  </div>
                  <div className="h-fit  grid grid-cols-7 p-4">
                    <div className="sm:col-span-3 col-span-full">
                      <nav
                        className="flex flex-1  sm:flex-col"
                        aria-label="Sidebar"
                      >
                        <ul
                          role="list"
                          className="flex flex-1 flex-col gap-y-7 sm:border-r sm:pe-4"
                        >
                          <li>
                            <ul role="list" className="-mx-2 space-y-1">
                              {navigation.map((item) => (
                                <li key={item.name}>
                                  <a
                                    className={classNames(
                                      item.current(oneShift)
                                        ? item.outTime(oneShift) ||
                                          item.cancel(oneShift) ||
                                          oneShift.status === Shift.NO_SHOW
                                          ? "bg-red-500 text-white"
                                          : "bg-sky-500 text-white animate-pulse"
                                        : item.passed(oneShift)
                                        ? "bg-green-500 text-white"
                                        : "text-gray-700 hover:text-sky-600 hover:bg-gray-100",
                                      "group flex gap-x-3 rounded-md px-2 py-2  sm:py-8 text-xs sm:text-sm leading-6 whitespace-nowrap font-semibold"
                                    )}
                                  >
                                    <item.icon
                                      className={classNames(
                                        item.current(oneShift) ||
                                          item.passed(oneShift)
                                          ? "text-white"
                                          : "text-gray-400 group-hover:text-sky-600",
                                        "h-6 w-6 shrink-0"
                                      )}
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                    {item.passed(oneShift) ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className={` h-6 ml-auto w-9 min-w-max shadow-sky-200  ${
                                          item.passed(oneShift) &&
                                          "text-green-300"
                                        } `}
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                        />
                                      </svg>
                                    ) : null}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </li>
                        </ul>
                      </nav>
                    </div>

                    <div className="sm:col-span-4 col-span-full p-4 flex items-center justify-center">
                      {!oneShift.tracking.clock_in.status &&
                        !oneShift.tracking.clock_out.status &&
                        !oneShift.paid &&
                        !oneShift.rated && (
                          <div className="flex flex-col relative">
                            {(navigation[0].outTime(oneShift) ||
                              navigation[0].cancel(oneShift) ||
                              oneShift.status === Shift.NO_SHOW) && (
                              <div className="w-full left-0 top-20  duration-300 transition-transform min-h-20 absolute flex  flex-col justify-center items-center rounded-md bg-sky-500 shadow-md ring-1 text-white text-lg font-bold p-4 ring-fuchsia-50 z-20">
                                <span className="">Time out</span>
                                <span
                                  onClick={() =>
                                    [Shift.NO_SHOW, Shift.CANCEL].includes(
                                      oneShift.status
                                    )
                                      ? {}
                                      : toogle()
                                  }
                                  className=" text-yellow-200 cursor-pointer"
                                >
                                  {oneShift.status === Shift.NO_SHOW
                                    ? " No show "
                                    : oneShift.status === Shift.CANCEL
                                    ? "Cancelled"
                                    : "No show ?"}{" "}
                                </span>

                                <div
                                  className={` ${
                                    toogleYn ? "inline-flex" : "hidden"
                                  } space-x-2 mt-3 transition-all `}
                                >
                                  <button
                                    onClick={toogle}
                                    className=" font-medium py-1 px-5 text-orange-600 hover:bg-orange-300 bg-orange-200 rounded-md "
                                  >
                                    No
                                  </button>
                                  <button
                                    onClick={noShow}
                                    className="font-medium text-teal-600 bg-teal-200 hover:bg-teal-300 rounded-md py-1 px-5"
                                  >
                                    Yes
                                  </button>
                                </div>
                              </div>
                            )}
                            <QRCode
                              className={`${
                                (navigation[0].outTime(oneShift) ||
                                  navigation[0].cancel(oneShift) ||
                                  oneShift.status === Shift.NO_SHOW) &&
                                "blur-sm"
                              }`}
                              value={JSON.stringify({
                                type: "in",
                                value:
                                  oneShift && oneShift.tracking.clock_in.value,
                                id: oneShift.id,
                                trgt: id,
                              })}
                            />

                            {confirm && (
                              <div className="flex  mt-3 justify-center items-center flex-col space-y-3 ">
                                <p className="mb-0 text-xs text-center">
                                  please note that you can cancel the shift
                                  until 24 hours before the begining after that
                                  you won't be refunded if you cancel it
                                </p>
                                <div className="inline-flex px-4  space-x-2 w-full justify-center">
                                  <button
                                    onClick={() => setConfirmation(false)}
                                    className="btn bg-orange-400 text-white hover:bg-orange-600 items-center inline-flex"
                                  >
                                    <ArrowLeftIcon className="h-4 w-4" />

                                    <span className="text-xs  md:text-sm">
                                      Back
                                    </span>
                                  </button>
                                  <button
                                    onClick={cancelShift}
                                    className="btn bg-sky-400  text-white hover:bg-sky-600 items-center inline-flex"
                                  >
                                    <CheckIcon className="h-4 w-4" />
                                    <span className="text-xs  md:text-sm">
                                      Confirm
                                    </span>
                                  </button>
                                </div>
                              </div>
                            )}
                            {!confirm && (
                              <div className="flex  mt-3 justify-center items-center flex-col space-y-3 ">
                                <p className="mb-0 text-xs text-center">
                                  please note that you can cancel the shift
                                  until 24 hours before the begining after that
                                  you won't be refunded if you cancel it
                                </p>
                                <div className="inline-flex px-4  space-x-2 w-full justify-center">
                                  <button
                                    disabled={
                                      navigation[0].outTime(oneShift) ||
                                      navigation[0].cancel(oneShift) ||
                                      oneShift.status === Shift.NO_SHOW
                                    }
                                    onClick={confirmClockIn}
                                    className="btn bg-sky-400  text-white hover:bg-sky-600  items-center inline-flex"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                      className="md:w-6 md:h-6 w-4 h-4"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M10.05 4.575a1.575 1.575 0 10-3.15 0v3m3.15-3v-1.5a1.575 1.575 0 013.15 0v1.5m-3.15 0l.075 5.925m3.075.75V4.575m0 0a1.575 1.575 0 013.15 0V15M6.9 7.575a1.575 1.575 0 10-3.15 0v8.175a6.75 6.75 0 006.75 6.75h2.018a5.25 5.25 0 003.712-1.538l1.732-1.732a5.25 5.25 0 001.538-3.712l.003-2.024a.668.668 0 01.198-.471 1.575 1.575 0 10-2.228-2.228 3.818 3.818 0 00-1.12 2.687M6.9 7.575V12m6.27 4.318A4.49 4.49 0 0116.35 15m.002 0h-.002"
                                      />
                                    </svg>
                                    <span className="text-xs  md:text-sm">
                                      Confirm
                                    </span>
                                  </button>
                                  <button
                                    disabled={
                                      navigation[0].outTime(oneShift) ||
                                      navigation[0].cancel(oneShift) ||
                                      oneShift.status === Shift.NO_SHOW
                                    }
                                    onClick={() => setConfirmation(true)}
                                    className="btn bg-orange-400 text-white hover:bg-orange-600  items-center inline-flex"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                      className="md:w-6 md:h-6 w-4 h-4"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                      />
                                    </svg>

                                    <span className="text-xs  md:text-sm">
                                      Cancel shift
                                    </span>
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                      {oneShift.tracking.clock_in.status &&
                        !oneShift.tracking.clock_out.status &&
                        !oneShift.paid &&
                        !oneShift.rated && (
                          <div className="flex flex-col">
                            <QRCode
                              value={JSON.stringify({
                                type: "out",
                                value:
                                  oneShift && oneShift.tracking.clock_out.value,
                                id: oneShift.id,
                                trgt: id,
                              })}
                            />
                            <div className="inline-flex w-full justify-center">
                              <button
                                onClick={confirmClockOut}
                                className="btn bg-sky-400 text-white hover:bg-sky-600 mt-9 items-center inline-flex"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-6 h-6"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M10.05 4.575a1.575 1.575 0 10-3.15 0v3m3.15-3v-1.5a1.575 1.575 0 013.15 0v1.5m-3.15 0l.075 5.925m3.075.75V4.575m0 0a1.575 1.575 0 013.15 0V15M6.9 7.575a1.575 1.575 0 10-3.15 0v8.175a6.75 6.75 0 006.75 6.75h2.018a5.25 5.25 0 003.712-1.538l1.732-1.732a5.25 5.25 0 001.538-3.712l.003-2.024a.668.668 0 01.198-.471 1.575 1.575 0 10-2.228-2.228 3.818 3.818 0 00-1.12 2.687M6.9 7.575V12m6.27 4.318A4.49 4.49 0 0116.35 15m.002 0h-.002"
                                  />
                                </svg>
                                <span>Confirm clock out</span>
                              </button>
                            </div>
                          </div>
                        )}

                      {oneShift.tracking.clock_in.status &&
                        oneShift.tracking.clock_out?.status &&
                        oneShift.paid &&
                        !oneShift.rated && (
                          <div className="flex flex-col">
                            <h1 className="text-lg text-center font-semibold mb-5">
                              Rate your Temper
                            </h1>
                            <ReactStars
                              count={5}
                              size={40}
                              value={stars}
                              onChange={setStars}
                              activeColor="#f59e0b"
                              classNames={"mb-5"}
                            />
                            <button
                              onClick={confirmRating}
                              className="btn bg-sky-400 text-white hover:bg-sky-600 mt-9"
                            >
                              Rate my Temper
                            </button>
                          </div>
                        )}

                      {oneShift.tracking.clock_in.status &&
                        oneShift.tracking.clock_out?.status &&
                        oneShift.paid &&
                        oneShift.rated && (
                          <div className="flex flex-col justify-center items-center ">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-20 h-20"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4.5 12.75l6 6 9-13.5"
                              />
                            </svg>
                            <h1 className="text-lg font-semibold">Done !</h1>
                          </div>
                        )}
                    </div>
                  </div>{" "}
                  <div className="inline-flex justify-center items-center w-full p-4">
                    <a
                      target="_blank"
                      href={oneShift.tracking.invoice.url}
                      className="text-sky font-bold text-center hover:text-sky-500 active:text-sky-800 "
                    >
                      Download the invoice
                    </a>
                  </div>
                </>
              )}

              {!oneShift ||
                (loading && (
                  <div className="h-96 flex justify-center items-center">
                    <Watch
                      height="80"
                      width="80"
                      radius="48"
                      color="#0ea5e9"
                      ariaLabel="watch-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                ))}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
