/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";

const settings = [
  {
    name: "Candidate Temper",
    id: "CANDIDATE",
    description:
      "Register as an individual if you're eligible to work and unlock opportunities to work flexibly on your own terms. Dive into a world of dynamic job roles that align with your skill set and preferences.",
  },
  {
    name: "Company Temper",
    id: "COMPANY",
    description:
      "Enlist as a company and gain access to a diverse pool of talented Temps. Simplify your staffing process, fill gaps promptly, and optimize your workforce as per your immediate requirements.",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function BusinessType() {
  const { type } = useSelector((state) => state.RegistrationSlice);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "RegistrationSlice/setType",
      payload: type ? type : settings[0],
    });
  }, []);

  return (
    <div className="grid">
      <RadioGroup
        value={type}
        onChange={(value) =>
          dispatch({
            type: "RegistrationSlice/setType",
            payload: value,
          })
        }
      >
        <RadioGroup.Label className="sr-only">Privacy setting</RadioGroup.Label>
        <div className="-space-y-px rounded-md bg-white">
          {settings.map((setting, settingIdx) => (
            <RadioGroup.Option
              key={setting.name}
              value={setting}
              className={({ checked }) =>
                classNames(
                  settingIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                  settingIdx === settings.length - 1
                    ? "rounded-bl-md rounded-br-md"
                    : "",
                  checked ? "z-10 border-sky-200 bg-sky-50" : "border-gray-200",
                  "relative flex cursor-pointer border p-4 focus:outline-none"
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <span
                    className={classNames(
                      checked
                        ? "bg-sky-600 border-transparent"
                        : "bg-white border-gray-300",
                      active ? "ring-2 ring-offset-2 ring-sky-600" : "",
                      "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                    )}
                    aria-hidden="true"
                  >
                    <span className="rounded-full bg-white w-1.5 h-1.5" />
                  </span>
                  <span className="ml-3 flex flex-col">
                    <RadioGroup.Label
                      as="span"
                      className={classNames(
                        checked ? "text-sky-900" : "text-gray-900",
                        "block text-sm font-medium"
                      )}
                    >
                      {setting.name}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className={classNames(
                        checked ? "text-sky-700" : "text-gray-500",
                        "block text-xs md:text-sm"
                      )}
                    >
                      {setting.description}
                    </RadioGroup.Description>
                  </span>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>

      <div className="inline-flex items-center justify-center mt-4">
        <div
          onClick={() =>
            dispatch({
              type: "RegistrationSlice/goNext",
            })
          }
          className="inline-flex items-center space-x-2 text-sky-600 cursor-pointer "
        >
          <span> Next</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 animate-pulse"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}
