import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  BookmarkIcon,
  BriefcaseIcon,
  BuildingOfficeIcon,
  CalendarIcon,
  ChartBarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  DocumentTextIcon,
  FolderIcon,
  ChatBubbleLeftIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  AdjustmentsHorizontalIcon,
} from "@heroicons/react/24/outline";
import AdminRoute from "./adminRoute";
import UpcomingShiftAdmin from "../../components/command-shift/upcoming-shift";
import { Link, useLocation } from "react-router-dom";
import CommandApplication from "../../components/command-shift/command-application";
import EditUser from "../../components/details-user/create_user";
import { useFetchStats } from "../../hook/users";
import InquiriesSide from "../../components/command-shift/upcoming-shift";
import ReplySection from "../../components/Messages/inquiries";
import { useDispatch, useSelector } from "react-redux";
import { useFetchCount } from "../../hook/contact";
import SideProfile from "../../components/utils/sideProfile";
import { fetchLogs, setOpenProfileSide } from "../../redux/admin";
import Toast from "../../components/utils/Toaster";
import useGlobalState from "../../hook/globalState";
import useAdmin from "../../hook/admin";
import CreateAdminUser from "../../components/details-user/admin-create";
const navigation = [
  { name: "Home", href: "/", icon: HomeIcon, current: true },
  { name: "Users", href: "/users", icon: UsersIcon, current: false },
  {
    name: "Candidates",
    href: "/candidates",
    icon: BriefcaseIcon,
    current: false,
  },
  {
    name: "Companies",
    href: "/companies",
    icon: BuildingOfficeIcon,
    current: false,
  },
  {
    name: "Invoices",
    href: "/invoices",
    icon: DocumentTextIcon,
    current: false,
  },
  { name: "Finance", href: "/finance", icon: ChartPieIcon, current: false },
  {
    name: "Utils",
    href: "/utils",
    icon: AdjustmentsHorizontalIcon,
    current: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function LayoutAdmin() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { openContact, inquirie, count } = useSelector(
    (state) => state.UserSlice
  );
  const { countMessages } = useSelector((state) => state.conversationSlice);

  const { admin } = useSelector((state) => state.adminSlice);
  const { openProfileSide } = useSelector((state) => state.adminSlice);
  useFetchStats();
  useFetchCount();
  useGlobalState();
  useAdmin();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLogs());
  }, []);

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-white">
        <body class="h-full">
        ```
      */}
      <div className=" relative">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                    <Link className="flex h-16 shrink-0 items-center" to={"/"}>
                      <img
                        className="h-8 w-auto"
                        src={require("../../assets/img/logo.png")}
                        alt="logo"
                      />
                    </Link>
                    <hr />
                    <nav className="flex  flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <Link
                            to={"/contact"}
                            className={classNames(
                              "text-gray-700 hover:text-sky-600 cursor-pointer hover:bg-gray-50",
                              "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                            )}
                          >
                            <div className="indicator">
                              <span className="indicator-item  h-4 inline-flex justify-center items-center rounded-full font-semibold text-white text-[0.650rem] w-4 bg-sky-600">
                                {count && count}
                              </span>
                              <BookmarkIcon
                                className={classNames(
                                  "text-gray-400 group-hover:text-sky-600",
                                  "h-6 w-6 shrink-0"
                                )}
                                aria-hidden="true"
                              />
                            </div>
                            Inquiries
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={"/messages"}
                            className={classNames(
                              "text-gray-700 hover:text-sky-600 cursor-pointer hover:bg-gray-50",
                              "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                            )}
                          >
                            <div className="indicator">
                              <span className="indicator-item  h-4 inline-flex justify-center items-center rounded-full font-semibold text-white text-[0.650rem] w-4 bg-red-600">
                                {countMessages && countMessages}
                              </span>
                              <ChatBubbleLeftIcon
                                className={classNames(
                                  "text-gray-400 group-hover:text-sky-600",
                                  "h-6 w-6 shrink-0"
                                )}
                                aria-hidden="true"
                              />
                            </div>
                            Messages
                          </Link>
                        </li>
                      </ul>
                    </nav>
                    <hr />
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <Link
                                  to={item.href}
                                  className={classNames(
                                    location.pathname === item.href
                                      ? "bg-gray-50 text-sky-600"
                                      : "text-gray-700 hover:text-sky-600 hover:bg-gray-50",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      location.pathname === item.href
                                        ? "text-sky-600"
                                        : "text-gray-400 group-hover:text-sky-600",
                                      "h-6 w-6 shrink-0"
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
            <Link className="flex h-16 shrink-0 items-center" to={"/"}>
              <img
                className="h-8 w-auto"
                src={require("../../assets/img/logo.png")}
                alt="Your Company"
              />
            </Link>
            <hr />
            <nav className="flex  flex-col">
              <ul role="list" className="-mx-2 space-y-1">
                <li>
                  <Link
                    to={"/contact"}
                    className={classNames(
                      "text-gray-700 hover:text-sky-600 cursor-pointer hover:bg-gray-50",
                      "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                    )}
                  >
                    <div className="indicator">
                      <span className="indicator-item  h-4 inline-flex justify-center items-center rounded-full font-semibold text-white text-[0.650rem] w-4 bg-sky-600">
                        {count && count}
                      </span>
                      <BookmarkIcon
                        className={classNames(
                          "text-gray-400 group-hover:text-sky-600",
                          "h-6 w-6 shrink-0"
                        )}
                        aria-hidden="true"
                      />
                    </div>
                    Inquiries
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/messages"}
                    className={classNames(
                      "text-gray-700 hover:text-sky-600 cursor-pointer hover:bg-gray-50",
                      "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                    )}
                  >
                    <div className="indicator">
                      <span className="indicator-item  h-4 inline-flex justify-center items-center rounded-full font-semibold text-white text-[0.650rem] w-4 bg-red-600">
                        {countMessages && countMessages}
                      </span>
                      <ChatBubbleLeftIcon
                        className={classNames(
                          "text-gray-400 group-hover:text-sky-600",
                          "h-6 w-6 shrink-0"
                        )}
                        aria-hidden="true"
                      />
                    </div>
                    Messages
                  </Link>
                </li>
              </ul>
            </nav>
            <hr />
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className={classNames(
                            location.pathname === item.href
                              ? "bg-gray-50 text-sky-600"
                              : "text-gray-700 hover:text-sky-600 hover:bg-gray-50",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              location.pathname === item.href
                                ? "text-sky-600"
                                : "text-gray-400 group-hover:text-sky-600",
                              "h-6 w-6 shrink-0"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>

                <li className="-mx-6 mt-auto">
                  <Link
                    onClick={() => dispatch(setOpenProfileSide())}
                    className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50"
                  >
                    <img
                      className="h-8 w-8 "
                      src={require("../../assets/img/gear.png")}
                      alt=""
                    />
                    <span className="sr-only">Your profile</span>
                    <span aria-hidden="true"> {admin && admin.name}</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">
            Dashboard
          </div>
          <button onClick={() => dispatch(setOpenProfileSide())}>
            <span className="sr-only">Your profile</span>
            <img
              className="h-8 w-8 "
              src={require("../../assets/img/gear.png")}
              alt=""
            />
          </button>
        </div>

        <main className="lg:pl-72">
          <div className="xl:pr-96">
            <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6">
              <AdminRoute />
            </div>
          </div>
        </main>
        {!openContact && !openProfileSide && (
          <aside className="fixed z-50 bg-white inset-y-0 right-0 hidden w-96 overflow-y-auto border-l border-gray-200 p-3 xl:block">
            <span className=" font-extralight text-2xl text-sky-600 ">
              Feeds{" "}
            </span>
            <hr className="my-4" />
            <InquiriesSide />
          </aside>
        )}
      </div>
      <CommandApplication />
      <EditUser />
      <CreateAdminUser />
      {inquirie.data && <ReplySection />}
      <SideProfile />
    </>
  );
}
