import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typewriter from "typewriter-effect/dist/core";
import { createUser } from "../redux/registration";

function FinishRegistration() {
  const [done, setDone] = useState(false);
  const {loading } = useSelector((state) => state.RegistrationSlice);
  const dispatch = useDispatch();
  useEffect(() => {
    try {
      dispatch(
        createUser()
      );
    } catch (error) {
      throw error;
    }

    new Typewriter("#typewriterL", {
      strings: ["Please wait a minute we are registering ..."],
      autoStart: true,
      loop: true,
    });
  }, []);

  return (
    <div className="max-h-[70vh] overflow-hidden ">
      {loading && (
        <div className="text-center flex flex-col items-center justify-center space-y-12">
          <h2 className="text-sm  font-medium md:text-xl" id="typewriterL">
            Please wait a minute we are registering !
          </h2>
          <img
            src={require("../assets/img/illustration/sander.jpeg")}
            className=" w-[20rem]"
            alt=""
          />
        </div>
      )}
      {!loading && (
        <div className="text-center flex flex-col items-center justify-center space-y-12">
          <h2 className="sr-only" id="typewriterL">
            Please wait a minute we are registering !
          </h2>
          <h2 className="text-sm font-medium md:text-xl">
            Please check your email to complete your registration.
          </h2>
          <img
            src={require("../assets/img/illustration/done-ok.jpeg")}
            className=" w-[20rem] rounded-lg"
            alt=""
          />
        </div>
      )}
    </div>
  );
}

export default FinishRegistration;
