import react, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchCountries } from "../redux/admin";

export default function useCountries() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCountries());
  }, []);
}
