import React from "react";
import { FadeIn } from "../components/utils/FadeIn";
import { Link } from "react-router-dom";

function ArrowIcon(props) {
  return (
    <svg viewBox="0 0 16 6" aria-hidden="true" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 3 10 .5v2H0v1h10v2L16 3Z"
      />
    </svg>
  );
}

function NewsletterForm() {
  return (
    <form className="max-w-sm">
      <h2 className="font-display text-sm font-semibold tracking-wider text-neutral-950">
        Sign up for our newsletter
      </h2>
      <p className="mt-4 text-sm text-neutral-700">
        Subscribe to get the latest design news, articles, resources and
        inspiration.
      </p>
      <div className="relative mt-6">
        <input
          type="email"
          placeholder="Email address"
          autoComplete="email"
          aria-label="Email address"
          className="block w-full rounded-2xl border border-neutral-300 bg-transparent py-4 pl-6 pr-20 text-base/6 text-neutral-950 ring-4 ring-transparent transition placeholder:text-neutral-500 focus:border-neutral-950 focus:outline-none focus:ring-neutral-950/5"
        />
        <div className="absolute inset-y-1 right-1 flex justify-end">
          <button
            type="submit"
            aria-label="Submit"
            className="flex aspect-square h-full items-center justify-center rounded-xl bg-sky-600 text-white transition hover:bg-sky-700"
          >
            <ArrowIcon className="w-4" />
          </button>
        </div>
      </div>
    </form>
  );
}

function Footer() {
  return (
    <footer class="w-full max-w-[85rem] py-16 px-4 sm:px-6 lg:px-8 mx-auto">
      <FadeIn class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-12 gap-6 mb-10">
        <div className="col-span-full  lg:col-span-2">
          <h4 class="text-xs font-semibold text-gray-900 uppercase dark:text-gray-100">
            About TempME
          </h4>

          <div class="mt-3 grid space-y-3 text-sm">
            <p>
              <a
                class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
                href="/about-tempme/story"
              >
                Our Story
              </a>
            </p>
            {/* <p>
              <a
                class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
               href="#"
              >
                Team
              </a>
            </p> */}
            <p>
              <a
                class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
                href="/about-tempme/careers"
              >
                Careers
              </a>
            </p>
          </div>
        </div>

        <div className="col-span-full  lg:col-span-2">
          <h4 class="text-xs font-semibold text-gray-900 uppercase dark:text-gray-100">
            For Tempers
          </h4>

          <div class="mt-3 grid space-y-3 text-sm">
            <p>
              <a
                class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
                href="/jobs"
              >
                Browse Jobs
              </a>
            </p>
            <p>
              <a
                class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
                href="/#testimonial"
              >
                Testimonials
              </a>
            </p>
            <p>
              <a
                class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
                href="/for-temper/FAQ"
              >
                Tempers FAQ
              </a>
            </p>
          </div>
        </div>

        <div className="col-span-full  lg:col-span-2">
          <h4 class="text-xs font-semibold text-gray-900 uppercase dark:text-gray-100">
            For Companies
          </h4>

          <div class="mt-3 grid space-y-3 text-sm">
            <p>
              <a
                class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
                href="/registration"
              >
                Company Sign Up
              </a>
            </p>

            <p>
              <a
                class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
                href="/for-companies/hiring-tips"
              >
                Hiring Tips
              </a>
            </p>
          </div>
        </div>

        <div className="col-span-full  lg:col-span-2">
          <h4 class="text-xs font-semibold text-gray-900 uppercase dark:text-gray-100">
            Support
          </h4>

          <div class="mt-3 grid space-y-3 text-sm">
            <p>
              <a
                class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
                href="/contact"
              >
                Contact Us
              </a>
            </p>

            <p>
              <a
                class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
                href="/support/terms"
              >
                Terms & Conditions
              </a>
            </p>
            <p>
              <a
                class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
                href="/support/privacy-policy"
              >
                Privacy Policy
              </a>
            </p>
          </div>
        </div>
        <div className="flex lg:justify-end col-span-full  lg:col-span-4">
          <NewsletterForm />
        </div>
      </FadeIn>

      <div className="mb-2 mt-2 flex flex-wrap items-end justify-between gap-x-6 gap-y-4 border-t border-neutral-950/10 pt-12">
        <Link to="/" aria-label="Home">
          <img
            className="h-8 w-auto bg-blend-multiply"
            src={require("../assets/img/brands/T.png")}
            alt="logo"
          />
        </Link>
        <p className="text-sm text-neutral-700">
          © TempME Inc {new Date().getFullYear()}
        </p>
      </div>
    </footer>
  );
}

export default Footer;
