import React from "react";
import { Link, useLocation } from "react-router-dom";
import Stars from "../rating/Stars";
import { useDispatch, useSelector } from "react-redux";
import { baseUrl } from "../../utils";
import moment from "moment";

function JobCompany({ items }) {
  const { company } = useSelector((state) => state.companySlice);

  return (
    <div className="card py-2 px-2 w-full shadow-md  border border-l-4 border-l-sky-400 ">
      <div className="flex">
        <div className="flex-1 flex justify-end ">
          <h1 className="text-end text-xs">
            {moment.unix(items.creatAt).local().fromNow()}
          </h1>
        </div>
      </div>

      <div className="flex ">
        <div className="flex-1 flex-col flex justify-start items-start overflow-hidden">
          <h1 className=" sm:text-xl whitespace-nowrap w-full  md:text-2xl text-transparent bg-gradient-to-b from-indigo-600 to-sky-600 bg-clip-text leading-8 font-extrabold ">
            {items.jobName}
          </h1>
          <span className="text-xs to-zinc-400">{items.location.city}</span>
        </div>
      </div>
      <div className="flex-1 inline-flex  justify-end items-center text-indigo-900">
        {!items.availability && (
          <span className="inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-semibold me-3 text-red-600">
            Expired
          </span>
        )}
        {items.availability && (
          <span className="inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium me-3 text-semibold-600">
            Available
          </span>
        )}

        <h1 className="text-sm  cursor-pointer underline-offset-1 font-normal leading-none text-right ">
          {[items.applications.length, items.nbCandidate].join(
            " application of "
          )}
        </h1>
      </div>
      <div className="grid grid-cols-2">
        <div className="flex justify-start my-2">
          {company && !company.logo && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-9 h-9"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
              />
            </svg>
          )}

          {company && (
            <img
              src={company.logo}
              alt=""
              className="w-9 h-9 object-scale-down mix-blend-darken  "
              srcset=""
            />
          )}
        </div>
        <div className="flex flex-col text-sm justify-end items-end  items-bottom">
          <Stars number={company.stars} />
        </div>
      </div>

      <span className="text-xs font-medium">Skill(s):</span>
      <div className="h-fit w-full md:hover:overflow-x-auto md:overflow-hidden overflow-scroll flex flex-nowrap space-x-4 py-2 ">
        {items.skill.map((sk) => (
          <span
            key={sk}
            className="inline-flex whitespace-nowrap items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-sky-900"
          >
            {sk}
          </span>
        ))}
      </div>
      <hr className="my-1" />
      <div className="max-h-16 overflow-y-auto hover:text-clip text-sm xs:mb-4">
        {items.description}
      </div>

      <div className="flex flex-row space-x-1 items-center justify-end text-sky-400">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5 h-5 animate-bounce"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
          />
        </svg>

        <h1 className="text-lg font-bold">-- --</h1>
      </div>

      <div className="flex flex-row space-x-3 items-center justify-end">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="xs:w-8 w-5 h-5 xs:h-8 text-sky-400"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
          />
        </svg>

        <h1 className="font-extrabold xs:text-4xl text-xl  text-sky-400">
          {[
            parseFloat(items.hourlyRate).toFixed(2) + " ",
            company.currency.code,
            "/h",
          ].join("")}
        </h1>
      </div>

      <hr className="my-1" />

      <div className="flex justify-center flex-wrap  py-2">
        <span className="bg-gray-100 text-gray-800 font-semibold text-xs mb-2 lg:text-sm  md:font-medium inline-flex items-center px-2.5 py-0.5 rounded mr-2 dark:bg-gray-700 dark:text-gray-400 border border-gray-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
            />
          </svg>
          &nbsp; {items.startDate}
        </span>
        <span className="bg-blue-100 text-blue-800 text-xs mb-2 lg:text-sm font-semibold md:font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          &nbsp; {[items.startHour, items.endHour].join(" - ")}
        </span>
      </div>
      <hr className="my-1" />
      <div className="flex space-x-3 justify-center">
        <Link
          to={`/jobs/${items.id}`}
          type="button"
          className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-700 shadow-sm hover:bg-slate-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-focus-600"
        >
          Details
        </Link>
        <Link
          to={`/job/create/${items.id}`}
          type="button"
          className="rounded-md bg-sky-400 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-focus-600"
        >
          Repost
        </Link>
      </div>
    </div>
  );
}

export default JobCompany;
