import { PlusIcon, ArchiveBoxIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import useCountries from "../../../hook/country";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import GoBack from "../../../components/utils/goBack";

function Countries() {
  useCountries();
  const { countries } = useSelector((state) => state.adminSlice);

  return (
    <>
      <GoBack />

      <h1 className="text-2xl font-extralight text-sky-600 my-9 ">
        Countries mangement
      </h1>

      <div>
        <div className=" col-span-3 transition-all ease-linear duration-200 max-h-[calc(100vh-10rem)] overflow-auto  relative pe-4  ">
          <h1>Countries</h1>
          <div className="mt-10 space-y-6">
            {countries &&
              countries.map((el) => (
                <Link
                  className={`rounded border border-dashed p-12  hover:bg-slate-50 hover:text-sky-600 flex flex-col gap-4 overflow-clip sm:flex-row w-full `}
                >
                  <h1 className="text-base font-medium w-full shrink">
                    {el.name}
                  </h1>

                  <div className=" space-x-2   flex ">
                    <button
                      disabled
                      type="button"
                      className="rounded bg-sky-50 whitespace-nowrap px-2 py-1  first-letter:capitalize text-sm font-semibold text-sky-600 shadow-sm hover:bg-sky-100"
                    >
                      {el.currency.name}
                    </button>
                    <button
                      disabled
                      type="button"
                      className="rounded bg-sky-50 whitespace-nowrap px-2 py-1 first-letter:capitalize text-sm font-semibold text-sky-600 shadow-sm hover:bg-sky-100"
                    >
                      {el.currency.symbol}
                    </button>
                    <button
                      type="button"
                      className="rounded bg-sky-50 whitespace-nowrap px-2 py-1 first-letter:capitalize text-sm font-semibold text-sky-600 shadow-sm hover:bg-sky-100"
                    >
                      {el.currency.code}
                    </button>
                    <button
                      type="button"
                      className="rounded bg-sky-50 whitespace-nowrap px-2 py-1 first-letter:capitalize text-sm font-semibold text-red-600 shadow-sm hover:bg-sky-100"
                    >
                      <ArchiveBoxIcon className="w-6 h-6" />
                    </button>
                  </div>
                </Link>
              ))}
          </div>

          <div className="top-0 absolute cursor-pointer hover:border  rounded-full  p-1 mb-10 right-2">
            <PlusIcon className="w-6 h-6  hover:text-gray-300  text-gray-500 " />
          </div>
        </div>
      </div>
    </>
  );
}

export default Countries;
