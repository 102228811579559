import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Dashboard from "./Dashboard/index";
import NotFound404 from "../../components/not-found-page/404-not-found";
import Users from "./Users";
import Candidates from "./candidates";
import Companies from "./Companies";
import { Company } from "../../components/details-user/company";
import OneUserEdit from "../../components/details-user/one_user_edit";
import Contact from "./contact";
import Finance from "./finance";
import Invoices from "./invoices";
import Messages from "./messages/page";
import Profile from "./profile";
import Utils from "./utils";
import Industries from "./utils/Industries";
import Countries from "./utils/countries";

function Router() {
  return <Outlet />;
}

function AdminRoute() {
  return (
    <TransitionGroup>
      <CSSTransition timeout={500} classNames="page">
        <Routes>
          <Route exact static path="/" element={<Router />}>
            <Route index element={<Dashboard />} />
            <Route path="/users" element={<Users />} />
            <Route path="/messages" element={<Messages />} />
            <Route path="/finance" element={<Finance />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/invoices" element={<Invoices />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/candidates" element={<Candidates />} />
            <Route path="/companies" element={<Companies />} />
            <Route path="/company/:name" element={<Company />} />
            <Route path="/user/:id" element={<OneUserEdit />} />
            <Route path="/utils" element={<Utils />} />
            <Route path="/utils/industries/:id" element={<Industries />} />
            <Route path="/utils/countries/:id" element={<Countries />} />
            <Route path="*" element={<Dashboard />} />
          </Route>
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default AdminRoute;
