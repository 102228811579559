import {
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";
import { FadeIn, FadeInStagger } from "../components/utils/FadeIn";

export default function Careers() {
  return (
    <div className="bg-white px-6 py-32 lg:px-8">
      <FadeInStagger className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <FadeIn>
          <p className="text-base font-semibold leading-7 text-sky-600">
            Careers
          </p>
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Ready to break free from the traditional 9-5 mold?
          </h1>
          <p className="mt-6 text-xl leading-8">
            Become a key player in our vibrant team at TempME! We are constantly
            seeking skilled and driven individuals eager to effect real change.
            If you're geared up to elevate your career within a nurturing,
            forward-thinking, and inclusive setting, we're excited to connect
            with you.
          </p>
        </FadeIn>

        <div className="mt-10 max-w-2xl">
          <p>Why Choose TempME?</p>
          <ul role="list" className="mt-8 max-w-xl space-y-8 text-gray-600">
            <FadeIn className="flex gap-x-3">
              <CheckCircleIcon
                className="mt-1 h-5 w-5 flex-none text-sky-600"
                aria-hidden="true"
              />
              <span>
                <strong className="font-semibold text-gray-900">
                  Pioneering Spirit:
                </strong>{" "}
                Leading the charge in reshaping the employment landscape, we
                champion innovative ideas.
              </span>
            </FadeIn>
            <FadeIn className="flex gap-x-3">
              <CheckCircleIcon
                className="mt-1 h-5 w-5 flex-none text-sky-600"
                aria-hidden="true"
              />
              <span>
                <strong className="font-semibold text-gray-900">
                  Team Synergy:
                </strong>{" "}
                Emphasizing a culture of collaboration, we embrace diversity and
                foster open dialogue.
              </span>
            </FadeIn>
            <FadeIn className="flex gap-x-3">
              <CheckCircleIcon
                className="mt-1 h-5 w-5 flex-none text-sky-600"
                aria-hidden="true"
              />
              <span>
                <strong className="font-semibold text-gray-900">
                  Career Advancement:
                </strong>{" "}
                Committed to our team's growth, we provide continuous learning
                and professional development opportunities.
              </span>
            </FadeIn>
            <FadeIn className="flex gap-x-3">
              <CheckCircleIcon
                className="mt-1 h-5 w-5 flex-none text-sky-600"
                aria-hidden="true"
              />
              <span>
                <strong className="font-semibold text-gray-900">
                  Making a Difference:
                </strong>{" "}
                Be part of our mission to foster equitable engagements and
                positively impact the community.
              </span>
            </FadeIn>
          </ul>
        </div>
      </FadeInStagger>
    </div>
  );
}
