import React, { useEffect, useState } from "react";
import Layout from "./apps/layout";
import { LandingRoute } from "./landing-page/Route";
import { useSelector } from "react-redux";
import { isTokenExpired } from "./redux/authentication";
import Roles from "./Enum/UserRoles";
import LayoutAdmin from "./apps/admin/layout";

function DirectionRoute() {
  const { token, role } = useSelector((state) => state.authenticationSlice);

  return token ? (
    [Roles.candidate, Roles.company].includes(role) ? (
      <Layout />
    ) : [Roles.admin].includes(role) ? (
      <LayoutAdmin />
    ) : (
      <LandingRoute />
    )
  ) : (
    <LandingRoute />
  );
}

export default DirectionRoute;
