import { useSelector, useDispatch } from "react-redux";
import { useEffect, useCallback } from "react";
import {
  fetchConversation,
  fetchMessages,
  sendMessage,
  resetMessage,
  updateRead,
  fetchCountMessage,
  fetchMessagesAdmin,
  fetchConversationToGetAdmin,
  fetchCountMessageFromAdmin,
} from "../redux/conversation";

export function useConversationAdmin() {
  const dispatch = useDispatch();
  const { userToSend } = useSelector((state) => state.conversationSlice);

  const fetchMessages = useCallback(() => {
    dispatch(fetchMessagesAdmin());
  }, [dispatch]);

  useEffect(() => {
    fetchMessages();
  }, [userToSend.id]);
}

export default function useConversation() {
  const dispatch = useDispatch();
  const { conv, messages, tabConvSelected } = useSelector(
    (state) => state.conversationSlice
  );

  const fetchData = useCallback(() => {
    dispatch(fetchConversation());
  }, [dispatch]);

  const fetchMessagesCallback = useCallback(
    (cnvId) => {
      dispatch(fetchMessages(cnvId));
    },
    [dispatch]
  );

  const sendMessageCallback = useCallback(
    (cnvId, message) => {
      dispatch(sendMessage({ cnvId, message }));
    },
    [dispatch]
  );

  const fetchCount = useCallback(() => {
    dispatch(fetchCountMessage());
    dispatch(fetchCountMessageFromAdmin());
  }, [dispatch]);

  const resetMessageCallback = useCallback(() => {
    dispatch(resetMessage());
  }, [dispatch]);

  const updateReadCallback = useCallback(
    (convId) => {
      dispatch(updateRead(convId));
    },
    [dispatch]
  );

  useEffect(() => {
    fetchData();
    fetchCount();
  }, [fetchData]);

  useEffect(() => {
    if (tabConvSelected === "Fs") {
      dispatch(fetchConversationToGetAdmin());
    }
  }, [tabConvSelected]);

  return {
    conv,
    fetchMessages: fetchMessagesCallback,
    messages,
    resetMessage: resetMessageCallback,
    sendMessage: sendMessageCallback,
    updateRead: updateReadCallback,
  };
}
