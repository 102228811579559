import { ApiClient } from "./api";

export class Industry {
  constructor() {
    this.apiClient = new ApiClient();
  }

  async getIndustries() {
    try {
      const { data } = await this.apiClient.get(`industry`);
      
      return data;
    } catch (error) {
      console.error(error);
    }
  }
}
