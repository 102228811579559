import { useEffect, useState } from "react";
import { Industry } from "../services/industry";
import { useSelector } from "react-redux";

const industry = new Industry();

export default function useIndustries() {
  const [datas, setDatas] = useState();
  const [initData, setInitData] = useState([]);
  const { updated } = useSelector((state) => state.adminSlice);

  function initialize(value) {
    setDatas(value);
    setInitData(JSON.stringify(value[0]));
    
  }

  useEffect(() => {
    fechData();
  }, [updated]);

  function fechData() {
    industry.getIndustries().then(initialize);
  }

  return { datas, initData };
}
