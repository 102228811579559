import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import Profile from "./profile";
import useCandidate from "../../../hook/candidate";
import { SpinnerLoadData } from "../../../components/utils/spinner-load";
import { useSelector } from "react-redux";
function Item({ name, description, link, icon, arrayMissData = [] }) {
  return (
    <Link
      to={`.?name=${name}&tab=${link}`}
      className="p-4 border md:w-96 rounded-lg  border-dashed flex flex-col"
    >
      <div className=" inline-flex space-x-3 text-sky-600 ">
        {icon}
        <h1 className="md:text-lg text-sm">{name}</h1>
      </div>
      <p className="md:text-sm text-xs text-gray-500">{description} </p>

      <div className="mt-2 flex justify-end gap-3">
        {arrayMissData.map((el) => (
          <div className="py-1 animate-pulse px-5 text-red-800 font-bold md:text-sm text-xs bg-red-100 w-fit rounded-lg">
            {el}
          </div>
        ))}
      </div>
    </Link>
  );
}

function Index() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { datas } = useCandidate();


  return !searchParams.get("tab") && datas ? (
    <div className="grid justify-center  gap-4  p-8 ">
      <Item
        name="Account details"
        link={0}
        description={
          "Access and manage your personal account details including profile information, settings, and preferences."
        }
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
            />
          </svg>
        }
      />
      <Item
        link={1}
        name="Notifications settings"
        description={
          "View and customize your notification settings. Stay informed about important updates and alerts related to your activities."
        }
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5"
            />
          </svg>
        }
      />
      <Item
        link={2}
        name="Finance"
        description={
          "Overview of your financial transactions, billing information, and other monetary operations. Manage your financial data and review past transactions."
        }
        arrayMissData={
          datas.user.stripe &&
          datas.user.stripe.requirements.currently_due.length > 0
            ? [
                "We neeed you to complete your financial information to complete your registration",
              ]
            : []
        }
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z"
            />
          </svg>
        }
      />
      <Item
        link={3}
        name="ID Document"
        description={
          "Submit or update your identification documents necessary for verification processes. Ensure your account meets required compliance standards."
        }
        arrayMissData={
          !datas?.user?.legalDocumentId?.path ||
          !datas?.user?.legalDocumentId?.documentNumber ||
          !datas?.user?.legalDocumentId?.issueDate ||
          !datas?.user?.legalDocumentId?.expiryDate
            ? [
                "Please provide a valid ID document to complete your registration",
              ]
            : []
        }
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z"
            />
          </svg>
        }
      />
      <Item
        link={4}
        description={
          "Manage and track your earnings and payouts. Set up and configure payout methods, and view transaction history."
        }
        name="Payouts"
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 0 1-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0 1 12 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5"
            />
          </svg>
        }
      />
    </div>
  ) : datas  ?  (
    <Profile />
  ) : (
    <SpinnerLoadData />
  );
}

export default Index;
