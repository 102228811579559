import { FadeIn, FadeInStagger } from "../components/utils/FadeIn";

const faqs = [
  {
    question: "User Accounts",
    answer:
      "Users must provide accurate, current, and complete information when creating an account. Accounts are personal and cannot be shared or transferred.",
  },
  {
    question: "Service Use",
    answer:
      "TempME provides a marketplace for freelancers and clients. Users agree to use this platform professionally and ethically.",
  },
  {
    question: "Payments",
    answer:
      "Payment terms are agreed upon between freelancers and clients. TempME facilitates these payments but is not responsible for disputes over fees or services.",
  },
  {
    question: "Intellectual Property",
    answer:
      "All content on TempME, including text, graphics, and user interfaces, belongs to TempME and is protected by intellectual property laws.",
  },
  {
    question: "Termination",
    answer:
      "We reserve the right to terminate or suspend access to our service immediately, without prior notice, for any breach of these Terms.",
  },
  {
    question: "Governing Law",
    answer:
      "These Terms are governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law provisions.",
  },
  {
    question: "Changes",
    answer:
      "We reserve the right, at our sole discretion, to modify or replace these Terms at any time.",
  },
  // More questions...
];

export default function TermsAndConditions() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:pt-32 lg:px-8 lg:py-40">
        <FadeInStagger className="lg:grid lg:grid-cols-12 lg:gap-8">
          <FadeIn className="lg:col-span-5">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
              Terms & Conditions
            </h2>
            <p className="mt-4 text-base leading-7 text-gray-600">
              Welcome to TempME. By accessing our platform, you agree to these
              Terms and Conditions. These govern your use of TempME and all
              services provided.
            </p>
          </FadeIn>
          <div className="mt-10 lg:col-span-7 lg:mt-0">
            <dl className="space-y-10">
              {faqs.map((faq) => (
                <FadeIn key={faq.question}>
                  <dt className="text-base font-semibold leading-7 text-sky-900">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">
                    {faq.answer}
                  </dd>
                </FadeIn>
              ))}
            </dl>
          </div>
        </FadeInStagger>
      </div>
    </div>
  );
}
