import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Candidate } from "../services/candidate";

const candidate = new Candidate();

export const updateProfileCandidate = createAsyncThunk(
  "calendar/updateProfileCandidate",
  async (data, thunkAPI) => {
   const response  =  await candidate.putProfileCandidate(data);
    return response;
  }
);

const today = new Date(); // Date d'aujourd'hui
const startTime = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate() - 1,
  23,
  59,
  0
); // Date de début une journée avant
const endTime = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate(),
  23,
  59,
  0
);

export const CalendarSlice = createSlice({
  name: "CalendarSlice",
  initialState: {
    availability: "FULL",
    date: null,
    open: false,
    startTime: startTime,
    endTime: endTime,
    profileAv: {},
    profileData : {}
    
  },
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload;
    },
    setDate: (state, action) => {
      state.date = action.payload;
    },
    setAvailability: (state, action) => {
      state.availability = action.payload;
      state.profileAv.profile.availability[state.date] = action.payload;
    },
    setProfileAv: (state, action) => {
      state.profileAv = action.payload;
    },
    setEndStart: (state, action) => {
      let temp = new Date(action.payload);

      state.endTime = new Date(
        temp.getFullYear(),
        temp.getMonth(),
        temp.getDate(),
        23,
        59,
        0
      );

      state.startTime = new Date(
        temp.getFullYear(),
        temp.getMonth(),
        temp.getDate() - 1,
        23,
        59,
        0
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateProfileCandidate.fulfilled, (state, action) => {
      state.open = false;
      state.profileData = action.payload;
    });
  },
});
