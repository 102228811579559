import { createSlice } from "@reduxjs/toolkit";

export const GeolocationSlice = createSlice({
  name: "GeolocationSlice",
  initialState: {
    latitude: 0,
    longitude: 0,
  },
  reducers: {
    setLatitude: (state, action) => {
      state.latitude = action.payload;
    },
    setLongitude: (state, action) => {
      state.longitude = action.payload;
    },
  },
});
