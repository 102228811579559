import { baseUrl } from "../utils";

export class ApiClient {
  constructor() {
    this.baseUrl = baseUrl;
    this.token = localStorage.getItem("userToken");
  }

  async get(endpoint) {
    const response = await fetch(`${this.baseUrl}/api/${endpoint}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
    });

    if (!response.ok) {
      const err = await response.json();

      if (err.code === "revoked_token") {
        localStorage.clear();
      }
      throw new Error(`API error: ${response.status}`);
    }

    return response.json();
  }
  async getPublic(endpoint) {
    const response = await fetch(endpoint);

    if (!response.ok) {
       const err = await response.json();

       if (err.code === "revoked_token") {
         localStorage.clear();
       }
      throw new Error(`API error: ${response.status}`);
    }

    return response.json();
  }

  async post(endpoint, data) {
    const response = await fetch(`${this.baseUrl}/api/${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
       const err = await response.json();

       if (err.code === "revoked_token") {
         localStorage.clear();
       }
      throw new Error(`API error: ${response.status}`);
    }

    return response.json();
  }

  async postRefreshToken(endpoint, data) {
    const response = await fetch(`${this.baseUrl}/api/${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
       const err = await response.json();

       if (err.code === "revoked_token") {
         localStorage.clear();
       }
      throw new Error(`API error: ${response.status}`);
    }

    return response.json();
  }

  async postWithDocument(endpoint, data, document) {
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    formData.append("document", document);

    const response = await fetch(`${this.baseUrl}/api/${endpoint}`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });

    if (!response.ok) {
       const err = await response.json();

       if (err.code === "revoked_token") {
         localStorage.clear();
       }
      throw new Error(`API error: ${response.status}`);
    }

    return response.json();
  }

  async put(endpoint, data) {
    const response = await fetch(`${this.baseUrl}/api/${endpoint}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
       const err = await response.json();

       if (err.code === "revoked_token") {
         localStorage.clear();
       }
      throw new Error(`API error: ${response.status}`);
    }

    return response.json();
  }

  async delete(endpoint, data) {
    const response = await fetch(`${this.baseUrl}/api/${endpoint}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
       
      const err = await response.json();

       if (err.code === "revoked_token") {
         localStorage.clear();
       }
      throw new Error(`API error: ${response.status}`);
    }

    return response.json();
  }
}
