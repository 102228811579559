import { FadeIn, FadeInStagger } from "../components/utils/FadeIn";

const faqs = [
  {
    id: 1,
    question: "What is TempME work platform?",
    answer: `TempME platform is an online marketplace that connects freelancers, independent contractors, or "Tempers" with clients or businesses seeking specific services. It offers a diverse range of short-term, project-based job opportunities across various industries.`,
  },
  {
    id: 2,
    question: "How does the TempME work platform work?",
    answer: `TempME work platforms typically allow freelancers to create profiles, showcase their skills, and bid on job listings posted by clients. Clients review the profiles, select a Temper, and then collaborate with them to complete the project.`,
  },
  {
    id: 3,
    question: "How do I get started as a TempME worker on the platform?",
    answer: `To get started, you need to sign up on the platform, create a profile, and verify your skills and qualifications. You can then browse job listings, submit proposals, and apply for instant opportunities.`,
  },
  {
    id: 4,
    question: "What types of TempMEs are available on the platform?",
    answer: `TempME work platforms offer a wide variety of opportunities, including warehouse, retail, hospitality, and much more. You can find jobs in almost any industry or skill set.`,
  },
  {
    id: 5,
    question: "How do I get paid for my work on the platform?",
    answer: `Payment from TempME is instant via Stripe once checked out of the assignment. The platform typically facilitates payments, and you may receive funds upon successful completion of a Temp assignment according to the terms agreed upon with the client.`,
  },
  {
    id: 6,
    question: "What fees are associated with using the platform?",
    answer: `TempME is transparent and the hourly rate advertised is the rate received for your work.`,
  },
  {
    id: 7,
    question:
      "Are TempME workers considered independent contractors or employees?",
    answer: `TempME workers are generally considered independent contractors, which means they are responsible for managing their taxes, insurance, and other legal and financial aspects of their work. The platform typically does not provide benefits like health insurance or retirement plans.`,
  },
  {
    id: 8,
    question: "What happens if a dispute arises between me and a client?",
    answer: `Most TempME work platforms have dispute resolution mechanisms in place. You can typically reach out to the platform's support team to help mediate and resolve the issue.`,
  },
  {
    id: 9,
    question: "Is it safe to use a TempME work platform?",
    answer: `TempME work platforms prioritizes safety and security, it's crucial to exercise caution and follow best practices. Research clients, read reviews, and be vigilant about sharing personal information. Always use secure payment methods and report any suspicious activity to the platform.`,
  },
  {
    id: 10,
    question: "Can I work on multiple TempME work platforms simultaneously?",
    answer: `Yes, many TempME workers use multiple platforms to diversify their income streams. Just be sure to manage your workload and commitments effectively. However due to our proprietary finance and reporting built into your profile you may find it easier to manage all your liabilities by just utilising TempME.`,
  },
  {
    id: 11,
    question:
      "How do I build a strong profile and attract companies on the platform?",
    answer: `To stand out, create a professional and detailed profile that highlights your skills, experience, and past work. We have a 5 star rating system which will allow you to build ‘your brand’ and we recommend you fully complete and regularly update your profile to get the best assignments.`,
  },
  {
    id: 12,
    question:
      "Can I use TempME work as a long-term career or is it better for short-term projects?",
    answer: `TempME work can be both short-term and long-term. Some individuals use it to supplement their income, while others turn it into a full-time career. It ultimately depends on your goals and preferences.`,
  },
  // More questions...
];

export default function FAQ() {
  return (
    <div className="bg-white">
      <FadeInStagger className="mx-auto max-w-7xl divide-y divide-gray-900/10 px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
          Frequently asked questions
        </h2>
        <dl className="mt-10 space-y-8 divide-y divide-gray-900/10">
          {faqs.map((faq) => (
            <FadeIn
              key={faq.id}
              className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8"
            >
              <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">
                {faq.question}
              </dt>
              <dd className="mt-4 lg:col-span-7 lg:mt-0">
                <p className="text-base leading-7 text-gray-600">
                  {faq.answer}
                </p>
              </dd>
            </FadeIn>
          ))}
        </dl>
      </FadeInStagger>
    </div>
  );
}
