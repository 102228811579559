import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { User } from "../services/user";
import jwt_decode from "jwt-decode";
import Roles from "../Enum/UserRoles";
import { fetchCandidate } from "./candidate";
import { fetchCompany } from "./company";

const user = new User();

export const updateUser = createAsyncThunk(
  "authenticationSlice/updateUser",
  async ({ data }) => {
    return await user.updateUser(data);
  }
);
export const updateUserB = createAsyncThunk(
  "authenticationSlice/updateUserB",
  async ({ data }, ThunkAPI) => {
    await user.updateUser(data);

    const state = ThunkAPI.getState();
    const { role } = state.authenticationSlice;

    if (role === Roles.company) {
      ThunkAPI.dispatch(fetchCompany());
    } else if (role === Roles.candidate) {
      ThunkAPI.dispatch(fetchCandidate());
    }
  }
);

export function isTokenExpired(token) {
  const decoded = jwt_decode(token);
  const currentTime = Date.now() / 1000; // Obtenir le temps actuel en secondes

  return decoded.exp < currentTime;
}

const initialToken = localStorage.getItem("userToken");

let initialRole = null;
let initialId = null;
let initialRoleMember = null;
let member_name = null;

if (initialToken && !isTokenExpired(initialToken)) {
  initialRole = jwt_decode(initialToken).role;
  initialId = jwt_decode(initialToken).id;
  initialRoleMember = jwt_decode(initialToken)?.member_role
    ? jwt_decode(initialToken)?.member_role
    : null;
  member_name = jwt_decode(initialToken)?.member_name
    ? jwt_decode(initialToken)?.member_name
    : null;

  if (
    window.location.pathname !== "/" &&
    window.location.pathname === "/login"
  ) {
    var newURL = window.location.href.replace("/login", "");
    window.history.replaceState({}, document.title, newURL);
  }
} else {
  // Si le token est expiré, le supprimer du localStorage
  localStorage.removeItem("userToken");
}

export const connect = createAsyncThunk(
  "authenticationSlice/connect",
  async ({ email, password, sub_user }) => {
    if (sub_user) {
      return await user.connectSubUser({
        email: email,
        password: password,
      });
    } else {
      return await user.connectUser({
        email: email,
        password: password,
      });
    }
  }
);
export const reset = createAsyncThunk(
  "authenticationSlice/reset",
  async ({ email }) => {
    return await user.resetPassword(email);
  }
);

export const authenticationSlice = createSlice({
  name: "authenticationSlice",
  initialState: {
    role: initialRole, // Utiliser le rôle initial déduit du token (si disponible)
    token: initialToken, // Utiliser le token initial du localStorage (si disponible)
    loading: false,
    error: null,
    id: initialId,
    role_member: initialRoleMember,
    member_name: member_name,
    exist: false,
    pushSent: false,
    log_out: false,
  },
  reducers: {
    setLogout: (state, action) => {
      state.log_out = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(connect.pending, (state) => {
        state.loading = true;
      })
      .addCase(connect.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action.payload);
        if (action.payload && action.payload.token) {
          if (!isTokenExpired(action.payload.token)) {
            // Stocker le token dans le localStorage
            localStorage.setItem("userToken", action.payload.token);
            state.token = action.payload.token;
            state.role = jwt_decode(action.payload.token).role;
            state.id = jwt_decode(action.payload.token).id;
            state.role_member = jwt_decode(action.payload.token).member_role
              ? jwt_decode(action.payload.token).member_role
              : null;
            state.member_name = jwt_decode(action.payload.token).member_name
              ? jwt_decode(action.payload.token).member_name
              : null;
            window.location.replace("/");
          } else {
            state.error = "Token has expired. Please log in again.";
          }
        } else if (action.payload && action.payload.link) {
          window.location.replace(action.payload.link);
        } else {
          state.error = "Something went wrong. Please try again.";
        }
      })
      .addCase(reset.pending, (state) => {
        state.loading = true;
      })
      .addCase(reset.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload && action.payload.message) {
          state.exist = true;
          state.error = null;
          setTimeout(() => {
            window.location.replace("/login");
            state.exist = false;
          }, [30000]);
        } else {
          state.error = "User does not exist !";
        }
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.pushSent = true;
      });
  },
});

export const { setLogout } = authenticationSlice.actions;
