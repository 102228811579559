import React, { useEffect } from "react";
import Map from "./Map";
import Stat from "./Stat";
import Upcoming from "./Upcoming";
import JobInstant from "./Job";
import Employers from "../../../components/showcase/Employers";
import useCompanies from "../../../hook/companies";

function Dashboard() {
  useCompanies();

  return (
    <div className="  p-2 sm:p-4 flex flex-col">
      <div className="lg:hidden block">
        <h1 className="text-sky-600 text-lg md:text-xl font-medium mb-4">
          Instant apply
        </h1>
        <JobInstant />

        <div className="h-64 xl:hidden my-3">
          <Map />
        </div>
      </div>

      <Stat />
      <hr className="my-5" />

      <div className="lg:block hidden">
        <h1 className="text-sky-600 text-lg md:text-xl font-medium mb-4">
          Instant apply
        </h1>
        <JobInstant />

        <div className="h-64 xl:hidden my-3">
          <Map />
        </div>
      </div>

      <h1 className="text-sky-600 text-lg md:text-xl font-medium mb-4 leading-none">
        You're in good company
      </h1>
      <Employers />

      <hr className="my-5" />

      <Upcoming />
      <hr className="my-5" />
    </div>
  );
}

export default Dashboard;
