import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdmins, fetchAuthAdmin } from "../redux/admin";

export default function useAdmin() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAuthAdmin());
  }, []);
}

export function useGetAdmins() {
  const dispatch = useDispatch();

  const { admin, admins, limit, page } = useSelector(
    (state) => state.adminSlice
  );

  useEffect(() => {
    dispatch(fetchAdmins());
  }, [limit, page]);
}
