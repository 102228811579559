import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/20/solid";
import { useFetchContact } from "../../../hook/contact";
import { useDispatch, useSelector } from "react-redux";
import {
  setInquirie,
  setOpenContact,
  setOpenToAnswer,
  setPageContact,
} from "../../../redux/users";
import moment from "moment";
import TextArea from "../../../components/Messages/textArea";
import { useState } from "react";

export default function Contact() {
  useFetchContact();
  const { contacts, page_contact, limit, inquirie, openToAnswer } = useSelector(
    (state) => state.UserSlice
  );

  const start = (page_contact - 1) * limit + 1;
  const end = Math.min(page_contact * limit, contacts.total);
  const dispatch = useDispatch();

  function handleAnswer(person) {
    dispatch(setInquirie(person));
    dispatch(setOpenToAnswer(true));
  }

  function handleUpdate() {
    dispatch(setOpenToAnswer(false));
  }

  return (
    <>
      <h1 className=" font-extralight text-2xl text-sky-600">Inquiries</h1>

      <div className="grid grid-cols-12  ">
        <div className=" col-span-6 ">
          <nav
            className="flex  w-full items-center justify-between  bg-white px-4 py-3 sm:px-6"
            aria-label="Pagination"
          >
            <div className="flex flex-1 justify-between items-center sm:justify-end">
              <div className="hidden sm:block me-4">
                <p className="text-sm text-gray-700">
                  <span className="font-medium">{start}</span> -
                  <span className="font-medium">{end}</span> /
                  <span className="font-medium">{contacts.total}</span> results
                </p>
              </div>
              <button
                onClick={() => dispatch(setPageContact(page_contact - 1))}
                disabled={start === 1}
                className=" disabled:bg-slate-400/40 disabled:text-white relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
              >
                <ChevronLeftIcon className="w-4 h-4" />
              </button>
              <button
                disabled={end === contacts.total}
                onClick={() => dispatch(setPageContact(page_contact + 1))}
                className="disabled:bg-slate-400/40 disabled:text-white relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
              >
                <ChevronRightIcon className="w-4 h-4" />
              </button>
            </div>
          </nav>
          <div className=" space-y-3 max-h-[calc(100vh-10rem)] overflow-auto">
            {contacts.contacts &&
              contacts.contacts.map((person) => (
                <div
                  key={person.data.email}
                  className="relative  border border-dashed rounded-md grid grid-cols-12  gap-4 cursor-pointer p-4 hover:bg-gray-50/60 "
                >
                  <div className="flex flex-col col-span-full">
                    <h1 className="font-medium text-lg">{person.data.name}</h1>
                    <div className="ms-2 border-l flex flex-col space-y-2 border-gray-600/70 mt-2 ps-3">
                      <a
                        href={`mailto:${person.email}`}
                        className="text-sm font-light text-sky-600"
                      >
                        {person.data.email}
                      </a>
                      <a
                        href={`tel:${person.data.phone}`}
                        className="text-sm font-light text-sky-600"
                      >
                        {person.data.phone}
                      </a>
                      <time
                        className="text-sm font-light   text-end "
                        dateTime={person.date}
                      >
                        Sent : {moment(person.date).fromNow()}
                      </time>
                    </div>
                  </div>
                  <div className="flex flex-col col-span-full overflow-hidden">
                    <p className="bg-gray-400/20 w-full p-2 rounded-md">
                      {person.data.description}
                    </p>
                  </div>
                  <div className="col-span-full flex justify-end items-end font-medium space-x-6">
                    <a
                      role="button"
                      $
                      className="text-red-600 hover:text-red-800"
                      onClick={() => {
                        handleAnswer(person);
                      }}
                    >
                      Delete
                    </a>

                    <a
                      role="button"
                      className="hover:text-sky-800 text-sky-600"
                      onClick={() => {
                        handleAnswer(person);
                      }}
                    >
                      Reply
                    </a>
                  </div>
                </div>
              ))}
          </div>
        </div>

        {openToAnswer && (
          <div className="col-span-6 p-2">
            <div>
              <h1 className="text-lg "> {inquirie && inquirie.data.name}</h1>
              <p className="bg-gray-400/20 w-full p-3 mt-4 rounded-md border border-sky-600 max-h-96 overflow-y-scroll ">
                {inquirie && inquirie.data.description}
              </p>

              <TextArea setUpdated={handleUpdate} className="mt-20" />
            </div>
          </div>
        )}

        {!openToAnswer && (
          <div className="col-span-6 p-2 animate-pulse text-2xl flex justify-center items-center">
            Reply inquire
          </div>
        )}
      </div>
    </>
  );
}
