import React, { useEffect, useRef, useState } from "react";
import { baseUrl } from "../utils";
import { FadeIn, FadeInStagger } from "../components/utils/FadeIn";

function VideoSection() {
  const videoRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [showButton, setShowButton] = useState(true);

  const togglePlay = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  };

  // Mettez à jour l'état `isPlaying` lorsque le bouton de lecture/pause est cliqué
  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);

    // Ajoutez les écouteurs d'événements pour play et pause
    video.addEventListener("play", handlePlay);
    video.addEventListener("pause", handlePause);

    const handleKeyPress = (e) => {
      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
    };

    window.document.addEventListener("keypress", handleKeyPress);

    // Supprimez les écouteurs d'événements lors du nettoyage
    return () => {
      video.removeEventListener("play", handlePlay);
      video.removeEventListener("pause", handlePause);
    };
  }, []);

  useEffect(() => {
    if (isPlaying) {
      setShowButton(false);
    } else {
      setShowButton(true);
    }
  }, [isPlaying]);

  return (
    <div class="relative overflow-hidden">
      <FadeInStagger class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-10">
        <FadeIn class="max-w-2xl text-center mx-auto">
          <h1 class="block text-3xl font-bold text-gray-800 lg:text-4xl  dark:text-white">
            Designed For You to Get More{" "}
            <span class="text-sky-600">Simple</span>
          </h1>
          <p class="mt-3 text-lg text-gray-800 dark:text-gray-400">
            Build your business now.
          </p>
        </FadeIn>

        <FadeIn class="mt-10 relative max-w-5xl  mx-auto" onClick={togglePlay}>
          <video
            ref={videoRef}
            loop
            autoPlay={false}
            muted={true}
            poster={require("../assets/img/poster.png")}
            class="w-full object-cover h-auto z-50  rounded-xl"
          >
            <source
              src={[baseUrl, "api/upload/read/video"].join("/")}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>

          <div class="absolute inset-0 w-full h-full">
            <div class="flex flex-col justify-center items-center w-full h-full">
              {showButton && (
                <a
                  class={`justify-center inline-flex items-center cursor-pointer gap-x-1.5 text-center text-sm bg-white text-gray-800 hover:text-gray-600 rounded-full transition focus:outline-none focus:ring-2 focus:ring-sky-600 focus:ring-offset-2 focus:ring-offset-white transition py-3 px-4 dark:bg-black dark:text-gray-200 dark:hover:text-gray-400 dark:focus:ring-offset-black`}
                  onClick={(e) => {
                    e.stopPropagation();
                    togglePlay();
                  }}
                >
                  <svg
                    class="w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z" />
                  </svg>
                  Play the overview
                </a>
              )}
            </div>
          </div>

          <div class="absolute bottom-12 -left-20 -z-[1] w-48 h-48 bg-gradient-to-b from-orange-500 to-white p-px rounded-lg dark:to-slate-900">
            <div class="bg-white w-48 h-48 rounded-lg dark:bg-slate-900"></div>
          </div>

          <div class="absolute -top-12 -right-20 -z-[1] w-48 h-48 bg-gradient-to-t from-sky-600 to-cyan-400 p-px rounded-full">
            <div class="bg-white w-48 h-48 rounded-full dark:bg-slate-900"></div>
          </div>
        </FadeIn>
      </FadeInStagger>
    </div>
  );
}

export default VideoSection;
