import React, { useState } from "react";
import JobCompany from "../../../components/Job/job-company";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import { useJobCompany } from "../../../hook/job";
import { setJobPage } from "../../../redux/company";

function Jobs() {
  useJobCompany();
  const dispatch = useDispatch();
  const { jobs, job_page, job_limit, job_search } = useSelector(
    (state) => state.companySlice
  );
  const start = (job_page - 1) * job_limit + 1;
  const end = Math.min(job_page * job_limit, jobs.total);
  return (
    <div className="max-h-[calc(100vh-8rem)] grid gap-y-3  overflow-hidden">
      <div className="p-2 grid gap-y-4">
        <div className="col-span-full">
          <h1 className="text-xl text-sky-400 font-medium">Job published</h1>
        </div>
      </div>
      <nav
        className="flex  w-full items-center justify-between  bg-white px-4 py-3 sm:px-6"
        aria-label="Pagination"
      >
        <div className="hidden sm:block">
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{start}</span> to{" "}
            <span className="font-medium">{end}</span> of{" "}
            <span className="font-medium">{jobs.total}</span> results
          </p>
        </div>
        <div className="flex flex-1 justify-between sm:justify-end">
          <button
            onClick={() => dispatch(setJobPage(job_page - 1))}
            disabled={start === 1}
            className=" disabled:bg-slate-400/40 disabled:text-white relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
          >
            Previous
          </button>
          <button
            disabled={end === jobs.total}
            onClick={() => dispatch(setJobPage(job_page + 1))}
            className="disabled:bg-slate-400/40 disabled:text-white relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
          >
            Next
          </button>
        </div>
      </nav>
      <div
        className={`col-span-full  xl:grid-cols-3 px-4 max-h-[calc(100vh-12rem)] overflow-y-scroll  md:grid-cols-2  grid-cols-1 grid gap-4 divide-y pb-32  `}
      >
        {jobs.datas && jobs.datas.map((item) => <JobCompany items={item} />)}
      </div>
    </div>
  );
}

export default Jobs;
