import React, { useEffect, useState } from "react";
import useCandidate from "../../../hook/candidate";

import Account from "./Account";
import Notifications from "./Notifications";
import Finance from "./Finance";
import useCompany from "../../../hook/company";
import { useDispatch, useSelector } from "react-redux";
import { setStage } from "../../../redux/navigation";
import NewMember from "./new-member";
import useShift from "../../../hook/shift";
import { usePayouts } from "../../../hook/stripe";
import { StripeDashboard } from "../Finance/stripe-dashbord";
import { useLocation, useSearchParams } from "react-router-dom";
import GoBack from "../../../components/utils/goBack";

function Profile() {
  const { company } = useCompany();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  useShift();
  usePayouts();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const params = {};
  for (let [key, value] of queryParams.entries()) {
    params[key] = value;
  }

  const { stage } = useSelector((state) => state.NavigationSlice);

  const secondaryNavigation = [
    { name: "Account", href: 0, current: stage === 0 },
    { name: "Notifications", href: 1, current: stage === 1 },
    { name: "Finance", href: 2, current: stage === 2 },
    { name: "Teams", href: 3, current: stage === 3 },
    { name: "Payouts", href: 4, current: stage === 4 },
  ];

  useEffect(() => {
    const tab = parseInt(searchParams.get("tab"));

    console.warn(tab);

    dispatch(setStage(tab));
  }, [searchParams.get("tab")]);

  return (
    company && (
      <main className="md:max-w-7xl w-sm overflow-hidden">
        <header className="border-b border-gray-300">
          {/* Secondary navigation */}
          <nav className="flex overflow-x-auto py-4">
            <ul
              role="list"
              className="flex items-center  flex-none gap-x-6 px-4 text-sm font-semibold leading-6 cursor-pointer text-gray-600 sm:px-6 lg:px-8"
            >
              <li key={"back"}>
                <GoBack />
              </li>
              {secondaryNavigation.map((item) => (
                <li key={item.name}>
                  <a
                    onClick={() =>
                      setSearchParams({
                        name: item.name,
                        tab: item.href,
                      })
                    }
                    className={
                      item.current ? "text-sky-400 relative" : "relative"
                    }
                  >
                    {company &&
                      company.user &&
                      !company.user.stripe &&
                      item.href === 2 && (
                        <span class="absolute top-0 -right-1 inline-flex items-center w-3.5 h-3.5 rounded-full border-2 border-white text-xs font-medium transform -translate-y-1/2 translate-x-1/2 bg-red-500 text-white">
                          <span class="sr-only">Badge value</span>
                        </span>
                      )}
                    {company &&
                      company.user &&
                      company.user.stripe &&
                      company.user.stripe.requirements.currently_due.length >
                        0 &&
                      item.href === 2 && (
                        <span class="absolute top-0 -right-1 inline-flex items-center w-3.5 h-3.5 rounded-full border-2 border-white text-xs font-medium transform -translate-y-1/2 translate-x-1/2 bg-red-500 text-white">
                          <span class="sr-only">Badge value</span>
                        </span>
                      )}

                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </header>

        {/* Settings forms */}

        {company && stage === 0 && <Account />}
        {company && stage === 1 && <Notifications />}
        {company && stage === 2 && <Finance />}
        {company && stage === 4 && <StripeDashboard />}
        {company && stage === 3 && <NewMember />}
      </main>
    )
  );
}

export default Profile;
