/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  BellIcon,
  BriefcaseIcon,
  ChartBarSquareIcon,
  Cog6ToothIcon,
  HomeIcon,
  UserIcon,
  XMarkIcon,
  ChatBubbleLeftRightIcon,
  CalendarDaysIcon,
  ClockIcon,
  ChartPieIcon,
  UserCircleIcon,
  BookmarkIcon,
  TableCellsIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  Cog8ToothIcon,
  FireIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import Candidate from "./candidate";
import Company from "./company";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Map from "./candidate/Dashboard/Map";
import ModalAvailability from "../components/modal/modal-availability";
import ModalApply from "../components/modal/modal-apply";
import { useDispatch, useSelector } from "react-redux";
import usePushNotifications from "../hook/notification";
import useConversation from "../hook/conversation";
import Notification from "../components/notification";
import useCandidate from "../hook/candidate";
import { baseUrl } from "../utils";
import useCompany from "../hook/company";
import useNotifications from "../hook/notifications";
import Roles from "../Enum/UserRoles";
import Application from "./company/Application";
import CommandShift from "../components/command-shift/command-shift";
import CommandApplication from "../components/command-shift/command-application";
import Applications from "../components/application";
import { updateProfileCandidate } from "../redux/candidate";
import socket from "../redux/socket";
import ModalComplete from "../components/modal/modal-complete";
import ModalActivate from "../components/modal/modal-activate";
import { updateProfileCompany } from "../redux/company";
import { setStage } from "../redux/navigation";
import ModalCard from "../components/modal/model-card";
import CommandTeams from "../components/Teams/Teams";
import EditMember from "../components/Teams/Edit-member";
import { setImage } from "../redux/conversation";
import SuccessScan from "../components/modal/success-scan";

import { setLogout } from "../redux/authentication";
import { SpinnerLoad } from "../components/utils/spinner-load";

const navigation = {
  company: [
    {
      name: "Home",
      to: "/",
      icon: HomeIcon,
      current: window.location.pathname == "/",
      roles: [Roles.company, Roles.manager, Roles.member],
    },
    {
      name: "Jobs",
      to: "/jobs",
      icon: BriefcaseIcon,
      current: window.location.pathname == "/jobs",
      roles: [Roles.company, Roles.manager, Roles.member],
    },
    {
      name: "Candidate",
      to: "/candidate",
      icon: UserIcon,
      current: window.location.pathname == "/#",
      roles: [Roles.company, Roles.manager, Roles.member],
    },
    {
      name: "Shift",
      to: "/shift",
      icon: CalendarDaysIcon,
      current: window.location.pathname == "/#",
      roles: [Roles.company, Roles.manager, Roles.member],
    },
    {
      name: "Finance",
      to: "/profile?tab=2&name=Finance",
      icon: ChartPieIcon,
      tab: 2,
      roles: [Roles.company, Roles.manager],
    },
  ],

  candidate: [
    {
      name: "Home",
      to: "/",
      icon: HomeIcon,
      current: window.location.pathname == "/",
      roles: [Roles.candidate],
    },
    {
      name: "Jobs",
      to: "/jobs",
      icon: BriefcaseIcon,
      current: window.location.pathname == "/jobs",
      roles: [Roles.candidate],
    },
    {
      name: "Upcoming",
      to: "/upcoming",
      icon: ClockIcon,
      current: window.location.pathname == "/#",
      roles: [Roles.candidate],
    },
    {
      name: "Availability",
      to: "/availability",
      icon: CalendarDaysIcon,
      current: window.location.pathname == "/#",
      roles: [Roles.candidate],
    },
    {
      name: "History",
      to: "/history",
      icon: TableCellsIcon,
      current: window.location.pathname == "/#",
      roles: [Roles.candidate],
    },
    {
      name: "Finance",
      to: "/profile?tab=2&name=Finance",
      icon: ChartPieIcon,
      tab: 2,
      roles: [Roles.candidate],
    },
  ],
};

const componnent = [
  {
    role: "CANDIDATE",
    componnent: <Candidate />,
  },
  {
    role: "COMPANY",
    componnent: <Company />,
  },
];

const componnentUtils = [
  {
    role: "CANDIDATE",
    componnent: [
      <ModalAvailability />,
      <ModalApply />,
      <Notification />,
      <ModalComplete />,
      <SuccessScan />,
    ],
  },
  {
    role: "COMPANY",
    componnent: [
      <Notification />,
      <CommandShift />,
      <CommandApplication />,
      <Applications />,
      <ModalActivate />,
      <ModalCard />,
      <CommandTeams />,
      <EditMember />,
    ],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Layout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [infoShow, setInfoShow] = useState(true);
  const location = useLocation();
  const { role, id, member_name, role_member } = useSelector(
    (state) => state.authenticationSlice
  );
  const { q, filterOpen } = useSelector((state) => state.FilterSlice);
  const {
    convId,
    countNotification,
    countMessages,
    notifOpen,
    tabConvSelected,
  } = useSelector((state) => state.conversationSlice);
  useNotifications();
  const { applicationLaunch, candidateLaunch } = useSelector(
    (state) => state.ApplicationSlice
  );
  const { datas, loading } = useCandidate(role === "CANDIDATE");
  const { company, onLoad } = useCompany(role === "COMPANY");
  const { shifPunch } = useSelector((state) => state.ShiftSlice);

  console.warn(role_member);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { conv } = useConversation();

  function handleDetail(id, name, image, userToSend) {
    setSidebarOpen(false);

    dispatch(setImage(image));
    dispatch({
      type: "conversation/setDetail",
    });
    dispatch({
      type: "conversation/setConvId",
      payload: id,
    });
    dispatch({
      type: "conversation/setSendTo",
      payload: name,
    });
    dispatch({
      type: "conversation/setUserToSend",
      payload: userToSend,
    });
  }

  function handleFocus() {
    if (role === Roles.candidate) {
      if (location.pathname !== "/jobs") {
        navigate("/jobs");
      }
    } else {
      if (location.pathname !== "/candidate") {
        navigate("/candidate");
      }
    }
  }

  const logout = async () => {
    const token = localStorage.getItem("userToken");
    dispatch(setLogout(true));
    await fetch([baseUrl, "api/login/out"].join("/"), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(() => {
      localStorage.clear();
      dispatch(setLogout(false));
      window.location.replace("/login");
    });

    // Rediriger l'utilisateur ou mettre à jour l'état de l'interface utilisateur
  };

  return (
    <>
      <div className="relative">
        <div>
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-50 lg:hidden"
              onClose={() => setSidebarOpen(false)}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-900/80" />
              </Transition.Child>

              <div className="fixed inset-0 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                        <button
                          type="button"
                          className="-m-2.5 p-2.5"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XMarkIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </Transition.Child>
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex grow flex-col gap-y-5 overflow-y-auto  bg-white px-6 pb-4">
                      <Link
                        to={"/"}
                        className="flex h-16 shrink-0 items-center"
                      >
                        <img
                          className="h-8 w-auto"
                          src={require("../assets/img/logo.png")}
                          alt="Your Company"
                        />
                      </Link>

                      <hr />

                      <form className="relative  h-8 " action="#" method="GET">
                        <label htmlFor="search-field" className="sr-only">
                          Search
                        </label>
                        <MagnifyingGlassIcon
                          className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <input
                          id="search-field"
                          className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                          placeholder="Search..."
                          type="search"
                          name="search"
                          value={q}
                          onChange={(e) =>
                            dispatch({
                              type: "FilterSlice/setQuery",
                              payload: e.target.value,
                            })
                          }
                        />
                      </form>
                      <hr />
                      <nav className="flex  flex-col">
                        <ul
                          role="list"
                          className="flex flex-1 flex-col gap-y-7"
                        >
                          <li>
                            <ul role="list" className="-mx-2 space-y-1">
                              {role === Roles.company && (
                                <li>
                                  <a
                                    onClick={() => {
                                      setSidebarOpen(false);
                                      dispatch({
                                        type: "ApplicationSlice/setApplicationLaunch",
                                      });
                                    }}
                                    className={classNames(
                                      "text-gray-700 hover:text-sky-600 cursor-pointer hover:bg-gray-50",
                                      "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                    )}
                                  >
                                    <div className="indicator">
                                      <span className="indicator-item  h-4 inline-flex justify-center items-center rounded-full font-semibold text-white text-[0.650rem] w-4 bg-sky-600">
                                        {countNotification}
                                      </span>
                                      <BookmarkIcon
                                        className={classNames(
                                          "text-gray-400 group-hover:text-sky-600",
                                          "h-6 w-6 shrink-0"
                                        )}
                                        aria-hidden="true"
                                      />
                                    </div>
                                    Latest Applications
                                  </a>
                                </li>
                              )}
                              <li>
                                <a
                                  onClick={() => {
                                    setSidebarOpen(false);
                                    dispatch({
                                      type: "conversation/setNotifOpen",
                                    });
                                  }}
                                  className={classNames(
                                    "text-gray-700 hover:text-sky-600 cursor-pointer hover:bg-gray-50",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  <div className="indicator">
                                    <span className="indicator-item  h-4 inline-flex justify-center items-center rounded-full font-semibold text-white text-[0.650rem] w-4 bg-red-600">
                                      {countNotification}
                                    </span>
                                    {}
                                    {role === Roles.candidate &&
                                      datas &&
                                      datas.hotJob && (
                                        <FireIcon
                                          className={classNames(
                                            "text-orange-400 animate-pulse group-hover:text-sky-600",
                                            "h-6 w-6 shrink-0"
                                          )}
                                          aria-hidden="true"
                                        />
                                      )}
                                    {((role === Roles.candidate &&
                                      datas &&
                                      !datas.hotJob) ||
                                      role === Roles.company) && (
                                      <BellIcon
                                        className={classNames(
                                          "text-gray-400 group-hover:text-sky-600",
                                          "h-6 w-6 shrink-0"
                                        )}
                                        aria-hidden="true"
                                      />
                                    )}
                                  </div>
                                  Notification
                                </a>
                              </li>
                              <li>
                                <Link
                                  to={"/messages"}
                                  onClick={() => {
                                    setSidebarOpen(false);
                                  }}
                                  className={classNames(
                                    location.pathname === "/messages"
                                      ? "bg-gray-50 text-sky-600"
                                      : "text-gray-700 hover:text-sky-600 hover:bg-gray-50",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  <div className="indicator">
                                    <span className="indicator-item  h-4 inline-flex justify-center items-center rounded-full font-semibold text-white text-[0.650rem] w-4 bg-red-600">
                                      {countMessages}
                                    </span>
                                    <ChatBubbleLeftRightIcon
                                      className={classNames(
                                        "text-gray-400 group-hover:text-sky-600",
                                        "h-6 w-6 shrink-0"
                                      )}
                                      aria-hidden="true"
                                    />
                                  </div>
                                  Message
                                </Link>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </nav>
                      <hr />

                      <nav className="flex flex-1 flex-col">
                        <ul
                          role="list"
                          className="flex flex-1 flex-col gap-y-7"
                        >
                          <li>
                            <ul role="list" className="-mx-2 space-y-1">
                              {navigation[role.toLocaleLowerCase()].map(
                                (item) =>
                                  item.roles.includes(role) &&
                                  (role_member === null ||
                                    item.roles.includes(role_member)) && (
                                    <li
                                      key={item.name}
                                      onClick={() =>
                                        item.tab
                                          ? dispatch(setStage(item.tab))
                                          : undefined
                                      }
                                    >
                                      <Link
                                        onClick={() => setSidebarOpen(false)}
                                        to={item.to}
                                        className={classNames(
                                          location.pathname === item.to
                                            ? "bg-gray-50 text-sky-600"
                                            : "text-gray-700 hover:text-sky-600 hover:bg-gray-50",
                                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                        )}
                                      >
                                        <item.icon
                                          className={classNames(
                                            location.pathname === item.to
                                              ? "text-sky-600"
                                              : "text-gray-400 group-hover:text-sky-600",
                                            "h-6 w-6 shrink-0"
                                          )}
                                          aria-hidden="true"
                                        />
                                        {item.name}
                                      </Link>
                                    </li>
                                  )
                              )}
                            </ul>
                          </li>
                          <li>
                            <div className="text-xs font-semibold leading-6 text-gray-400">
                              Quick contact
                            </div>
                            <ul
                              role="list"
                              className="-mx-2 mt-2 space-y-1 max-h-72 overflow-hidden hover:overflow-scroll"
                            >
                              {conv &&
                                conv.map((item) => (
                                  <li
                                    key={
                                      role === "CANDIDATE"
                                        ? item.company.name
                                        : [
                                            item.candidate.firstName,
                                            item.candidate.lastName,
                                          ].join(" ")
                                    }
                                  >
                                    <Link
                                      to={"/messages"}
                                      onClick={() =>
                                        handleDetail(
                                          item.id,
                                          role === Roles.candidate
                                            ? item.company.name
                                            : [
                                                item.candidate.firstName,
                                                item.candidate.lastName,
                                              ].join(" "),
                                          role === Roles.candidate
                                            ? item.company.logo
                                            : item.candidate.candidateProfile
                                                .profile.image,
                                          role === Roles.candidate
                                            ? item.company.userId
                                            : item.candidate.userId
                                        )
                                      }
                                      href={item.href}
                                      className={classNames(
                                        "text-gray-700 hover:text-sky-600 hover:bg-gray-50",
                                        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                      )}
                                    >
                                      <span
                                        className={classNames(
                                          item.id === convId
                                            ? " border-sky-600 bg-sky-600 text-white font-bold shadow-md"
                                            : "text-gray-400 border-gray-200 group-hover:border-sky-600 group-hover:text-sky-600 font-medium  bg-white",
                                          "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] "
                                        )}
                                      >
                                        {role === "CANDIDATE"
                                          ? item.company.name.substring(0, 1)
                                          : [
                                              item.candidate.firstName,
                                              item.candidate.lastName,
                                            ]
                                              .join(" ")
                                              .substring(0, 1)}
                                      </span>
                                      <span className="truncate text-sm">
                                        {role === "CANDIDATE"
                                          ? item.company.name
                                          : [
                                              item.candidate.firstName,
                                              item.candidate.lastName,
                                            ].join(" ")}
                                      </span>
                                    </Link>
                                  </li>
                                ))}
                            </ul>
                          </li>
                          {role === Roles.candidate && datas && (
                            <li>
                              <div className="text-xs font-semibold leading-6 text-gray-400">
                                Quick settings
                              </div>
                              <button
                                onClick={() =>
                                  dispatch(
                                    updateProfileCandidate({
                                      id: datas.id,
                                      params: {
                                        hotJob: !datas.hotJob,
                                      },
                                    })
                                  )
                                }
                                className={classNames(
                                  datas.hotJob
                                    ? "text-orange-500 hover:text-red-600"
                                    : "hover:text-sky-600",
                                  "text-gray-700  hover:bg-gray-50",
                                  "group  inline-flex items-center   gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full"
                                )}
                              >
                                <FireIcon
                                  className={classNames(
                                    datas.hotJob
                                      ? "text-orange-500 animate-pulse hover:text-red-600"
                                      : "group-hover:text-sky-600",
                                    "h-6 w-6 shrink-0 text-gray-400 "
                                  )}
                                  aria-hidden="true"
                                />
                                Hot job{" "}
                                <span className="text-xs font-normal">
                                  {datas.hotJob ? "(enabled)" : "(disabled)"}
                                </span>
                              </button>
                            </li>
                          )}
                          {(role_member === null ||
                            role_member === Roles.manager) && (
                            <li className="mt-auto">
                              <Link
                                to="/profile"
                                onClick={() => {
                                  setSidebarOpen(false);
                                }}
                                className={classNames(
                                  location.pathname === "/profile"
                                    ? "bg-gray-50 text-sky-600"
                                    : "text-gray-700 hover:text-sky-600 hover:bg-gray-50",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                )}
                              >
                                <Cog6ToothIcon
                                  className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-sky-600"
                                  aria-hidden="true"
                                />
                                Profile
                              </Link>
                            </li>
                          )}
                        </ul>
                      </nav>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>

          {/* Static sidebar for desktop */}
          <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4 ">
              <Link to={"/"} className="flex h-16 shrink-0 items-center">
                <img
                  className="h-8 w-auto"
                  src={require("../assets/img/logo.png")}
                  alt="Your Company"
                />
              </Link>
              <hr />

              <form className="relative  h-8 " action="#" method="GET">
                <label htmlFor="search-field" className="sr-only">
                  Search
                </label>
                <MagnifyingGlassIcon
                  className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                  aria-hidden="true"
                />
                <input
                  id="search-field"
                  className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                  placeholder="Search..."
                  type="search"
                  name="search"
                  onFocus={handleFocus}
                  value={q}
                  onChange={(e) => {
                    dispatch({
                      type: "FilterSlice/setQuery",
                      payload: e.target.value,
                    });
                  }}
                />
              </form>

              <hr />

              <nav className="flex  flex-col">
                <ul role="list" className="flex flex-1 flex-col gap-y-7">
                  <li>
                    <ul role="list" className="-mx-2 space-y-1">
                      <li>
                        <a
                          onClick={() =>
                            dispatch({
                              type: "conversation/setNotifOpen",
                            })
                          }
                          className={classNames(
                            "text-gray-700 hover:text-sky-600 cursor-pointer hover:bg-gray-50",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          <div className="indicator">
                            <span className="indicator-item  h-4 inline-flex justify-center items-center rounded-full font-semibold text-white text-[0.650rem] w-4 bg-red-600">
                              {countNotification}
                            </span>

                            {role === Roles.candidate &&
                              datas &&
                              datas.hotJob && (
                                <FireIcon
                                  className={classNames(
                                    "text-orange-600 group-hover:text-sky-600",
                                    "h-6 w-6 shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                              )}

                            {((role === Roles.candidate &&
                              datas &&
                              !datas.hotJob) ||
                              role === Roles.company) && (
                              <BellIcon
                                className={classNames(
                                  "text-gray-400 group-hover:text-sky-600",
                                  "h-6 w-6 shrink-0"
                                )}
                                aria-hidden="true"
                              />
                            )}
                          </div>
                          Notification
                        </a>
                      </li>
                      <li>
                        <Link
                          to={"/messages"}
                          className={classNames(
                            location.pathname === "/messages"
                              ? "bg-gray-50 text-sky-600"
                              : "text-gray-700 hover:text-sky-600 hover:bg-gray-50",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          <div className="indicator">
                            <span className="indicator-item  h-4 inline-flex justify-center items-center rounded-full font-semibold text-white text-[0.650rem] w-4 bg-red-600">
                              {countMessages}
                            </span>
                            <ChatBubbleLeftRightIcon
                              className={classNames(
                                "text-gray-400 group-hover:text-sky-600",
                                "h-6 w-6 shrink-0"
                              )}
                              aria-hidden="true"
                            />
                          </div>
                          Message
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <hr />
              <nav className="flex flex-1 flex-col">
                <ul role="list" className="flex flex-1 flex-col gap-y-7">
                  <li>
                    <ul role="list" className="-mx-2 space-y-1">
                      {navigation[role.toLocaleLowerCase()].map(
                        (item) =>
                          item.roles.includes(role) &&
                          (role_member === null ||
                            item.roles.includes(role_member)) && (
                            <li
                              key={item.name}
                              onClick={() =>
                                item.tab
                                  ? dispatch(setStage(item.tab))
                                  : undefined
                              }
                            >
                              <Link
                                to={item.to}
                                className={classNames(
                                  location.pathname === item.to
                                    ? "bg-gray-50 text-sky-600"
                                    : "text-gray-700 hover:text-sky-600 hover:bg-gray-50",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                )}
                              >
                                <item.icon
                                  className={classNames(
                                    location.pathname === item.to
                                      ? "text-sky-600"
                                      : "text-gray-400 group-hover:text-sky-600",
                                    "h-6 w-6 shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                                {item.name}
                              </Link>
                            </li>
                          )
                      )}
                    </ul>
                  </li>
                  <li>
                    <div className="text-xs font-semibold leading-6 text-gray-400">
                      Quick contact
                    </div>
                    <ul
                      role="list"
                      className="-mx-2 mt-2 space-y-1 max-h-72 overflow-hidden hover:overflow-scroll"
                    >
                      {conv &&
                        conv.map((item) => (
                          <li
                            key={
                              role === "CANDIDATE"
                                ? item.company.name
                                : [
                                    item.candidate.firstName,
                                    item.candidate.lastName,
                                  ].join(" ")
                            }
                          >
                            <Link
                              to={"/messages"}
                              onClick={() =>
                                handleDetail(
                                  item.id,
                                  role === Roles.candidate
                                    ? item.company.name
                                    : [
                                        item.candidate.firstName,
                                        item.candidate.lastName,
                                      ].join(" "),
                                  role === Roles.candidate
                                    ? item.company.logo
                                    : item.candidate.candidateProfile.profile
                                        .image,
                                  role === Roles.candidate
                                    ? item.company.userId
                                    : item.candidate.userId
                                )
                              }
                              href={item.href}
                              className={classNames(
                                "text-gray-700 hover:text-sky-600 hover:bg-gray-50",
                                "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                              )}
                            >
                              <span
                                className={classNames(
                                  item.id === convId
                                    ? " border-sky-600 bg-sky-600 text-white font-bold shadow-md"
                                    : "text-gray-400 border-gray-200 group-hover:border-sky-600 group-hover:text-sky-600 font-medium  bg-white",
                                  "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] "
                                )}
                              >
                                {role === "CANDIDATE"
                                  ? item.company.name.substring(0, 1)
                                  : [
                                      item.candidate.firstName,
                                      item.candidate.lastName,
                                    ]
                                      .join(" ")
                                      .substring(0, 1)}
                              </span>
                              <span className="truncate text-sm">
                                {role === "CANDIDATE"
                                  ? item.company.name
                                  : [
                                      item.candidate.firstName,
                                      item.candidate.lastName,
                                    ].join(" ")}
                              </span>
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </li>
                  {role === Roles.candidate && datas && (
                    <li>
                      <div className="text-xs font-semibold leading-6 text-gray-400">
                        Quick settings
                      </div>
                      <button
                        onClick={() =>
                          dispatch(
                            updateProfileCandidate({
                              id: datas.id,
                              params: {
                                hotJob: !datas.hotJob,
                              },
                            })
                          )
                        }
                        className={classNames(
                          datas.hotJob
                            ? "text-orange-500 hover:text-red-600"
                            : "hover:text-sky-600",
                          "text-gray-700  hover:bg-gray-50",
                          "group  inline-flex items-center   gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full"
                        )}
                      >
                        <FireIcon
                          className={classNames(
                            datas.hotJob
                              ? "text-orange-500 animate-pulse hover:text-red-600"
                              : "group-hover:text-sky-600",
                            "h-6 w-6 shrink-0 text-gray-400 "
                          )}
                          aria-hidden="true"
                        />
                        Hot job{" "}
                        <span className="text-xs font-normal">
                          {datas.hotJob ? "(enabled)" : "(disabled)"}
                        </span>
                      </button>
                    </li>
                  )}

                  {(role_member === null || role_member === Roles.manager) && (
                    <li className="mt-auto">
                      <Link
                        to={"/profile"}
                        className={classNames(
                          location.pathname === "/profile"
                            ? "bg-gray-50 text-sky-600"
                            : "text-gray-700 hover:text-sky-600 hover:bg-gray-50",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                        )}
                      >
                        <UserIcon
                          className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-sky-600"
                          aria-hidden="true"
                        />
                        Profile
                      </Link>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>

          <div className="lg:pl-72 ">
            <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
              <button
                type="button"
                className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Separator */}
              <div
                className="h-6 w-px bg-gray-900/10 lg:hidden"
                aria-hidden="true"
              />

              <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                <form className="relative flex flex-1" action="#" method="GET">
                  <label htmlFor="search-field" className="sr-only">
                    Search
                  </label>
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <input
                    id="search-field"
                    className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                    placeholder="Search..."
                    type="search"
                    name="search"
                    onFocus={handleFocus}
                    value={q}
                    onChange={(e) =>
                      dispatch({
                        type: "FilterSlice/setQuery",
                        payload: e.target.value,
                      })
                    }
                  />
                </form>
                <div className="flex items-center gap-x-4 lg:gap-x-6">
                  <button
                    type="button"
                    onClick={() =>
                      dispatch({
                        type: "conversation/setNotifOpen",
                      })
                    }
                    className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500  cursor-pointer"
                  >
                    <span className="sr-only">View notifications</span>
                    <div className="indicator">
                      <span className="indicator-item  h-4 inline-flex justify-center items-center rounded-full font-semibold text-white text-[0.650rem] w-4 bg-red-600">
                        {countNotification}
                      </span>
                      {role === Roles.candidate && datas && datas.hotJob && (
                        <FireIcon
                          className="h-6 w-6 animate-pulse text-orange-600"
                          aria-hidden="true"
                        />
                      )}

                      {((role === Roles.candidate && datas && !datas.hotJob) ||
                        role === Roles.company) && (
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      )}
                    </div>
                  </button>

                  {/* Separator */}
                  <div
                    className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                    aria-hidden="true"
                  />

                  {/* Profile dropdown */}

                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="-m-1.5 flex items-center p-1.5">
                        <span className="sr-only">Open user menu</span>
                        {role === "CANDIDATE" && datas && (
                          <img
                            className="h-8 w-8 rounded-full bg-gray-50"
                            src={datas.candidateProfile.profile.image}
                            alt=""
                          />
                        )}

                        {role === "COMPANY" && company && (
                          <img
                            className="h-8 w-8 rounded-full bg-gray-50"
                            src={company.logo}
                            alt=""
                          />
                        )}

                        {["CANDIDATE", "COMPANY"].includes(role) &&
                          [loading, onLoad].includes(true) && (
                            <div className="h-8 w-8 rounded-full bg-slate-200  animate-pulse"></div>
                          )}

                        <span className="hidden lg:flex lg:items-center">
                          <span
                            className="ml-4 text-sm mb-0 flex flex-col space-y-0 font-semibold  text-gray-900"
                            aria-hidden="true"
                          >
                            {role === "CANDIDATE" &&
                              datas &&
                              [datas.firstName, datas.lastName].join(" ")}

                            {company && role === "COMPANY" && company.name}

                            {member_name && (
                              <span className=" text-sky-600 text-end text-[0.6rem]">
                                {member_name} | {role_member}
                              </span>
                            )}
                          </span>
                          <ChevronDownIcon
                            className="ml-2 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-4 py-3">
                          <p className="text-sm">Signed in as </p>

                          {!role_member && (
                            <p className="truncate text-sm font-medium text-gray-900">
                              {role === "COMPANY" &&
                                company &&
                                company.user.email}
                              {role === "CANDIDATE" &&
                                datas &&
                                datas.user.email}
                            </p>
                          )}
                          {role_member && (
                            <p className="truncate text-sm font-medium text-gray-900">
                              {role_member}
                            </p>
                          )}
                        </div>
                        {(role_member === null ||
                          role_member === Roles.manager) && (
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/profile"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  Account settings
                                </Link>
                              )}
                            </Menu.Item>
                          </div>
                        )}

                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={logout}
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block w-full px-4 py-2 text-left text-sm"
                                )}
                              >
                                Sign out
                              </button>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>

                  {/*    <Menu as="div" className="relative">
                  
               
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <Link
                              onClick={() => item.func()}
                              to={item.href}
                              className={classNames(
                                active ? "bg-gray-50" : "",
                                "block px-3 py-1 text-sm leading-6 text-gray-900"
                              )}
                            >
                              {item.name}
                            </Link>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu> */}
                  {role === Roles.company && (
                    <>
                      {role_member === null || role_member === Roles.manager ? (
                        <Link
                          to={"/job/create"}
                          className="relative hidden disabled:bg-slate-400 md:inline-flex items-center rounded-md shadow-sky-200 bg-sky-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                        >
                          Create new job
                        </Link>
                      ) : (
                        <button
                          disabled
                          className="relative hidden cursor-not-allowed disabled:bg-slate-400 md:inline-flex items-center rounded-md shadow-sky-200 bg-sky-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                        >
                          Create new job
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>

            <main
              className={`${
                ["/messages", "/candidate"].includes(location.pathname)
                  ? "pb-0"
                  : "pb-16"
              }  lg:pb-0`}
            >
              <div className="xl:pr-[21vw]">
                <div className="px-0 py-0 sm:px-7 lg:px-0 lg:py-0">
                  {infoShow && company && !company.user.visible && (
                    <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
                      <div
                        className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
                        aria-hidden="true"
                      >
                        <div
                          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#2d8cff] to-[#f17ce5] opacity-30"
                          style={{
                            clipPath:
                              "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
                          }}
                        />
                      </div>
                      <div
                        className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
                        aria-hidden="true"
                      >
                        <div
                          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#33a7ff] to-[#9054ff] opacity-30"
                          style={{
                            clipPath:
                              "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
                          }}
                        />
                      </div>

                      <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
                        <p className="text-sm leading-6 text-gray-900">
                          <strong className="font-semibold">Team TempME</strong>
                          <svg
                            viewBox="0 0 2 2"
                            className="mx-2 inline h-0.5 w-0.5 fill-current"
                            aria-hidden="true"
                          >
                            <circle cx={1} cy={1} r={1} />
                          </svg>
                          {company &&
                          company.industry &&
                          company.description &&
                          company.crn &&
                          company.vat &&
                          company.user.stripe &&
                          company.user.stripe.requirements.currently_due
                            .length === 0
                            ? "Your company profile seems be completed "
                            : " Thanks to have join us, for a better experience complete your company profile to allow you post job and accept candidates"}
                        </p>
                        {company &&
                        company.industry &&
                        company.description &&
                        company.crn &&
                        company.vat &&
                        company.user.stripe &&
                        company.user.stripe.requirements.currently_due ? (
                          <button
                            onClick={() =>
                              dispatch(
                                updateProfileCompany({
                                  id: company.id,
                                  params: {
                                    visible: true,
                                  },
                                })
                              )
                            }
                            className="flex-none rounded-full bg-green-600 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                          >
                            Activate my account
                            <span aria-hidden="true">&rarr;</span>
                          </button>
                        ) : (
                          <Link
                            to="/profile"
                            className="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                          >
                            Complete <span aria-hidden="true">&rarr;</span>
                          </Link>
                        )}
                      </div>
                      <div className="flex flex-1 justify-end">
                        <button
                          type="button"
                          onClick={() => setInfoShow(false)}
                          className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
                        >
                          <span className="sr-only">Dismiss</span>
                          <XMarkIcon
                            className="h-5 w-5 text-gray-900"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>
                  )}
                  {infoShow && datas && !datas.user.visible && (
                    <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
                      <div
                        className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
                        aria-hidden="true"
                      >
                        <div
                          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#2d8cff] to-[#f17ce5] opacity-30"
                          style={{
                            clipPath:
                              "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
                          }}
                        />
                      </div>
                      <div
                        className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
                        aria-hidden="true"
                      >
                        <div
                          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#33a7ff] to-[#9054ff] opacity-30"
                          style={{
                            clipPath:
                              "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
                          }}
                        />
                      </div>

                      <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
                        <p className="text-sm leading-6 text-gray-900">
                          <strong className="font-semibold">Team TempME</strong>
                          <svg
                            viewBox="0 0 2 2"
                            className="mx-2 inline h-0.5 w-0.5 fill-current"
                            aria-hidden="true"
                          >
                            <circle cx={1} cy={1} r={1} />
                          </svg>
                          {datas &&
                          datas?.hourlyRate?.value &&
                          datas?.radius &&
                          datas?.title &&
                          datas?.user?.legalDocumentId?.path &&
                          datas.user.stripe &&
                          datas.user.stripe.requirements.currently_due
                            .length === 0
                            ? "Your profile seems be completed "
                            : " Thanks to have join us, for a better experience complete your profile to apply and be visible for companies."}
                        </p>
                        {datas &&
                        datas?.hourlyRate?.value &&
                        datas?.radius &&
                        datas.title &&
                        datas?.user?.legalDocumentId?.path &&
                        datas.user.stripe &&
                        datas.user.stripe.requirements.currently_due.length ===
                          0 ? (
                          <button
                            onClick={() =>
                              dispatch(
                                updateProfileCandidate({
                                  id: datas.id,
                                  params: {
                                    visible: true,
                                  },
                                })
                              )
                            }
                            className="flex-none rounded-full bg-green-600 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                          >
                            Render me visible for companies{" "}
                            <span aria-hidden="true">&rarr;</span>
                          </button>
                        ) : (
                          <Link
                            to="/profile"
                            className="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                          >
                            Complete <span aria-hidden="true">&rarr;</span>
                          </Link>
                        )}
                      </div>
                      <div className="flex flex-1 justify-end">
                        <button
                          type="button"
                          onClick={() => setInfoShow(false)}
                          className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
                        >
                          <span className="sr-only">Dismiss</span>
                          <XMarkIcon
                            className="h-5 w-5 text-gray-900"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>
                  )}

                  {componnent.map((el) => el.role === role && el.componnent)}
                </div>
              </div>
            </main>

            <aside
              className={`fixed inset-y-0 right-0 hidden w-[21vw] overflow-y-auto border-l border-gray-200  ${
                role === Roles.candidate && "py-0 px-4"
              }  sm:px-2 lg:px-0 xl:flex ${
                role === Roles.company && "pt-[calc(4rem+10px)] px-2 "
              } `}
            >
              {role === Roles.candidate && <Map />}
              {role === Roles.company && <Application />}
            </aside>
          </div>
        </div>
        {componnentUtils.map((el) => el.role === role && el.componnent)}
        {role === Roles.company &&
          !["/messages", "/candidate"].includes(location.pathname) &&
          !applicationLaunch &&
          !candidateLaunch &&
          !shifPunch &&
          !notifOpen && (
            <div class="fixed z-50 lg:hidden w-full h-16 max-w-lg -translate-x-1/2 bg-white border border-gray-200 rounded-full bottom-1 left-1/2 dark:bg-gray-700 dark:border-gray-600">
              <div class="grid h-full max-w-lg grid-cols-5 mx-auto">
                <Link
                  to={"/"}
                  data-tooltip-target="tooltip-home"
                  type="button"
                  class="inline-flex flex-col items-center justify-center px-5 rounded-l-full hover:bg-gray-50 dark:hover:bg-gray-800 group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    class="w-7 h-7 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-sky-600 dark:group-hover:text-sky-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                    />
                  </svg>
                </Link>
                <button
                  data-tooltip-target="tooltip-wallet"
                  type="button"
                  class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group"
                >
                  <a
                    onClick={() => {
                      setSidebarOpen(false);
                      dispatch({
                        type: "ApplicationSlice/setApplicationLaunch",
                      });
                    }}
                    class="w-7 h-7 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-sky-600 dark:group-hover:text-sky-500"
                  >
                    <div className="indicator">
                      <span className="indicator-item  h-4 inline-flex justify-center items-center rounded-full font-semibold text-white text-[0.650rem] w-4 bg-sky-600">
                        {countNotification}
                      </span>
                      <BookmarkIcon
                        class="w-7 h-7 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-sky-600 dark:group-hover:text-sky-500"
                        aria-hidden="true"
                      />
                    </div>
                  </a>
                </button>

                <div class="flex items-center justify-center">
                  <Link
                    to={"/job/create"}
                    data-tooltip-target="tooltip-new"
                    type="button"
                    class="inline-flex items-center justify-center w-10 h-10 font-medium bg-sky-600 rounded-full hover:bg-sky-700 group focus:ring-4 focus:ring-sky-300 focus:outline-none dark:focus:ring-sky-800"
                  >
                    <svg
                      class="w-4 h-4 text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 18 18"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 1v16M1 9h16"
                      />
                    </svg>
                  </Link>
                </div>

                <button
                  data-tooltip-target="tooltip-settings"
                  type="button"
                  class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group"
                >
                  <Link
                    to={"/messages"}
                    onClick={() => {
                      setSidebarOpen(false);
                    }}
                    class="w-7 h-7 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-sky-600 dark:group-hover:text-sky-500"
                  >
                    <div className="indicator">
                      <span className="indicator-item  h-4 inline-flex justify-center items-center rounded-full font-semibold text-white text-[0.650rem] w-4 bg-red-600">
                        {countMessages}
                      </span>
                      <ChatBubbleLeftRightIcon
                        class="w-7 h-7 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-sky-600 dark:group-hover:text-sky-500"
                        aria-hidden="true"
                      />
                    </div>
                  </Link>
                </button>

                <Link
                  to={"/shift"}
                  data-tooltip-target="tooltip-profile"
                  type="button"
                  class="inline-flex flex-col items-center justify-center px-5 rounded-r-full hover:bg-gray-50 dark:hover:bg-gray-800 group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1}
                    stroke="currentColor"
                    class="w-7 h-7 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-sky-600 dark:group-hover:text-sky-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          )}
        {role === Roles.candidate &&
          !["/messages"].includes(location.pathname) &&
          !filterOpen &&
          !notifOpen && (
            <div class="fixed z-50 lg:hidden w-full h-16 max-w-lg -translate-x-1/2 bg-white border border-gray-200 rounded-full bottom-1 left-1/2 dark:bg-gray-700 dark:border-gray-600">
              <div class="grid h-full max-w-lg grid-cols-5 mx-auto">
                <Link
                  to={"/"}
                  data-tooltip-target="tooltip-home"
                  type="button"
                  class="inline-flex flex-col items-center justify-center px-5 rounded-l-full hover:bg-gray-50 dark:hover:bg-gray-800 group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    class="w-7 h-7 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-sky-600 dark:group-hover:text-sky-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                    />
                  </svg>
                </Link>
                <Link
                  to={"/upcoming"}
                  data-tooltip-target="tooltip-wallet"
                  type="button"
                  class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group"
                >
                  <a
                    onClick={() => {
                      setSidebarOpen(false);
                      dispatch({
                        type: "ApplicationSlice/setApplicationLaunch",
                      });
                    }}
                    class="w-7 h-7 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-sky-600 dark:group-hover:text-sky-500"
                  >
                    <ClockIcon
                      class="w-7 h-7 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-sky-600 dark:group-hover:text-sky-500"
                      aria-hidden="true"
                    />
                  </a>
                </Link>

                <div class="flex items-center  justify-center">
                  <button
                    onClick={() =>
                      window.ReactNativeWebView.postMessage(
                        JSON.stringify({ data: "open-camera" })
                      )
                    }
                    data-tooltip-target="tooltip-new"
                    type="button"
                    class="inline-flex items-center justify-center w-10 h-10 font-medium bg-sky-600 rounded-full hover:bg-sky-700 group focus:ring-4 focus:ring-sky-300 focus:outline-none dark:focus:ring-sky-800"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      class="w-5 h-5 text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z"
                      />
                    </svg>
                  </button>
                </div>

                <button
                  data-tooltip-target="tooltip-settings"
                  type="button"
                  class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group"
                >
                  <Link
                    to={"/messages"}
                    onClick={() => {
                      setSidebarOpen(false);
                    }}
                    class="w-7 h-7 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-sky-600 dark:group-hover:text-sky-500"
                  >
                    <div className="indicator">
                      <span className="indicator-item  h-4 inline-flex justify-center items-center rounded-full font-semibold text-white text-[0.650rem] w-4 bg-red-600">
                        {countMessages}
                      </span>
                      <ChatBubbleLeftRightIcon
                        class="w-7 h-7 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-sky-600 dark:group-hover:text-sky-500"
                        aria-hidden="true"
                      />
                    </div>
                  </Link>
                </button>

                <Link
                  to={"/availability"}
                  data-tooltip-target="tooltip-profile"
                  type="button"
                  class="inline-flex flex-col items-center justify-center px-5 rounded-r-full hover:bg-gray-50 dark:hover:bg-gray-800 group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    class="w-7 h-7 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-sky-600 dark:group-hover:text-sky-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          )}
        <SpinnerLoad />
      </div>
    </>
  );
}
