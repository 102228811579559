import React, { useState } from "react";
import useIndustries from "../hook/industry";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import { PhotoIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import { useRegistration } from "../hook/registration";
import { useSelector } from "react-redux";
import { FilePreview } from "../components/utils/preview";
import { SpinnerLoadData } from "../components/utils/spinner-load";

function DocumentID({ id, onUpdate, temp, save, isChangedDocument }) {
  const { candidate, onLoad } = useSelector((state) => state.candidateSlice);

  console.log(isChangedDocument());

  return !onLoad ? (
    <div
      id={id}
      className="grid grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8"
    >
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Provide us an ID document
        </h2>
        <p className="mt-1 text-sm leading-4 text-gray-600">
          We need a document ID to allow you get your money on the flow
        </p>
      </div>
      <div className="grid  grid-cols-1 gap-x-6 md:gap-y-8 gap-y-4 md:h-auto  sm:grid-cols-6 md:col-span-2">
        <div className="col-span-full">
          <label
            htmlFor="account-number"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Document ID number
            <span className="text-red-600 after:content-['*']"></span>
          </label>
          <div className="relative mt-2 rounded-md shadow-sm">
            <input
              type="text"
              name="legalDocumentId.documentNumber"
              id="account-number"
              onChange={onUpdate}
              value={temp?.legalDocumentId?.documentNumber}
              required
              autoCapitalize="characters"
              defaultValue={candidate.user?.legalDocumentId?.documentNumber}
              className={`block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset  ${
                candidate.user?.legalDocumentId?.documentNumber
                  ? "ring-gray-300"
                  : "ring-red-500"
              } placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 text-sm sm:leading-6`}
              placeholder="ABC00000"
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <QuestionMarkCircleIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
          </div>
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="date-of-issue"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Date of issue
            <span className="text-red-600 after:content-['*']"></span>
          </label>
          <div className="mt-2">
            <input
              type="date"
              value={temp?.legalDocumentId?.issueDate}
              name="legalDocumentId.issueDate"
              defaultValue={candidate.user?.legalDocumentId?.issueDate}
              onChange={onUpdate}
              required
              id="date-of-issue"
              className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset  ${
                candidate.user?.legalDocumentId?.issueDate
                  ? "ring-gray-300"
                  : "ring-red-500"
              } placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 text-sm sm:leading-6`}
              placeholder={moment("YYYY-MM-DD")}
            />
          </div>
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="email"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Expiry date
            <span className="text-red-600 after:content-['*']"></span>
          </label>
          <div className="mt-2">
            <input
              type="date"
              required
              value={temp?.legalDocumentId?.expiryDate}
              onChange={onUpdate}
              name="legalDocumentId.expiryDate"
              defaultValue={candidate.user?.legalDocumentId?.expiryDate}
              id="expiry-date"
              className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset  ${
                candidate.user?.legalDocumentId?.expiryDate
                  ? "ring-gray-300"
                  : "ring-red-500"
              } placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 text-sm sm:leading-6`}
              placeholder={moment("YYYY-MM-DD")}
            />
          </div>
        </div>
        <div className="col-span-full">
          <label
            htmlFor="cover-photo"
            className="block text-sm font-medium leading-6 text-gray-800"
          >
            Document<span className="text-red-600 after:content-['*']"></span>
          </label>
          <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
            <div className="text-center">
              {temp?.legalDocumentId?.doc && (
                <p className="font-medium">
                  {temp?.legalDocumentId?.doc?.name}
                </p>
              )}

              {/*  {candidate.user?.legalDocumentId?.path &&
                  !temp?.legalDocumentId?.doc && (
                    <p className="font-medium text-cyan-500 cursor-pointer">
                      {candidate.user?.legalDocumentId?.path} (ID uploaded)
                    </p>
                  )}
 */}
              {!candidate.user?.legalDocumentId?.path && (
                <PhotoIcon
                  className="mx-auto h-12 w-12 text-gray-300"
                  aria-hidden="true"
                />
              )}
              {(temp?.legalDocumentId?.doc ||
                candidate.user?.legalDocumentId?.path) && (
                <FilePreview
                  href={
                    temp?.legalDocumentId?.doc
                      ? URL.createObjectURL(temp?.legalDocumentId?.doc)
                      : candidate.user?.legalDocumentId?.path
                  }
                  filePath={
                    temp?.legalDocumentId?.doc
                      ? URL.createObjectURL(temp?.legalDocumentId?.doc)
                      : candidate.user?.legalDocumentId?.path
                  }
                />
              )}

              <div className="mt-4 flex text-sm justify-center items-center leading-6 text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer rounded-md bg-white font-semibold text-sky-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-sky-600 focus-within:ring-offset-2 hover:text-sky-500"
                >
                  {!temp?.legalDocumentId?.doc ? (
                    <span>Upload a file</span>
                  ) : (
                    <span>Upload a new file ?</span>
                  )}

                  <input
                    required
                    id="file-upload"
                    name="legalDocumentId.doc"
                    onChange={onUpdate}
                    type="file"
                    accept=".jpg, .jpeg, .png, .gif, .pdf"
                    className="sr-only"
                  />
                </label>
              </div>
              <p className="text-xs leading-5 text-gray-600">
                PNG, JPG, GIF up to 10MB
              </p>
            </div>
          </div>
        </div>
        <div className="mt-8 flex">
          {temp.legalDocumentId &&
            (temp?.legalDocumentId?.documentNumber ||
              temp?.legalDocumentId?.issueDate ||
              temp?.legalDocumentId?.expiryDate ||
              temp?.legalDocumentId?.doc?.name) && (
              <button
                onClick={save}
                className="rounded-md disabled:bg-slate-500 disabled:opacity-50  bg-sky-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500"
              >
                save
              </button>
            )}
        </div>
      </div>
    </div>
  ) : (
    <SpinnerLoadData />
  );
}

export default DocumentID;
