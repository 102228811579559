import { ApiClient } from "./api";

export class Candidate {
  constructor() {
    this.apiClient = new ApiClient();
  }

  async getCandidate() {
    try {
      const { data } = await this.apiClient.get(`candidate/authreq`);

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async getOneCandidate(id) {
    try {
      const { data } = await this.apiClient.get(`candidate/one/${id}`);

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async getCandidates(filter = {}) {
    try {
      const { data } = await this.apiClient.get(`candidate`);

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async getCandidatesAdmin(page = 1, limit = 10, query = "") {
    try {
      const { data } = await this.apiClient.get(
        `candidate/all/candidates?page=${page}&limit=${limit}&search=${query}`
      );

      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async putProfileCandidate(params = {}) {
    try {
      const { data } = await this.apiClient.put(
        `candidateProfile/${params.id}`,
        params.profile
      );

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async updateCandidate(id, params) {
    try {
      const { data } = await this.apiClient.put(`candidate/${id}`, params);

      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async postImage(id = null, doc) {
    try {
      const { data } = await this.apiClient.postWithDocument(
        `upload/candidate/${id}`,
        {},
        doc
      );

      return data;
    } catch (error) {
      console.error(error);
    }
  }
}
