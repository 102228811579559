import React, { useEffect } from "react";
import InstantJob, {
  InstantJobLoading,
} from "../../../components/Job/instant-job";
import useJob from "../../../hook/job";
import { useDispatch, useSelector } from "react-redux";

function JobInstant() {
  const { data, hasMore, error, fetchData, loading } = useJob({
    instant: true,
    all: true,
  });
  const { r, hr, creatAt, starsAsc, distAsc } = useSelector(
    (state) => state.FilterSlice
  );

  return (
    <div className="flex flex-auto w-full h-auto overflow-x-hidden hover:overflow-x-auto ">
      <div className="flex space-x-4 pe-4 p-3">
        {data && data.map((item) => <InstantJob key={item.id} item={item} />)}
        {data && data.length === 0 && !loading && (
          <p className=" text-xs  md:text-sm">
            No openings jobs available !
          </p>
        )}
        {loading && (
          <>
            <InstantJobLoading />
            <InstantJobLoading />
            <InstantJobLoading />
            <InstantJobLoading />
          </>
        )}
      </div>
    </div>
  );
}

export default JobInstant;
