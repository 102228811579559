export default class Shift {
  static VALID = "VALID";
  static CANCEL = "CANCEL";
  static NO_SHOW = "NO_SHOW";

  static STATUS(string) {
    switch (string) {
      case "NO_SHOW":
        return "bg-red-600";
      case "CANCEL":
        return "bg-orange-400";
      case "VALID":
        return "bg-green-400";
      default:
        break;
    }
  }
}
