import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Companies } from "../services/companies";

const companies = new Companies();

export const fetchOneCompany = createAsyncThunk(
  "DetailSlice/fetchOneCompany",
  async (id) => {
    return await companies.getOneCompany(id);
  }
);

export const DetailSlice = createSlice({
  name: "DetailSlice",
  initialState: {
    item: {},
  },
  reducers: {
    resetItem: (state) => {
      state.item = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOneCompany.fulfilled, (state, action) => {
      state.item = action.payload;
    });
  },
});
