/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useEffect, useState } from "react";
import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  FunnelIcon,
  MinusIcon,
  PlusIcon,
  Squares2X2Icon,
} from "@heroicons/react/20/solid";
import JobCard from "../../../components/Job/Job-card";
import { useDispatch, useSelector } from "react-redux";
import useJob from "../../../hook/job";
import InfiniteScroll from "react-infinite-scroll-component";
import useGeolocation from "../../../hook/geolocation";
import useCompanies from "../../../hook/companies";

const sortOptions = [
  { name: "Most Popular", href: "#", current: true },
  { name: "Best Rating", href: "#", current: false },
  { name: "Newest", href: "#", current: false },
  { name: "Price: Low to High", href: "#", current: false },
  { name: "Price: High to Low", href: "#", current: false },
];

const filters = [
  {
    id: "sector",
    name: "Sector",
    options: [
      { value: "new-arrivals", label: "New Arrivals", checked: false },
      { value: "sale", label: "Sale", checked: false },
      { value: "travel", label: "Travel", checked: true },
      { value: "organization", label: "Organization", checked: false },
      { value: "accessories", label: "Accessories", checked: false },
    ],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Jobs() {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const { data, hasMore, error, fetchData } = useJob({ all: true });

  const { r, hr, creatAt, starsAsc, distAsc, filterOpen } = useSelector(
    (state) => state.FilterSlice
  );
  const dispatch = useDispatch();
  const locateme = useGeolocation();
  const [hrValue, setHrValue] = useState(hr);
  const [rValue, setRValue] = useState(r);
  const [hrDebounceTimer, setHrDebounceTimer] = useState(null);
  const [rDebounceTimer, setRDebounceTimer] = useState(null);

  const handleHrChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    setHrValue(newValue);

    if (hrDebounceTimer) {
      clearTimeout(hrDebounceTimer);
    }

    setHrDebounceTimer(
      setTimeout(() => {
        // Appel à l'API avec la valeur hr actuelle après le délai
        dispatch({
          type: "FilterSlice/setHr",
          payload: e.target.value,
        });
      }, 500) // Délai de 500 millisecondes
    );
  };

  const handleRChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    setRValue(newValue);

    if (rDebounceTimer) {
      clearTimeout(rDebounceTimer);
    }

    setRDebounceTimer(
      setTimeout(() => {
        // Appel à l'API avec la valeur r actuelle après le délai
        dispatch({
          type: "FilterSlice/setR",
          payload: e.target.value,
        });
      }, 500) // Délai de 500 millisecondes
    );
  };

  const subCategories = [
    { name: "Best rated", href: "setStarsAsc", for: starsAsc },
    { name: "Near me", href: "setDistAsc", for: distAsc },
    { name: "Newest", href: "setCreatAt", for: creatAt },
  ];

  return (
    <div className="h-[calc(100vh-4rem)] overflow-hidden">
      {/* Mobile filter dialog */}
      <Transition.Root show={mobileFiltersOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative  z-40 lg:hidden"
          onClose={() => {
            setMobileFiltersOpen(false);
            dispatch({
              type: "FilterSlice/setFilterOpen",
              payload: false,
            });
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed bottom-0 h-full items-end w-full z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-y-full"
              enterTo="translate-y-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-y-0"
              leaveTo="translate-y-full"
            >
              <Dialog.Panel className="relative bottom-0 flex h-[60%] w-full  flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                <div className="flex items-center justify-between px-4">
                  <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                  <button
                    type="button"
                    className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                    onClick={() => {
                      setMobileFiltersOpen(false);
                      dispatch({
                        type: "FilterSlice/setFilterOpen",
                        payload: false,
                      });
                    }}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Filters */}
                <form className="mt-4 border-t border-gray-200">
                  <h3 className="sr-only">Categories</h3>

                  <div className="space-y-4 px-4 flex flex-col mt-6 border-b border-gray-200 pb-6 text-sm font-medium text-gray-900">
                    <label
                      for="default-range"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Hourly rate
                    </label>
                    <input
                      id="default-range"
                      type="range"
                      value={hrValue}
                      onChange={handleHrChange}
                      className="w-full h-2 bg-gray-200 rounded-lg appearance-none  cursor-pointer dark:bg-gray-700"
                    />

                    <span className="inline-flex self-end w-fit whitespace-nowrap items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-semibold text-sky-900">
                      {hrValue} GBP/H
                    </span>

                    <label
                      for="default-range"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      distance me - job
                    </label>
                    <input
                      id="default-range"
                      type="range"
                      max={1000}
                      value={rValue}
                      onChange={handleRChange}
                      className="w-full h-2 bg-gray-200 rounded-full appearance-none cursor-pointer dark:bg-gray-700"
                    />

                    <span className="inline-flex self-end w-fit whitespace-nowrap items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-semibold text-sky-900">
                      {r} Km
                    </span>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <main className="mx-auto max-w-7xl px-1 sm:px-3 lg:px-8 h-full">
        <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-4">
          <h1 className=" md:text-4xl font-bold tracking-tight text-sky-400">
            Jobs near you !{" "}
          </h1>

          <div className="flex items-center">
            <button
              type="button"
              className="-m-2 ml-5 p-2 hidden  text-gray-400 hover:text-gray-500 sm:ml-7"
            >
              <span className="sr-only">View grid</span>
              <Squares2X2Icon className="h-5 w-5" aria-hidden="true" />
            </button>

            <button
              type="button"
              onClick={() => {
                setMobileFiltersOpen(!mobileFiltersOpen);
                dispatch({
                  type: "FilterSlice/setFilterOpen",
                  payload: !filterOpen,
                });
              }}
              className="-m-2 ml-5 p-2 text-gray-400 hover:text-gray-500 sm:ml-7"
            >
              <span className="sr-only">View grid</span>
              <FunnelIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>

        <section aria-labelledby="products-heading" className="pb-24 pt-1">
          <h2 id="products-heading" className="sr-only">
            Jobs
          </h2>
          <div className="lg:hidden inline-flex space-x-3 justify-end w-full items-end mb-1 py-2">
            {subCategories.map((category) => (
              <button
                key={category.href}
                type="button"
                onClick={() =>
                  dispatch({
                    type: `FilterSlice/${category.href}`,
                    payload: !category.for,
                  })
                }
                className={`rounded-md ${
                  category.for
                    ? "bg-sky-400 text-white"
                    : "bg-white text-gray-900"
                }  px-3.5 py-2.5 text-xs font-semibold  shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50`}
              >
                {category.name}
              </button>
            ))}
          </div>
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
            {/* Filters */}
            <form className="hidden lg:block">
              <h3 className="sr-only">Categories</h3>

              <ul
                role="list"
                className="space-y-4 border-b border-gray-200 pb-6 text-sm font-medium text-gray-900"
              >
                {subCategories.map((category) => (
                  <li key={category.name}>
                    <button
                      type="button"
                      onClick={() =>
                        dispatch({
                          type: `FilterSlice/${category.href}`,
                          payload: !category.for,
                        })
                      }
                      className={`rounded-md ${
                        category.for
                          ? "bg-sky-400 text-white hover:bg-sky-600"
                          : "bg-white text-gray-900 hover:bg-gray-100/50"
                      }  px-3.5 py-2.5 text-sm font-semibold  shadow-sm ring-1 ring-inset ring-gray-300 `}
                    >
                      {category.name}
                    </button>
                  </li>
                ))}
              </ul>

              <div className="space-y-4 flex flex-col mt-6 border-b border-gray-200 pb-6 text-sm font-medium text-gray-900">
                <label
                  for="default-range"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Hourly rate
                </label>
                <input
                  id="default-range"
                  type="range"
                  max={1000}
                  value={hrValue}
                  onChange={handleHrChange}
                  className="w-full h-2 bg-gray-200 appearance-none rounded-lg  cursor-pointer dark:bg-gray-700"
                />

                <span className="inline-flex self-end w-fit whitespace-nowrap items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-semibold text-sky-900">
                  {hrValue} GBP/H
                </span>

                <label
                  for="default-range"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  distance me - job
                </label>
                <input
                  id="default-range"
                  type="range"
                  max={9000}
                  step={10}
                  value={rValue}
                  onChange={handleRChange}
                  className="w-full h-2 bg-gray-200 appearance-none rounded-full cursor-pointer dark:bg-gray-700"
                />

                <span className="inline-flex self-end w-fit whitespace-nowrap items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-semibold text-sky-900">
                  {r} Km
                </span>
              </div>
            </form>

            {/* Product grid */}
            <div className="lg:col-span-3 pb-8 md:py-4 px-2 h-[calc(100vh-12rem)] md:h-[calc(100vh-12rem)] overflow-y-scroll">
              <div>
                <InfiniteScroll
                  dataLength={data.length}
                  className="grid grid-cols-1   md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 3xl:grid-cols-3 gap-3 lg:gap-2"
                  hasMore={hasMore}
                  next={fetchData}
                  loader={
                    <p className="md:text-sm  text-xs font-medium ">
                      Loading...
                    </p>
                  }
                  endMessage={
                    <p className="md:text-lg text-sky-700 text-sm font-medium ">
                      No more job to load
                    </p>
                  }
                >
                  {data &&
                    data.map((items) => (
                      <JobCard key={items.id} items={items} />
                    ))}
                </InfiniteScroll>
                {error && (
                  <p className="md:text-sm  text-xs font-medium ">
                    Error: {error.message}
                  </p>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
