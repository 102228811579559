import React, { useState } from "react";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import {
  ConnectPayments,
  ConnectComponentsProvider,
  ConnectPayouts,
} from "@stripe/react-connect-js";
import { ApiClient } from "../../../services/api";
import { Container } from "../../../components/utils/Container";
import { useSelector } from "react-redux";
import { SpinnerLoadData } from "../../../components/utils/spinner-load";

const api = new ApiClient();

export function StripeDashboard() {
  const { company } = useSelector((state) => state.companySlice);
  const [load, setLoad] = useState(false);

  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      // Fetch the AccountSession client secret
      setLoad(false);
      const response = await api.post(
        `stripe/account_session?acct=${company.user.stripe.id}`
      );
      const { client_secret: clientSecret } = response.data;

      setLoad(true);

      return clientSecret;
    };
    return loadConnectAndInitialize({
      // This is a placeholder - it should be replaced with your publishable API key.
      // Sign in to see your own test API key embedded in code samples.
      // Don’t submit any personally identifiable information in requests made with this key.
      publishableKey:
        "pk_test_51Ls2bXI7eOuKUJKPnNzFkYGPgK0NpWPCJm9SyEzK6y50yy7V49cuNl7rrJd4e8vhV4pB4CWlhLs2EecBCYzwSotC00j7FXAV20",
      fetchClientSecret: fetchClientSecret,
      appearance: {
        overlays: "dialog",
        variables: {
          colorPrimary: "#3AA9FD",
          colorBackground: "#FFFFFF",
          spacingUnit: "10px",
        },
      },
    });
  });

  return (
    <Container className={"py-6"}>
      {load ? (
        <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
          <ConnectPayouts />
        </ConnectComponentsProvider>
      ) : (
        <SpinnerLoadData />
      )}
    </Container>
  );
}
