import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { useFetchUser } from "../../hook/users";
import { adminValidateUserEmail, setId, updateUser } from "../../redux/users";
import Roles from "../../Enum/UserRoles";
import { fetchOneCompany } from "../../redux/details";
import { setCandidateLaunch } from "../../redux/application";
import GoBack from "../utils/goBack";
import {
  BuildingOfficeIcon,
  CreditCardIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";
import { FilePreview } from "../utils/preview";
import OptionUser from "../utils/Options-admin";

export default function OneUserEdit() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.UserSlice);
  const { id } = useParams();
  const [tab, setTab] = useState(1);

  useFetchUser();
  useEffect(() => {
    dispatch(setId(id));
  }, []);

  function updateForDoc(type) {
    let doc = { ...user.legalDocumentId };
    doc.status = type;
    dispatch(
      updateUser({
        data: {
          legalDocumentId: doc,
        },
      })
    );
  }

  return (
    user && (
      <ul role="list" className="divide-y divide-gray-200">
        <li className="py-4">
          <GoBack />

          {user.roles && user.roles.includes(Roles.candidate) && (
            <div className="  grid  justify-center">
              <span className="isolate inline-flex rounded-md shadow-sm ">
                <button
                  type="button"
                  onClick={() => setTab(1)}
                  className="relative inline-flex md:w-96 items-center  justify-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                >
                  Details
                </button>

                <button
                  type="button"
                  disabled={!user.legalDocumentId}
                  onClick={() => setTab(2)}
                  className="relative -ml-px inline-flex  disabled:bg-gray-200 disabled:cursor-not-allowed disabled:text-gray-50 md:w-96 items-center justify-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                >
                  ID document
                </button>
              </span>
            </div>
          )}

          <div className="mt-5">
            <div className=" ">
              <div className="">
                <div className="mb-3">
                  <h2 className="text-xl font-bold text-gray-800 dark:text-gray-200">
                    User - {id}
                  </h2>
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    Edit user detail
                  </p>
                </div>
                <div className="flex justify-end py-4">
                  <OptionUser />
                </div>

                {tab === 1 && (
                  <form>
                    {/*    <div className="my-5 flex justify-end gap-x-2">
                    <button
                      type="button"
                      className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50  disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-sky-600 text-white hover:bg-sky-700  disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                    >
                      Save changes
                    </button>
                  </div> */}
                    <div className="max-sm:space-y-4 sm:grid sm:grid-cols-12 gap-2 sm:gap-6">
                      <div className="sm:col-span-4">
                        <label
                          for="af-account-email"
                          className="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200"
                        >
                          Name
                        </label>
                      </div>

                      <div className="sm:col-span-8">
                        <input
                          disabled
                          value={
                            user.roles && user?.roles.includes(Roles.company)
                              ? user.company?.name
                              : [
                                  user.candidate?.firstName,
                                  user.candidate?.lastName,
                                ].join(" ")
                          }
                          id="af-account-email"
                          type="text"
                          className="py-2 px-3 pe-11 block w-full  border-gray-200 shadow-sm text-sm rounded-lg focus:border-sky-500 focus:ring-sky-500  disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                        />
                      </div>

                      {user.roles && user.roles.includes(Roles.company) && (
                        <>
                          <div className="sm:col-span-4">
                            <label
                              for="af-account-email"
                              className="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200"
                            >
                              C.R.N
                            </label>
                          </div>

                          <div className="sm:col-span-8">
                            <input
                              disabled
                              value={user.company?.crn}
                              id="af-account-email"
                              type="text"
                              className="py-2 px-3 pe-11 block  w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-sky-500 focus:ring-sky-500  disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                            />
                          </div>
                        </>
                      )}
                      {user.roles && user.roles.includes(Roles.candidate) && (
                        <>
                          <div className="sm:col-span-4">
                            <label
                              for="af-account-email"
                              className="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200"
                            >
                              Title
                            </label>
                          </div>

                          <div className="sm:col-span-8">
                            <input
                              disabled
                              value={user.candidate?.title}
                              id="af-account-email"
                              type="text"
                              className="py-2 px-3 pe-11 block w-full  border-gray-200 shadow-sm text-sm rounded-lg focus:border-sky-500 focus:ring-sky-500  disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                            />
                          </div>
                        </>
                      )}
                      {user.roles && user.roles.includes(Roles.candidate) && (
                        <>
                          <div className="sm:col-span-4">
                            <label
                              for="af-account-email"
                              className="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200"
                            >
                              Hourly rate
                            </label>
                          </div>

                          <div className="sm:col-span-8">
                            <input
                              disabled
                              value={user.candidate?.hourlyRate?.value}
                              id="af-account-email"
                              type="text"
                              className="py-2 px-3 pe-11  block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-sky-500 focus:ring-sky-500  disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                            />
                          </div>
                        </>
                      )}
                      {user.roles && user.roles.includes(Roles.candidate) && (
                        <>
                          <div className="sm:col-span-4">
                            <label
                              for="af-account-email"
                              className="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200"
                            >
                              Currency
                            </label>
                          </div>

                          <div className="sm:col-span-8">
                            <input
                              disabled
                              value={user.candidate?.hourlyRate?.currency?.code}
                              id="af-account-email"
                              type="text"
                              className="py-2 px-3 pe-11  block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-sky-500 focus:ring-sky-500  disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                            />
                          </div>
                        </>
                      )}
                      {user.roles && user.roles.includes(Roles.company) && (
                        <>
                          <div className="sm:col-span-4">
                            <label
                              for="af-account-email"
                              className="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200"
                            >
                              Currency
                            </label>
                          </div>

                          <div className="sm:col-span-8">
                            <input
                              disabled
                              value={user.company?.currency?.code}
                              id="af-account-email"
                              type="text"
                              className="py-2 px-3  pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-sky-500 focus:ring-sky-500  disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                            />
                          </div>
                        </>
                      )}

                      {user.roles && user.roles.includes(Roles.company) && (
                        <>
                          <div className="sm:col-span-4">
                            <label
                              for="af-account-email"
                              className="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200"
                            >
                              VAT
                            </label>
                          </div>

                          <div className="sm:col-span-8">
                            <input
                              disabled
                              value={user.company?.vat}
                              id="af-account-email"
                              type="text"
                              className="py-2  px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-sky-500 focus:ring-sky-500  disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                            />
                          </div>
                        </>
                      )}

                      <div className="sm:col-span-4">
                        <label
                          for="af-account-email"
                          className="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200"
                        >
                          Email / User ID
                        </label>
                      </div>

                      <div
                        className={` ${
                          !user.wasVerified ? "sm:col-span-4" : "sm:col-span-8"
                        }`}
                      >
                        <input
                          disabled
                          value={user.email}
                          id="af-account-email"
                          type="text"
                          className="py-2 px-3  pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-sky-500 focus:ring-sky-500  disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                        />
                      </div>
                      {!user.wasVerified && (
                        <div className="sm:col-span-4">
                          <button
                            onClick={() =>
                              dispatch(
                                adminValidateUserEmail({
                                  email: user.email,
                                })
                              )
                            }
                            type="button"
                            className="rounded-full bg-green-200 px-3.5 py-2 text-sm font-semibold text-green-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          >
                            Send a confirmation link
                          </button>
                        </div>
                      )}

                      <div className="sm:col-span-4">
                        <label
                          for="af-account-email"
                          className="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200"
                        >
                          Phone
                        </label>
                      </div>

                      <div className="sm:col-span-8">
                        <input
                          disabled
                          value={
                            user.roles && user?.roles.includes(Roles.company)
                              ? user.company?.phone
                              : user.candidate?.phone
                          }
                          id="af-account-email"
                          type="tel"
                          className="py-2  px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-sky-500 focus:ring-sky-500  disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                        />
                      </div>
                      <div className="sm:col-span-4">
                        <label
                          for="af-account-email"
                          className="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200"
                        >
                          Location
                        </label>
                      </div>

                      <div className="grid sm:col-span-8 gap-4 ">
                        <div className="sm:col-span-4">
                          <input
                            disabled
                            value={
                              user.roles && user?.roles.includes(Roles.company)
                                ? user.company?.address?.street
                                : user.candidate?.address?.street
                            }
                            id="af-account-email"
                            type="text"
                            className="py-2  px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-sky-500 focus:ring-sky-500  disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                          />
                        </div>
                        <div className="sm:col-span-4">
                          <input
                            value={
                              user.roles && user?.roles.includes(Roles.company)
                                ? user.company?.address.city
                                : user.candidate?.address.city
                            }
                            id="af-account-email"
                            type="text"
                            className="py-2 px-3 pe-11 block w-full  border-gray-200 shadow-sm text-sm rounded-lg focus:border-sky-500 focus:ring-sky-500  disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                          />
                        </div>
                        <div className="sm:col-span-4">
                          <input
                            disabled
                            value={
                              user.roles && user?.roles.includes(Roles.company)
                                ? user.company?.address?.zip
                                : user.candidate?.address?.zip
                            }
                            id="af-account-email"
                            type="text"
                            className="py-2 px-3  pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-sky-500 focus:ring-sky-500  disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                          />
                        </div>
                        <div className="sm:col-span-4">
                          <input
                            value={
                              user.roles && user?.roles.includes(Roles.company)
                                ? user.company?.address?.state
                                : user.candidate?.address?.state
                            }
                            id="af-account-email"
                            type="text"
                            className="py-2 px-3 pe-11 block w-full  border-gray-200 shadow-sm text-sm rounded-lg focus:border-sky-500 focus:ring-sky-500  disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-4">
                        <label
                          for="af-account-email"
                          className="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200"
                        >
                          Role
                        </label>
                      </div>

                      <div className="sm:col-span-4">
                        <input
                          value={user.roles}
                          id="af-account-email"
                          disabled
                          type="text"
                          className="py-2 px-3 pe-11 block w-full   border-gray-200 shadow-sm text-sm rounded-lg focus:border-sky-500 focus:ring-sky-500  disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                        />
                      </div>
                      <div
                        className={`sm:col-span-4 flex items-center ${
                          user.visible
                            ? "text-sky-600"
                            : "text-gray-500 cursor-not-allowed"
                        }  font-semibold justify-center rounded-lg bg-slate-100/30`}
                      >
                        <Link
                          to={
                            user.roles &&
                            !user.roles.includes(Roles.candidate) &&
                            user.visible
                              ? `/company/${user.company?.name}`
                              : ""
                          }
                          onClick={() =>
                            user &&
                            user.roles.includes(Roles.candidate) &&
                            user.visible
                              ? dispatch(setCandidateLaunch(user.candidate?.id))
                              : user.visible &&
                                dispatch(fetchOneCompany(user.company?.id))
                          }
                        >
                          {user.roles &&
                            user.roles.includes(Roles.candidate) &&
                            user.candidate?.id}
                          {user.roles &&
                            user.roles.includes(Roles.company) &&
                            user.company?.id}
                        </Link>
                      </div>
                      <div className="sm:col-span-4">
                        <label
                          for="af-account-email"
                          className="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200"
                        >
                          Stripe ID
                        </label>
                      </div>

                      <div className="sm:col-span-8">
                        <input
                          value={user?.stripe}
                          id="af-account-email"
                          disabled
                          type="text"
                          className="py-2 px-3 pe-11 block w-full   border-gray-200 shadow-sm text-sm rounded-lg focus:border-sky-500 focus:ring-sky-500  disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                        />
                      </div>
                      <div className="sm:col-span-4">
                        <label
                          for="af-account-email"
                          className="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200"
                        >
                          Notfication
                        </label>
                      </div>

                      <div className="sm:col-span-8">
                        <input
                          value={user.notification?.push ? "Yes" : "No"}
                          id="af-account-email"
                          disabled
                          type="text"
                          className="py-2 px-3 pe-11 block w-full   border-gray-200 shadow-sm text-sm rounded-lg focus:border-sky-500 focus:ring-sky-500  disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                        />
                      </div>
                      <div className="sm:col-span-4">
                        <label
                          for="af-account-email"
                          className="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200"
                        >
                          Email checked
                        </label>
                      </div>

                      <div className="sm:col-span-8">
                        <input
                          value={user.wasVerified ? "Yes" : "No"}
                          id="af-account-email"
                          disabled
                          type="text"
                          className="py-2 px-3 pe-11 block w-full   border-gray-200 shadow-sm text-sm rounded-lg focus:border-sky-500 focus:ring-sky-500  disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                        />
                      </div>
                      <div className="sm:col-span-4">
                        <label
                          for="af-account-email"
                          className="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200"
                        >
                          Visible
                        </label>
                      </div>

                      <div className="sm:col-span-8">
                        <input
                          value={user.visible ? "Yes" : "No"}
                          id="af-account-email"
                          disabled
                          type="text"
                          className="py-2 px-3 pe-11 block w-full   border-gray-200 shadow-sm text-sm rounded-lg focus:border-sky-500 focus:ring-sky-500  disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                        />
                      </div>
                    </div>
                  </form>
                )}
                {tab === 2 && user.roles.includes(Roles.candidate) && (
                  <div className="flex flex-col items-center justify-center my-9">
                    <FilePreview filePath={user.legalDocumentId.path} />
                    <hr />
                    <div className="grid grid-cols-6 gap-5 max-w-lg mt-6">
                      <div className="col-span-full">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          ID number
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="email"
                            value={user?.legalDocumentId?.documentNumber}
                            id="email"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="col-span-3">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Issue date
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="email"
                            id="email"
                            value={user?.legalDocumentId?.issueDate}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="col-span-3">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Expiry date
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="email"
                            id="email"
                            value={user?.legalDocumentId?.expiryDate}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    </div>

                    <span className="isolate inline-flex rounded-md shadow-sm my-5">
                      <button
                        type="button"
                        onClick={() =>
                          updateForDoc({
                            status: "valid",
                          })
                        }
                        className="relative inline-flex items-center w-96 justify-center rounded-l-md bg-green-400 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                      >
                        Validate
                      </button>
                      <button
                        onClick={() =>
                          updateForDoc({
                            status: "rejected",
                            content: "please provide us an other document",
                          })
                        }
                        type="button"
                        className="relative -ml-px inline-flex items-center w-96 justify-center bg-red-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                      >
                        Reject
                      </button>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </li>
      </ul>
    )
  );
}
