import { useState, useEffect } from "react";

// Cette fonction renvoie le breakpoint actuel selon la taille de la fenêtre.
const getBreakpoint = (width) => {
  if (width < 320) return "xs"; // Mobile (petit)
  if (width < 640) return "sm"; // Mobile (normal)
  if (width < 768) return "md"; // Tablette
  if (width < 1024) return "lg"; // Ordinateur (petit)
  return "xl"; // Ordinateur (grand)
};

// Ce Hook surveille les redimensionnements de la fenêtre et renvoie le breakpoint actuel.
const useCurrentBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState(
    getBreakpoint(window.innerWidth)
  );

  useEffect(() => {
    const handleResize = () => {
      setBreakpoint(getBreakpoint(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return breakpoint;
};

export default useCurrentBreakpoint;
