import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Admin } from "../services/admin";
import {
  setEmail,
  setFirstName,
  setLastName,
  setLocation,
  setPhone,
} from "./registration";

const admin = new Admin();

export const fetchAuthAdmin = createAsyncThunk(
  "adminSlice/fetchAuthAdmin",
  async () => {
    return await admin.getAuthAdmin();
  }
);
export const fetchCountries = createAsyncThunk(
  "adminSlice/fetchCountries",
  async () => {
    return await admin.getCountries();
  }
);
export const fetchLogs = createAsyncThunk("adminSlice/fetchLogs", async () => {
  return await admin.getLogs();
});
export const createIndustry = createAsyncThunk(
  "adminSlice/createIndustry",
  async () => {
    return await admin.getAuthAdmin();
  }
);
export const updateIndustry = createAsyncThunk(
  "adminSlice/updateIndustry",
  async ({ id, params }) => {
    return await admin.updateIndustry(id, params);
  }
);

export const fetchAdmins = createAsyncThunk(
  "adminSlice/fetchAdmins",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const { page, limit, query } = state.adminSlice;
    return await admin.getAdmins(page, limit, query);
  }
);

export const createUserAdmin = createAsyncThunk(
  "UserSlice/createSubUser",
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState();
    const { firstName, lastName, phone, email, location } =
      state.RegistrationSlice;

    await admin
      .createAdmin({
        name: [firstName, lastName].join(" "),
        email,

        metadata: {
          location,
          phone,
        },
      })
      .then(() => {
        thunkAPI.dispatch(
          setFirstName({
            target: {
              value: "",
            },
          })
        );
        thunkAPI.dispatch(
          setLastName({
            target: {
              value: "",
            },
          })
        );
        thunkAPI.dispatch(
          setEmail({
            target: {
              value: "",
            },
          })
        );
        thunkAPI.dispatch(
          setPhone({
            target: {
              value: "",
            },
          })
        );
        thunkAPI.dispatch(setLocation(""));
        thunkAPI.dispatch(closeSideUser());
      });
  }
);

export const adminSlice = createSlice({
  name: "adminSlice",
  initialState: {
    openProfileSide: false,
    userSide: false,
    admin: {},
    admins: [],
    page: 1,
    limit: 6,
    query: "",
    countries: [],
    updated: true,
    feeds: [],
  },
  reducers: {
    setOpenProfileSide: (state) => {
      state.openProfileSide = true;
    },
    setCloseProfileSide: (state) => {
      state.openProfileSide = false;
    },
    openSideUser: (state) => {
      state.userSide = true;
    },
    closeSideUser: (state) => {
      state.userSide = false;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAuthAdmin.fulfilled, (state, action) => {
      state.admin = action.payload;
    });

    builder.addCase(fetchAdmins.fulfilled, (state, action) => {
      state.admins = action.payload;
    });

    builder.addCase(updateIndustry.pending, (state, action) => {
      state.updated = false;
    });
    builder.addCase(updateIndustry.fulfilled, (state, action) => {
      state.updated = true;
    });
    builder.addCase(fetchCountries.fulfilled, (state, action) => {
      state.countries = action.payload;
    });
    builder.addCase(fetchLogs.fulfilled, (state, action) => {
      state.feeds = action.payload;
    });
  },
});

export const {
  setCloseProfileSide,
  setOpenProfileSide,
  openSideUser,
  closeSideUser,
  setPage,
  setQuery,
  setLimit,
} = adminSlice.actions;
