import React from "react";
import Stats from "./stats";
import TableFinanceHistory from "../../../components/finance/table";
import { useBalance } from "../../../hook/stripe";

function Finance() {
  useBalance();
  return (
    <div>
      <Stats />
      <TableFinanceHistory className="mt-10 p-4 border rounded-md" />
    </div>
  );
}

export default Finance;
