import React from "react";
import { FadeIn, FadeInStagger } from "../components/utils/FadeIn";

function ShowCase() {
  return (
    <>
      <FadeInStagger class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <FadeIn className="text-center my-3">
          <h1 class="text-2xl sm:text-4xl font-bold text-sky-600 dark:text-gray-200">
            Build your career today
          </h1>
          <p className="text-gray-800">
            Work on your prefered industry and switch as you feel like !
          </p>
        </FadeIn>

        <div class="mx-auto my-20  max-w-3xl grid grid-cols-12 gap-6 lg:gap-8">
          <FadeIn class="col-span-6 sm:col-span-4 text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="mx-auto h-auto w-7 md:w-9 text-gray-800 dark:text-gray-200"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 15.75l-2.489-2.489m0 0a3.375 3.375 0 10-4.773-4.773 3.375 3.375 0 004.774 4.774zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <div class="mt-2 sm:mt-6">
              <h3 class="text-lg font-semibold text-sky-600 dark:text-gray-200">
                Search
              </h3>
            </div>
          </FadeIn>

          <FadeIn class="col-span-6 sm:col-span-4 text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="mx-auto h-auto w-7 md:w-9 text-gray-800 dark:text-gray-200"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zm-7.518-.267A8.25 8.25 0 1120.25 10.5M8.288 14.212A5.25 5.25 0 1117.25 10.5"
              />
            </svg>

            <div class="mt-2 sm:mt-6">
              <h3 class="text-lg font-semibold text-sky-600 dark:text-gray-200">
                Apply
              </h3>
            </div>
          </FadeIn>

          <FadeIn class="col-span-6 col-start-4 sm:col-span-4 text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="mx-auto h-auto w-7 md:w-9 text-gray-800 dark:text-gray-200"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
              />
            </svg>

            <div class="mt-2 sm:mt-6">
              <h3 class="text-lg font-semibold text-sky-600 dark:text-gray-200">
                Work
              </h3>
            </div>
          </FadeIn>
        </div>
        <div class="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
          <FadeIn class="group flex flex-col h-full bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7]">
            <div class="h-52 flex flex-col justify-center items-center overflow-hidden bg-blue-600 rounded-t-xl">
              <img
                src={require("../assets/img/industries/driver.jpeg")}
                className="h-full object-cover w-full rounded-t-xl transition duration-300 ease-in-out hover:scale-110"
                alt=""
              />
            </div>
            <div class="p-4 md:p-6">
              <span class="block mb-1 text-xs font-semibold uppercase text-blue-600 dark:text-blue-500">
                Driver
              </span>
              <h3 class="text-xl font-semibold text-gray-800 dark:text-gray-300 dark:hover:text-white">
                Temp Driver
              </h3>
              <p class="mt-3 text-gray-500">
                Navigate roads; ensuring timely, safe delivery of goods and
                passengers.
              </p>
            </div>
          </FadeIn>

          <FadeIn class="group flex flex-col h-full bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7]">
            <div class="h-52 flex flex-col justify-center items-center overflow-hidden bg-rose-500 rounded-t-xl">
              <img
                src={require("../assets/img/industries/plumber.jpeg")}
                className="h-full object-cover w-full object-top rounded-t-xl transition duration-300 ease-in-out hover:scale-110"
                alt=""
              />
            </div>
            <div class="p-4 md:p-6">
              <span class="block mb-1 text-xs font-semibold uppercase text-rose-600 dark:text-rose-500">
                Plumber
              </span>
              <h3 class="text-xl font-semibold text-gray-800 dark:text-gray-300 dark:hover:text-white">
                Temp Plumber
              </h3>
              <p class="mt-3 text-gray-500">
                Fix, maintain water systems; guaranteeing functional, leak-free
                homes.
              </p>
            </div>
          </FadeIn>

          <FadeIn class="group flex flex-col h-full bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7]">
            <div class="h-52 flex flex-col justify-center items-center overflow-hidden bg-amber-900 rounded-t-xl">
              <img
                src={require("../assets/img/industries/hopitality.jpeg")}
                className="h-full object-cover w-full rounded-t-xl transition duration-300 ease-in-out hover:scale-110"
                alt=""
              />
            </div>
            <div class="p-4 md:p-6">
              <span class="block mb-1 text-xs font-semibold uppercase text-pink-600">
                Hospitality
              </span>
              <h3 class="text-xl font-semibold text-gray-800 dark:text-gray-300 dark:hover:text-white">
                Temp Hospitality Worker
              </h3>
              <p class="mt-3 text-gray-500">
                Serve guests; creating memorable stays with warmth and care.
              </p>
            </div>
          </FadeIn>
          <FadeIn class="group flex flex-col h-full bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7]">
            <div class="h-52 flex flex-col justify-center items-center overflow-hidden bg-amber-500 rounded-t-xl">
              <img
                src={require("../assets/img/industries/warehouse.jpeg")}
                className="h-full object-cover w-full rounded-t-xl transition duration-300 ease-in-out hover:scale-110"
                alt=""
              />
            </div>
            <div class="p-4 md:p-6">
              <span class="block mb-1 text-xs font-semibold uppercase text-indigo-700">
                Warehouse & Logistics
              </span>
              <h3 class="text-xl font-semibold text-gray-800 dark:text-gray-300 dark:hover:text-white">
                Temp Warehouser
              </h3>
              <p class="mt-3 text-gray-500">
                Manage goods storage; ensuring efficient distribution and
                inventory control.
              </p>
            </div>
          </FadeIn>
          <FadeIn class="group flex flex-col h-full bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7]">
            <div class="h-52 flex flex-col justify-center items-center overflow-hidden bg-amber-500 rounded-t-xl">
              <img
                src={require("../assets/img/industries/consumer.jpeg")}
                className="h-full object-cover w-full rounded-t-xl transition duration-300 ease-in-out hover:scale-110"
                alt=""
              />
            </div>
            <div class="p-4 md:p-6">
              <span class="block mb-1 text-xs font-semibold uppercase text-amber-500">
                Consumer Service
              </span>
              <h3 class="text-xl font-semibold text-gray-800 dark:text-gray-300 dark:hover:text-white">
                Temp Customer Service Agent
              </h3>
              <p class="mt-3 text-gray-500">
                Address client needs; fostering brand trust and satisfaction.
              </p>
            </div>
          </FadeIn>
          <FadeIn class="group flex flex-col h-full bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7]">
            <div class="h-52 flex flex-col justify-center items-center overflow-hidden bg-amber-500 rounded-t-xl">
              <img
                src={require("../assets/img/industries/retails.jpeg")}
                className="h-full object-cover w-full rounded-t-xl transition duration-300 ease-in-out hover:scale-110"
                alt=""
              />
            </div>
            <div class="p-4 md:p-6">
              <span class="block mb-1 text-xs font-semibold uppercase text-fuchsia-600">
                Retail
              </span>
              <h3 class="text-xl font-semibold text-gray-800 dark:text-gray-300 dark:hover:text-white">
                Temp Retail Associate
              </h3>
              <p class="mt-3 text-gray-500">
                Showcase products; guiding customers to ideal purchases.
              </p>
            </div>
          </FadeIn>
        </div>
      </FadeInStagger>
    </>
  );
}

export default ShowCase;
