import { useEffect, useState } from "react";
import { ApiClient } from "../services/api";

const api = new ApiClient();

function usePushNotifications() {
  const [error, setError] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    if (!("serviceWorker" in navigator)) {
      // Service Workers aren't supported
      setError("Service Workers are not supported by your browser");
      return;
    }

    if (!("PushManager" in window)) {
      // Push API isn't supported
      setError("Push API is not supported by your browser");
      return;
    }

    const registerServiceWorker = async () => {
      const registration = await navigator.serviceWorker.register("service-worker.js");

      const subscribe = async () => {
        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(
            "BKM1huCzM6RlPIltNFwOUW5_tRx9Ia7UYgWOzmIyQoUZK8fkcAaWNdD3jjTfsAFbxnkzH4FWsIjfTQ81LDd5-J0"
          ),
        });

        // Envoie les données d'abonnement au serveur

        await api.post("subscribe", subscription);

        setIsSubscribed(true);
      };

      if (Notification.permission === "granted") {
        subscribe();
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            subscribe();
          }
        });
      }
    };

    registerServiceWorker().catch(setError);
  }, []);

  return { isSubscribed, error };
}

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
}

export default usePushNotifications;
