import { useDispatch, useSelector } from "react-redux";

import Roles from "../../Enum/UserRoles";
import { Link } from "react-router-dom";
import { setCandidateLaunch } from "../../redux/application";
import { fetchOneCompany } from "../../redux/details";
import { setPage } from "../../redux/users";
import { useUsers } from "../../hook/users";
import { baseUrl } from "../../utils";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import Search from "../search/search";

const people = [
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  // More people...
];

export default function UsersD(props) {
  useUsers();

  const { users, page, limit } = useSelector((state) => state.UserSlice);
  const dispatch = useDispatch();

  const start = (page - 1) * limit + 1;
  const end = Math.min(page * limit, users.total);

  return (
    <div {...props}>
      <h1 className="text-2xl font-extralight text-sky-600">Users</h1>
      <Search type={"u"} />
      <nav
        className="flex  w-full items-center justify-between  bg-white px-4 py-3 sm:px-6"
        aria-label="Pagination"
      >
        <div className="flex flex-1 justify-between sm:justify-end items-center">
          <div className="hidden sm:block me-4">
            <p className="text-sm text-gray-700">
              <span className="font-medium">{start}</span> -
              <span className="font-medium">{end}</span> /
              <span className="font-medium">{users.total}</span> results
            </p>
          </div>
          <button
            onClick={() => dispatch(setPage(page - 1))}
            disabled={start === 1}
            className="disabled:bg-slate-400/40 disabled:text-white relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
          >
            <ChevronLeftIcon className="w-4 h-4" />
          </button>
          <button
            disabled={end === users.total}
            onClick={() => dispatch(setPage(page + 1))}
            className="disabled:bg-slate-400/40 disabled:text-white relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
          >
            <ChevronRightIcon className="w-4 h-4" />
          </button>
        </div>
      </nav>

      <div className="px-4 sm:px-6 lg:px-8 sm:max-w-7xl  overflow-x-scroll">
        <div className="-mx-4 mt-8 sm:-mx-0 ">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  #ID
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  Name
                </th>

                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Role
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {users.users &&
                users.users.map((person) => (
                  <tr key={person.email}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      #{person.id}
                    </td>
                    <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                      <div className="flex items-center">
                        <div className="h-11 w-11 flex-shrink-0">
                          <img
                            className="h-11 w-11 rounded-full"
                            src={
                              person.roles &&
                              person.roles.includes(Roles.candidate)
                                ? person.candidate?.candidateProfile?.profile
                                    ?.image
                                : person.roles.includes(Roles.company)
                                ? person?.company?.logo
                                : require("../../assets/img/gear.png")
                            }
                            alt="image"
                          />
                        </div>
                        <div className="ml-4">
                          <div className="font-medium text-gray-900">
                            {person.roles.includes(Roles.candidate) &&
                              person.candidate?.lastName}

                            {person.roles.includes(Roles.company) &&
                              person.company?.name}
                          </div>
                          <div className="mt-1 w-60 truncate text-gray-500">
                            {person.email}
                          </div>
                        </div>
                      </div>
                    </td>

                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <div className="flex items-center space-x-1">
                        <span
                          className={`inline-flex items-center rounded-md ${
                            person.wasVerified
                              ? "bg-green-50  text-green-700"
                              : "bg-red-50  text-red-700"
                          } px-2 py-1 text-xs font-medium  ring-1 ring-inset ring-green-600/20`}
                        >
                          Active
                        </span>
                        <span
                          className={`inline-flex items-center rounded-md ${
                            person.visible
                              ? "bg-green-50  text-green-700"
                              : "bg-red-50  text-red-700"
                          } px-2 py-1 text-xs font-medium ring-1 ring-inset ring-green-600/20`}
                        >
                          visible
                        </span>
                      </div>
                    </td>
                    <td
                      className={`whitespace-nowrap px-3 py-5 text-sm ${
                        person.visible
                          ? "text-sky-500"
                          : "text-slate-500/60 cursor-not-allowed"
                      } `}
                    >
                      <Link
                        to={
                          person.roles.includes(Roles.company) && person.visible
                            ? `/company/${person.company?.name}`
                            : ""
                        }
                        /*  to={
                        person.roles.includes(Roles.candidate)
                          ? `/candidates/${person.candidate?.id}`
                          : `/companies/${person.company?.id}`
                      } */
                        onClick={() =>
                          person.roles.includes(Roles.candidate) &&
                          person.visible
                            ? dispatch(setCandidateLaunch(person.candidate?.id))
                            : person.roles.includes(Roles.company) &&
                              person.visible
                            ? dispatch(fetchOneCompany(person.company?.id))
                            : {}
                        }
                      >
                        {person.roles}@
                        {person.roles.includes(Roles.candidate) &&
                          person.candidate?.id}
                        {person.roles.includes(Roles.company) &&
                          person.company?.id}
                        {person.roles.includes(Roles.admin) && person.id}
                      </Link>
                    </td>
                    <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <Link
                        to={`/user/${person.id}`}
                        className="text-sky-600 hover:text-sky-900"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>
                        <span className="sr-only">, {person.id}</span>
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          {/*     <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
              >
                #ID
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Role
              </th>
              <th
                scope="col"
                className="py-3.5 pl-2 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
              >
                Name
              </th>

              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Email
              </th>

              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {users &&
              users.map((person) => (
                <tr key={person.email}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                    #{person.id}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-sky-600">
                    <Link
                      to={
                        !person.roles.includes(Roles.candidate)
                          ? `/company/${person.company?.name}`
                          : ""
                      }
                     
                      onClick={() =>
                        person.roles.includes(Roles.candidate)
                          ? dispatch(setCandidateLaunch(person.candidate?.id))
                          : dispatch(fetchOneCompany(person.company?.id))
                      }
                    >
                      {person.roles}@
                      {person.roles.includes(Roles.candidate) &&
                        person.candidate?.id}
                      {person.roles.includes(Roles.candidate) &&
                        person.company?.id}
                    </Link>
                  </td>
                  <td className="whitespace-nowrap  py-4 pl-2 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                    <span className="w-20 truncate">
                      {person.roles.includes(Roles.candidate) &&
                        person.candidate?.lastName}

                      {person.roles.includes(Roles.company) &&
                        person.company?.name}
                    </span>
                  </td>

                  <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                    {person.email}
                  </td>

                  <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                    <a href="#" className="text-sky-600 hover:text-sky-900">
                      Edit<span className="sr-only">, {person.name}</span>
                    </a>
                  </td>
                </tr>
              ))}
          </tbody>
        </table> */}
        </div>
      </div>
    </div>
  );
}
