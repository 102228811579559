import React from "react";

function Card({ title, ...props }) {
  return (
    <>
      <a
        {...props}
        className=" bg-slate-50 hover:border-neutral-700 text-2xl border font-medium cursor-pointer  hover:underline  h-96 rounded-md  p-8  flex justify-center items-center hover:shadow-md"
      >
        {title}
      </a>
    </>
  );
}

function Utils() {
  return (
    <>
      <h1 className="text-3xl text-sky-600 font-extralight mb-9">
        Management
      </h1>
      <div className="grid grid-cols-2 gap-4">
        <Card href="/utils/industries/1" title={"Industries"} />
        <Card href="/utils/countries/1" title={"Countries"} />
      </div>
    </>
  );
}

export default Utils;
