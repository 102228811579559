import { useDispatch, useSelector } from "react-redux";
import { baseUrl } from "../../../utils";
import moment from "moment";
import { setModalCardOpen } from "../../../redux/company";
import { useLocation } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PayementDetails() {
  const { company } = useSelector((state) => state.companySlice);
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-sky-900">
            Bank account details
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            See here the bank acounts already registred to receive funds from
            Stripe
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <a
            href={[
              baseUrl,
              `/api/stripe/create-account/${company.user.id}`,
            ].join("")}
            target="_top"
            type="button"
            className="block rounded-md bg-sky-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
          >
            Update
          </a>
        </div>
      </div>

      <div className="sm:-mx-4 mt-10 ring-1  ring-gray-300  rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Id{" "}
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Bank name
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Country
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Currency
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Last4
              </th>
              <th
                scope="col"
                className="hidden lg:table-cell relative py-3.5 pl-3 pr-4 sm:pr-6"
              >
                <span className="sr-only">Select</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {company.user.stripe &&
              company.user.stripe.external_accounts.data.map(
                (plan, planIdx) => (
                  <tr key={plan.id}>
                    <td
                      className={classNames(
                        planIdx === 0 ? "" : "border-t border-transparent",
                        "relative py-4 pl-4 pr-3 text-sm sm:pl-6"
                      )}
                    >
                      <div className="font-medium text-gray-900">{plan.id}</div>
                      <div className="mt-1 flex flex-col text-gray-500 sm:block lg:hidden">
                        <span>
                          {plan.bank_name} / {plan.country}
                        </span>
                        <span className="hidden sm:inline">·</span>
                        <div className="inline-flex items-center space-x-2">
                          {company.user &&
                            company.user.stripe &&
                            company.user.stripe.requirements.currently_due
                              .length > 0 && (
                              <div className="w-2 h-2 rounded-full bg-red-600"></div>
                            )}
                          {company.user &&
                            company.user.stripe &&
                            company.user.stripe.requirements.currently_due
                              .length === 0 && (
                              <div className="w-2 h-2 rounded-full bg-green-600"></div>
                            )}

                          <span>enabled</span>
                        </div>
                      </div>
                      {planIdx !== 0 ? (
                        <div className="absolute -top-px left-6 right-0 h-px bg-gray-200" />
                      ) : null}
                    </td>
                    <td
                      className={classNames(
                        planIdx === 0 ? "" : "border-t border-gray-200",
                        "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                      )}
                    >
                      {plan.bank_name}
                    </td>
                    <td
                      className={classNames(
                        planIdx === 0 ? "" : "border-t border-gray-200",
                        "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                      )}
                    >
                      {plan.country}
                    </td>
                    <td
                      className={classNames(
                        planIdx === 0 ? "" : "border-t border-gray-200",
                        "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell uppercase"
                      )}
                    >
                      {plan.currency}
                    </td>
                    <td
                      className={classNames(
                        planIdx === 0 ? "" : "border-t border-gray-200",
                        "px-3 py-3.5 text-sm text-gray-500"
                      )}
                    >
                      {plan.last4}
                    </td>
                    <td
                      className={classNames(
                        planIdx === 0 ? "" : "border-t border-transparent",
                        "relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 hidden lg:table-cell"
                      )}
                    >
                      <div className="inline-flex items-center space-x-2">
                        {company.user &&
                          company.user.stripe &&
                          company.user.stripe.requirements.currently_due
                            .length > 0 && (
                            <div className="w-2 h-2 rounded-full bg-red-600"></div>
                          )}
                        {company.user &&
                          company.user.stripe &&
                          company.user.stripe.requirements.currently_due
                            .length === 0 && (
                            <div className="w-2 h-2 rounded-full bg-green-600"></div>
                          )}

                        <span>enabled</span>
                      </div>
                    </td>
                  </tr>
                )
              )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export function CreditCards() {
  const { company } = useSelector((state) => state.companySlice);
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const params = {};
  for (let [key, value] of queryParams.entries()) {
    params[key] = value;
  }

  // Now you can use 'params' object in your component
  console.log(params);
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-sky-900">
            Credit cards details
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            See here the credit cards already registred to pay yours shift to
            TempMe
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <a
            onClick={() => dispatch(setModalCardOpen())}
            target="_top"
            type="button"
            className={`block rounded-md ${
              params?.focus === "card" && 'animate-pulse'
            } bg-sky-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 focus:outline-sky-900`}
          >
            New card
          </a>
        </div>
      </div>

      <div className="sm:-mx-4 mt-10 ring-1  ring-gray-300  rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Id{" "}
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Type
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Expires
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Created
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Last4
              </th>
              <th
                scope="col"
                className="hidden lg:table-cell relative py-3.5 pl-3 pr-4 sm:pr-6"
              >
                <span className="sr-only">Select</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {company.user.stripe &&
              company.user.stripe.paymentMethods.data.map((plan, planIdx) => (
                <tr key={plan.id}>
                  <td
                    className={classNames(
                      planIdx === 0 ? "" : "border-t border-transparent",
                      "relative py-4 pl-4 pr-3 text-sm sm:pl-6"
                    )}
                  >
                    <div className="font-medium text-gray-900">{plan.id}</div>
                    <div className="mt-1 flex flex-col text-gray-500 sm:block  lg:hidden">
                      <span>
                        {plan.card.brand} / {plan.card.country}
                      </span>
                      <span className="hidden sm:inline">·</span>
                      <div className="inline-flex items-center space-x-2">
                        {company.user &&
                          company.user.stripe &&
                          company.user.stripe.requirements.currently_due
                            .length > 0 && (
                            <div className="w-2 h-2 rounded-full bg-red-600"></div>
                          )}
                        {company.user &&
                          company.user.stripe &&
                          company.user.stripe.requirements.currently_due
                            .length === 0 && (
                            <div className="w-2 h-2 rounded-full bg-green-600"></div>
                          )}

                        <span>enabled</span>
                      </div>
                    </div>
                    {planIdx !== 0 ? (
                      <div className="absolute -top-px left-6 right-0 h-px bg-gray-200" />
                    ) : null}
                  </td>
                  <td
                    className={classNames(
                      planIdx === 0 ? "" : "border-t border-gray-200",
                      "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                    )}
                  >
                    {plan.card.brand}
                  </td>
                  <td
                    className={classNames(
                      planIdx === 0 ? "" : "border-t border-gray-200",
                      "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                    )}
                  >
                    {[plan.card.exp_month, plan.card.exp_year].join("/")}
                  </td>
                  <td
                    className={classNames(
                      planIdx === 0 ? "" : "border-t border-gray-200",
                      "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell "
                    )}
                  >
                    {moment.unix(plan.created).local().fromNow()}
                  </td>
                  <td
                    className={classNames(
                      planIdx === 0 ? "" : "border-t border-gray-200",
                      "px-3 py-3.5 text-sm text-gray-500"
                    )}
                  >
                    {plan.card.last4}
                  </td>
                  <td
                    className={classNames(
                      planIdx === 0 ? "" : "border-t border-transparent",
                      "relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 hidden lg:table-cell"
                    )}
                  >
                    <div className="inline-flex items-center space-x-2">
                      {company.user &&
                        company.user.stripe &&
                        company.user.stripe.requirements.currently_due.length >
                          0 && (
                          <div className="w-2 h-2 rounded-full bg-red-600"></div>
                        )}
                      {company.user &&
                        company.user.stripe &&
                        company.user.stripe.requirements.currently_due
                          .length === 0 && (
                          <div className="w-2 h-2 rounded-full bg-green-600"></div>
                        )}

                      <span>enabled</span>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
