import { useSelector } from "react-redux";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Stats() {
  const { stats } = useSelector((state) => state.ShiftSlice);
  const { balance } = useSelector((state) => state.stripeSlice);
  console.log(balance.available);
  return (
    <>
      <div className=" bg-white border rounded-md p-4">
        <h1 className="text-2xl font-extralight text-sky-600">Balance</h1>
        <h1 className="mt-4 text-4xl font-bold uppercase inline-block bg-gradient-to-r from-indigo-600 text-transparent bg-clip-text to-sky-600 ">
          {balance.available &&
            balance.available[0] &&
            [balance.available[0].currency, balance.available[0].amount].join(
              " "
            )}
        </h1>
        <div className="mt-8 grid sm:grid-cols-3 gap-3">
          <div className="  rounded-md p-3 bg-[#F3F4F6] ">
            <img
              src={require("../../../assets/img/illustration/wall-clock_595045.png")}
              className="w-8 h-8 "
              alt=""
              srcset=""
            />

            <h1 className="mt-3 font-light   ">Total hours</h1>
            <h1 className="mt-1 font-bold text-xl text-sky-600 ">
              {" "}
              {stats.totalHours}
            </h1>
          </div>
          <div className=" bg-[#F3F4F6] rounded-md p-3 ">
            <img
              src={require("../../../assets/img/illustration/income_8206197.png")}
              className="w-8 h-8 "
              alt=""
              srcset=""
            />

            <h1 className="mt-3 font-light ">Expenses</h1>
            <h1 className="mt-1 font-bold text-xl text-sky-600 ">
              {stats.totalExpenses}
            </h1>
          </div>
          <div className=" bg-[#F3F4F6] rounded-md p-3 ">
            <img
              src={require("../../../assets/img/illustration/calendar_10403058.png")}
              className="w-8 h-8 "
              alt=""
              srcset=""
            />

            <h1 className="mt-3 font-light ">Upcoming shift</h1>
            <h1 className="mt-1 font-bold text-xl text-sky-600">
              {" "}
              {stats.validShiftsCount}
            </h1>
          </div>
        </div>
      </div>
    </>
  );
}
