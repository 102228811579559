// socketSlice.js
import { createSlice } from "@reduxjs/toolkit";
import socket from "./socket"; // Importez l'instance du socket
import {
  fetchConversation,
  fetchConversationToGetAdmin,
  fetchCountMessage,
  fetchMessages,
  fetchMessagesAdmin,
  fetchNotification,
  fetchNotificationCount,
  setUserToSend,
} from "./conversation";
import { getApplicationCompany } from "./application";
import { fetchShifts, updateShift } from "./shift";
import { updateUser } from "./authentication";

export const socketSlice = createSlice({
  name: "socket",
  initialState: {
    messages: [],
    successScann: false,
    content: null,
  },

  reducers: {
    addMessage: (state, action) => {
      state.messages.push(action.payload);
    },
    openSuccess: (state) => {
      state.successScann = true;
    },
    closeSuccess: (state) => {
      state.successScann = false;
    },
    setContent: (state, action) => {
      state.content = action.payload;
    },
  },
});

export const { addMessage, openSuccess, closeSuccess, setContent } =
  socketSlice.actions;

export const setupSocket = (dispatch, state) => {
  let pushSent = false;
  socket.on("connect", () => {
    socket.emit("register", state.authenticationSlice.id);
  });

  socket.on("new-message", (message) => {
    dispatch(addMessage(message));
    dispatch(fetchCountMessage());
    dispatch(fetchConversation());
    dispatch(fetchConversationToGetAdmin());
    dispatch(fetchMessages(message.message.data));
  });
  socket.on("new-message-to-admin", (message) => {
    dispatch(fetchMessagesAdmin());
  });

  socket.on("new-application", (message) => {
    dispatch(addMessage(message));
    dispatch(getApplicationCompany());
    dispatch(fetchNotification());
    dispatch(fetchNotificationCount());
  });
  socket.on("new-notification", (message) => {
    dispatch(addMessage(message));
    dispatch(fetchNotification());
    dispatch(fetchNotificationCount());
    dispatch(fetchShifts());
  });
  socket.on("new-location", (message) => {
    dispatch(addMessage(message));
    dispatch({
      type: "GeolocationSlice/setLatitude",
      payload: message.message.data.coords.latitude,
    });
    dispatch({
      type: "GeolocationSlice/setLongitude",
      payload: message.message.data.coords.longitude,
    });
  });

  socket.on("success-scan-shift-in", (message) => {
    const temp = message.oneShift;
    temp.tracking.clock_in.status = true;
    dispatch(updateShift({ id: temp.id, params: temp }));
  });

  socket.on("success-shift-scanned", (message) => {
    dispatch(fetchNotification());
    dispatch(fetchNotificationCount());
    dispatch(openSuccess());
    dispatch(setContent(message.message.data.content));
  });

  socket.on("success-scan-shift-out", (message) => {
    const temp = message.oneShift;
    temp.tracking.clock_out.status = true;
    temp.paid = true;
    dispatch(updateShift({ id: temp.id, params: temp }));
  });
  socket.on("receive-push-notification", (message) => {
    const data = message.message.data;

    if (window.ReactNativeWebView && !pushSent) {
      dispatch(
        updateUser({
          data: {
            pushToken: data,
          },
        })
      );
      pushSent = true;
    }
  });
};

export default socketSlice.reducer;
