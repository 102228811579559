// LeadsComponent.js

import React, { useCallback, useEffect, useState } from "react";
import ApexCharts from "apexcharts"; // Assurez-vous d'avoir installé apexcharts et react-apexcharts.
import {
  ArrowDownIcon,
  ArrowUpIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import useShift from "../../../hook/shift";
import { baseUrl, formatName } from "../../../utils";
import Shift from "../../../Enum/shift";
import useCurrentBreakpoint from "../../../hook/tailwindBreakPoint";
import moment from "moment";
import { Dropdown } from "flowbite-react";
import { setFilter, setPage, setSearch } from "../../../redux/shift";

const options = {
  colors: ["#38BDF8", "#CB00FF", "#666666"],
  series: [],
  chart: {
    type: "bar",
    height: "300px",
    fontFamily: "Inter, sans-serif",
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "70%",
      borderRadiusApplication: "end",
      borderRadius: 8,
    },
  },
  tooltip: {
    shared: true,
    intersect: false,
    style: {
      fontFamily: "Inter, sans-serif",
    },
  },
  states: {
    hover: {
      filter: {
        type: "darken",
        value: 1,
      },
    },
  },
  stroke: {
    show: true,
    width: 0,
    colors: ["transparent"],
  },
  grid: {
    show: false,
    strokeDashArray: 4,
    padding: {
      left: 2,
      right: 2,
      top: -14,
    },
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: true,
    position: "bottom",
  },
  xaxis: {
    floating: false,
    labels: {
      show: true,
      style: {
        cssClass: "text-xs font-normal fill-gray-500 dark:fill-gray-400",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
  },
  fill: {
    opacity: 1,
  },
};

const Row = ({ shift, index, key }) => {
  const dispatch = useDispatch();
  const brkPoint = useCurrentBreakpoint();

  return (
    <tr
      key={key}
      id={`tr-${index}`}
      onClick={() =>
        ["sm", "xs"].includes(brkPoint)
          ? dispatch({
              type: "ShiftSlice/setShiftPunch",
              payload: shift.id,
            })
          : {}
      }
      autoFocus
      className="bg-white border-b dark:bg-gray-800  dark:border-gray-700 hover:bg-gray-50 focus:outline-1 focus:outline-sky-500 dark:hover:bg-gray-600"
    >
      <th
        scope="row"
        className="flex items-center justify-left px-5 py-4 text-gray-900 whitespace-nowrap dark:text-white"
      >
        <img
          className="w-10 h-10  rounded-full"
          src={shift.application.candidate.candidateProfile.profile.image}
          alt="Jese image"
        />
        <div className="pl-3">
          <div className="text-xs md:text-sm  font-semmibold">
            {[
              shift.application.candidate.firstName,
              shift.application.candidate.lastName,
            ].join(" ")}
          </div>
          <div className="font-normal text-gray-500">
            {shift.application.candidate.user.email}
          </div>
        </div>
      </th>
      <td className="px-2 py-4">{formatName(shift.application.job.jobName)}</td>
      <td className="px-3 py-4">
        <div className="flex items-center whitespace-nowrap">
          {moment(
            `${shift.application.job.startDate} ${shift.application.job.startHour}`,
            "YYYY-MM-DD hh:mm A"
          ).fromNow()}
        </div>
      </td>
      <td className="px-2 py-4 sm:table-cell hidden">
        <button
          type="button"
          onClick={() =>
            dispatch({
              type: "ShiftSlice/setShiftPunch",
              payload: shift.id,
            })
          }
          className={`rounded-full ${
            shift.status === Shift.NO_SHOW &&
            "bg-orange-400 hover:bg-orange-600"
          } ${shift.status === Shift.CANCEL && "bg-red-600 hover:bg-red-400"} ${
            shift.status === Shift.VALID && "bg-sky-400 hover:bg-sky-500"
          }  p-1 text-white shadow-md shadow-sky-200  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600`}
        >
          <PlusIcon className="h-4 w-4" aria-hidden="true" />
        </button>
      </td>
    </tr>
  );
};

const Stats = ({ company }) => {
  const chartRef = React.useRef(null);
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState("Last 7 days");
  const { shifts, page, limit } = useSelector((state) => state.ShiftSlice);

  const [currentData, setCurrentData] = useState(
    company.extra.chart.dataLast7Days
  );

  const [name, setName] = useState(null);

  useEffect(() => {
    const chart = new ApexCharts(chartRef.current, {
      ...options,
      series: currentData,
    });
    chart.render();

    return () => chart.destroy();
  }, [currentData]);

  const start = (page - 1) * 10 + 1;
  const end = Math.min(page * 10, shifts?.total);

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
    switch (e.target.value) {
      case "Last 7 days":
        setCurrentData(company.extra.chart.dataLast7Days);
        break;
      case "This month":
        setCurrentData(company.extra.chart.dataThisMonth);
        break;
      case "This year":
        setCurrentData(company.extra.chart.dataThisYear);
        break;
      case "All":
        setCurrentData(company.extra.chart.allYears);
        break;
      case "Job":
        setCurrentData(
          company.extra.chart.dataThisYear.filter((d) => d.name === "Job")
        );
        break;
      case "Application":
        setCurrentData(
          company.extra.chart.dataThisYear.filter(
            (d) => d.name === "Application"
          )
        );
        break;
      case "Accepted":
        setCurrentData(
          company.extra.chart.dataThisYear.filter((d) => d.name === "Accepted")
        );
        break;
      default:
        break;
    }
  };

  return (
    <div className="grid gap-2 md:grid-cols-5">
      <div className="max w-full md:col-span-2 bg-white rounded-lg shadow dark:bg-gray-800 p-4 md:p-6">
        <div className="flex justify-between pb-4 mb-4 border-b border-gray-200 dark:border-gray-700">
          <div className="flex items-center">
            <div className="w-12 h-12 rounded-lg bg-gray-100 dark:bg-gray-700 flex items-center justify-center mr-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-7 h-7"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
                />
              </svg>
            </div>
            <div>
              <h5 className="leading-none text-xl md:text-3xl bg-gradient-to-br text-transparent font-bold from-sky-800 to-sky-300 bg-clip-text dark:text-white pb-1">
                {company && company.jobs.number}
              </h5>
              <p className="text-xs md:text-sm font-normal text-gray-500 dark:text-gray-400">
                Live jobs posted
              </p>
            </div>
          </div>
        </div>

        <div class="grid col-span-1   xs:grid-cols-2">
          <dl class="flex  items-center">
            <dt class="text-gray-500 dark:text-gray-400 text-xs sm:text-sm font-normal mr-1">
              Spent:
            </dt>
            <dd class="text-green-500 text-xs sm:text-sm dark:text-white font-semibold">
              {company &&
                [company.currency.code, company.finance.totalSpent].join(" ")}
            </dd>
          </dl>
          <dl class="flex col-span-1 items-center xs:justify-end">
            <dt class="text-gray-500 dark:text-gray-400 text-xs sm:text-sm font-normal mr-1">
              Total Hours:
            </dt>
            <dd class="text-green-500 text-xs sm:text-sm dark:text-white font-semibold">
              {company && company.finance.totalHours} H
            </dd>
          </dl>
        </div>

        <div ref={chartRef} id="column-chart"></div>

        <div className="grid grid-cols-1 items-center border-gray-200 border-t dark:border-gray-700 justify-between">
          <div className="flex justify-between items-center pt-5">
            <div>
              <select
                id="location"
                name="location"
                className="mt-2 block text-xs md:text-base  rounded-md w-full border-none font-medium  py-1.5 pl-3 pr-10 text-gray-900  focus:ring-2 focus:ring-sky-600 sm:text-sm sm:leading-6"
                value={selectedOption}
                onChange={handleSelectChange}
              >
                <option>Last 7 days</option>
                <option>This month</option>
                <option>This year</option>
                <option>All</option>
              </select>
            </div>
            <div>
              <select
                id="location"
                name="location"
                className="mt-2 block  text-xs md:text-base rounded-md w-full border-none font-medium  py-1.5 pl-3 pr-10 text-gray-900  focus:ring-2 focus:ring-sky-600 sm:text-sm sm:leading-6"
                value={selectedOption}
                onChange={handleSelectChange}
              >
                <option>Job</option>
                <option>Application</option>
                <option>Accepted</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="relative overflow-hidden md:col-span-3 max-h-[35rem] pb-8 shadow-md sm:rounded-lg">
        <span className="px-2 text-xs md:text-sm font-medium">Shifts</span>
        <div className="flex flex-wrap sm:space-y-0 space-y-2 items-center justify-between pb-4 px-2 mt-2 bg-white dark:bg-gray-900">
          <div>
            <select
              id="location"
              name="location"
              className="mt-2 block w-full text-xs md:text-sm rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-sky-600 sm:text-sm sm:leading-6"
              defaultValue="All"
              onChange={(e) => dispatch(setFilter(e.currentTarget.value))}
            >
              <option value={""}>All</option>
              <option value={"VALID"}>Upcoming</option>
              <option value={"CANCEL"}>Cancelled</option>
              <option value={"NO_SHOW"}>No show</option>
            </select>
          </div>
          <label for="table-search" className="sr-only">
            Search
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              onChange={(e) => dispatch(setSearch(e.currentTarget.value))}
              id="table-search-users"
              className="block p-2 pl-10 text-xs md:text-sm text-gray-900 border border-gray-300 rounded-lg max-w-80  focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search for users"
            />
          </div>
        </div>
        <div className="h-[75%]  overflow-hidden hover:overflow-scroll">
          <table className="w-full  text-[0.650rem] leading-3 md:text-sm text-center md:text-left text-gray-500 dark:text-gray-400">
            <thead className="text-[0.70rem] leading-3 md:text-xs  text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0">
              <tr>
                <th scope="col" className="px-4 py-3">
                  Name
                </th>
                <th scope="col" className="px-2 py-3">
                  Position
                </th>
                <th scope="col" className="px-2 py-3">
                  Date
                </th>
                <th scope="col" className="px-2 sm:table-cell hidden py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {shifts &&
                shifts.shifts &&
                shifts.shifts.map((shift) => (
                  <Row key={shift.id} shift={shift} />
                ))}
            </tbody>
          </table>
        </div>
        <nav
          className="flex  w-full items-center justify-between  bg-white px-4 py-3 sm:px-6"
          aria-label="Pagination"
        >
          <div className="hidden sm:block">
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">{start}</span> to{" "}
              <span className="font-medium">{end}</span> of{" "}
              <span className="font-medium">{shifts?.total}</span> results
            </p>
          </div>
          <div className="flex flex-1 justify-between sm:justify-end">
            <button
              onClick={() => dispatch(setPage(page - 1))}
              disabled={start === 1}
              className=" disabled:bg-slate-400/40 disabled:text-white relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
            >
              Previous
            </button>
            <button
              disabled={end === shifts?.total}
              onClick={() => dispatch(setPage(page + 1))}
              className="disabled:bg-slate-400/40 disabled:text-white relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
            >
              Next
            </button>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Stats;
