import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MenuFinance({ transaction }) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="flex items-center rounded-full  text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          <span className="sr-only">Open options</span>
          <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 disabled:bg-red-400 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {transaction.status === "VALID" && (
            <div className="py-1">
              {!transaction.paid && (
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href={transaction.tracking?.invoice?.link_to_pay}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm disabled:bg-gray-400"
                      )}
                    >
                      Pay the invoice
                    </a>
                  )}
                </Menu.Item>
              )}

              {transaction.paid && (
                <Menu.Item>
                  {({ active }) => (
                    <a
                      target="_blank"
                      href={transaction.tracking?.invoice?.url}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      Donwnload the invoice
                    </a>
                  )}
                </Menu.Item>
              )}

              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-red-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    Cancel the shift
                  </a>
                )}
              </Menu.Item>
              <form method="POST" action="#">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      type="submit"
                      className={classNames(
                        active
                          ? "bg-gray-100 text-gray-900"
                          : "text-orange-500",
                        "block w-full px-4 py-2 text-left text-sm"
                      )}
                    >
                      Candidate no show ?
                    </button>
                  )}
                </Menu.Item>
              </form>
            </div>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
