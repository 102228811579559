import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllInvoices,
  fetchBalance,
  fetchPayouts,
  setPage,
} from "../redux/stripe";
import { useCallback, useEffect } from "react";

export function useStripeInvoices() {
  const dispatch = useDispatch();
  const { page, limit, invoice_id, customer_id } = useSelector(
    (state) => state.stripeSlice
  );

  const fetchData = useCallback(() => {
    dispatch(fetchAllInvoices());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setPage(1));
  }, [limit, invoice_id, customer_id]);

  useEffect(() => {
    fetchData();
  }, [fetchData, page, limit, invoice_id, customer_id]);
}

export function useBalance() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBalance());
  });
}
export function usePayouts() {
  const dispatch = useDispatch();
  const { lastPayoutId, firstPayoutId } = useSelector(
    (state) => state.stripeSlice
  );

  useEffect(() => {
    dispatch(fetchPayouts());
  }, [lastPayoutId, firstPayoutId]);
}
