import { useCallback, useEffect, useState } from "react";
import { Shift } from "../services/shift";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchShifts,
  fetchStats,
  fetchTableFinanceShifts,
  fetchTableFinanceShiftsCandidate,
  setDateF,
  setPage,
} from "../redux/shift";

const shift = new Shift();

export default function useShift() {
  const dispatch = useDispatch();
  const { date_f, page, limit, search, filter } = useSelector(
    (state) => state.ShiftSlice
  );

  const fetchData = useCallback(() => {
    dispatch(fetchShifts());
  }, [dispatch]);

   useEffect(() => {
     dispatch(setPage(1));
   }, [ limit, search, filter]);
   
  useEffect(() => {
    fetchData();
  }, [fetchData, page, limit, search, filter]);
}
export function useTableFinance() {
  const dispatch = useDispatch();
  const { date_f, page, limit } = useSelector((state) => state.ShiftSlice);

  const fetchData = useCallback(() => {
    dispatch(fetchTableFinanceShifts());
    dispatch(fetchStats());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setPage(1));
  }, [date_f]);

  useEffect(() => {
    fetchData();
  }, [fetchData, date_f, page, limit]);
}
export function useTableFinanceCandidate() {
  const dispatch = useDispatch();
  const { date_f, page, limit } = useSelector((state) => state.ShiftSlice);

  const fetchData = useCallback(() => {
    dispatch(fetchTableFinanceShiftsCandidate());
    dispatch(fetchStats());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setPage(1));
  }, [date_f]);

  useEffect(() => {
    fetchData();
  }, [fetchData, date_f, page, limit]);
}
