import { ApiClient } from "./api";

export class Companies {
  constructor() {
    this.apiClient = new ApiClient();
  }

  async getCompanies(page, limit, query) {
    try {
      const { data } = await this.apiClient.get(
        `company?page=${page}&limit=${limit}&search=${query}`
      );

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async getCompaniesAdmin(page, limit, query) {
    try {
      const { data } = await this.apiClient.get(
        `company/all/companies?page=${page}&limit=${limit}&search=${query}`
      );

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async getCompaniesAdminList(query) {
    try {
      const { data } = await this.apiClient.get(
        `company/all/companies/list?search=${query}`
      );

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async addCreditCard(params) {
    try {
      const { data } = await this.apiClient.post(`stripe/add-card`, params);

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async getOneCompany(id = null) {
    try {
      const { data } = await this.apiClient.get(`company/one/${id}`);

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async getAuthCompany() {
    try {
      const { data } = await this.apiClient.get(`company/authreq`);

      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async updateProfile(id = null, params) {
    try {
      const { data } = await this.apiClient.put(`company/${id}`, params);

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async postLogo(id = null, doc) {
    try {
      const { data } = await this.apiClient.postWithDocument(
        `upload/company/${id}`,
        {},
        doc
      );

      return data;
    } catch (error) {
      console.error(error);
    }
  }
}
