import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  BanknotesIcon,
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  StarIcon,
} from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import {
  AdjustmentsHorizontalIcon,
  BriefcaseIcon,
  CalendarDaysIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { fetchOneCandidate } from "../../redux/application";
import { baseUrl } from "../../utils";
import Availability from "./availability";
import Stars from "../rating/Stars";
import { useCallback } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const navigation = [
  {
    name: "Profile",
    href: 1,
    icon: UserIcon,

    current: true,
  },
  {
    name: "Availability",
    href: 2,
    icon: CalendarDaysIcon,
    current: false,
  },

  {
    name: "Experiences as Temper",
    href: 3,
    icon: BriefcaseIcon,

    current: false,
  },
  {
    name: "Skills",
    href: 4,
    icon: AdjustmentsHorizontalIcon,
    current: false,
  },
];

export default function CommandApplication() {
  const [query, setQuery] = useState("");
  const { candidateLaunch, candidate, idCandidate } = useSelector(
    (state) => state.ApplicationSlice
  );
  const dispatch = useDispatch();
  const [index, setIndex] = useState(1);

  const getRandomItem = useCallback((arr) => {
    // get random index value
    const randomIndex = Math.floor(Math.random() * arr.length);

    // get random item
    const item = arr[randomIndex];

    return item;
  });

  useEffect(() => {
    dispatch(fetchOneCandidate({ id: idCandidate }));
  }, [idCandidate]);

  return (
    <Transition.Root
      show={candidateLaunch}
      as={Fragment}
      afterLeave={() => setQuery("")}
      appear
    >
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() =>
          dispatch({
            type: "ApplicationSlice/setClose",
          })
        }
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed flex justify-center inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto py-20 px-4  md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-2xl  transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              {candidate && (
                <div className="md:h-fit h-[calc(100vh-10rem)] overflow-y-scroll grid grid-cols-7 gap-4 p-4">
                  <div className="sm:col-span-3 col-span-full md:border-r">
                    <nav
                      className="flex flex-1  sm:flex-col md:pe-10"
                      aria-label="Sidebar"
                    >
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li
                                key={item.name}
                                onClick={() => setIndex(item.href)}
                                className="cursor-pointer"
                              >
                                <a
                                  className={classNames(
                                    item.href === index
                                      ? "bg-sky-500 text-white"
                                      : "text-gray-700 hover:text-sky-600 hover:bg-gray-100",
                                    "group flex gap-x-3 rounded-md px-2 py-2  sm:py-8 text-xs sm:text-sm leading-6 whitespace-nowrap font-semibold"
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.href === index
                                        ? "text-white"
                                        : "text-gray-400 group-hover:text-sky-600",
                                      "h-6 w-6 shrink-0"
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div className="sm:col-span-4 col-span-full ">
                    {index === 1 && (
                      <div className="grid grid-cols-6 gap-4">
                        <div className="col-span-full flex justify-center items-center">
                          <img
                            src={candidate?.candidateProfile?.profile?.image}
                            alt={"person"}
                            className=" w-20 h-20 object-cover border rounded-full"
                          />
                        </div>
                        <div className="col-span-full">
                          <h1 className="text-center text-lg">
                            {[candidate.firstName, candidate.lastName].join(
                              " "
                            )}
                          </h1>
                          <h1 className="col-span-full text-xs font-semibold text-indigo-600 text-center">
                            {candidate.title}
                          </h1>

                          <div className="inline-flex w-full justify-center items-center">
                            <Stars
                              number={candidate?.stats?.totalAverageStars}
                            />
                          </div>
                          <div className="inline-flex items-center justify-end w-full space-x-2 text-sky-600">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="sm:w-6 w-4 h-4 sm:h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                              />
                            </svg>

                            <span className="sm:text-2xl   font-extrabold">
                              {[
                                parseFloat(
                                  candidate?.hourlyRate?.value
                                ).toFixed(2) + " ",
                                candidate?.hourlyRate?.currency?.code,
                                "/h",
                              ].join("")}
                            </span>
                          </div>

                          <span className="isolate inline-flex w-full rounded-md shadow-sm my-4">
                            <button
                              type="button"
                              className="relative inline-flex w-1/2 justify-center items-center rounded-l-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                            >
                              <a href={`tel:${candidate.phone}`}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-6 h-6"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                                  />
                                </svg>
                              </a>
                            </button>
                            <button
                              type="button"
                              className="relative -ml-px w-1/2 inline-flex justify-center items-center rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                            >
                              <a href={`mailto:${candidate.user.email}`}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-6 h-6"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                                  />
                                </svg>
                              </a>
                            </button>
                          </span>
                        </div>
                        <div className="col-span-full ">
                          <ul className="space-y-5">
                            <li>
                              <dl>
                                <dt className="text-slate-500 text-sm inline-flex items-center space-x-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-4 h-4"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25"
                                    />
                                  </svg>
                                  <span> Email address</span>
                                </dt>
                                <dd className=" text-sm ps-3">
                                  {" "}
                                  {candidate.user.email}{" "}
                                </dd>
                              </dl>
                            </li>
                            <li>
                              <dl>
                                <dt className="text-slate-500 text-sm inline-flex items-center space-x-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-4 h-4"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                                    />
                                  </svg>{" "}
                                  <span>Location</span>
                                </dt>
                                <dd className=" text-sm ps-3">
                                  {" "}
                                  {candidate.address?.street} <br />
                                  {candidate.address?.zip} <br />
                                  {candidate.address?.city} <br />{" "}
                                  {candidate.address?.state}{" "}
                                </dd>
                              </dl>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                    {index === 2 && <Availability candidate={candidate} />}
                    {index === 3 && (
                      <ul className="max-h-96 overflow-hidden hover:overflow-y-auto space-y-3  px-4 py-2">
                        {candidate.applications &&
                          candidate.applications.map((ap) => (
                            <li key={ap.id}>
                              <div className="h-20 ring-1 ring-sky-400 rounded-md grid grid-cols-6 p-4 shadow-md">
                                <div className="col-span-1 flex justify-center items-center">
                                  <img
                                    src={require("../../assets/img/brands/T.png")}
                                    alt=""
                                    srcset=""
                                    className="w-9 h-9"
                                  />
                                </div>
                                <div className="col-span-4">
                                  <h1 className="text-base mb-0 font-medium">
                                    {ap.job.jobName}
                                  </h1>
                                  <p className="text-[0.650rem] font-semibold">
                                    {ap.job.company.name}
                                  </p>
                                </div>
                                <div className="col-span-1 flex justify-center items-center">
                                  <div className="inline-flex justify-center items-center space-x-2">
                                    <StarIcon className="w-5 h-5 text-orange-500" />
                                    <span>
                                      {ap.shifts[0].starRating?.candidate}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                      </ul>
                    )}
                    {index === 4 && (
                      <div className="flex justify-center flex-wrap  items-center gap-3">
                        {candidate.candidateProfile?.profile?.skills.map(
                          (sk) => (
                            <span
                              key={sk.name}
                              className={`inline-flex items-center rounded-md bg-${getRandomItem(
                                ["teal", "gray", "fuchsia"]
                              )}-100 px-3 py-3 text-sm font-medium text-${getRandomItem(
                                ["teal", "gray", "fuchsia"]
                              )}-500 ring-1 ring-inset ring-green-600/20`}
                            >
                              {sk.name}
                            </span>
                          )
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
