import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import {
  addCardCompany,
  setCvc,
  setExp,
  setModalCardOpen,
  setNumber,
} from "../../redux/company";
import { getCardType, isValidCardNumber } from "../../utils";

export default function ModalCard() {
  const { modalCardOpen, cvc, exp, number } = useSelector(
    (state) => state.companySlice
  );
  const dispatch = useDispatch();
  const cancelButtonRef = useRef(null);

  const icon = {
    Visa: require("../../assets/img/card/visa.png"),
    MasterCard: require("../../assets/img/card/mastecard.png"),
    Amex: require("../../assets/img/card/amex.png"),
    Discover: require("../../assets/img/card/discover.png"),
  };

  function handleExp() {
    const isValid = new RegExp("^(0[1-9]|1[0-2])/[0-9]{2}$").test(exp);
    if (!isValid) {
      dispatch(setExp(""));
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(addCardCompany());
    
  }

  return (
    <Transition.Root show={modalCardOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => dispatch(setModalCardOpen())}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative  mb-20 transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <form onSubmit={handleSubmit}>
                  <fieldset className="">
                    <legend className="block text-sm font-medium leading-6 text-gray-900">
                      Card Details
                    </legend>
                    <div className="mt-2 -space-y-px rounded-md bg-white shadow-sm">
                      <div className="relative">
                        <label htmlFor="card-number" className="sr-only">
                          Card number
                        </label>
                        <input
                          type="text"
                          name="card-number"
                          id="card-number"
                          required
                          value={number}
                          onChange={(e) => dispatch(setNumber(e.target.value))}
                          className={`relative block w-full rounded-none pr-12 rounded-t-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ${
                            number && isValidCardNumber(number)
                              ? "ring-gray-300"
                              : "ring-red-600"
                          }  placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6`}
                          placeholder="Card number"
                        />
                        {number && (
                          <img
                            className="w-9 h-9 absolute top-0 right-2"
                            src={icon[getCardType(number)]}
                            alt=""
                          />
                        )}
                      </div>

                      <div className="flex -space-x-px">
                        <div className="w-1/2 min-w-0 flex-1">
                          <label
                            htmlFor="card-expiration-date"
                            className="sr-only"
                          >
                            Expiration date
                          </label>
                          <input
                            type="text"
                            name="card-expiration-date"
                            id="card-expiration-date"
                            pattern="^(0[1-9]|1[0-2])/[0-9]{2}$"
                            value={exp}
                            required
                            onChange={(e) => dispatch(setExp(e.target.value))}
                            onBlur={handleExp}
                            className={`relative block w-full rounded-none rounded-bl-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ${
                              number && isValidCardNumber(number)
                                ? "ring-gray-300"
                                : "ring-red-600"
                            }  placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6`}
                            placeholder="MM / YY"
                          />
                        </div>
                        <div className="min-w-0 flex-1">
                          <label htmlFor="card-cvc" className="sr-only">
                            CVC
                          </label>
                          <input
                            type="text"
                            name="card-cvc"
                            required
                            value={cvc}
                            pattern="^[0-9]{3}$"
                            onChange={(e) => dispatch(setCvc(e.target.value))}
                            id="card-cvc"
                            className={`relative block w-full rounded-none rounded-br-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ${
                              number && isValidCardNumber(number)
                                ? "ring-gray-300"
                                : "ring-red-600"
                            }  placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6`}
                            placeholder="CVC"
                          />
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  {/*     <fieldset className="mt-6 bg-white">
                  <legend className="block text-sm font-medium leading-6 text-gray-900">
                    Billing address
                  </legend>
                  <div className="mt-2 -space-y-px rounded-md shadow-sm">
                    <div>
                      <label htmlFor="country" className="sr-only">
                        Country
                      </label>
                      <select
                        id="country"
                        name="country"
                        autoComplete="country-name"
                        className="relative block w-full rounded-none rounded-t-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                      >
                        <option>United States</option>
                        <option>Canada</option>
                        <option>Mexico</option>
                      </select>
                    </div>
                    <div>
                      <label htmlFor="postal-code" className="sr-only">
                        ZIP / Postal code
                      </label>
                      <input
                        type="text"
                        name="postal-code"
                        id="postal-code"
                        autoComplete="postal-code"
                        className="relative block w-full rounded-none rounded-b-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                        placeholder="ZIP / Postal code"
                      />
                    </div>
                  </div>
                </fieldset> */}
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 sm:col-start-2"
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => dispatch(setModalCardOpen())}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
