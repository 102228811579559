import { ApiClient } from "./api";

export class Conversation {
  constructor() {
    this.apiClient = new ApiClient();
  }

  async getConversation(pt = "") {
    try {
      const { data } = await this.apiClient.get(`conversation?pt=${pt}`);

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async getCountMessage() {
    try {
      const { data } = await this.apiClient.get(`counter/message`);

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async getCountMessageFromAdmin() {
    try {
      const { data } = await this.apiClient.get(`counter/message/from-support`);

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async getNotifications() {
    try {
      const { data } = await this.apiClient.get(`notification`);

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async updateNotification(id, params = {}) {
    try {
      const { data } = await this.apiClient.put(`notification/${id}`, params);

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async getCountNotification() {
    try {
      const { data } = await this.apiClient.get(`notification/count`);

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async setLogNotification() {
    try {
      const { data } = await this.apiClient.put(`notifpagelog`);

      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async getMessages(convId) {
    try {
      const { data } = await this.apiClient.get(`messages/${convId}`);

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async getMessagesAdmin(id, role) {
    try {
      const { data } = await this.apiClient.get(
        `messages/admin/get/conversation?id=${id}&role=${role}`
      );

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async updateRead(convId) {
    try {
      const { data } = await this.apiClient.put(`messages/${convId}`);

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async sendMessage({ convId, message }) {
    try {
      const { data } = await this.apiClient.post(`messages`, {
        conversationId: convId,
        message: message,
      });

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async adminSendMessage(params) {
    try {
      const { data } = await this.apiClient.post(
        `messages/admin/create/one`,
        params
      );

      return data;
    } catch (error) {
      console.error(error);
    }
  }
}
