import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";

import Application from "../../apps/company/Application/index";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Applications() {
  const dispatch = useDispatch();
  const { applicationLaunch } = useSelector((state) => state.ApplicationSlice);

  return (
    <Transition.Root show={applicationLaunch} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() =>
          dispatch({
            type: "ApplicationSlice/setApplicationLaunch",
          })
        }
      >
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed top-12 bottom-0 right-0 flex max-w-full pl-0 md:pl-16">
              <Transition.Child
                as={Fragment}
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="p-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                         
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-sky-500"
                            onClick={() =>
                              dispatch({
                                type: "ApplicationSlice/setApplicationLaunch",
                              })
                            }
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    
                    <Application />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
