import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/20/solid";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCandidateAdmin, setPage } from "../../../redux/candidate";
import { baseUrl, truncateString } from "../../../utils";
import Stars from "../../../components/rating/Stars";

export default function Example() {
  const dispatch = useDispatch();
  const { candidates, page, limit } = useSelector(
    (state) => state.candidateSlice
  );
  const { query, type } = useSelector((state) => state.UserSlice);

  const fetchAllCandidates = useCallback(() => {
    dispatch(fetchAllCandidateAdmin());
  }, [dispatch]);

  useEffect(() => {
    if (type === "c" && query) {
      fetchAllCandidates();
    }
  }, [query]);
  useEffect(() => {
    fetchAllCandidates();
  }, [page]);

  useEffect(() => {
    if (type === "c") {
      dispatch(setPage(1));
    }
  }, [query]);

  const start = (page - 1) * limit + 1;
  const end = Math.min(page * limit, candidates.total);

  return (
    <>
      <nav
        className="flex  w-full items-center justify-between  bg-white px-4 py-3 sm:px-6"
        aria-label="Pagination"
      >
        <div className="hidden sm:block">
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{start}</span> to{" "}
            <span className="font-medium">{end}</span> of{" "}
            <span className="font-medium">{candidates.total}</span> results
          </p>
        </div>
        <div className="flex flex-1 justify-between sm:justify-end">
          <button
            disabled={start === 1}
            onClick={() => dispatch(setPage(page - 1))}
            className="disabled:bg-slate-400/40 relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
          >
            Previous
          </button>
          <button
            disabled={end === candidates.total}
            onClick={() => dispatch(setPage(page + 1))}
            className="disabled:bg-slate-400/40 relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
          >
            Next
          </button>
        </div>
      </nav>
      <ul
        role="list"
        className="grid grid-cols-1 gap-6 mt-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
      >
        {candidates.datas &&
          candidates.datas.map((item, index) => (
            <li
              key={index}
              className="col-span-1 card flex relative  flex-col divide-y divide-gray-200 snap-center bg-white rounded-lg  text-center shadow  "
            >
              {item.user.visible && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  onClick={() =>
                    dispatch({
                      type: "ApplicationSlice/setCandidateLaunch",
                      payload: item.id,
                    })
                  }
                  className="w-6 h-6 absolute top-2 z-10 text-slate-500  animate-pulse cursor-pointer right-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                  />
                </svg>
              )}

              <div className="flex relative flex-1 flex-col p-4 -z-0">
                {!item.candidateProfile?.profile?.image && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={0.7}
                    stroke="currentColor"
                    className="mx-auto h-20 w-20 flex-shrink-0 rounded-full text-sky-400"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
                    />
                  </svg>
                )}
                {item.candidateProfile?.profile?.image && (
                  <img
                    className="mx-auto h-32 w-32 flex-shrink-0 rounded-full"
                    src={item.candidateProfile?.profile?.image}
                    alt=""
                  />
                )}

                <h3 className="mt-6 text-sm font-medium text-gray-900">
                  {[
                    truncateString(item.firstName, 9),
                    truncateString(item.lastName, 9),
                  ].join(" ")}
                </h3>
                <dl className="mt-1 flex flex-grow flex-col justify-between">
                  <dt className="sr-only">Title</dt>
                  <dd className="text-sm text-gray-500">{item.title}</dd>
                  <dt className="sr-only">Role</dt>
                  <dd className="mt-3 inline-flex justify-center items-center">
                    <Stars number={item.stats.totalAverageStars} />
                  </dd>

                  <dt className="sr-only">Houly Rate</dt>
                  <dd className="mt-4 inline-flex justify-end font-black text-sky-400 text-xl items-center">
                    {[
                      parseFloat(item?.hourlyRate?.value).toFixed(2) + " ",
                      item.hourlyRate?.currency?.code,
                      "/h",
                    ].join("")}
                  </dd>
                </dl>
              </div>
              <div>
                <div className="-mt-px flex divide-x divide-gray-200">
                  <div className="flex w-0 flex-1">
                    <a
                      href={`mailto:${item.email}`}
                      className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                    >
                      <EnvelopeIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      Email
                    </a>
                  </div>
                  <div className="-ml-px flex w-0 flex-1">
                    <a
                      href={`tel:${item.telephone}`}
                      className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                    >
                      <PhoneIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      Call
                    </a>
                  </div>
                </div>
              </div>
            </li>
          ))}
      </ul>
    </>
  );
}
