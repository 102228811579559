import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { useFetchContact } from "../../hook/contact";
import { useDispatch, useSelector } from "react-redux";
import {
  setInquirie,
  setOpenContact,
  setOpenToAnswer,
  setPageContact,
} from "../../redux/users";
import moment from "moment";
import { useLocation } from "react-router-dom";
import Feeds from "../feeds/feeds";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function InquiriesSide() {
  useFetchContact();

  const { contacts, page_contact, limit } = useSelector(
    (state) => state.UserSlice
  );
  const start = (page_contact - 1) * limit + 1;
  const end = Math.min(page_contact * limit, contacts.total);
  const dispatch = useDispatch();
  const location = useLocation();

  function handleAnswer(person) {
    dispatch(setInquirie(person));
    dispatch(setOpenToAnswer(true));
    location.pathname = "/contact";
  }

  return (
    <>
      <Feeds />
    </>
  );
}
