import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Candidate } from "../services/candidate";
import { User } from "../services/user";

const candidate = new Candidate();
const user = new User();

export const fetchCandidate = createAsyncThunk(
  "candidateSlice/fetchCandidate",
  async () => {
    return await candidate.getCandidate();
  }
);
export const updateBankAccount = createAsyncThunk(
  "candidateSlice/updateBankAccount",
  async (arg, ThunkAPI) => {
    const state = ThunkAPI.getState();
    const { accountNumber, country } = state.candidateSlice;

    const ip = await user.getIp();

    await user.postBankAccount({
      country: JSON.parse(country).country,
      account_number: accountNumber,
      currency: JSON.parse(country).currency,
      ip: ip,
    });

    return await candidate.getCandidate();
  }
);
export const fetchAllCandidate = createAsyncThunk(
  "candidateSlice/fetchAllCandidate",
  async () => {
    return await candidate.getCandidates();
  }
);
export const fetchAllCandidateAdmin = createAsyncThunk(
  "candidateSlice/fetchAllCandidateAdmin",
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState();
    const { page, limit } = state.candidateSlice;
    const { query } = state.UserSlice;
    return await candidate.getCandidatesAdmin(page, limit, query);
  }
);

export const updateProfileCandidate = createAsyncThunk(
  "candidateSlice/updateProfile",
  async ({ id, params = {} }) => {
    await candidate.updateCandidate(id, params);
    return await candidate.getCandidate();
  }
);
export const udateUserData = createAsyncThunk(
  "candidateSlice/updateUserData",
  async ({ params, file }) => {
    console.log(params);

    await user.postDocumentData(params, file);
    return await candidate.getCandidate();
  }
);
export const postImage = createAsyncThunk(
  "candidateSlice/postImage",
  async ({ id, file }) => {
    await candidate.postImage(id, file);
    return await candidate.getCandidate();
  }
);

export const candidateSlice = createSlice({
  name: "candidateSlice",
  initialState: {
    candidate: null,
    onLoad: false,
    modalOpen: false,
    accountNumber: null,
    country: JSON.stringify({
      country: "GB",
      currency: "gbp",
      pattern: "^GBd{2}[A-Z]{4}d{14}$",
      exemple: "GB29NWBK60161331926819",
    }),
    candidates: [],
    pattern: "^GBd{2}[A-Z]{4}d{14}$",
    exemple: "GB29NWBK60161331926819",
    page: 1,
    limit: 10,
    query: "",
  },
  reducers: {
    setOpen: (state) => {
      state.modalOpen = !state.modalOpen;
    },
    setAccountNumber: (state, action) => {
      state.accountNumber = action.payload;
    },
    setCountry: (state, action) => {
      state.country = action.payload;

      state.pattern = JSON.parse(action.payload).pattern;
      state.exemple = JSON.parse(action.payload).exemple;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCandidate.pending, (state) => {
        state.onLoad = true;
      })
      .addCase(fetchCandidate.fulfilled, (state, action) => {
        state.onLoad = false;
        state.candidate = action.payload;
      })
      .addCase(fetchAllCandidate.pending, (state) => {
        state.onLoad = true;
      })
      .addCase(fetchAllCandidate.fulfilled, (state, action) => {
        state.onLoad = false;
        state.candidates = action.payload;
      })
      .addCase(fetchAllCandidateAdmin.fulfilled, (state, action) => {
       
        state.onLoad = false;
        state.candidates = action.payload;
      })
      .addCase(postImage.pending, (state) => {
        state.onLoad = true;
      })
      .addCase(postImage.fulfilled, (state, action) => {
        state.onLoad = false;
        state.candidate = action.payload;
      })
      .addCase(updateProfileCandidate.pending, (state) => {
        state.onLoad = true;
      })
      .addCase(udateUserData.pending, (state) => {
        state.onLoad = true;
      })
      .addCase(udateUserData.fulfilled, (state, action) => {
        state.onLoad = false;
        state.candidate = action.payload;
      })
      .addCase(updateBankAccount.pending, (state) => {
        state.onLoad = true;
      })
      .addCase(updateBankAccount.fulfilled, (state, action) => {
        state.onLoad = false;
        state.candidate = action.payload;
      })
      .addCase(updateProfileCandidate.fulfilled, (state, action) => {
        state.onLoad = false;
        state.candidate = action.payload;
      });
  },
});

export const { setAccountNumber, setCountry, setPage, setQuery } =
  candidateSlice.actions;
