import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import JobCard from "../Job/Job-card";
import useJob from "../../hook/job";
import { useDispatch, useSelector } from "react-redux";
import { DetailSclice } from "../../redux/details";
import NotFound404 from "../not-found-page/404-not-found";
import { baseUrl } from "../../utils";
import InstantJob, { InstantJobLoading } from "../Job/instant-job";
import GoBack from "../utils/goBack";
import { Border } from "../utils/Border";

export const Company = ({ admin = false }) => {
  const { item } = useSelector((state) => state.DetailSlice);
  const { data, loading } = useJob({ all: false, companyPage: true });

  return item.id ? (
    <div>
      <GoBack />
      <div className="grid  md:grid-cols-12 gap-3 sm:p-3  ">
        <div className="md:col-span-4 grid  px-4">
          <div className="rounded-sm mt-6 border-1 border-gray-300 p-2 sm:p-4 shadow-sm h-fit ring-1 ring-offset-slate-400 ring-gray-200">
            <div className="h-32  relative bg-gradient-to-t rounded-md  to-sky-600 from-sky-300">
              {!item.logo && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="inline-block h-20 w-20 rounded-md absolute bottom-[-3rem] ms-6 text-white bg-gradient-to-t from-sky-700 to-slate-200"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
                  />
                </svg>
              )}

              {item.logo && (
                <img
                  src={item.logo}
                  alt="logo"
                  className="inline-block h-20 w-20 rounded-md absolute bottom-[-3rem] ms-6"
                  srcset=""
                />
              )}
            </div>
            <div className=" px-4 ms-[calc(6rem+2px)] mt-1">
              <h1 className="font-extrabold text-sm ">{item.name}</h1>
              <span className="inline-flex space-x-3 text-xs text-gray-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                  />
                </svg>
                {item.address.city}
              </span>
            </div>
            <div className="grid mt-5 grid-cols-1 gap-y-3 font-medium">
              <dl>
                <dt className="text-sm text-slate-500 not-italic">
                  Email address
                </dt>
                <dd className="text-sm">
                  {" "}
                  <address>
                    <a href={`mailto:${item.email}`}> {item.email}</a>
                  </address>
                </dd>
              </dl>
              <dl>
                <dt className="text-sm text-slate-500">Phone</dt>
                <dd className="text-sm">
                  <address>
                    <a href={`tel:${item.phone}`}>{item.phone}</a>
                  </address>
                </dd>
              </dl>
              <dl>
                <dt className="text-sm text-slate-500">Company address</dt>
                <dd className="text-sm">
                  {item.address.street} <br />
                  {item.address.zip} {item.address.city} <br />
                  {item.state}
                </dd>
              </dl>
            </div>
          </div>
        </div>
        <div className="md:col-span-8 grid space-x-2 divide-y gap-y-3 ">
          <div className="md:inline-flex grid gap-1 grid-cols-2 md:space-x-2 md:h-32 md:w-full md:p-0 p-3">
            <div className="md:flex-1 shadow-sm border-dashed rounded-md p-4 border-gray-300 border grid grid-cols-1 justify-center items-center">
              <span className="font-semibold text-xs md:text-sm text-center">
                Jobs posted
              </span>
              <span className="text-center font-extrabold">
                {item.extra.totalJobs}
              </span>
            </div>
            <div className="md:flex-1 shadow-sm border-dashed rounded-md p-4 border-gray-300 border grid justify-center items-center">
              <span className="font-semibold text-xs md:text-sm text-center">
                Applicants
              </span>
              <span className="text-center font-extrabold">
                {item.extra.totalApplications}
              </span>
            </div>
            <div className="md:flex-1 shadow-sm border-dashed rounded-md p-4 border-gray-300 border grid justify-center items-center">
              <span className="font-semibold text-xs md:text-sm text-center">
                Accepted
              </span>
              <span className="text-center font-extrabold">
                {item.extra.totalAcceptedApplications}
              </span>
            </div>
            <div className="md:flex-1 shadow-sm border-dashed rounded-md p-4 border-gray-300 border grid justify-center items-center">
              <span className="font-semibold text-xs md:text-sm text-center">
                Stars rating
              </span>
              <span className="text-center font-extrabold">{item.stars}</span>
            </div>
          </div>
          <div className="grid py-3">
            <dl>
              <dt className="text-sm font-medium mb-2 text-gray-500 ">
                About the company
              </dt>

              <dd className="text-gray-700 font-normal">{item.description}</dd>
            </dl>
          </div>

          {!admin && (
            <div className="static py-3 ">
              <h1 className="text-sm font-medium mb-2 text-gray-500 ">
                Live positions
              </h1>
              <div className="grid grid-cols-1 gap-3 px-4 ">
                {data &&
                  data.map((items) => (
                    <InstantJob key={items.id} item={items} />
                  ))}
                {loading && (
                  <>
                    <InstantJobLoading />
                    <InstantJobLoading />
                    <InstantJobLoading />
                    <InstantJobLoading />
                    <InstantJobLoading />
                    <InstantJobLoading />
                    <InstantJobLoading />
                    <InstantJobLoading />
                    <InstantJobLoading />
                    <InstantJobLoading />
                  </>
                )}
              </div>
            </div>
          )}

          {}
        </div>
      </div>
    </div>
  ) : (
    <NotFound404 />
  );
};
