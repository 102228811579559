import { useState } from "react";
import { Jobs } from "../../../services/job";

const job = new Jobs();

export function useCreateJob(initialValues) {
  const [form, setForm] = useState(initialValues);

  function handleInputChange(event) {
    const { name, value } = event.target;

    if (name.includes(".")) {
      const [outerKey, innerKey] = name.split(".");
      setForm({
        ...form,
        [outerKey]: { ...form[outerKey], [innerKey]: value },
      });
    } else {
     
      if (name === "hourlyRate") 
      {
        const isValid = new RegExp("^[0-9/\\.]{0,5}$").test(value);
        if (isValid) {
          setForm({ ...form, [name]: value });
        } else {
          window.document.getElementsByName("name")[0].value = "";
          setForm({ ...form, [name]: "" });
        }
      }
      else {
        setForm({ ...form, [name]: value });
      }
    }
  }

  function handleCheckboxChange(event) {
    const { name, checked, value } = event.target;

    // Assurez-vous que la clé existe et est un tableau
    if (!Array.isArray(form[name])) {
      console.error(
        `La clé ${name} doit être un tableau dans l'objet de formulaire.`
      );
      return;
    }

    // Si la case est cochée, ajoutez la valeur au tableau, sinon supprimez-la
    if (checked) {
      setForm({ ...form, [name]: [...form[name], value] });
    } else {
      setForm({
        ...form,
        [name]: form[name].filter((item) => item !== value),
      });
    }
  }

  function convertTo12HourFormat(time) {
    const [hour, minute] = time.split(":").map(Number);

    if (hour < 0 || hour > 23 || minute < 0 || minute > 59) {
      return "Heure invalide";
    }

    let period = "AM";
    let convertedHour = hour;

    if (hour >= 12) {
      period = "PM";
      if (hour > 12) {
        convertedHour = hour - 12;
      }
    } else if (hour === 0) {
      convertedHour = 12;
    }

    return `${convertedHour}:${minute} ${period}`;
  }

  function calculateTimeDifference(startTime, endTime) {
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const [endHour, endMinute] = endTime.split(":").map(Number);

    if (
      startHour < 0 ||
      startHour > 23 ||
      startMinute < 0 ||
      startMinute > 59 ||
      endHour < 0 ||
      endHour > 23 ||
      endMinute < 0 ||
      endMinute > 59
    ) {
      return "Heure invalide";
    }

    let totalMinutes =
      endHour * 60 + endMinute - (startHour * 60 + startMinute);

    if (totalMinutes < 0) {
      return "L'heure de fin doit être postérieure à l'heure de début";
    }

    const totalHours = totalMinutes / 60;

    return totalHours;
  }

  function reset() {
    setForm(initialValues);
  }

  function save() {
    job.postJob(form).then(() => {
      window.location.reload();
    });
  }

  return {
    form,
    handleInputChange,
    handleCheckboxChange,
    reset,
    setForm,
    calculateTimeDifference,
    convertTo12HourFormat,
    save,
  };
}
