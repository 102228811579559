import {
  PlusIcon,
  PaperAirplaneIcon,
  ArrowDownLeftIcon,
} from "@heroicons/react/20/solid";
import React, { useEffect, useRef, useState } from "react";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { baseUrl } from "../../utils";
import useCurrentBreakpoint from "../../hook/tailwindBreakPoint";
import useConversation from "../../hook/conversation";
import Roles from "../../Enum/UserRoles";
import { locale } from "moment/moment";
import GoBack from "../utils/goBack";
import TabMessages from "./tab-messages";
import { motion } from "framer-motion";
import { setImage } from "../../redux/conversation";

function CardConversation({ item, selected = false, role }) {
  const { tabConvSelected } = useSelector((state) => state.conversationSlice);

  return (
    <motion.div
      whileHover={{ scale: 1.003 }}
      onHoverStart={(e) => {}}
      onHoverEnd={(e) => {}}
      key={item.id}
      className={`grid grid-cols-12 h-[75px] overflow-hidden relative ${
        !item.lastMessage.readAt && item.lastMessage.sender !== role
          ? "bg-indigo-50 text-indigo-800"
          : "bg-white ring-sky-100 "
      } ${
        selected ? "bg-sky-100  shadow-md font-semibold transition-all  " : ""
      } ring-1  rounded-md shadow-sm   cursor-pointer`}
    >
      <span className="text-xs font-normal text-slate-950/40  absolute right-4 top-2 truncate">
        {moment.unix(item.lastMessage.creatAt).local().fromNow()}
      </span>
      <div className="col-span-2  grid justify-center items-center">
        {tabConvSelected !== "Fs" && role === Roles.company && (
          <img
            src={item.candidate.candidateProfile?.profile?.image}
            className={`h-10 w-10 ${
              selected ? "mix-blend-multiply" : "mix-blend-darken"
            }`}
            alt="img"
          />
        )}
        {tabConvSelected !== "Fs" && role === Roles.candidate && (
          <img
            src={item.company.logo}
            className={`h-10 w-10 ${
              selected ? "mix-blend-multiply" : "mix-blend-darken"
            }`}
            alt="img"
          />
        )}
        {tabConvSelected === "Fs" && (
          <img
            src={require("../../assets/img/gear.png")}
            className={`h-10 w-10 ${
              selected ? "mix-blend-multiply" : "mix-blend-darken"
            }`}
            alt="img"
          />
        )}
      </div>
      <div className="col-span-10 py-3 space-y-1">
        <h1 className="font-semibold text-sm truncate">
          {tabConvSelected !== "Fs"
            ? role === Roles.candidate
              ? item.company.name
              : [item.candidate.firstName, item.candidate.lastName].join(" ")
            : "Support"}
        </h1>
        <p className="truncate text-sm">{item.lastMessage.message}</p>
      </div>
    </motion.div>
  );
}

function ChatBubble({
  incoming = false,
  outgoing = false,
  item,
  image = null,
  role,
}) {
  const { candidate } = useSelector((state) => state.candidateSlice);
  const { company } = useSelector((state) => state.companySlice);
  const { tabConvSelected } = useSelector((state) => state.conversationSlice);

  return incoming ? (
    <div className="chat chat-start">
      <div className="chat-image avatar">
        <div className="w-10 rounded-full">
          {tabConvSelected !== "Fs" && <img src={image} />}
          {tabConvSelected === "Fs" && (
            <img src={require("../../assets/img/gear.png")} />
          )}
        </div>
      </div>

      <div className="chat-bubble bg-sky-500 text-white">{item.message}</div>
      <div className="chat-footer opacity-50 text-xs">
        {moment.unix(item.creatAt).local().fromNow()}
      </div>
    </div>
  ) : (
    <div className="chat chat-end ">
      <div className="chat-image avatar">
        <div className="w-10 rounded-full">
          {role === Roles.candidate && (
            <img src={candidate.candidateProfile.profile.image} />
          )}
          {role === Roles.company && <img src={company.logo} />}
        </div>
      </div>

      <div className="chat-bubble bg-base-1 text-white">{item.message}</div>
      <div className="chat-footer  opacity-50 text-xs">
        {" "}
        {moment.unix(item.creatAt).local().fromNow()}
      </div>
    </div>
  );
}

function Messages() {
  const [message, setMessage] = useState(null);

  const {
    loading,
    convId,
    detail,
    sendTo,
    tabConvSelected,
    convAdmin,
    image,
    adminHasSentMessage,
  } = useSelector((state) => state.conversationSlice);

  const { role } = useSelector((state) => state.authenticationSlice);

  const dispatch = useDispatch();

  const {
    conv,
    fetchMessages,
    messages,
    resetMessage,
    sendMessage,
    updateRead,
  } = useConversation();
  const scrollableDivRef = useRef(null);
  const brk = useCurrentBreakpoint();

  function handleDetail(id, name, image, userToSend) {
    dispatch(setImage(image));
    dispatch({
      type: "conversation/setDetail",
    });
    dispatch({
      type: "conversation/setConvId",
      payload: id,
    });
    dispatch({
      type: "conversation/setSendTo",
      payload: name,
    });
    dispatch({
      type: "conversation/setUserToSend",
      payload: userToSend,
    });

    setTimeout(() => {
      scrollToBottom();
    }, 2000);
  }

  function handleSendMessage() {
    sendMessage(convId, message);
  }

  const scrollToBottom = () => {
    if (scrollableDivRef.current) {
      const scrollableDiv = scrollableDivRef.current;
      scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
    setMessage("");
  }, [messages]);

  useEffect(() => {
    if (convId) {
      fetchMessages(convId);
      updateRead(convId);
    } else {
      resetMessage();
    }
  }, [detail]);

  return (
    <div className="grid grid-cols-12 h-[calc(100vh-4rem)] overflow-hidden bg-base-100 ">
      <div
        className={`col-span-full ${
          detail
            ? brk === "sm" || brk === "xs" || brk === "md"
              ? "hidden"
              : "block"
            : ""
        } md:col-span-4 border-r overscroll-auto h-full overflow-hidden p-2`}
      >
        <h1 className="font-bold text-xl text-gray-400">Chat</h1>
        <br />
        <TabMessages />

        <div className="ring-1 ring-sky-200 rounded-lg shadow mt-4 relative">
          <input
            type="text"
            className=" w-full relative py-1 px-4 border-transparent rounded-lg  pr-9"
            placeholder="Search conversation..."
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5 absolute  bottom-1.5  right-3 cursor-pointer"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
            />
          </svg>
        </div>

        <h1 className="font-semibold text-lg mt-7 text-gray-600">
          Recent Chats
        </h1>

        <div
          className={`py-6 grid  gap-y-1 divide-y px-1 overflow-y-auto md:overflow-y-hidden md:hover:overflow-y-scroll max-h-[calc(100vh-15rem)]   `}
        >
          {[conv, convAdmin][tabConvSelected === "G" ? 0 : 1] &&
            [conv, convAdmin][tabConvSelected === "G" ? 0 : 1].map(
              (item, index) => (
                <a
                  key={index}
                  role="button"
                  onClick={() =>
                    tabConvSelected !== "Fs"
                      ? handleDetail(
                          item.id,
                          role === Roles.candidate
                            ? item.company.name
                            : [
                                item.candidate.firstName,
                                item.candidate.lastName,
                              ].join(" "),
                          role === Roles.candidate
                            ? item.company.logo
                            : item.candidate.candidateProfile.profile.image,
                          role === Roles.candidate
                            ? item.company.userId
                            : item.candidate.userId
                        )
                      : handleDetail(item.id, "Support", "", item.admin)
                  }
                >
                  <CardConversation
                    selected={convId === item.id}
                    item={item}
                    key={index}
                    role={role}
                  />
                </a>
              )
            )}
        </div>
      </div>
      <div
        className={`col-span-full overflow-hidden  h-full w-full md:col-span-8 ${
          detail ? "flex" : "hidden"
        }  md:flex flex-col  px-3 py-2  border-none`}
      >
        {!messages && (
          <div className="flex-1 flex justify-center items-center">
            <h1 className="animate-pulse text-lg font-semibold text-gray-600">
              Select a conversation to chat with someone
            </h1>
          </div>
        )}
        {messages && (
          <>
            {" "}
            <div className="grid  flex-none grid-cols-12">
              <div className="col-span-full grid grid-cols-12 h-[3rem] bg-white  rounded-md  ">
                {(brk === "sm" || brk === "xs" || brk === "md") && (
                  <div className="col-span-2 grid justify-center items-center">
                    <ArrowLeftIcon
                      onClick={() =>
                        dispatch({
                          type: "conversation/setDetail",
                        })
                      }
                      className=" h-6 w-6 "
                      aria-hidden="true"
                    />
                  </div>
                )}

                <div className=" col-span-10 md:col-span-12 grid px-4  justify-end items-center py-3">
                  <div className="inline-flex space-x-2 items-center justify-end">
                    <h1 className="text-xs sm:text-sm font-bold text-sky-400 uppercase cursor-pointer  truncate">
                      {sendTo}
                    </h1>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 sm:w-6 sm:h-6 cursor-pointer"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              ref={scrollableDivRef}
              className="flex flex-col h-[calc(80%)] mt-3  overflow-y-scroll md:overflow-hidden md:hover:overflow-y-scroll scroll-smooth  py-5"
            >
              {messages &&
                messages.map((msg) => (
                  <ChatBubble
                    key={msg.id}
                    outgoing={
                      tabConvSelected !== "Fs"
                        ? role === Roles.candidate
                          ? msg.sender === "CANDIDATE"
                          : msg.sender === "COMPANY"
                        : role === msg.sender
                    }
                    incoming={
                      tabConvSelected !== "Fs"
                        ? role === Roles.candidate
                          ? msg.sender === "COMPANY"
                          : msg.sender === "CANDIDATE"
                        : msg.sender === "ADMIN"
                    }
                    item={msg}
                    image={image}
                    role={role}
                  />
                ))}
            </div>
            <div className="flex w-full flex-none  justify-center items-center sm:px-4">
              <div className="bg-white h-20 grid w-full mb-5 grid-cols-12 gap-x-2 mt-5 rounded-md ring-1 justify-center items-center ring-sky-400">
                <div className="col-span-9 sm:col-span-10  px-3">
                  <textarea
                    className="textarea w-full h-11 "
                    placeholder="type here"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
                <div className="col-span-3 sm:col-span-2 flex justify-center">
                  <motion.button
                    whileHover={{ scale: 1.2 }}
                    onHoverStart={(e) => {}}
                    onHoverEnd={(e) => {}}
                    type="button"
                    onClick={handleSendMessage}
                    className="rounded-full bg-sky-600 p-2 text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                  >
                    <PaperAirplaneIcon
                      className={`sm:h-5 h-4 ${
                        loading && "animate-ping"
                      } w-4 sm:w-5 rotate-[-30deg]`}
                      aria-hidden="true"
                    />
                  </motion.button>
                </div>
              </div>
            </div>{" "}
          </>
        )}
      </div>
    </div>
  );
}

export default Messages;
