/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import BusinessType from "./business-type";
import { useDispatch, useSelector } from "react-redux";
import Roles from "../Enum/UserRoles";
import { useEffect, useState } from "react";
import Typewriter from "typewriter-effect/dist/core";
import {
  setValues,
  setStage,
  setPrefix,
  createUserSuccess,
  setName,
  setFirstName,
  setLastName,
  setPhone,
  setEmail,
  setPassword,
  setPasswordConfirmation,
  setLocation,
  createUser,
  setCountry,
  checkEmail,
} from "../redux/registration";
import FinishRegistration from "./Finish";
import jwtDecode from "jwt-decode";
import GoBack from "../components/utils/goBack";

export default function Registration() {
  const {
    type,
    stage,
    firstName,
    lastName,
    phone,
    email,
    password,
    passwordConfirmation,
    name,
    location,
  } = useSelector((state) => state.RegistrationSlice);
  const dispatch = useDispatch();

  useEffect(() => {
    new Typewriter("#typewriter", {
      strings: [
        "Empower Your Future, One Shift at a Time!",
        "Unlock Opportunities. Redefine Your Potential.",
        "Step Forward, Shape Your Destiny Today!",
      ],
      autoStart: true,
      loop: true,
    });
  }, []);

  function handleCredentialResponse(rs) {
    console.log(rs);
    console.log(jwtDecode(rs.credential));
  }

  useEffect(() => {
    /* global google */
    /* google.accounts.id.initialize({
      client_id:
        "898168810706-74ftti6aob3pt2v4llksora3o8f8ju09.apps.googleusercontent.com",
      callback: handleCredentialResponse,
    });

    google.accounts.id.renderButton(document.getElementById("signInDiv"), {
      theme: "filled_blue",
      size: "large",
      text: "signup_with",
      width: "100%",
    }); */
    // google.accounts.id.prompt();
  }, [stage]);

  return (
    <>
      <div className="flex h-screen min-h-full flex-1 overflow-y-scroll">
        <div className="relative  hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={require("../assets/img/illustration/work-well.jpeg")}
            alt=""
          />
          <div className="absolute bottom-2 w-[300px] rounded-md font-semibold  p-4 left-2 bg-white">
            <h1 className="text-2xl" id="typewriter">
              {" "}
              Choose to be the better version of yourself
            </h1>
          </div>
        </div>

        <div className="flex flex-1 flex-col relative justify-center px-4 md:py-10 py-3 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          {stage === 0 && (
            <div className="absolute sm:block hidden left-0 top-3 inset-y-0">
              <GoBack />
            </div>
          )}

          <div className="mx-auto overflow-y-hidden  hover:overflow-y-scroll   w-full max-w-sm lg:w-96">
            <div>
              <a href="/">
                <img
                  className="h-6 xs:h-10 w-auto"
                  src={require("../assets/img/logo.png")}
                  alt="logo"
                />
              </a>

              <h2 className="mt-4 md:mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Sign up
              </h2>

              <p className="mt-2 text-xs xs:text-sm leading-6 text-gray-500">
                Already have an account?{" "}
                <a
                  href="/login"
                  className="font-semibold text-sky-600 hover:text-sky-500"
                >
                  Sign in here
                </a>
              </p>
            </div>

            {/*  {stage === 1 && <div className="mt-4 w-full" id="signInDiv"></div>} */}
            <div className="mt-5 md:mt-10 ">
              <div>
                {stage === 0 && <BusinessType />}
                {stage === 1 && type && type.id === Roles.company && (
                  <Company />
                )}
                {stage === 1 && type && type.id === Roles.candidate && (
                  <Candidate />
                )}
                {stage === 2 && <FinishRegistration />}
              </div>
            </div>

            {stage === 1 && (
              <div className="inline-flex items-center justify-center mt-4 w-full">
                <div
                  onClick={() =>
                    dispatch({
                      type: "RegistrationSlice/goBack",
                    })
                  }
                  className="inline-flex items-center space-x-2 hover:text-sky-500 cursor-pointer "
                >
                  <span> Back </span>
                </div>
              </div>
            )}

            {stage === 2 && (
              <div className="inline-flex items-center justify-center mt-4 w-full">
                <div
                  onClick={() => window.location.replace("/login")}
                  className="inline-flex items-center space-x-2 hover:text-sky-500 cursor-pointer "
                >
                  <span className="text-sky-600 font-bold"> Login </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function Company() {
  const [location, setLocationS] = useState({
    street: null,
    city: null,
    state: null,
    zip: null,
  });
  const { name, phone, emailUsed, password, passwordConfirmation, prefix } =
    useSelector((state) => state.RegistrationSlice);
  const [focus, setFocus] = useState(false);
  const dispatch = useDispatch();

  function handleChangeLocation(event) {
    const { name, value } = event.target;

    setLocationS({ ...location, [name]: value });
  }

  const [cgu, setCgu] = useState(false);

  function handleSetLocation() {
    dispatch(setLocation(location));
  }

  function handleSubmit(e) {
    e.preventDefault();

    dispatch({
      type: "RegistrationSlice/goNext",
    });
  }

  return (
    <form onSubmit={handleSubmit} className="gap-y-2 gap-x-3  grid grid-cols-6">
      <div className="col-span-full">
        <label
          htmlFor="name"
          className="block text-xs xs:text-sm font-medium leading-6 text-gray-900"
        >
          Company name
        </label>
        <div className="mt-2">
          <input
            id="name"
            name="name"
            value={name}
            onChange={(e) => dispatch(setName(e))}
            type="text"
            autoComplete="company-name"
            required
            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600  text-xs xs:text-sm sm:leading-6"
          />
        </div>
      </div>

      <fieldset className="col-span-full">
        <legend className="block  text-xs xs:text-sm font-medium leading-6 text-gray-900">
          Company address
        </legend>
        <div className="mt-2 -space-y-px rounded-md bg-white shadow-sm">
          <div>
            <label htmlFor="street" className="sr-only">
              country
            </label>
            <select
              id="location"
              name="country"
              required
              onBlur={handleSetLocation}
              onChange={(e) => dispatch(setCountry(e))}
              className="relative block w-full rounded-none rounded-t-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-sky-600  text-xs xs:text-sm sm:leading-6"
            >
              <option selected disabled>
                Country
              </option>
              <option value={1}> United Kingdom </option>
              <option value={2}> Kingdom of Saudi Arabia </option>
              <option value={3}> United Arab Emirates </option>
            </select>
          </div>
          <div>
            <label htmlFor="street" className="sr-only">
              Street
            </label>
            <input
              type="text"
              name="street"
              id="street"
              onBlur={handleSetLocation}
              onChange={handleChangeLocation}
              autoComplete="street-address"
              className="relative block w-full rounded-none  border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-sky-600  text-xs xs:text-sm sm:leading-6"
              placeholder="Street"
            />
          </div>
          <div className="flex -space-x-px">
            <div className="w-1/2 min-w-0 flex-1">
              <label htmlFor="city" className="sr-only">
                City
              </label>
              <input
                type="text"
                name="city"
                id="city"
                onBlur={handleSetLocation}
                onChange={handleChangeLocation}
                autoComplete="address-level2"
                className="relative block w-full rounded-none rounded-bl-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-sky-600  text-xs xs:text-sm sm:leading-6"
                placeholder="City"
              />
            </div>
            <div className="min-w-0 flex-1">
              <label htmlFor="zip-code" className="sr-only">
                Zip
              </label>
              <input
                type="text"
                name="zip"
                onBlur={handleSetLocation}
                onChange={handleChangeLocation}
                id="zip-code"
                autoComplete="postal-code"
                className="relative block w-full rounded-none  border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-sky-600  text-xs xs:text-sm sm:leading-6"
                placeholder="Zip"
              />
            </div>
            <div className="min-w-0 flex-1">
              <label htmlFor="state" className="sr-only">
                State
              </label>
              <input
                type="text"
                onChange={handleChangeLocation}
                name="state"
                id="state"
                onBlur={handleSetLocation}
                autoComplete="address-level1"
                className="relative block w-full rounded-none rounded-br-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-sky-600  text-xs xs:text-sm sm:leading-6"
                placeholder="State"
              />
            </div>
          </div>
        </div>
      </fieldset>

      <div className="col-span-full">
        <label
          htmlFor="phone"
          className="block text-xs xs:text-sm font-medium leading-6 text-gray-900"
        >
          Phone
        </label>
        <div className="mt-2 relative">
          <div className="absolute inset-y-0 left-0 flex items-center">
            <label htmlFor="prefix" className="sr-only">
              Prefix
            </label>
            <select
              id="prefix"
              name="phone.prefix"
              onChange={(e) => dispatch(setPrefix(e))}
              value={prefix}
              className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-sky-600 md:text-sm text-xs"
            >
              <option value={"+44"}>UK (+44)</option>
              <option value={"+966"}>KSA (+966)</option>
              <option value={"+971"}>UAE (+971)</option>
              <option value={"+230"}>MU (+230)</option>
              <option value={"+1"}>CAN (+1)</option>
            </select>
          </div>
          <input
            id="phone"
            name="phone.value"
            value={phone}
            onChange={(e) => dispatch(setPhone(e))}
            type="tel"
            autoComplete="phone-number"
            required
            className="block w-full pl-32 rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600  text-xs xs:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div className="col-span-full">
        <label
          htmlFor="email"
          className="block text-xs xs:text-sm font-medium leading-6 text-gray-900"
        >
          Email address
        </label>
        <div className="mt-2">
          <input
            id="email"
            name="email"
            type="email"
            onChange={(e) => dispatch(setEmail(e))}
            onBlur={() => dispatch(checkEmail())}
            autoComplete="email"
            required
            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600  text-xs xs:text-sm sm:leading-6"
          />
          {emailUsed && (
            <p className="text-[0.65rem] font-semibold md:text-xs text-end text-red-600">
              Email already used
            </p>
          )}
        </div>
      </div>

      {focus && (
        <div className="col-span-full">
          <p style={{ fontSize: "0.8rem" }}>
            Password requirements: 8+ characters, 1 lowercase, 1 uppercase, 1
            number, 1 special character.
          </p>
        </div>
      )}

      <div className="col-span-full">
        <label
          htmlFor="password"
          className="block text-xs xs:text-sm font-medium leading-6 text-gray-900"
        >
          Password
        </label>
        <div className="mt-2" data-tip="Gimme a strong password">
          <input
            id="password"
            name="password"
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            onChange={(e) => dispatch(setPassword(e))}
            type="password"
            autoComplete="current-password"
            pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^#()_+={}|:;'<>,.-])[A-Za-z\d@$!%*?&^#()_+={}|:;'<>,.-]{8,}"
            required
            className="block w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600  text-xs xs:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div className="col-span-full">
        <label
          htmlFor="password"
          className="block text-xs xs:text-sm font-medium leading-6 text-gray-900"
        >
          Confirm password
        </label>
        <div className="mt-2">
          <input
            id="password"
            name="password"
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            onChange={(e) => dispatch(setPasswordConfirmation(e))}
            type="password"
            pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^#()_+={}|:;'<>,.-])[A-Za-z\d@$!%*?&^#()_+={}|:;'<>,.-]{8,}"
            autoComplete="current-password"
            required
            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600  text-xs xs:text-sm sm:leading-6"
          />
        </div>
      </div>

      <div className=" col-span-full pl-1">
        <div className="relative mt-4 flex items-start">
          <div className="flex h-6 items-center">
            <input
              id="comments"
              aria-describedby="comments-description"
              name="comments"
              type="checkbox"
              onChange={(e) => setCgu(e.target.checked)}
              className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label htmlFor="comments" className="font-medium text-gray-900">
              I agree to{" "}
              <a
                href="/support/terms"
                target="_blank"
                className="text-sky-500 hover:underline"
              >
                {" "}
                CGU{" "}
              </a>
            </label>
          </div>
        </div>
      </div>

      <div className="col-span-full mt-5">
        <button
          disabled={emailUsed || password !== passwordConfirmation || !cgu}
          type="submit"
          className="flex w-full disabled:bg-gray-300 justify-center rounded-md bg-sky-600 px-3 py-1.5 text-xs xs:text-sm font-semibold leading-6 text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
        >
          Sign up
        </button>
      </div>
    </form>
  );
}
function Candidate() {
  const dispatch = useDispatch();
  const [cgu, setCgu] = useState(false);
  const [focus, setFocus] = useState(false);
  const {
    type,
    stage,
    firstName,
    lastName,
    phone,
    email,
    password,
    passwordConfirmation,
    name,
    location,
    prefix,
    emailUsed,
  } = useSelector((state) => state.RegistrationSlice);

  function handleSubmit(e) {
    e.preventDefault();

    dispatch({
      type: "RegistrationSlice/goNext",
    });
  }

  return (
    <form onSubmit={handleSubmit} className="gap-y-2 gap-x-3 grid grid-cols-6 ">
      <div className="col-span-3">
        <label
          htmlFor="firstName"
          className="block text-xs xs:text-sm font-medium leading-6 text-gray-900"
        >
          First name
        </label>
        <div className="mt-2">
          <input
            id="firstName"
            name="firstName"
            value={firstName}
            onChange={(e) => dispatch(setFirstName(e))}
            type="text"
            autoComplete="first-name"
            required
            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600  text-xs xs:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div className="col-span-3">
        <label
          htmlFor="lastName"
          className="block text-xs xs:text-sm font-medium leading-6 text-gray-900"
        >
          Last name
        </label>
        <div className="mt-2">
          <input
            id="lastName"
            name="lastName"
            value={lastName}
            type="text"
            onChange={(e) => dispatch(setLastName(e))}
            autoComplete="last-name"
            required
            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600  text-xs xs:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div className="col-span-full">
        <label
          htmlFor="phone"
          className="block text-xs xs:text-sm font-medium leading-6 text-gray-900"
        >
          Phone
        </label>
        <div className="mt-2 relative">
          <div className="absolute inset-y-0 left-0 flex items-center">
            <label htmlFor="prefix" className="sr-only">
              Prefix
            </label>
            <select
              id="prefix"
              name="phone.prefix"
              onChange={(e) => dispatch(setPrefix(e))}
              value={prefix}
              className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-sky-600 md:text-sm text-xs"
            >
              <option value={"+44"}>UK (+44)</option>
              <option value={"+966"}>KSA (+966)</option>
              <option value={"+971"}>UAE (+971)</option>
              <option value={"+230"}>MU (+230)</option>
              <option value={"+1"}>CAN (+1)</option>
            </select>
          </div>
          <input
            id="phone"
            name="phone"
            value={phone}
            type="tel"
            onChange={(e) => dispatch(setPhone(e))}
            autoComplete="phone-number"
            required
            className="block w-full pl-32 rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600  text-xs xs:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div className="col-span-full">
        <label
          htmlFor="email"
          className="block text-xs xs:text-sm font-medium leading-6 text-gray-900"
        >
          Email address
        </label>
        <div className="mt-2">
          <input
            id="email"
            name="email"
            value={email}
            type="email"
            onBlur={() => dispatch(checkEmail())}
            onChange={(e) => dispatch(setEmail(e))}
            autoComplete="email"
            required
            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600  text-xs xs:text-sm sm:leading-6"
          />
        </div>
        {emailUsed && (
          <p className="text-[0.65rem] font-semibold md:text-xs text-end text-red-600">
            Email already used
          </p>
        )}
      </div>

      {focus && (
        <div className="col-span-full">
          <p style={{ fontSize: "0.8rem" }}>
            Password requirements: 8+ characters, 1 lowercase, 1 uppercase, 1
            number, 1 special character.
          </p>
        </div>
      )}

      <div className="col-span-full">
        <label
          htmlFor="password"
          className="block text-xs xs:text-sm font-medium leading-6 text-gray-900"
        >
          Password
        </label>
        <div className="mt-2">
          <input
            id="password"
            onChange={(e) => dispatch(setPassword(e))}
            value={password}
            name="password"
            type="password"
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            autoComplete="current-password"
            pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^#()_+={}|:;'<>,.-])[A-Za-z\d@$!%*?&^#()_+={}|:;'<>,.-]{8,}"
            required
            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600  text-xs xs:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div className="col-span-full">
        <label
          htmlFor="password"
          className="block text-xs xs:text-sm font-medium leading-6 text-gray-900"
        >
          Confirm password
        </label>
        <div className="mt-2">
          <input
            id="password"
            value={passwordConfirmation}
            onChange={(e) => dispatch(setPasswordConfirmation(e))}
            name="password"
            type="password"
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            autoComplete="current-password"
            pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^#()_+={}|:;'<>,.-])[A-Za-z\d@$!%*?&^#()_+={}|:;'<>,.-]{8,}"
            required
            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600  text-xs xs:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div className=" col-span-full pl-1">
        <div className="relative mt-4 flex items-start">
          <div className="flex h-6 items-center">
            <input
              id="comments"
              aria-describedby="comments-description"
              name="comments"
              onChange={(e) => setCgu(e.target.checked)}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label htmlFor="comments" className="font-medium text-gray-900">
              I agree to{" "}
              <a
                href="/support/terms"
                target="_blank"
                className="text-sky-500 hover:underline"
              >
                {" "}
                CGU{" "}
              </a>
            </label>
          </div>
        </div>
      </div>

      <div className="col-span-full mt-5">
        <button
          disabled={emailUsed || password !== passwordConfirmation || !cgu}
          type="submit"
          className="flex w-full justify-center disabled:bg-gray-300 rounded-md bg-sky-600 px-3 py-1.5 text-xs xs:text-sm font-semibold leading-6 text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
        >
          Sign up
        </button>
      </div>
    </form>
  );
}
