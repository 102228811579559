import clsx from "clsx";

function Office({ name, children, invert = false }) {
  return (
    <address
      className={clsx(
        "text-sm not-italic",
        invert ? "text-neutral-300" : "text-neutral-600"
      )}
    >
      <strong className={invert ? "text-white" : "text-sky-600"}>
        {name}
      </strong>
      <br />
      {children}
    </address>
  );
}

export function Offices({ invert = false, ...props }) {
  return (
    <ul role="list" {...props}>
      <li>
        <Office name="Dubai Office" invert={invert}>
          Office 201 Goldcrest
          <br />
          Executive Cluster C, JLT Dubai United Arab Emirates PO Box 478403
        </Office>
      </li>
      <li>
        <Office name="United Kingdom Office" invert={invert}>
          10 Margaret Street
          <br />
          London, United Kingdom W1W 8RL
        </Office>
      </li>
      <li>
        <Office name="Africa Office" invert={invert}>
          eMagine Solutions
          <br />
          Vivea Business Park Moka Maurititus
        </Office>
      </li>
    </ul>
  );
}
