import React, { useEffect, useRef, useState } from "react";
import {
  PencilIcon,
  QuestionMarkCircleIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import { baseUrl, prefix } from "../../../utils";
import useIndustries from "../../../hook/industry";
import { useLogo, useUpdateProfile } from "../../../hook/profile";
import DocumentID from "../../../landing-page/document-id";
import useCurrencies from "../../../hook/currency";
import {
  checkPasswordAnChange,
  openDeleteModal,
  setConfirmPassword,
  setNewPassword,
  setPasswordToCheck,
} from "../../../redux/users";
import SliderKm from "../../../components/slider/Slider";
import { SpinnerLoadData } from "../../../components/utils/spinner-load";
function Account() {
  const [enableEdit, setEnableEdit] = useState(false);
  const { candidate, onLoad } = useSelector((state) => state.candidateSlice);
  const { new_password, confirm_password, error, password_to_ckeck } =
    useSelector((state) => state.UserSlice);
  const { datas, initData } = useIndustries();
  const [industry, setIndustry] = useState(null);
  const file = useRef();
  const data = JSON.parse(JSON.stringify(candidate));
  const [focus, setFocus] = useState(false);
  const {
    onUpdate,
    isChanged,
    saveCandidate,
    temp,
    saveUserCandidate,
    isChangedDocument,
  } = useUpdateProfile(data, data);
  const { getFile, theFile, save, saveImageCandidate, reset } = useLogo();
  const currency = useCurrencies();
  const dispatch = useDispatch();

  function handleChangePassword(e) {
    e.preventDefault();

    dispatch(checkPasswordAnChange());
  }

  useEffect(() => {
    if (initData) {
      setIndustry(initData);
    }
  }, [initData]);

  return onLoad ? (
    <SpinnerLoadData />
  ) : (
    <div className="divide-y divide-gray-300 relative">
      <div className="grid   grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-800">
            Personal Information
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Use a permanent address where you can receive mail.
          </p>
        </div>

        <form className="md:col-span-2">
          <div className="grid grid-cols-1 gap-x-6 md:gap-y-8 gap-y-4  sm:grid-cols-6">
            <div className="col-span-full  flex flex-wrap items-center gap-x-8">
              {onLoad && (
                <div className="h-24 w-24 flex-none rounded-lg bg-slate-200 animate-pulse object-cove"></div>
              )}

              {candidate && (
                <img
                  src={
                    theFile
                      ? URL.createObjectURL(theFile)
                      : candidate.candidateProfile.profile.image
                  }
                  alt=""
                  className="h-24 w-24 flex-none rounded-lg bg-gray-200 object-cover"
                />
              )}
              {!theFile && (
                <div>
                  <button
                    type="button"
                    onClick={() => file.current.click()}
                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-800 shadow-sm hover:bg-gray-100"
                  >
                    Change avatar
                  </button>
                  <p className="mt-2 text-xs leading-5 text-gray-600">
                    JPG, GIF or PNG. 1MB max.
                  </p>
                  <input
                    ref={file}
                    type="file"
                    onChange={(e) => {
                      getFile(e.target.files[0]);
                    }}
                    className="hidden"
                    accept=".jpg,.jpeg,.png,.gif"
                    size={2 * 1024}
                    name=""
                    id=""
                  />
                </div>
              )}

              {theFile && (
                <div className="flex flex-col space-y-4 ">
                  <h1
                    onClick={() =>
                      saveImageCandidate(candidate.candidateProfile.id)
                    }
                    className="text-lg font-medium cursor-pointer active:text-sky-700 text-sky-500"
                  >
                    save image
                  </h1>
                  <h1
                    onClick={reset}
                    className="text-lg font-medium cursor-pointer active:text-red-700 text-red-500"
                  >
                    Cancel
                  </h1>
                </div>
              )}
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-6 text-gray-800"
              >
                First name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="first-name"
                  id="first-name"
                  defaultValue={candidate.firstName}
                  readOnly
                  autoComplete="given-name"
                  className="block pl-3 w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium leading-6 text-gray-800"
              >
                Last name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="last-name"
                  id="last-name"
                  defaultValue={candidate.lastName}
                  readOnly
                  autoComplete="family-name"
                  className="block pl-3 w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-full">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-800"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  readOnly
                  defaultValue={candidate.user.email}
                  autoComplete="email"
                  className="block pl-3 w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-full">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-800"
              >
                Phone
              </label>
              <div className="mt-2 relative">
                <div className="absolute inset-y-0 left-0 flex items-center">
                  <label htmlFor="prefix" className="sr-only">
                    Prefix
                  </label>
                  <select
                    id="prefix"
                    name="phone.prefix"
                    onChange={onUpdate}
                    value={temp.phone.prefix}
                    className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-sky-600 text-sm"
                  >
                    <option value={"+44"}>UK (+44)</option>
                    <option value={"+966"}>KSA (+966)</option>
                    <option value={"+971"}>UAE (+971)</option>
                  </select>
                </div>
                <input
                  id="phone"
                  name="phone.value"
                  type="tel"
                  value={temp.phone.value}
                  defaultValue={candidate.phone}
                  onChange={onUpdate}
                  autoComplete="phone"
                  className="block pl-32 w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-full">
              <label
                htmlFor="username"
                className="block text-sm font-medium leading-6 text-gray-800"
              >
                Username
              </label>
              <div className="mt-2">
                <div className="flex rounded-md bg-white border-gray-300 focus-within:ring-2 ring-1 ring-inset ring-gray-300 focus-within:ring-sky-500">
                  <span className="flex select-none items-center pl-3 text-gray-500 text-sm">
                    tempme.co/candidate/
                  </span>
                  <input
                    type="text"
                    name="username"
                    id="username"
                    defaultValue={`${candidate.firstName}-${candidate.lastName}`}
                    readOnly
                    autoComplete="username"
                    className="flex-1 border-0 overflow-clip lowercase bg-transparent py-1.5 pl-1 text-gray-800 focus:ring-0 text-sm sm:leading-6"
                    placeholder="janesmith"
                  />
                </div>
              </div>
            </div>
            <div className="col-span-full">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium leading-6 text-gray-800"
              >
                Title
                <span className="text-red-600 after:content-['*']"></span>
              </label>
              <div className="relative mt-2 rounded-md shadow-sm">
                <input
                  type="text"
                  name="title"
                  onChange={onUpdate}
                  defaultValue={candidate.title}
                  id="title"
                  className={`block pl-3 w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ${
                    candidate.title ? "ring-gray-300" : "ring-red-500"
                  }  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 text-sm sm:leading-6`}
                  placeholder="Fashion"
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                  <PencilIcon
                    className="h-5 w-5 text-gray-400  hover:text-sky-600 cursor-pointer"
                    onClick={() => setEnableEdit(!enableEdit)}
                  />
                </div>
              </div>
            </div>

            {enableEdit && (
              <>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium leading-6 text-gray-800"
                  >
                    Sectors
                  </label>
                  <div className="mt-2">
                    <select
                      id="sector"
                      name="sector"
                      onChange={(e) => setIndustry(e.target.value)}
                      className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-sky-600 text-sm sm:leading-6"
                      defaultValue={datas && datas[0].name}
                    >
                      {datas &&
                        datas.map((el) => (
                          <option key={el.name} value={JSON.stringify(el)}>
                            {el.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium leading-6 text-gray-800"
                  >
                    Sub sector
                  </label>
                  <div className="mt-2">
                    <select
                      id="title"
                      name="title"
                      onChange={onUpdate}
                      className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-sky-600 text-sm sm:leading-6"
                      defaultValue={
                        industry && JSON.parse(industry).subSector[0].name
                      }
                    >
                      {industry &&
                        JSON.parse(industry).subSector.map((sub) => (
                          <option key={sub.name}>{sub.name}</option>
                        ))}
                    </select>
                  </div>
                </div>
              </>
            )}

            <div className="col-span-full">
              <div>
                <label
                  htmlFor="price"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Hourly rate
                  <span className="text-red-600 after:content-['*']"></span>
                </label>
                <div className="relative mt-2 ">
                  {/* <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 text-sm">
                      {temp?.hourlyRate?.currency
                        ? temp?.hourlyRate?.currency?.symbol
                        : candidate?.hourlyRate?.currency?.symbol
                        ? candidate?.hourlyRate?.currency?.symbol
                        : "£"}
                    </span>
                  </div> */}
                  {/*   <input
                    type="text"
                    name="hourlyRate.value"
                    value={temp.hourlyRate?.value}
                    onChange={onUpdate}
                    defaultValue={candidate.hourlyRate?.value}
                    id="price"
                    className={`block w-full rounded-md border-0 py-1.5 pl-12 pr-20 text-gray-900 ring-1 ring-inset ${
                      candidate.hourlyRate ? "ring-gray-300" : "ring-red-500"
                    } placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 text-sm sm:leading-6`}
                    placeholder="0.00"
                  /> */}

                  <div className="flex flex-col justify-end items-end space-y-6">
                    <span className="p-2 border-2 rounded-lg text-gray-500 text-sm">
                      {candidate?.hourlyRate?.currency?.code && (
                        <span className="text-gray-500 text-sm">
                          {temp.hourlyRate?.value} &nbsp;
                          {temp?.hourlyRate?.currency
                            ? temp?.hourlyRate?.currency?.code + "/H"
                            : candidate?.hourlyRate?.currency?.code + "/H"
                            ? candidate?.hourlyRate?.currency?.code + "/H"
                            : "GBP/H"}
                        </span>
                      )}

                      {!candidate?.hourlyRate?.currency?.code && (
                        <>
                          <label htmlFor="currency" className="sr-only">
                            Currency
                          </label>
                          {temp.hourlyRate?.value} &nbsp;
                          <select
                            id="currency"
                            onChange={onUpdate}
                            name="hourlyRate.currency"
                            autoFocus={!candidate.hourlyRate?.currency}
                            className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-sky-600 text-sm"
                          >
                            {currency &&
                              currency.map((val) => (
                                <option value={JSON.stringify(val)}>
                                  {val.code}/H
                                </option>
                              ))}
                          </select>
                        </>
                      )}
                    </span>
                    <input
                      id="default-range"
                      type="range"
                      step={0.5}
                      name="hourlyRate.value"
                      value={temp.hourlyRate?.value}
                      onChange={onUpdate}
                      defaultValue={candidate.hourlyRate?.value}
                      required
                      class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-full">
              <label
                htmlFor="price"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Prefered distance to work
                <span className="text-red-600 after:content-['*']"></span>
              </label>
              <div className="relative mt-2 ">
                <div className="flex flex-col justify-end items-end space-y-6">
                  <span className="p-2 border-2 rounded-lg text-gray-500 text-sm">
                    {temp?.radius} Km away
                  </span>
                  <input
                    id="default-range"
                    type="range"
                    name="radius"
                    step={0.5}
                    onChange={onUpdate}
                    value={temp?.radius}
                    required
                    defaultValue={candidate.radius}
                    class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                  />
                </div>
                {/* <input
                  type="text"
                  name="radius"
                  id="price"
                  onChange={onUpdate}
                  value={temp?.radius}
                  defaultValue={candidate.radius}
                  required
                  className={`block w-full rounded-md border-0 py-1.5 pl-4 pr-12 text-gray-900 ring-1 ring-inset  ${
                    candidate.hourlyRate ? "ring-gray-300" : "ring-red-500"
                  } placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 text-sm sm:leading-6`}
                  placeholder="0.00"
                  aria-describedby="price-currency"
                />
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <span
                    className="text-gray-500 text-sm"
                    id="price-currency"
                  >
                    Km away
                  </span>
                </div> */}
              </div>
            </div>
          </div>

          <div className="mt-8 flex">
            <button
              disabled={isChanged()}
              onClick={saveCandidate}
              className="rounded-md disabled:bg-slate-500 disabled:opacity-50  bg-sky-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500"
            >
              save
            </button>
          </div>
        </form>
      </div>
      <div className="grid  grid-cols-1 gap-x-8  gap-y-4 md:gap-y-8 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-800">
            Location
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Please enter your home location for better experience
          </p>
        </div>

        <form className="md:col-span-2">
          <div className="grid grid-cols-1 gap-x-6 gap-y-4 md:gap-y-8  sm:grid-cols-6">
            <div className="col-span-3">
              <label
                for="street"
                className="block text-sm font-medium leading-6 text-gray-800"
              >
                Street
              </label>
              <div className="mt-2">
                <input
                  id="street"
                  name="address.street"
                  type="text"
                  defaultValue={candidate.address.street}
                  onChange={onUpdate}
                  autocomplete="street"
                  className="block pl-3 w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-3">
              <label
                for="city"
                className="block text-sm font-medium leading-6 text-gray-800"
              >
                City
              </label>
              <div className="mt-2">
                <input
                  id="city"
                  name="address.city"
                  onChange={onUpdate}
                  type="text"
                  defaultValue={candidate.address.city}
                  autocomplete="city"
                  className="block pl-3 w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-3">
              <label
                for="zip"
                className="block text-sm font-medium leading-6 text-gray-800"
              >
                Zip/Post code
              </label>
              <div className="mt-2">
                <input
                  id="address.zip"
                  name="address.zip"
                  onChange={onUpdate}
                  type="text"
                  defaultValue={candidate.address.zip}
                  autocomplete="zip"
                  className="block pl-3 w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-3">
              <label
                for="state"
                className="block text-sm font-medium leading-6 text-gray-800"
              >
                State
              </label>
              <div className="mt-2">
                <input
                  id="state"
                  name="address.state"
                  defaultValue={candidate.address.state}
                  onChange={onUpdate}
                  type="state"
                  autocomplete="state"
                  className="block pl-3 w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>

          <div className="mt-8 flex">
            <button
              disabled={isChanged()}
              onClick={saveCandidate}
              className="rounded-md disabled:bg-slate-500 disabled:opacity-50  bg-sky-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500"
            >
              save
            </button>
          </div>
        </form>
      </div>


      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-800">
            Change password
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Update your password associated with your account.
          </p>
        </div>

        <form onSubmit={handleChangePassword} className="md:col-span-2">
          {error.status === "failed" && (
            <div className="rounded-md bg-red-50 p-4 my-3">
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircleIcon
                    className="h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">
                    Something went wrong
                  </h3>
                  <div className="mt-2 text-sm text-red-700">
                    <ul role="list" className="list-disc space-y-1 pl-5">
                      <li>{error.content}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
            <div className="col-span-full">
              <label
                htmlFor="current-password"
                className="block text-sm font-medium leading-6 text-gray-800"
              >
                Current password
              </label>
              <div className="mt-2">
                <input
                  id="current-password"
                  name="current_password"
                  value={password_to_ckeck}
                  onChange={(e) =>
                    dispatch(setPasswordToCheck(e.currentTarget.value))
                  }
                  type="password"
                  required
                  autoComplete="current-password"
                  className="block pl-3 w-full rounded-md  border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 text-sm sm:leading-6"
                />
              </div>
            </div>

            {focus && (
              <div className="col-span-full">
                <p style={{ fontSize: "0.8rem" }}>
                  Password requirements: 8+ characters, 1 lowercase, 1
                  uppercase, 1 number, 1 special character.
                </p>
              </div>
            )}
            <div className="col-span-full">
              <label
                htmlFor="new-password"
                className="block text-sm font-medium leading-6 text-gray-800"
              >
                New password
              </label>
              <div className="mt-2">
                <input
                  value={new_password}
                  id="new-password"
                  name="new_password"
                  type="password"
                  onFocus={() => setFocus(true)}
                  onBlur={() => setFocus(false)}
                  required
                  pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}"
                  onChange={(e) =>
                    dispatch(setNewPassword(e.currentTarget.value))
                  }
                  autoComplete="new-password"
                  className="block pl-3 w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="col-span-full">
              <label
                htmlFor="confirm-password"
                className="block text-sm font-medium leading-6 text-gray-800"
              >
                Confirm password
              </label>
              <div className="mt-2">
                <input
                  value={confirm_password}
                  onFocus={() => setFocus(true)}
                  onBlur={() => setFocus(false)}
                  id="confirm-password"
                  name="confirm_password"
                  required
                  pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}"
                  onChange={(e) =>
                    dispatch(setConfirmPassword(e.currentTarget.value))
                  }
                  type="password"
                  autoComplete="new-password"
                  className="block pl-3 w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>

          <div className="mt-8 flex">
            <button
              type="submit"
              className="rounded-md bg-sky-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500"
            >
              Save
            </button>
          </div>
        </form>
      </div>
      <div className="grid  grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-800">
            Delete account
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            No longer want to use our service? You can delete your account here.
            This action is not reversible. All information related to this
            account will be deleted permanently.
          </p>
        </div>

        <div className="flex items-start md:col-span-2">
          <button
            onClick={() => dispatch(openDeleteModal())}
            className="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400"
          >
            Yes, delete my account
          </button>
        </div>
      </div>
    </div>
  );
}

export default Account;
