import React, { useState } from "react";
import useCurrentBreakpoint from "../../hook/tailwindBreakPoint";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { createSubUser, setCloseModal } from "../../redux/users";
import {
  setEmail,
  setFirstName,
  setLastName,
  setLocation,
  setPhone,
  setPrefix,
  setRole,
} from "../../redux/registration";
import { closeSideUser, createUserAdmin } from "../../redux/admin";

function CreateAdminUser() {
  const brk = useCurrentBreakpoint();
  const dispatch = useDispatch();
  const { userSide } = useSelector((state) => state.adminSlice);

  const { firstName, lastName, phone, email, prefix, location } = useSelector(
    (state) => state.RegistrationSlice
  );

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(createUserAdmin());
  }

  return (
    <Transition.Root show={userSide} as={Fragment} appear>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => dispatch(closeSideUser())}
      >
        <Transition.Child
          as={Fragment}
          enter={(brk !== "sm" || brk !== "xs") && "ease-out duration-300"}
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave={(brk !== "sm" || brk !== "xs") && "ease-in duration-200"}
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed flex justify-center inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto py-20 px-4  md:p-20">
          <Transition.Child
            as={Fragment}
            enter={(brk !== "sm" || brk !== "xs") && "ease-out duration-300"}
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave={(brk !== "sm" || brk !== "xs") && "ease-in duration-200"}
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-2xl  transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <div class="bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-gray-800 dark:border-gray-700">
                <div class="p-4 sm:p-7">
                  <div class="text-center">
                    <h2 class="block text-2xl font-bold text-gray-800 dark:text-gray-200">
                      Add a new admin member
                    </h2>
                    <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
                      Register a new member
                    </p>
                  </div>

                  <div class="mt-5">
                    <form
                      onSubmit={handleSubmit}
                      className="gap-y-4 gap-x-3 grid grid-cols-6 "
                    >
                      <div className="col-span-3">
                        <label
                          htmlFor="firstName"
                          className="block text-xs xs:text-sm font-medium leading-6 text-gray-900"
                        >
                          First name
                        </label>
                        <div className="mt-2">
                          <input
                            id="firstName"
                            name="firstName"
                            value={firstName}
                            onChange={(e) => dispatch(setFirstName(e))}
                            type="text"
                            autoComplete="first-name"
                            required
                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600  text-xs xs:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="col-span-3">
                        <label
                          htmlFor="lastName"
                          className="block text-xs xs:text-sm font-medium leading-6 text-gray-900"
                        >
                          Last name
                        </label>
                        <div className="mt-2">
                          <input
                            id="lastName"
                            name="lastName"
                            value={lastName}
                            type="text"
                            onChange={(e) => dispatch(setLastName(e))}
                            autoComplete="last-name"
                            required
                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600  text-xs xs:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="col-span-full">
                        <label
                          htmlFor="phone"
                          className="block text-xs xs:text-sm font-medium leading-6 text-gray-900"
                        >
                          Phone
                        </label>
                        <div className="mt-2 relative">
                          <div className="absolute inset-y-0 left-0 flex items-center">
                            <label htmlFor="prefix" className="sr-only">
                              Prefix
                            </label>
                            <select
                              id="prefix"
                              name="phone.prefix"
                              onChange={(e) => dispatch(setPrefix(e))}
                              value={prefix}
                              className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-sky-600 md:text-sm text-xs"
                            >
                              <option value={"+44"}>UK (+44)</option>
                              <option value={"+966"}>KSA (+966)</option>
                              <option value={"+971"}>UAE (+971)</option>
                            </select>
                          </div>
                          <input
                            id="phone"
                            name="phone"
                            value={phone}
                            type="tel"
                            onChange={(e) => dispatch(setPhone(e))}
                            autoComplete="phone-number"
                            required
                            className="block w-full pl-32 rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600  text-xs xs:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="col-span-full">
                        <label
                          htmlFor="email"
                          className="block text-xs xs:text-sm font-medium leading-6 text-gray-900"
                        >
                          Email address
                        </label>
                        <div className="mt-2">
                          <input
                            id="email"
                            name="email"
                            value={email}
                            type="email"
                            onChange={(e) => dispatch(setEmail(e))}
                            autoComplete="email"
                            required
                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600  text-xs xs:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="col-span-full">
                        <label
                          htmlFor="location"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Role
                        </label>
                        <select
                          id="location"
                          name="location"
                          disabled
                          onChange={(e) => dispatch(setRole(e))}
                          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          defaultValue="ADMIN"
                        >
                          <option value={"ADMIN"}>ADMIN</option>
                        </select>
                      </div>

                      <div className="col-span-full my-5">
                        <div className="relative">
                          <div
                            className="absolute inset-0 flex items-center"
                            aria-hidden="true"
                          >
                            <div className="w-full border-t border-gray-300" />
                          </div>
                          <div className="relative flex justify-center">
                            <span className="bg-white px-2 text-sm text-gray-500">
                              Extra data
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-full">
                        <label
                          htmlFor="email"
                          className="block text-xs xs:text-sm font-medium leading-6 text-gray-900"
                        >
                          Location
                        </label>
                        <div className="mt-2">
                          <input
                            id="email"
                            name="email"
                            value={location}
                            type="text"
                            onChange={(e) =>
                              dispatch(setLocation(e.currentTarget.value))
                            }
                            autoComplete="email"
                            required
                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600  text-xs xs:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="col-span-full mt-5">
                        <button
                          type="submit"
                          className="flex w-full justify-center disabled:bg-gray-300 rounded-md bg-sky-600 px-3 py-1.5 text-xs xs:text-sm font-semibold leading-6 text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                        >
                          Add new member
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default CreateAdminUser;
