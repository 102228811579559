import { useDispatch, useSelector } from "react-redux";
import { setPage } from "../../../redux/shift";
import { Link } from "react-router-dom";
import moment from "moment";
import Application from "../../../Enum/Applicatition";
import { setShiftDate } from "../../../redux/application";
import { ArrowDownTrayIcon } from "@heroicons/react/20/solid";
import MenuFinance from "./menu";

export default function TableFinance() {
  const { shifts, limit, page } = useSelector((state) => state.ShiftSlice);
  const { company } = useSelector((state) => state.companySlice);
  const dispatch = useDispatch();
  const start = (page - 1) * 10 + 1;
  const end = Math.min(page * 10, shifts.total);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-sky-900">
           Shift transactions
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A table of payment and shift done
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Link
            to={"/jobs"}
            type="button"
            className="block rounded-md bg-sky-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
          >
            Post a job
          </Link>
        </div>
      </div>

      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Shift ID
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Candidate
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Postion
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Hourly Rate
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Total hour
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    status
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Net amount
                  </th>
                  <th
                    scope="col"
                    className="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-0"
                  >
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {shifts.shifts &&
                  shifts.shifts.map((transaction) => (
                    <tr key={transaction.id}>
                      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                        <div className="flex flex-col">
                          <span> # {transaction.id}</span>
                          <span className="mt-2">
                            {transaction.paid && (
                              <span className="inline-flex items-center rounded-md bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700">
                                Paid
                              </span>
                            )}
                            {!transaction.paid &&
                              !["NO_SHOW", "CANCEL"].includes(
                                transaction.status
                              ) && (
                                <span className="inline-flex  items-center rounded-md bg-gray-100 px-1.5 py-0.5 text-xs font-medium text-gray-600">
                                  Pending
                                </span>
                              )}
                          </span>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                        {[
                          transaction.application.candidate.firstName,
                          transaction.application.candidate.lastName,
                        ].join(" ")}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                        <div className="flex flex-col">
                          <span>{transaction.application.job.jobName}</span>
                          <span className="text-xs  text-gray-400">
                            {moment(
                              ` ${transaction.application.job.startDate}  ${transaction.application.job.startHour}`,
                              "YYYY-MM-DD hh:mm A"
                            ).fromNow()}
                          </span>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {[
                          company?.currency?.code,
                          parseFloat(
                            transaction.application?.job?.hourlyRate
                          ).toFixed(2),
                        ].join(" ")}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {parseFloat(
                          transaction.application.job.totalHour
                        ).toFixed(2)}{" "}
                        H
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {transaction.status === "VALID" && (
                          <span className="inline-flex items-center rounded-md bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700">
                            Valid
                          </span>
                        )}
                        {["NO_SHOW", "CANCEL"].includes(transaction.status) && (
                          <span className="inline-flex  items-center rounded-md bg-gray-100 px-1.5 py-0.5 text-xs font-medium text-gray-600">
                            Cancelled
                          </span>
                        )}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {[
                          company?.currency?.code,
                          parseFloat(
                            transaction.application.job.totalHour *
                              transaction.application?.job?.hourlyRate
                          ).toFixed(2),
                        ].join(" ")}
                      </td>

                      <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        <MenuFinance transaction={transaction} />
                        {/*       <a
                          href={`${
                            transaction.paid
                              ? transaction.tracking?.invoice?.url
                              : ""
                          }`}
                          className={`text-sky-600 hover:text-sky-900 cursor-pointer `}
                        >
                          {!transaction.paid && "Pay"}
                          {transaction.paid && (
                            <ArrowDownTrayIcon className="w-5 h-5 mx-auto" />
                          )}

                          <span className="sr-only">, {transaction.id}</span>
                        </a> */}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <nav
              className="flex  w-full items-center justify-between  bg-white px-4 py-3 sm:px-6"
              aria-label="Pagination"
            >
              <div className="hidden sm:block">
                <p className="text-sm text-gray-700">
                  Showing <span className="font-medium">{start}</span> to{" "}
                  <span className="font-medium">{end}</span> of{" "}
                  <span className="font-medium">{shifts.total}</span> results
                </p>
              </div>
              <div className="flex flex-1 justify-between sm:justify-end">
                <button
                  onClick={() => dispatch(setPage(page - 1))}
                  disabled={start === 1}
                  className=" disabled:bg-slate-400/40 disabled:text-white relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                >
                  Previous
                </button>
                <button
                  disabled={end === shifts.total}
                  onClick={() => dispatch(setPage(page + 1))}
                  className="disabled:bg-slate-400/40 disabled:text-white relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                >
                  Next
                </button>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
