import { PlusIcon, ArchiveBoxIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import useIndustries from "../../../hook/industry";
import { Link, useParams } from "react-router-dom";
import { SpinnerLoadData } from "../../../components/utils/spinner-load";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useDispatch } from "react-redux";
import { updateIndustry } from "../../../redux/admin";
import GoBack from "../../../components/utils/goBack";

function Industries() {
  const industries = useIndustries();
  const [selected, setSelected] = useState([]);
  const [show, setSelectShow] = useState("");
  const [skill_name, setSkillName] = useState("");
  const [sub_sector_name, setSub_sector_name] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();

  function handleSaveSkill() {
    dispatch(
      updateIndustry({
        id: selected.id,
        params: {
          skills: [skill_name, ...selected.skills],
        },
      })
    );

    setSelectShow("skills");
    setSkillName("");
  }

  function deleteSkill(index) {
    dispatch(
      updateIndustry({
        id: selected.id,
        params: {
          skills: selected.skills.filter((sk, ix) => ix !== index),
        },
      })
    );
  }
  function handleSaveSub_sector() {
    const lastID = selected.subSector[selected.subSector.length - 1].id;

    dispatch(
      updateIndustry({
        id: selected.id,
        params: {
          subSector: [
            { id: lastID + 1, name: sub_sector_name },
            ...selected.subSector,
          ],
        },
      })
    );

    setSelectShow("sub_sector");
    setSub_sector_name("");
  }

  function deleteSubSector(index) {
    dispatch(
      updateIndustry({
        id: selected.id,
        params: {
          subSector: selected.subSector.filter((ss, ix) => ix !== index),
        },
      })
    );
  }

  useEffect(() => {
    if (industries.datas) {
      setSelected(industries.datas[id - 1]);
    }
  }, [industries.datas, id]);

  console.log(selected);

  return !industries.datas && !selected ? (
    <SpinnerLoadData />
  ) : (
    <>
      <GoBack />
      <h1 className="text-2xl font-extralight text-sky-600 my-9 ">
        Industries mangement
      </h1>
      <div
        className={`grid transition-all ease-in-out duration-500  ${
          ["sub_sector", "skills", "new_skill", "new_sub_sector"].includes(show)
            ? "grid-cols-5 "
            : ""
        } gap-4  `}
      >
        <div className=" col-span-3 transition-all ease-linear duration-200 max-h-[calc(100vh-10rem)] overflow-auto  relative pe-4  ">
          <h1>sector(s)</h1>
          <div className="mt-10 space-y-6">
            {industries.datas &&
              industries.datas.map((el, index) => (
                <Link
                  to={`/utils/industries/${el.id}`}
                  className={`rounded border border-dashed p-12 ${
                    index === id - 1 ? " bg-slate-50 text-sky-600" : ""
                  } hover:bg-slate-50 hover:text-sky-600 inline-flex w-full `}
                >
                  <h1 className="text-base font-medium w-full shrink">
                    {el.name}
                  </h1>

                  <div className=" space-x-2 flex ">
                    <button
                      type="button"
                      onClick={() => setSelectShow("skills")}
                      className="rounded bg-sky-50 whitespace-nowrap px-2 py-1 text-sm font-semibold text-rose-600 shadow-sm hover:bg-sky-100"
                    >
                      Skills
                    </button>
                    <button
                      type="button"
                      onClick={() => setSelectShow("sub_sector")}
                      className="rounded bg-sky-50 whitespace-nowrap px-2 py-1 text-sm font-semibold text-sky-600 shadow-sm hover:bg-sky-100"
                    >
                      Sub-sectors
                    </button>
                    <button
                      type="button"
                      onClick={() => setSelectShow("")}
                      className="rounded bg-sky-50 whitespace-nowrap px-2 py-1 text-sm font-semibold text-red-600 shadow-sm hover:bg-sky-100"
                    >
                      <ArchiveBoxIcon className="w-6 h-6" />
                    </button>
                  </div>
                </Link>
              ))}
          </div>

          {/*   <div className="top-0 absolute cursor-pointer hover:border  rounded-full  p-1 mb-10 right-2">
            <PlusIcon className="w-6 h-6  hover:text-gray-300  text-gray-500 " />
          </div> */}
        </div>
        <div
          className={`col-span-2 ${
            ["sub_sector"].includes(show) ? "" : "hidden"
          } transition-all ease-in-out duration-500 max-h-[calc(100vh-10rem)] overflow-auto   pe-4 relative `}
        >
          <h1>Sub-sector(s)</h1>
          <div className="mt-10 space-y-3">
            {selected &&
              selected?.subSector?.map((el, i) => (
                <div className="rounded border border-dashed p-4 mt-  inline-flex w-full ">
                  <h1 className="text-base font-medium w-full shrink">
                    {el.name}
                  </h1>

                  <div className=" space-x-2 inline-flex ">
                    <button
                      type="button"
                      onClick={() => deleteSubSector(i)}
                      className="rounded bg-sky-50 whitespace-nowrap px-2 py-1 text-sm font-semibold text-red-600 shadow-sm hover:bg-sky-100"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))}
          </div>

          <div
            onClick={() => setSelectShow("new_sub_sector")}
            className="top-0 absolute cursor-pointer hover:border  rounded-full  p-1  mb-10 right-2"
          >
            <PlusIcon className="w-6 h-6  hover:text-gray-300  text-gray-500 " />
          </div>
        </div>

        <div
          className={`col-span-2 ${
            ["skills"].includes(show) ? "" : "hidden"
          } relative  transition-all ease-in-out duration-500  max-h-[calc(100vh-10rem)] overflow-auto`}
        >
          <h1>Skill(s)</h1>
          <div className="mt-10 space-y-3">
            {selected &&
              selected?.skills?.map((el, i) => (
                <div className="rounded border border-dashed p-4 mt-  inline-flex w-full ">
                  <h1 className="text-base font-medium w-full shrink">{el}</h1>

                  <div className=" space-x-2 inline-flex ">
                    <button
                      onClick={() => deleteSkill(i)}
                      type="button"
                      className="rounded bg-sky-50 whitespace-nowrap px-2 py-1 text-sm font-semibold text-red-600 shadow-sm hover:bg-sky-100"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))}
          </div>

          <div
            onClick={() => setSelectShow("new_skill")}
            className="top-0 absolute cursor-pointer hover:border  rounded-full  p-1  right-2"
          >
            <PlusIcon className="w-6 h-6  hover:text-gray-300  text-gray-500 " />
          </div>
        </div>
        <div
          className={`col-span-2 ${
            ["new_skill"].includes(show) ? "" : "hidden"
          } relative  transition-all ease-in-out duration-500  max-h-[calc(100vh-10rem)] overflow-auto`}
        >
          <h1>New skill</h1>
          <div className="mt-10 space-y-3">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Skill name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="skill_name"
                  value={skill_name}
                  onChange={(e) => setSkillName(e.target.value)}
                  id="skill_name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                  placeholder="Communication"
                />
              </div>
            </div>
            <button
              type="button"
              onClick={handleSaveSkill}
              className="rounded-md bg-white px-3 py-2 text-sm mt-20 w-full font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Save skill
            </button>
          </div>

          <div
            onClick={() => setSelectShow("")}
            className="top-0 absolute cursor-pointer hover:border  rounded-full  p-1  right-2"
          >
            <XMarkIcon className="w-6 h-6  hover:text-gray-300  text-gray-500 " />
          </div>
        </div>
        <div
          className={`col-span-2 ${
            ["new_sub_sector"].includes(show) ? "" : "hidden"
          } relative  transition-all ease-in-out duration-500  max-h-[calc(100vh-10rem)] overflow-auto`}
        >
          <h1>New sub-sector</h1>
          <div className="mt-10 space-y-3">
            <div>
              <label
                htmlFor="sub_sector_name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Sub-sector name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  value={sub_sector_name}
                  onChange={(e) => setSub_sector_name(e.target.value)}
                  name="sub_sector_name"
                  id="sub_sector_name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                  placeholder="Hotels"
                />
              </div>
            </div>
            <button
              onClick={() => handleSaveSub_sector()}
              type="button"
              className="rounded-md bg-white px-3 py-2 text-sm mt-20 w-full font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Save sub-sector
            </button>
          </div>

          <div
            onClick={() => setSelectShow("")}
            className="top-0 absolute cursor-pointer hover:border  rounded-full  p-1  right-2"
          >
            <XMarkIcon className="w-6 h-6  hover:text-gray-300  text-gray-500 " />
          </div>
        </div>
        {/*
         */}
      </div>
    </>
  );
}

export default Industries;
