import React, { useEffect, useState } from "react";
import useCandidate from "../../../hook/candidate";

import Account from "./Account";
import Notifications from "./Notifications";
import Finance from "./Finance";
import { useDispatch, useSelector } from "react-redux";
import { setStage } from "../../../redux/navigation";
import { useTableFinanceCandidate } from "../../../hook/shift";
import { usePayouts } from "../../../hook/stripe";
import { StripeDashboard } from "../Finance/stripe-dashbord";
import { useLocation, useSearchParams } from "react-router-dom";
import DocumentID from "../../../landing-page/document-id";
import { useUpdateProfile } from "../../../hook/profile";
import GoBack from "../../../components/utils/goBack";
import { SpinnerLoadData } from "../../../components/utils/spinner-load";

function Profile() {
  const { datas } = useCandidate();
  const { candidate, onLoad } = useSelector((state) => state.candidateSlice);
  const { stage } = useSelector((state) => state.NavigationSlice);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const secondaryNavigation = [
    { name: "Account", href: 0, current: stage === 0 },
    { name: "Notifications", href: 1, current: stage === 1 },
    { name: "Finance", href: 2, current: stage === 2 },
    { name: "ID Document", href: 3, current: stage === 3 },
    { name: "Payouts", href: 4, current: stage === 4 },
  ];

  const data = JSON.parse(JSON.stringify(candidate));
  const {
    onUpdate,
    isChanged,
    saveCandidate,
    temp,
    saveUserCandidate,
    isChangedDocument,
    load,
  } = useUpdateProfile(data, data);

  usePayouts();
  useTableFinanceCandidate();
  const location = useLocation();

  useEffect(() => {
    const tab = parseInt(searchParams.get("tab"));

    console.warn(tab);

    dispatch(setStage(tab));
  }, [searchParams.get("tab")]);

  return datas ? (
    <main className="md:max-w-7xl w-sm overflow-hidden">
      <header className="border-b border-gray-300">
        {/* Secondary navigation */}
        <nav className="flex overflow-x-auto py-4">
          <ul
            role="list"
            className="flex items-center  flex-none gap-x-6 px-4 text-sm font-semibold leading-6 cursor-pointer text-gray-600 sm:px-6 lg:px-8"
          >
            <li key={"back"}>
              <GoBack />
            </li>
            {secondaryNavigation
              .filter((el) =>
                datas &&
                datas.user &&
                datas.user.stripe &&
                datas.user.stripe.requirements.currently_due.length === 0
                  ? true
                  : el.href !== 4
              )
              .filter((el) =>
                datas && datas.user && !datas.user.stripe ? el.href !== 2 : true
              )

              .map((item) => (
                <li key={item.name}>
                  <a
                    href={item.href === "#id-document" ? item.href : null}
                    onClick={() =>
                      item.href === "#id-document"
                        ? dispatch(setStage(0))
                        : setSearchParams({
                            name: item.name,
                            tab: item.href,
                          })
                    }
                    className={
                      item.current ? "text-sky-400 relative " : "relative "
                    }
                  >
                    {datas &&
                      item.href === 3 &&
                      (!datas?.user?.legalDocumentId?.path ||
                        !datas?.user?.legalDocumentId?.documentNumber ||
                        !datas?.user?.legalDocumentId?.issueDate ||
                        !datas?.user?.legalDocumentId?.expiryDate) && (
                        <span class="absolute top-0 -right-1 inline-flex items-center w-3.5 h-3.5 rounded-full border-2 border-white text-xs font-medium transform -translate-y-1/2 translate-x-1/2 bg-red-500 text-white">
                          <span class="sr-only">Badge value</span>
                        </span>
                      )}

                    {datas &&
                      ((item.href === 2 && datas.user && !datas.user.stripe) ||
                        (datas.user.stripe &&
                          datas.user.stripe.requirements.currently_due.length >
                            0 &&
                          item.href === 2)) && (
                        <span class="absolute top-0 -right-1 inline-flex items-center w-3.5 h-3.5 rounded-full border-2 border-white text-xs font-medium transform -translate-y-1/2 translate-x-1/2 bg-red-500 text-white">
                          <span class="sr-only">Badge value</span>
                        </span>
                      )}

                    {item.name}
                  </a>
                </li>
              ))}
          </ul>
        </nav>
      </header>

      {/* Settings forms */}

      {datas && stage === 0 && <Account />}
      {datas && stage === 1 && <Notifications />}
      {datas && stage === 2 && <Finance />}
      {datas &&
        stage === 4 &&
        datas.user.stripe &&
        datas.user.stripe.requirements.currently_due.length === 0 && (
          <StripeDashboard />
        )}
      {datas && stage === 3 && (
        <DocumentID
          onUpdate={onUpdate}
          save={saveUserCandidate}
          temp={temp}
          isChangedDocument={isChangedDocument}
        />
      )}
    </main>
  ) : (
    <SpinnerLoadData />
  );
}

export default Profile;
