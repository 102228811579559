import { Fragment, useCallback, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";
import useCompanies from "../../hook/companies";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCompanies, setPage } from "../../redux/company";
import { baseUrl } from "../../utils";
import { Link } from "react-router-dom";
import { fetchOneCompany } from "../../redux/details";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import Search from "../search/search";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function OursCompanies(props) {
  const { companies, limit, page, loading } = useSelector(
    (state) => state.companySlice
  );
  const { query, type } = useSelector((state) => state.UserSlice);
  const dispatch = useDispatch();
  const fetchData = useCallback(() => {
    dispatch(fetchAllCompanies());
  }, [dispatch]);

  useEffect(() => {
    if (type === "comp" && query) {
      fetchData();
    }
  }, [fetchData, query]);

  useEffect(() => {
    fetchData();
  }, [fetchData, page, limit]);

  useEffect(() => {
    if (type === "comp") {
      dispatch(setPage(1));
    }
  }, [query]);

  const start = (page - 1) * limit + 1;
  const end = Math.min(page * limit, companies.total);

  return (
    <div {...props}>
      <h1 className=" text-2xl font-extralight text-sky-600">Ours companies</h1>
      <Search type={"comp"} />
      <nav
        className="flex  w-full items-center justify-between  bg-white px-4 py-3 sm:px-6"
        aria-label="Pagination"
      >
        <div className="flex flex-1 justify-between items-center sm:justify-end">
          <div className="hidden sm:block me-4">
            <p className="text-sm text-gray-700">
              <span className="font-medium">{start}</span> -
              <span className="font-medium">{end}</span> /
              <span className="font-medium">{companies.total}</span> results
            </p>
          </div>
          <button
            onClick={() => dispatch(setPage(page - 1))}
            disabled={start === 1}
            className=" disabled:bg-slate-400/40 disabled:text-white relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
          >
            <ChevronLeftIcon className="w-4 h-4" />
          </button>
          <button
            disabled={end === companies.total}
            onClick={() => dispatch(setPage(page + 1))}
            className="disabled:bg-slate-400/40 disabled:text-white relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
          >
            <ChevronRightIcon className="w-4 h-4" />
          </button>
        </div>
      </nav>

      <ul
        role="list"
        className="grid grid-cols-1  mt-4 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8"
      >
        {!loading &&
          companies.datas &&
          companies.datas.map((client) => (
            <li
              key={client.id}
              className="overflow-hidden rounded-xl border border-gray-200"
            >
              <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                <img
                  src={client.logo}
                  alt={client.name}
                  className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
                />
                <div className="text-sm font-medium leading-6 text-gray-900">
                  {client.name}
                </div>
                <Menu as="div" className="relative ml-auto">
                  <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                    <span className="sr-only">Open options</span>
                    <EllipsisHorizontalIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to={`/company/${client.name}`}
                            className={classNames(
                              active ? "bg-gray-50" : "",
                              "block px-3 py-1 text-sm leading-6 text-gray-900"
                            )}
                            onClick={() =>
                              dispatch(fetchOneCompany(client?.id))
                            }
                          >
                            View<span className="sr-only">, {client.name}</span>
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to={`/user/${client.userId}`}
                            className={classNames(
                              active ? "bg-gray-50" : "",
                              "block px-3 py-1 text-sm leading-6 text-gray-900"
                            )}
                          >
                            Edit<span className="sr-only">, {client.name}</span>
                          </Link>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                <div className="flex justify-between gap-x-4 py-3">
                  <dt className="text-gray-500">Last invoice</dt>
                  <dd className="text-gray-700">
                    <time>
                      {" "}
                      {client?.lastInvoice &&
                        new Date(
                          client?.lastInvoice?.created * 1000
                        ).toLocaleDateString()}{" "}
                      {!client?.lastInvoice && "---"}
                    </time>
                  </dd>
                </div>
                <div className="flex justify-between gap-x-4 py-3">
                  <dt className="text-gray-500">Amount</dt>
                  <dd className="flex items-start gap-x-2">
                    <div className="font-medium text-gray-900"></div>
                    <div
                      className={classNames(
                        "rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset"
                      )}
                    >
                      {client?.lastInvoice &&
                        [
                          client?.lastInvoice?.currency.toUpperCase(),
                          (client?.lastInvoice?.amount_due / 100).toFixed(2),
                        ].join(" ")}
                      {!client?.lastInvoice && "00.00"}
                    </div>
                  </dd>
                </div>
              </dl>
            </li>
          ))}
      </ul>
    </div>
  );
}
