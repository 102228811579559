import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Typewriter from "typewriter-effect/dist/core";
import { connect } from "../redux/authentication";

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
export default function Login() {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const dispatch = useDispatch();
  const [showP, setShowP] = useState(false);
  const [subUser, setSubUser] = useState(false);

  const { error, role, loading } = useSelector(
    (state) => state.authenticationSlice
  );

  useEffect(() => {
    new Typewriter("#typewriter", {
      strings: [
        "Empower Your Future, One Shift at a Time!",
        "Unlock Opportunities. Redefine Your Potential.",
        "Step Forward, Shape Your Destiny Today!",
      ],
      autoStart: true,
      loop: true,
    });
  }, []);

  function SubUserEnabled() {
    setSubUser(true);
  }
  function SubUserDisabled() {
    setSubUser(false);
  }

  return (
    <>
      <div className="flex h-screen min-h-full flex-1">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none relative lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96 ">
            <div>
              <Link to={"/"}>
                <img
                  className="h-10 w-auto"
                  src={require("../assets/img/logo.png")}
                  alt="logo"
                />
              </Link>

              {subUser ? (
                <>
                  <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Company sub user
                  </h2>
                  <p className="text-sm leading-6 text-gray-500">
                    Use the login you've received from your company
                  </p>
                </>
              ) : (
                <>
                  <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Sign in to your account
                  </h2>
                  <p className="mt-2 text-sm leading-6 text-gray-500">
                    Not a member?{" "}
                    <Link
                      to={"/registration"}
                      className="font-semibold text-sky-600 hover:text-sky-500"
                    >
                      Become a Temper today!
                    </Link>
                  </p>
                </>
              )}
            </div>

            <div className="mt-10">
              <div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    dispatch(
                      connect({
                        email: email,
                        password: password,
                        sub_user: subUser,
                      })
                    );
                  }}
                  className="space-y-6"
                >
                  {error && (
                    <div className="rounded-md bg-red-50 p-4">
                      <div className="flex">
                        <div className="flex-shrink-0"></div>
                        <div className="ml-3">
                          <h3 className="text-sm font-medium text-red-800">
                            {error}
                          </h3>
                        </div>
                      </div>
                    </div>
                  )}

                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email address / user ID
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        autoComplete="email"
                        required
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Password
                    </label>
                    <div className="mt-2 relative">
                      <input
                        id="password"
                        name="password"
                        type={showP ? "text" : "password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="current-password"
                        required
                        className="block  w-full pr-10 rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        onClick={() => setShowP((s) => !s)}
                        className="w-5 h-5 hover:text-indigo-300  text-gray-500 absolute top-2 right-2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                      />

                      <label
                        htmlFor="remember-me"
                        className="ml-3 block text-sm leading-6 text-gray-700"
                      >
                        Remember me
                      </label>
                    </div>

                    <div className="text-sm leading-6">
                      <Link
                        to={"/reset-password"}
                        className="font-semibold text-sky-600 hover:text-sky-500"
                      >
                        Forgot password?
                      </Link>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md bg-sky-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                    >
                      {loading ? (
                        <>
                          <svg
                            aria-hidden="true"
                            class="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-white dark:fill-gray-500"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <span class="sr-only">Loading...</span>
                        </>
                      ) : (
                        <> Sign in</>
                      )}
                    </button>
                  </div>
                </form>

                <div className="flex justify-center mt-6">
                  {!subUser && (
                    <a
                      role="button"
                      className="mt-4 inline-flex justify-end items-center space-x-2  font-semibold underline text-sm  text-sky-600"
                      onClick={SubUserEnabled}
                    >
                      <span>Sign in as company sub-user</span>
                    </a>
                  )}

                  {subUser && (
                    <a
                      role="button"
                      className="mt-4 inline-flex justify-end items-center space-x-2 underline font-semibold text-sm  text-sky-600"
                      onClick={SubUserDisabled}
                    >
                      Not a company sub-user
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={require("../assets/img/illustration/waitress.jpeg")}
            alt=""
          />
          <div className="absolute bottom-2 w-[300px] rounded-md font-semibold  p-4 right-2 bg-white">
            <h1 className="text-2xl" id="typewriter">
              {" "}
              Choose to be the better version of yourself !
            </h1>
          </div>
        </div>
      </div>
    </>
  );
}
