import { BriefcaseIcon, BuildingOfficeIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useUsers } from "../../../hook/users";
import { baseUrl } from "../../../utils";
import Roles from "../../../Enum/UserRoles";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function LastUsers() {
  useUsers();
  const { users, page, limit } = useSelector((state) => state.UserSlice);

  return (
    <div class="flex -space-x-2">
      {users.users &&
        users.users.map((person) => (
          <img
            class="inline-block h-8 w-8 rounded-full ring-2 ring-white"
            src={
              person.roles && person.roles.includes(Roles.candidate)
                ? 
                    person.candidate?.candidateProfile?.profile?.image
                  
                : person.roles.includes(Roles.company)
                ? person?.company?.logo
                : require('../../../assets/img/gear.png')
            }
            alt="Image Description"
          />
        ))}

      <div class="hs-dropdown relative inline-flex [--placement:top-left]">
        <button
          id="hs-avatar-group-dropdown"
          class="hs-dropdown-toggle inline-flex items-center justify-center h-8 w-8 rounded-full bg-white border-2 border-white font-medium text-gray-700 shadow-sm align-middle hover:bg-gray-300 focus:outline-none focus:bg-blue-100 focus:text-blue-600 focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-800 dark:text-gray-400 dark:hover:text-white dark:focus:bg-blue-100 dark:focus:text-blue-600 dark:focus:ring-offset-gray-800"
        >
          <span class="font-medium leading-none">9+</span>
        </button>

        
      </div>
    </div>
  );
}

function Card({ title, extraContent, value }) {
  return (
    <div className="bg-white h-36 p-4 shadow-sm rounded-md border flex flex-col">
      <h1 className=" font-light  text-lg text-gray-600 ">{title}</h1>
      <h1 className="mt-4 text-2xl font-bold  inline-block bg-gradient-to-t from-indigo-700 text-transparent bg-clip-text to-sky-600 ">
        {value}
      </h1>
      <div className="inline-flex w-full justify-end"> {extraContent} </div>
    </div>
  );
}

export default function Stats() {
  const { stats } = useSelector((state) => state.UserSlice);

  return (
    <div class=" grid xl:grid-cols-4 gap-2 mx-auto mb-10">
      <Card
        title={"Total users"}
        value={stats && stats.totalUsers}
        extraContent={<LastUsers />}
      />
      <Card
        title={"Candidate"}
        value={[stats && stats.candidatePercentage, "%"].join(" ")}
        extraContent={
          <img
            src={require("../../../assets/img/illustration/selection_4308902.png")}
            className="w-8 h-8"
          />
        }
      />
      <Card
        title={"Companie"}
        value={[stats && stats.companyPercentage, "%"].join(" ")}
        extraContent={
          <img
            src={require("../../../assets/img/illustration/skycraper_1506605.png")}
            className="w-8 h-8"
          />
        }
      />
      <Card
        title={"Jobs"}
        value={[stats && stats.jobsCount].join(" ")}
        extraContent={
          <img
            src={require("../../../assets/img/illustration/job-search_537466.png")}
            className="w-8 h-8"
          />
        }
      />

      {/*    <div class="grid md:grid-cols-4 h-52 border border-gray-200 shadow-sm rounded-xl overflow-hidden dark:border-gray-700">
        <Link
          class="block p-4 md:p-5 relative bg-white hover:bg-gray-50 before:absolute before:top-0 before:left-0 before:w-full before:h-px md:before:w-px md:before:h-full before:bg-gray-200 before:first:bg-transparent dark:bg-slate-900 dark:hover:bg-slate-800 dark:before:bg-gray-700"
          to={"/users"}
        >
          <div class="flex md:grid lg:flex gap-y-3 gap-x-5">
            <svg
              class="flex-shrink-0 w-5 h-5 text-gray-500"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z" />
            </svg>

            <div class="grow">
              <p class="text-xs uppercase tracking-wide font-medium text-gray-800 dark:text-gray-200">
                Total users
              </p>
              <h3 class="mt-1 text-xl sm:text-2xl font-semibold text-sky-600 dark:text-sky-500">
               
              </h3>
            </div>
          </div>
        </Link>

        <Link
          class="block p-4 md:p-5 relative bg-white hover:bg-gray-50 before:absolute before:top-0 before:left-0 before:w-full before:h-px md:before:w-px md:before:h-full before:bg-gray-200 before:first:bg-transparent dark:bg-slate-900 dark:hover:bg-slate-800 dark:before:bg-gray-700"
          to={"/candidates"}
        >
          <div class="flex md:grid lg:flex gap-y-3 gap-x-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              class="flex-shrink-0 w-5 h-5 text-gray-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
              />
            </svg>

            <div class="grow">
              <p class="text-xs uppercase tracking-wide font-medium text-gray-800 dark:text-gray-200">
                Candidates
              </p>
              <h3 class="mt-1 text-xl sm:text-2xl font-semibold text-sky-600 dark:text-sky-500">
             
              </h3>
            </div>
          </div>
        </Link>

        <Link
          class="block p-4 md:p-5 relative bg-white hover:bg-gray-50 before:absolute before:top-0 before:left-0 before:w-full before:h-px md:before:w-px md:before:h-full before:bg-gray-200 before:first:bg-transparent dark:bg-slate-900 dark:hover:bg-slate-800 dark:before:bg-gray-700"
          to={"/companies"}
        >
          <div class="flex md:grid lg:flex gap-y-3 gap-x-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              class="flex-shrink-0 w-5 h-5 text-gray-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205l3 1m1.5.5l-1.5-.5M6.75 7.364V3h-3v18m3-13.636l10.5-3.819"
              />
            </svg>

            <div class="grow">
              <p class="text-xs uppercase tracking-wide font-medium text-gray-800 dark:text-gray-200">
                Companies
              </p>
              <h3 class="mt-1 text-xl sm:text-2xl font-semibold text-sky-600 dark:text-sky-500">
                {stats && stats.companyPercentage} %
              </h3>
            </div>
          </div>
        </Link>

        <a
          class="block p-4 md:p-5 relative bg-white hover:bg-gray-50 before:absolute before:top-0 before:left-0 before:w-full before:h-px md:before:w-px md:before:h-full before:bg-gray-200 before:first:bg-transparent dark:bg-slate-900 dark:hover:bg-slate-800 dark:before:bg-gray-700"
          href="#"
        >
          <div class="flex md:grid lg:flex gap-y-3 gap-x-5">
            <svg
              class="flex-shrink-0 w-5 h-5 text-gray-500"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
            </svg>

            <div class="grow">
              <p class="text-xs uppercase tracking-wide font-medium text-gray-800 dark:text-gray-200">
                Jobs
              </p>
              <h3 class="mt-1 text-xl sm:text-2xl font-semibold text-sky-600 dark:text-sky-500">
                {stats && stats.jobsCount}
              </h3>
            </div>
          </div>
        </a>
      </div> */}
    </div>

    /*   <dl className="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-4">
      {stats.map((stat) => (
        <div
          key={stat.name}
          className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8"
        >
          <dt className="text-sm font-medium leading-6 text-gray-500">
            {stat.name}
          </dt>
          <dd
            className={classNames(
              stat.changeType === "negative"
                ? "text-rose-600"
                : "text-gray-700",
              "text-xs font-medium"
            )}
          >
            {stat.change}
          </dd>
          <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
            {stat.value}
          </dd>
        </div>
      ))}
    </dl> */
  );
}
