import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function useGeolocation() {
  const { latitude, longitude } = useSelector(
    (state) => state.GeolocationSlice
  );
  const { candidate, onLoad } = useSelector((state) => state.candidateSlice);
  const dispatch = useDispatch();

  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  function success(pos) {
    const crd = pos.coords;

    dispatch({
      type: "GeolocationSlice/setLatitude",
      payload: crd.latitude,
    });

    dispatch({
      type: "GeolocationSlice/setLongitude",
      payload: crd.longitude,
    });
  }

  function error(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);

    if(candidate && candidate?.address.lat )
    {

       dispatch({
         type: "GeolocationSlice/setLatitude",
         payload: candidate?.address.lat,
       });

       dispatch({
         type: "GeolocationSlice/setLongitude",
         payload: candidate?.address.lng,
       });
    }

  }

  useEffect(() => {
    if (!window.ReactNativeWebView) 
    {
      navigator.geolocation.getCurrentPosition(success, error, options);
    }
  }, [onLoad]);

  // The empty array as the second argument makes this effect run only once on component mount
}
