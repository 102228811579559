import { Fragment, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { EllipsisVerticalIcon, PlusIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import useNotifications from "../../hook/notifications";
import Notification from "../../Enum/Notification";

import { baseUrl } from "../../utils";
import { Link } from "react-router-dom";
import { fetchOneCompany } from "../../redux/details";
import {
  updateApplication,
  updateNotification,
} from "../../redux/conversation";
import Roles from "../../Enum/UserRoles";
import moment from "moment";
import ReactStars from "react-rating-stars-component";
import { updateStarRatingCandidate } from "../../redux/shift";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Notifications() {
  const dispatch = useDispatch();
  const { notifOpen, notifications } = useSelector(
    (state) => state.conversationSlice
  );
  const { role } = useSelector((state) => state.authenticationSlice);

  const [tab, setTab] = useState(0);
  const [stars, setStars] = useState(1);

  const tabs = [
    { name: "All", href: 0, current: tab === 0, vA: true },
    { name: "Applications", href: 1, current: tab === 1, vA: true },
    { name: "Invitations", href: 2, current: tab === 2, vA: false },
  ];

  const confirmRating = (id) => {
    dispatch(
      updateStarRatingCandidate({
        id: id,
        params: {
          company: stars,
        },
      })
    );
  };

  console.log();

  return (
    <Transition.Root show={notifOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() =>
          dispatch({
            type: "conversation/setNotifOpen",
          })
        }
      >
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed top-12 bottom-0 right-0 flex max-w-full pl-0 md:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="p-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          Notifications
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-sky-500"
                            onClick={() =>
                              dispatch({
                                type: "conversation/setNotifOpen",
                              })
                            }
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="border-b border-gray-200">
                      <div className="px-6">
                        <nav className="-mb-px flex space-x-6">
                          {tabs.map(
                            (tab) =>
                              tab.vA && (
                                <a
                                  type="button"
                                  key={tab.name}
                                  onClick={() => setTab(tab.href)}
                                  className={classNames(
                                    tab.current
                                      ? "border-sky-400 text-sky-400"
                                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                    "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium cursor-pointer"
                                  )}
                                >
                                  {tab.name}
                                </a>
                              )
                          )}
                        </nav>
                      </div>
                    </div>
                    <ul
                      role="list"
                      className="flex-1 divide-y divide-gray-200 overflow-y-auto pb-16"
                    >
                      {notifications && notifications.length === 0 && (
                        <p className="px-2 mt-4 text-center animate-pulse font-semibold text-sky-800 text-sm">
                          In this section, you'll find all pending notifications
                          that require a response. Please note, notifications
                          related to jobs that are no longer available will be
                          automatically removed
                        </p>
                      )}
                      {notifications &&
                        notifications
                          .filter((notif) =>
                            tab === 1
                              ? role === Roles.candidate
                                ? [
                                    Notification.REJECTED,
                                    Notification.NEED_RECONF,
                                  ].includes(notif.message)
                                : [
                                    Notification.NEW_APPLICATION,
                                    Notification.RECONFIRMED,
                                    Notification.NOT_RECONFIRMED,
                                  ].includes(notif.message)
                              : tab === 2
                              ? role === Roles.candidate
                                ? [
                                    "INVITATION_APPLY",
                                    Notification.INVITE,
                                  ].includes(notif.message)
                                : ""
                              : true
                          )
                          .map((item) => (
                            <li
                              key={item.handle}
                              onClick={() =>
                                dispatch(
                                  updateNotification({
                                    id: item.id,
                                    params: { isRead: true },
                                  })
                                )
                              }
                              className={` cursor-pointer ${
                                item.isRead ? "" : "bg-slate-100"
                              }`}
                            >
                              <div className="group  relative flex items-center px-5 py-4">
                                <a className="-m-1  flex-1 p-1">
                                  {role === Roles.candidate && (
                                    <div className="relative flex min-w-0 flex-1 items-center">
                                      <span className="absolute right-0 -top-4 text-xs mt-1">
                                        {moment
                                          .unix(item.sentAt)
                                          .local()
                                          .fromNow()}
                                      </span>
                                      <span className="relative inline-block flex-shrink-0">
                                        {!item.job.company.logo && (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-10 h-10 text-sky-400"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
                                            />
                                          </svg>
                                        )}
                                        {item.job.company.logo && (
                                          <img
                                            className="h-10 w-10 mix-blend-darken "
                                            src={item.job.company.logo}
                                            alt=""
                                          />
                                        )}

                                        <span
                                          className={classNames(
                                            item.status === "online"
                                              ? "bg-green-400"
                                              : "bg-gray-300",
                                            "absolute top-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white"
                                          )}
                                          aria-hidden="true"
                                        />
                                      </span>
                                      <div className="ml-4 truncate">
                                        <p className="truncate text-sm font-medium text-gray-900">
                                          {item.job.jobName}
                                        </p>
                                        <p className="truncate text-xs text-gray-500">
                                          {item.user.roles.includes("CANDIDATE")
                                            ? item.job.company.name
                                            : item.application.candidate
                                                .lastName}
                                        </p>
                                      </div>
                                    </div>
                                  )}

                                  {role === Roles.company && (
                                    <div className="relative flex min-w-0 flex-1 items-center">
                                      <span className="absolute right-0 -top-4 text-xs mt-1">
                                        {moment
                                          .unix(item.sentAt)
                                          .local()
                                          .fromNow()}
                                      </span>
                                      <span className="relative inline-block flex-shrink-0">
                                        {!item.application?.candidate
                                          .candidateProfile.profile.image && (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-10 h-10 text-sky-400"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
                                            />
                                          </svg>
                                        )}
                                        {item.application?.candidate
                                          .candidateProfile.profile.image && (
                                          <img
                                            className="h-10 w-10 mix-blend-darken "
                                            src={
                                              item.application.candidate
                                                .candidateProfile.profile.image
                                            }
                                            alt=""
                                          />
                                        )}

                                        <span
                                          className={classNames(
                                            item.status === "online"
                                              ? "bg-green-400"
                                              : "bg-gray-300",
                                            "absolute top-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white"
                                          )}
                                          aria-hidden="true"
                                        />
                                      </span>
                                      <div className="ml-4 truncate">
                                        <p className="truncate text-sm font-medium text-gray-900">
                                          {item.job?.jobName}
                                        </p>
                                        <p className="truncate text-xs text-gray-500">
                                          {item.user.roles.includes(
                                            "COMPANY"
                                          ) &&
                                            item.application?.candidate
                                              .lastName}
                                        </p>
                                      </div>
                                    </div>
                                  )}

                                  <div className="mt-3 w-full">
                                    <span
                                      className={`inline-flex w-full justify-center font-semibold rounded-sm ${
                                        [
                                          "INVITATION_APPLY",
                                          Notification.INVITE,
                                        ].includes(item.message)
                                          ? "bg-sky-400 text-white"
                                          : [
                                              Notification.REJECTED,
                                              Notification.NOT_RECONFIRMED,
                                            ].includes(item.message)
                                          ? "bg-red-500 text-white"
                                          : [
                                              Notification.NEW_APPLICATION,
                                            ].includes(item.message)
                                          ? "bg-sky-700 text-white animate-pulse"
                                          : [
                                              Notification.RATE_EMPLOYER,
                                            ].includes(item.message)
                                          ? "bg-yellow-100 text-yellow-700"
                                          : "bg-green-100 text-green-700"
                                      }  px-2 py-1 text-xs font-medium `}
                                    >
                                      {Notification.TYPE(item.message)}
                                    </span>
                                  </div>
                                  {role === Roles.candidate &&
                                    [Notification.NEED_RECONF].includes(
                                      item.message
                                    ) && (
                                      <div className="inline-flex py-2 space-x-4 justify-center  items-center w-full">
                                        <button
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            dispatch(
                                              updateNotification({
                                                id: item.id,
                                                params: { answered: true },
                                              })
                                            );
                                            dispatch({
                                              type: "conversation/setUserToSend",
                                              payload: item.job.company.userId,
                                            });
                                            dispatch(
                                              updateApplication({
                                                id: item.application.id,
                                                params: {
                                                  acceptedConfirmation: false,
                                                },
                                              })
                                            );
                                          }}
                                          type="button"
                                          className="text-red-400  border hover:bg-red-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-4 h-4"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              d="M6 18L18 6M6 6l12 12"
                                            />
                                          </svg>

                                          <span className="sr-only">
                                            Icon description
                                          </span>
                                        </button>
                                        <button
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            dispatch(
                                              updateNotification({
                                                id: item.id,
                                                params: { answered: true },
                                              })
                                            );
                                            dispatch({
                                              type: "conversation/setUserToSend",
                                              payload: item.job.company.userId,
                                            });
                                            dispatch(
                                              updateApplication({
                                                id: item.application.id,
                                                params: {
                                                  acceptedConfirmation: true,
                                                },
                                              })
                                            );
                                          }}
                                          type="button"
                                          className="text-sky-400 border shadow-slate-200  hover:bg-sky-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500"
                                        >
                                          <svg
                                            className="w-4 h-4"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="currentColor"
                                            viewBox="0 0 18 18"
                                          >
                                            <path d="M3 7H1a1 1 0 0 0-1 1v8a2 2 0 0 0 4 0V8a1 1 0 0 0-1-1Zm12.954 0H12l1.558-4.5a1.778 1.778 0 0 0-3.331-1.06A24.859 24.859 0 0 1 6 6.8v9.586h.114C8.223 16.969 11.015 18 13.6 18c1.4 0 1.592-.526 1.88-1.317l2.354-7A2 2 0 0 0 15.954 7Z" />
                                          </svg>
                                          <span className="sr-only">
                                            Icon description
                                          </span>
                                        </button>
                                      </div>
                                    )}

                                  {role === Roles.candidate &&
                                    [Notification.RATE_EMPLOYER].includes(
                                      item.message
                                    ) && (
                                      <div
                                        onClick={(e) => e.stopPropagation()}
                                        className="inline-flex w-full items-center space-x-3 justify-center"
                                      >
                                        <ReactStars
                                          count={5}
                                          size={30}
                                          value={stars}
                                          onChange={setStars}
                                          activeColor="#f59e0b"
                                          classNames={"my-3"}
                                        />
                                        {item.application.shifts !== null &&
                                          item.application.shifts.length > 0 &&
                                          item.application.shifts[0] && (
                                            <div className=" my-3 inline-flex ">
                                              <span
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  dispatch(
                                                    updateNotification({
                                                      id: item.id,
                                                      params: {
                                                        answered: true,
                                                      },
                                                    })
                                                  );

                                                  confirmRating(
                                                    item.application.shifts[0]
                                                      .id
                                                  );
                                                }}
                                                className="text-sky-500 font-semibold hover:text-sky-300 active:text-sky-700"
                                              >
                                                Send
                                              </span>
                                            </div>
                                          )}
                                      </div>
                                    )}
                                  {[
                                    "INVITATION_APPLY",
                                    Notification.INVITE,
                                  ].includes(item.message) && (
                                    <div className="inline-flex py-2 space-x-4 justify-center  items-center w-full">
                                      <Link
                                        to={`/jobs/${item.job.id}`}
                                        onClick={() =>
                                          dispatch({
                                            type: "conversation/setNotifOpen",
                                          })
                                        }
                                        type="button"
                                        className="text-gray-500  border hover:bg-sky-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-sky-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth={1.5}
                                          stroke="currentColor"
                                          className="w-4 h-4"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                          />
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                          />
                                        </svg>

                                        <span className="sr-only">
                                          Icon description
                                        </span>
                                      </Link>
                                    </div>
                                  )}
                                </a>
                                <Menu
                                  as="div"
                                  className="relative ml-1 sm:ml-2 inline-block flex-shrink-0 text-left"
                                >
                                  <Menu.Button className="group relative inline-flex h-8 w-8 items-center justify-center rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">
                                    <span className="absolute -inset-1.5" />
                                    <span className="sr-only">
                                      Open options menu
                                    </span>
                                    <span className="flex h-full w-full items-center justify-center rounded-full">
                                      <EllipsisVerticalIcon
                                        className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </Menu.Button>
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items className="absolute right-9 top-0 z-10 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                      <div className="py-1">
                                        <Menu.Item>
                                          {({ active }) => (
                                            <Link
                                              to={`/jobs/${item.job.id}`}
                                              onClick={() =>
                                                dispatch({
                                                  type: "conversation/setNotifOpen",
                                                })
                                              }
                                              className={classNames(
                                                active
                                                  ? "bg-gray-100 text-gray-900"
                                                  : "text-gray-700",
                                                "block px-4 py-2 text-sm"
                                              )}
                                            >
                                              Job details
                                            </Link>
                                          )}
                                        </Menu.Item>
                                        <Menu.Item>
                                          {({ active }) =>
                                            role === Roles.candidate ? (
                                              <Link
                                                to={[
                                                  "/company",
                                                  item.job.company.name,
                                                ].join("/")}
                                                onClick={() => {
                                                  dispatch(
                                                    fetchOneCompany(
                                                      item.job.company.id
                                                    )
                                                  );

                                                  dispatch({
                                                    type: "conversation/setNotifOpen",
                                                  });
                                                }}
                                                className={classNames(
                                                  active
                                                    ? "bg-gray-100 text-gray-900"
                                                    : "text-gray-700",
                                                  "block px-4 py-2 text-sm"
                                                )}
                                              >
                                                View profile
                                              </Link>
                                            ) : (
                                              <Link
                                                onClick={() => {
                                                  dispatch({
                                                    type: "ApplicationSlice/setCandidateLaunch",
                                                    payload:
                                                      item.application.candidate
                                                        .id,
                                                  });
                                                  dispatch({
                                                    type: "conversation/setNotifOpen",
                                                  });
                                                }}
                                                className={classNames(
                                                  active
                                                    ? "bg-gray-100 text-gray-900"
                                                    : "text-gray-700",
                                                  "block px-4 py-2 text-sm"
                                                )}
                                              >
                                                View profile
                                              </Link>
                                            )
                                          }
                                        </Menu.Item>
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              </div>
                            </li>
                          ))}
                    </ul>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
