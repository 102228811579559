import { useCallback, useEffect, useState } from "react";
import { Companies } from "../services/companies";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCompaniesCandidate, setLimit } from "../redux/company";
import useCurrentBreakpoint from "./tailwindBreakPoint";

export default function useCompanies() {
  const dispatch = useDispatch();
  const { limit, page } = useSelector((state) => state.companySlice);
  const brk = useCurrentBreakpoint();

  const fetchData = useCallback(() => {
    dispatch(fetchAllCompaniesCandidate());
  }, []);

  console.log(brk);

  useEffect(() => {
    if (["md", "sm"].includes(brk)) {
      dispatch(setLimit(1));
    }
    if ([ "lg", "xl"].includes(brk)) {
      dispatch(setLimit(3));
    }
  }, [brk]);

  useEffect(() => {
    fetchData();
    if (["md", "sm"].includes(brk)) {
      dispatch(setLimit(1));
    }
  }, [fetchData, page, limit]);
}
