import { ApiClient } from "./api";

export class Shift {
  constructor() {
    this.apiClient = new ApiClient();
  }

  async getShift(page, limit, search, filter) {
    try {
      const { data } = await this.apiClient.get(
        `shift?page=${page}&limit=${limit}&search=${search}&filter=${filter}`
      );

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async getOneShift(id) {
    try {
      const { data } = await this.apiClient.get(`shift/${id}`);

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async getTableShift(date, page, limit) {
    try {
      const { data } = await this.apiClient.get(
        `shift/finance/btw?date_s=${date}&page=${page}&limit=${limit}`
      );

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async getTableShiftCandidate(date, page, limit) {
    try {
      const { data } = await this.apiClient.get(
        `shift/finance/candidate/btw?date_s=${date}&page=${page}&limit=${limit}`
      );

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async financeStats() {
    try {
      const { data } = await this.apiClient.get(`shift/finance/stats`);

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async updateShift(id, params = {}) {
    try {
      const { data } = await this.apiClient.put(`shift/${id}`, params);

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async updateStarRating(id, params = {}) {
    try {
      const { data } = await this.apiClient.put(`starrating/${id}`, params);

      return data;
    } catch (error) {
      console.error(error);
    }
  }
}
