import React from "react";
import { useSelector } from "react-redux";
import { useUpdateProfile } from "../../../hook/profile";

function Notifications() {
  const { candidate, onLoad } = useSelector((state) => state.candidateSlice);
  const data = JSON.parse(JSON.stringify(candidate));
  const {
    onUpdate,
    isChanged,
    saveCandidate,
    temp,
    saveUserCandidate,
    isChangedDocument,
    isChangedNotification,
    saveNotificationUser,
  } = useUpdateProfile(data, data);

  function handleSubmit(e) {
    e.preventDefault();

    saveNotificationUser();
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="sm:overflow-hidden sm:rounded-md">
          <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
            <div>
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Notifications
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Gimme your preferences here.
              </p>
            </div>
            {candidate && candidate.user.notification && (
              <fieldset>
                <legend className="text-sm font-semibold leading-6 text-gray-900">
                  Notify me when :
                </legend>
                <div className="mt-4 space-y-4">
                  <div className="flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        id="candidate.user.notification.notifyWhen.jobs"
                        name="user.notification.notifyWhen.jobs"
                        onChange={onUpdate}
                        type="checkbox"
                        defaultChecked={
                          candidate.user.notification.notifyWhen.jobs
                        }
                        className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-500"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor="comments"
                        className="font-medium text-gray-900"
                      >
                        Jobs
                      </label>
                      <p className="text-gray-500">
                        Get notified when companies post jobs.
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="flex items-start">
                      <div className="flex h-6 items-center">
                        <input
                          id="candidate.user.notification.notifyWhen.invitations"
                          name="user.notification.notifyWhen.invitations"
                          defaultChecked={
                            candidate.user.notification.notifyWhen.invitations
                          }
                          onChange={onUpdate}
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-500"
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6">
                        <label
                          htmlFor="candidate.user.notification.notifyWhen.invitations"
                          className="font-medium text-gray-900"
                        >
                          Invitations
                        </label>
                        <p className="text-gray-500">
                          Get notified when a company invite you.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="flex items-start">
                      <div className="flex h-6 items-center">
                        <input
                          id="candidate.user.notification.notifyWhen.offers"
                          name="user.notification.notifyWhen.offers"
                          type="checkbox"
                          onChange={onUpdate}
                          defaultChecked={
                            candidate.user.notification.notifyWhen.offers
                          }
                          className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-500"
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6">
                        <label
                          htmlFor="offers"
                          className="font-medium text-gray-900"
                        >
                          Offers
                        </label>
                        <p className="text-gray-500">
                          Get notified when a company accepts or rejects an
                          application.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            )}

            <hr />
            <fieldset className="mt-6">
              <legend className="text-sm font-semibold leading-6 text-gray-900">
                Push Notifications
              </legend>
              <p className="text-sm text-gray-500">
                These are delivered via phone.
              </p>
              <div className="mt-4 space-y-4">
                <div className="flex items-center">
                  <input
                    id="push-everything"
                    name="user.notification.push"
                    onChange={onUpdate}
                    type="radio"
                    value={true}
                    defaultChecked={candidate.user.notification.push}
                    className="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-500"
                  />
                  <label htmlFor="push-everything" className="ml-3">
                    <span className="block text-sm font-medium leading-6 text-gray-900">
                      Everything enabled
                    </span>
                  </label>
                </div>

                <div className="flex items-center">
                  <input
                    id="push-nothing"
                    name="user.notification.push"
                    onChange={onUpdate}
                    type="radio"
                    value={false}
                    defaultChecked={!candidate.user.notification.push}
                    className="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-500"
                  />
                  <label htmlFor="push-nothing" className="ml-3">
                    <span className="block text-sm font-medium leading-6 text-gray-900">
                      No push notifications
                    </span>
                  </label>
                </div>
              </div>
            </fieldset>
          </div>
          <div className="px-4 py-3 text-right sm:px-6">
            <button
              type="submit"
              disabled={isChangedNotification()}
              className="inline-flex disabled:bg-slate-400 justify-center rounded-md bg-sky-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Notifications;
