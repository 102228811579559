import clsx from "clsx";

export function Button({ invert = false, className, children, ...props }) {
  className = clsx(
    className,
    "inline-flex rounded-full px-4 py-1.5 text-sm font-semibold transition bg-sky-500 text-white hover:bg-sky-600 transition "
  );

  let inner = <span className="relative top-px">{children}</span>;

  if (typeof props.href === "undefined") {
    return (
      <button className={className} {...props}>
        {inner}
      </button>
    );
  }

  return (
    <a className={className} {...props}>
      {inner}
    </a>
  );
}
