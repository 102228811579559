import React from "react";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import NotFound404 from "../../components/not-found-page/404-not-found";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Messages from "../../components/Messages";
import Dashboard from "./Dashboard";
import Jobs from "./Jobs";
import CandidatePage from "./Candidate.js";
import Shift from "./Shift";
import Profile from "./Profile";
import JobForm from "./Jobs/create";
import useCompany from "../../hook/company";
import Details from "./Jobs/details";
import JobFormRepost from "./Jobs/repost";
import { useSelector } from "react-redux";
import Roles from "../../Enum/UserRoles.js";

function Router() {
  return <Outlet />;
}

function Company() {
  let location = useLocation();
  const { company, onLoad } = useCompany(true);
  const { role, id, member_name, role_member } = useSelector(
    (state) => state.authenticationSlice
  );
  return (
    <TransitionGroup>
      <CSSTransition key={location.key} timeout={500} classNames="page">
        <Routes>
          <Route exact static path="/" element={<Router />}>
            <Route index element={<Dashboard />} />
            <Route path="/jobs" element={company && <Jobs />} />
            {(role_member === null || role_member === Roles.manager) && (
              <Route path="/job/create" element={<JobForm />} />
            )}
            {(role_member === null || role_member === Roles.manager) && (
              <Route path="/job/create/:id" element={<JobFormRepost />} />
            )}

            <Route path="/jobs/:id" element={<Details />} />
            <Route path="/shift" element={<Shift />} />
            <Route path="/availability" />
            <Route path="/finance" />
            {(role_member === null || role_member === Roles.manager) && (
              <Route path="/profile" element={<Profile />} />
            )}
            <Route path="/messages" element={<Messages />} />
            <Route path="/candidate" element={<CandidatePage />} />
            <Route path="*" element={<Dashboard />} />
          </Route>
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default Company;
