import { ApiClient } from "./api";

export class Jobs {
  constructor() {
    this.apiClient = new ApiClient();
  }

  async postJob(job) {
    try {
      const { data } = await this.apiClient.post(`job`, job);

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async getJobs(filter = {}) {
    try {
      const { data } = await this.apiClient.get(
        `job/${JSON.stringify(filter)}`
      );

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async getOneJob(id) {
    try {
      const { data } = await this.apiClient.get(`job/one/${id}`);

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async getCompanyJobs(page, limit, search) {
    try {
      const { data } = await this.apiClient.get(
        `job/published/company?page=${page}&limit=${limit}&search=${search}`
      );

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async postApplication(jobId, note) {
    try {
      const { data } = await this.apiClient.post(`application`, {
        jobId: jobId,
        note: note,
      });

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async updateApplication(id, params = {}) {
    try {
      const { data } = await this.apiClient.put(`application/${id}`, params);

      return data;
    } catch (error) {
      console.error(error);
    }
  }
}
