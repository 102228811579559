import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/20/solid";
import Stars from "../../../components/rating/Stars";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCandidate } from "../../../redux/candidate";
import { baseUrl } from "../../../utils";
import Map from "./Map";

export default function Candidate() {
  const { candidates } = useSelector((state) => state.candidateSlice);
  const dispatch = useDispatch();
  const fetchAllCandidates = useCallback(() => {
    dispatch(fetchAllCandidate());
  }, [dispatch]);

  useEffect(() => {
    fetchAllCandidates();
  }, [fetchAllCandidates]);

  return (
    <div className="h-96 w-full ">
      <Map home />
    </div>
  );
}
